import React from 'react'
import { Carousel, CarouselImage, Dialog } from '@ftdr/blueprint-components-react'
import { appliancepb } from '../../../services/protobuf-models/appliance-ms-protobuf-models'
import { useSelectedSlideContext } from 'src/hooks/use-slide-context'
import './reset.css'
import { v4 } from 'uuid'

interface IGalleryDialog {
  isOpen: boolean
  onClose: () => void
  product: appliancepb.Product

}

const GalleryDialog: React.FC<IGalleryDialog> = ({
  isOpen,
  onClose,
  product,
}) => {
  const { selectedSlide: currentSlide, setSelectedSlide: setCurrentSlide } = useSelectedSlideContext()
  const length = product.images.length
  return (
    <Dialog
      id="queue-dialog"
      open={isOpen}
      onClose={onClose}
      modal={false}
      closeOnOutsideClick={true}
      showBackdrop={true}
      className="w-full overflow-auto max-h-full max"
      usePortal={true}
      overlayClassName="overlay-dialog-center-items"

    >
      <Carousel
        showArrows={length > 1}
        className="w-full"
        loop={length > 1}
        showIndicators={false}
        selectedSlide={currentSlide}
        onChange={(i) => setCurrentSlide(i)}
      >{product.images.map((v) => (<CarouselImage
        key={v4()}
        src={v.url as string}
        alt={v.imageName as string}
        className="object-contain p-1 h-full "
      />
      ))}

      </Carousel>
    </Dialog>
  )
}

export default GalleryDialog