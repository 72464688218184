import { filtersInitialState, FiltersStoreState } from './filters-store'
import {
  replacementInitialState,
  ReplacementStoreState,
} from './replacement-store'
import { AdminInitialState } from './admin-store'
import { SurveyStoreState } from './survey-store/survey-store.types'
import { AdminStoreState } from './admin-store/admin-store.types'
import { surveyInitialState } from './survey-store'
import { SelectedModelStoreState } from './selected-model-store/selected-model-store.types'
import { selectedModelInitialState } from './selected-model-store'
import { ProductStoreState } from './compare-store'
import { ProductInitialState } from './compare-store'
import { StatusesStoreState } from './statuses-store/statuses.store.types'
import { statusesInitialState } from './statuses-store'
import {
  replacementSurveyInitialState,
  ReplacementSurveyStoreState,
} from './replacemnt-survey-store'

export interface AppState {
  admin: AdminStoreState
  replacement: ReplacementStoreState
  filters: FiltersStoreState
  survey: SurveyStoreState
  selectedModel: SelectedModelStoreState
  compareProducts: ProductStoreState
  statuses: StatusesStoreState
  replacementSurvey: ReplacementSurveyStoreState
}

export const initialState: AppState = {
  admin: AdminInitialState,
  replacement: replacementInitialState,
  filters: filtersInitialState,
  survey: surveyInitialState,
  selectedModel: selectedModelInitialState,
  compareProducts: ProductInitialState,
  statuses: statusesInitialState,
  replacementSurvey: replacementSurveyInitialState,
}
