import React, { useMemo } from 'react'
import { TableColumn, Table } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from '../custom-fdr-components'
import { dataDetailsInfoModal as data } from './data'

const MoreDetailsInfoTable: React.FC = () => {
  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_NOTE_MODAL_HEADER_MESSAGE"
          />
        ),
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },

      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_NOTE_MODAL_HEADER_DESCRIPTION"
          />
        ),
        accessor: 'description', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
    ],
    []
  )
  return (
    <div className="pb-8 relative z-10">
      <Table
        data={data}
        columns={columns}
        striped="gray"
        variant="heavy"
        paginate={false}
        sortable={false}
        hover="gray"
      />
    </div>
  )
}

export default MoreDetailsInfoTable
