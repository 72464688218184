import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from 'src/components/custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { formatCentsCurrency } from '../../utils/internationalization-helper'
import { selectModel } from '../../store/selected-model-store'
import { useHistory } from 'react-router-dom'

interface ModelCardProps {
  model: appliancepb.IProduct
  id: string
}

export const ModelCard = ({ model, id }: ModelCardProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const dispatch = useDispatch()
  const history = useHistory()

  const firstImage = model.images && model.images[0]
  const { unit, height, width, depth } = model?.dimensions || {}
  const parsedUnit = unit === 'in' ? 'inches' : unit || ''

  const selectModelAndNavigate = (selectedModel: appliancepb.IProduct) => {
    dispatch(selectModel(selectedModel))
    history.push('/browse')
  }

  return (
    <div id="model-card">
      <div className="flex">
        <figure className="h-20 w-20 lg:h-48 lg:w-48 flex-shrink-0 mr-4 lg:mr-8">
          <img
            id="model-card-image"
            src={firstImage?.url || '/noimage.jpg'}
            alt={firstImage?.imageName || 'Product Image'}
          />
        </figure>
        <div id={`${id}-model-card-description`} className="relative">
          <Text
            id={`${id}-card-description`}
            variant="heading-06"
            className="mb-2"
          >
            {model.description}
          </Text>
          <Text id={`${id}-model-card-size`}>
            <strong id={`${id}-card-unit`}>
              {localizedText('PRODUCT_DETAILS_DIMENSIONS', {
                unit: parsedUnit,
              })}
            </strong>
            <span id={`${id}-card-sizes`} className="ml-2">
              {height}h x {width}w x {depth}d
            </span>
          </Text>
          <Text id={`${id}-model-card-base-price`} variant="heading-06">
            {formatCentsCurrency(model.basePrice || 0)}
          </Text>
          <Button
            id={`${id}-model-card-button`}
            label="Use as model"
            className="mt-4 lg:absolute bottom-0"
            onClick={() => selectModelAndNavigate(model)}
          />
        </div>
      </div>
    </div>
  )
}
