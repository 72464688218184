import React, { useCallback, useMemo, useState } from 'react'
import { Button, IconButton, IconEdit, TableColumn, useAppContext } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import {
  formatBilingStateCode,
  formatCentsCurrency,
  formatDeliverDate,
  formatTimestampToDate,
} from 'src/utils/internationalization-helper'
import Icon from 'src/screens/outright-payment-temp/shopping-cart/icon'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { usePaymentModalContext } from 'src/hooks/use-payment-modal-context'

export const usePaymentColumns = () => {
  const {
    setCartInfoModal,
    setCartInfoModalOpen,
    setRecordExternalOrderModal,
    setRecordExternalOrderModalOpen,
  } = usePaymentModalContext()

  const openDialogCart = useCallback(
    (cart: appliancepb.ICart[]) => {
      setCartInfoModal(cart)
      setCartInfoModalOpen(true)
    },
    //eslint-disable-next-line
    [],
  )

  const openActionDialog = useCallback(
    (data: appliancepb.IOutrightPaymentRecord) => {
      setRecordExternalOrderModal(data)
      setRecordExternalOrderModalOpen(true)
    },
    //eslint-disable-next-line
    [],
  )

  const {
    appSettings: { localizedText },
  } = useAppContext()

  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_ID')}
          </Text>
        ),
        accessor: 'id', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_STATUS')}
          </Text>
        ),
        accessor: 'status', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_COMPANY_CODE')}
          </Text>
        ),
        accessor: 'companyCode', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <Text variant='caption'>{value.toUpperCase()}</Text>
        ),
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('CONTRACT_ID_LABEL')}
          </Text>
        ),
        accessor: 'contractId', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant='caption'>{value}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_PAYMENT_TYPE')}
          </Text>
        ),
        accessor: 'paymentType', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant='caption'>{value}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_PAYMENT_ID')}
          </Text>
        ),
        accessor: 'paymentId', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_AMOUNT')}
          </Text>
        ),
        accessor: 'amount', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text>{formatCentsCurrency(value)}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_BILLING_STATE')}
          </Text>
        ),
        accessor: 'billingState', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <Text variant='caption'>{formatBilingStateCode(value)}</Text>
        ),
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_ORDER_DATE')}
          </Text>
        ),
        accessor: 'orderDate', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <Text>{formatDeliverDate(value.seconds)}</Text>
        ),
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_ORDER_NUMBER')}
          </Text>
        ),
        accessor: 'orderNumber', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => {
          const [isHovered, setIsHovered] = useState(false)
          return value.orderNumber ? (
            <Text>{value.orderNumber}</Text>
          ) : (
            <div className='relative inline-block'>
              <Button
                mainIcon={
                  <IconEdit
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  />
                }
                onClick={() => openActionDialog(value)}
                color='primary'
                label=''
                variant='ghost'
                size='small'
              />
              {isHovered && (
                <div
                  className='absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max bg-gray-700 text-white text-sm rounded px-2 py-1'>
                  Edit
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_VENDOR')}
          </Text>
        ),
        accessor: 'vendor', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_CART')}
          </Text>
        ),
        accessor: 'cart', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <IconButton
            icon={
              <div style={{ width: 24 }}>
                <Icon color='currentcolor' />
              </div>
            }
            className={
              !value?.cartItems || value?.cartItems?.length === 0
                ? 'opacity-30'
                : ''
            }
            onClick={() => openDialogCart(value)}
            disabled={!value?.cartItems || value?.cartItems?.length === 0}
            color='primary'
            label=''
            variant='ghost'
            size='small'
          />
        ),
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_MODEL_NUMBER')}
          </Text>
        ),
        accessor: 'modelNumber', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <>
            {value?.cart?.cartItems && value.cart.cartItems.length > 0 ? (
              <Text></Text>
            ) : (
              <Text>{value.modelNumber.toUpperCase()}</Text>
            )}
          </>
        ),
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_CREATED_DATE')}
          </Text>
        ),
        accessor: 'created', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <Text variant='caption'>{formatTimestampToDate(value)}</Text>
        ),
      },
      {
        Header: (
          <Text variant='heading-06'>
            {localizedText('PAYMENT_TABLE_HEAD_AGENT')}
          </Text>
        ),
        accessor: 'agent', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text>{value?.uniqueName}</Text>,
      },
    ],
    // eslint-disable-next-line
    [],
  )
  return { columns }
}
