import yup, { valueSelected } from '../../utils/yup'

export const confirmDialogSchema = yup
  .object()

  .when((values, schema) => {
    if (values.isRequired) {
      return schema.shape({
        reason: valueSelected(yup),
      })
    } else {
      return schema.shape({
        reason: valueSelected(yup, false),
      })
    }
  })
