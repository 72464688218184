import React, { createContext, useContext, useState, Dispatch } from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

type Props = {
  statusModalOpen: boolean
  setStatusModalOpen: (isOpen: boolean) => void

  infoModalOpen: boolean
  setInfoModalOpen: (isOpen: boolean) => void

  CILModalOpen: boolean
  setCILModalOpen: (isOpen: boolean) => void

  detailsInfoModalOpen: boolean
  setDetailsInfoModalOpen: (isOpen: boolean) => void

  dataInfoModal: appliancepb.INote[]
  setDataInfoModal: (data: appliancepb.INote[]) => void

  dataCILModal: appliancepb.IPriceInformation[]
  setDataCILModal: (data: appliancepb.IPriceInformation[]) => void

  selectedQueueItem: appliancepb.IFullReplacementDetails | null
  setSelectQueueItem: Dispatch<appliancepb.IFullReplacementDetails | null>

  orderDetailsModalOpen: boolean
  setOrderDetailModalOpen: Dispatch<boolean>

  currentReplacementId: string
  setCurrentReplacementId: Dispatch<string>

  noteIsSending: boolean
  setNoteIsSending: Dispatch<boolean>

  noteSendingError: string | null
  setNoteSendingError: Dispatch<string | null>

  isNotesTableRefreshing: boolean
  setIsNotesTableRefreshing: Dispatch<boolean>

  notesTableError: string | null
  setNotesTableError: Dispatch<string | null>
}
type TQueueModalContext = {
  children?: React.ReactNode
}
const QueueModalContext = createContext<Props>({} as Props)

const QueueModalProvider: React.FC<TQueueModalContext> = ({ children }) => {
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false)
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false)
  const [CILModalOpen, setCILModalOpen] = useState<boolean>(false)
  const [detailsInfoModalOpen, setDetailsInfoModalOpen] = useState<boolean>(
    false
  )
  const [dataCILModal, setDataCILModal] = useState<
    appliancepb.IPriceInformation[]
  >([])
  const [dataInfoModal, setDataInfoModal] = useState<appliancepb.INote[]>([])
  const [orderDetailsModalOpen, setOrderDetailModalOpen] = useState<boolean>(
    false
  )
  const [
    selectedQueueItem,
    setSelectQueueItem,
  ] = useState<appliancepb.IFullReplacementDetails | null>(null)

  const [currentReplacementId, setCurrentReplacementId] = useState<string>('')
  const [noteIsSending, setNoteIsSending] = useState<boolean>(false)
  const [noteSendingError, setNoteSendingError] = useState<string | null>(null)
  const [isNotesTableRefreshing, setIsNotesTableRefreshing] = useState<boolean>(
    false
  )
  const [notesTableError, setNotesTableError] = useState<string | null>(null)

  return (
    <QueueModalContext.Provider
      value={{
        // Status Modal

        statusModalOpen,
        setStatusModalOpen,

        // Info Modal

        infoModalOpen,
        setInfoModalOpen,

        // Details Info Modal

        detailsInfoModalOpen,
        setDetailsInfoModalOpen,

        // Data Info Modal
        dataInfoModal,
        setDataInfoModal,

        // CIL Modal
        CILModalOpen,
        setCILModalOpen,

        // Cash in Lieu Details Modal
        dataCILModal,
        setDataCILModal,

        // Order Details Modal

        selectedQueueItem,
        setSelectQueueItem,

        orderDetailsModalOpen,
        setOrderDetailModalOpen,

        currentReplacementId,
        setCurrentReplacementId,

        noteIsSending,
        setNoteIsSending,

        noteSendingError,
        setNoteSendingError,

        isNotesTableRefreshing,
        setIsNotesTableRefreshing,

        notesTableError,
        setNotesTableError,
      }}
    >
      {children}
    </QueueModalContext.Provider>
  )
}

const useQueueModalContext = () => useContext(QueueModalContext)

export { QueueModalProvider, useQueueModalContext }
