import React, { useEffect, useState } from 'react'
import { Image, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ProgressIndicatorComponent as ProgressIndicator,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { useReplacement } from '../../hooks/use-replacement'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { api } from '../../utils/api'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { formatCentsCurrency, formatDeliverDate } from '../../utils/internationalization-helper'
import { addReplacement } from 'src/store/compare-store'
import { createShortWarrantyMessage } from 'src/utils/helper'

type NullableProduct = appliancepb.Product | null

interface ConfirmationPageParams {
  replacementId: string
  productId: string
}

export const ConfirmationPage = () => {
  const [isGiftCard, setIsGiftCard] = useState<boolean>(false)

  const {
    appSettings: { localizedText },
  } = useAppContext()
  const dispatch = useDispatch()
  const { replacementId, productId } = useParams<ConfirmationPageParams>()
  const { replacement } = useReplacement(replacementId)
  const [product, setProduct] = useState<NullableProduct>(null)
  const parsedUnit =
    product?.dimensions?.unit === 'in'
      ? 'inches'
      : product?.dimensions?.unit || ''

  const addressId = replacement?.customer?.address?.ID || ''

  const measure = `${product?.dimensions?.height}h x ${product?.dimensions?.width}w x ${product?.dimensions?.depth}d`
  const cil = replacement?.replacementDetails?.cashInLieu
  const isOver3k = product && product.customerPrice > 3000 * 100 // price is in cents

  useEffect(() => {
    const getProduct = async () => {
      const product = await api.getReplacementProduct(
        replacementId,
        productId,
        addressId,
        replacement?.replacementDetails?.partRequestID || '',
      )
      setProduct(product)
    }
    getProduct()
  }, [replacementId, productId, addressId, replacement])

  useEffect(() => {
    setIsGiftCard(/^BH-/.test(productId))
  }, [productId])
  useEffect(() => {
    dispatch(addReplacement(replacementId))
    //eslint-disable-next-line
  }, [])

  if (isGiftCard) {
    return (
      <section className='-mt-8 lg:-mt-0'>
        {product ? (
          <article className='grid gap-8'>
            <div>
              <p
                id='confirmation-page-c-name'
                className='normal-case text-heading-04 lg:text-heading-03 text-primary'
              >
                {localizedText('CONFIRMATION_THANK_YOU', {
                  name: product?.description || '',
                })}
              </p>
              <p className='normal-case text-heading-04 lg:text-heading-03 text-primary'>
                {localizedText('CONFIRMATION_E_CARD_TITLE')}
              </p>
            </div>
            <div>
              <div className='flex flex-col md:flex-row'>
                <figure
                  className='md:mr-4 mb-4 md:mb-0 md:w-48'
                  id='confirmation-page-p-image-1'
                >
                  <Image
                    src={product.images[0]?.url || '/noimage.jpg'}
                    alt='Product Image'
                  />
                </figure>
                <div className='flex-grow-0'>
                  <Text
                    id='confirmation-page-p-description-1-m'
                    variant='heading-06'
                    className='lg:hidden'
                  >
                    {product.description}
                  </Text>
                  <Text
                    id='confirmation-page-p-description-1-d'
                    variant='heading-04'
                    className='hidden lg:block'
                  >
                    {product.description}
                  </Text>
                  <Text
                    id='confirmation-page-p-description-cil-1'
                    variant='heading-04'
                    className='mb-4'
                    textTemplateKey='CONFIRMATION_E_CARD_VALUE'
                    textTemplateData={{
                      cil: formatCentsCurrency(cil),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='p-4 bg-pale-50'>
              <div className='mb-6'>
                <Text
                  className='mb-2 normal-case'
                  variant='heading-06'
                  textTemplateKey='CONFIRMATION_WHAT_NEXT'
                />
                <ul className='list-inside list-disc'>
                  <li>
                    <Text
                      className='inline'
                      textTemplateKey='CONFIRMATION_E_CARD_DELIVERY'
                    />
                  </li>
                  <li>
                    <Text
                      className='inline'
                      textTemplateKey='CONFIRMATION_E_CARD_LOWES_SHOPPING'
                    />
                    <a
                      id='confirmation-page-p-lowes-link'
                      href='https://lowes.com'
                      target='_blank'
                      rel='noreferrer noopener'
                      className='text-interactive'
                    >
                      www.lowes.com.
                    </a>
                  </li>
                  <li>
                    <Text
                      className='inline'
                      textTemplateKey='CONFIRMATION_E_CARD_AMOUNT'
                    />
                  </li>
                </ul>
              </div>
              <div className='mb-6'>
                <Text
                  className='pr-1'
                  dangerouslySetInnerHTML={{
                    __html: localizedText('CONFIRMATION_CONTACT_INFO'),
                  }}
                />
              </div>
            </div>
          </article>
        ) : (
          /* loading */
          <div
            className='mt-8 flex justify-center'
            id='confirmation-page-spinner-wrapper-1'
          >
            <ProgressIndicator size='medium' data-testid='spinner' />
          </div>
        )}
      </section>
    )
  }

  return (
    <section className='-mt-8 lg:-mt-0'>
      {product ? (
        <article className='grid gap-8'>
          <div>
            <p
              id='confirmation-page-c-name-2'
              className='normal-case text-heading-04 lg:text-heading-03 text-primary'
            >
              {localizedText('CONFIRMATION_THANK_YOU', {
                name: product?.description || '',
              })}
            </p>
          </div>
          <div>
            <Text
              variant='heading-04'
              className='mb-4 normal-case'
              textTemplateKey='CONFIRMATION_YOU_ORDERED'
            />
            <div className='flex'>
              <figure
                className='md:mr-4 md:w-48'
                id='confirmation-page-image-2'
              >
                <Image
                  src={product.images[0]?.url || '/noimage.jpg'}
                  alt='Product Image'
                />
              </figure>
              <div className='flex-grow-0'>
                <Text
                  id='confirmation-page-p-description-2-m'
                  variant='heading-06'
                  className='lg:hidden'
                >
                  {product.description}
                </Text>
                <Text
                  id='confirmation-page-p-description-2-d'
                  variant='heading-04'
                  className='hidden lg:block'
                >
                  {product.description}
                </Text>
                <div className='hidden lg:block'>
                  <Text
                    id='confirmation-page-unit-2'
                    textTemplateKey='CONFIRMATION_DIMENSIONS'
                    textTemplateData={{ unit: parsedUnit }}
                  />
                  <Text>{measure}</Text>
                </div>
                <Text
                  id='confirmation-page-p-customer-price-2-m'
                  variant='heading-06'
                  className='lg:hidden'
                >
                  {formatCentsCurrency(product.customerPrice)}
                </Text>
                <Text
                  id='confirmation-page-p-customer-price-2-d'
                  variant='heading-04'
                  className='hidden lg:block mt-6'
                >
                  {formatCentsCurrency(product.customerPrice)}
                </Text>
                <Text
                  id='confirmation-page-over-limit'
                  variant='caption'
                  className='lg:mt-2'
                >
                  {isOver3k
                    ? localizedText('CHECKOUT_WARRANTY_OVER_3K')
                    : createShortWarrantyMessage(
                    product.warrantyCoverageMessage,
                    'warranty',
                  ) || localizedText('CHECKOUT_WARRANTY')}
                </Text>
              </div>
            </div>
          </div>
          <div className='p-4 bg-pale-50'>
            {product.customerPrice > 0 && (
              <div className='flex pb-6 mb-6 border-b-1 border-primary-500'>
                <Text
                  variant='heading-06'
                  className='normal-case'
                  textTemplateKey='CONFIRMATION_TOTAL_CHARGED_TITLE'
                />
                <Text
                  id='confirmation-page-p-customer-price-3'
                  variant='heading-06'
                  className='ml-auto'
                >
                  {formatCentsCurrency(product.customerPrice)}
                </Text>
              </div>
            )}
            <div className='mb-6'>
              <Text className='mb-2' textTemplateKey='CONFIRMATION_WHAT_NEXT' />
              <ul className='list-inside list-disc'>
                <li>
                  <Text
                    className='inline'
                    textTemplateKey='CONFIRMATION_RECEIVE_EMAIL'
                  />
                </li>
                <li>
                  <Text
                    className='inline'
                    textTemplateKey='CONFIRMATION_INSTALLATION_CONTACT'
                  />
                </li>

                {product.deliveryDate ? (
                  <li>
                    <Text
                      id='confirmation-page-p-delivery-date'
                      className='inline'
                      textTemplateKey={'DELIVERY_DATE'}
                      textTemplateData={{
                        deliveryDate: `${formatDeliverDate(
                          product.deliveryDate.seconds,
                        )}`,
                      }}
                    />
                  </li>
                ) : null}
              </ul>
            </div>
            <div className='mb-6 flex'>
              <Text
                className='pr-1'
                dangerouslySetInnerHTML={{
                  __html: localizedText('CONFIRMATION_CONTACT_INFO'),
                }}
              />
            </div>
          </div>
        </article>
      ) : (
        /* loading */
        <div
          className='mt-8 flex justify-center'
          id='confirmation-page-spinner-wrapper-2'
        >
          <ProgressIndicator size='medium' data-testid='spinner' />
        </div>
      )}
    </section>
  )
}
