import { emptyItem } from 'src/components/adminForms/initials'

export const initValues = {
  replacementID: '',
  contractID: '',
  dispatchID: '',
  firstName: '',
  lastName: '',
  billingState: emptyItem,
  vendor: emptyItem,
  sku: '',
  agreements: false,
}
