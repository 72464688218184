import { MenuItem } from 'src/utils/shared-types'
export type TPairs = [string, string]
const arr = [
  ['Move Appliance', 'MOVE'],
  ['Disconnect', 'DISC'],
  ['Accessories', 'ACCS'],
  ['Installation', 'INST'],
  ['Disposal', 'DISP'],
  ['Miscellaneous', 'MISC'],
  ['Delivery', 'DLVR'],
  ['Upgrade', 'ADM'],
  ['Restocking', 'REST'],
].sort((a, b) => a[0].localeCompare(b[0])) as TPairs[]
export interface ISubCategories {
  subcategory: string
  prefix: string
  description: (tenant: string) => string
}
export const subCategories: ISubCategories[] = arr.map((e) => ({
  subcategory: e[0],
  prefix: e[1],
  description: (tenant: string) => `${tenant} Default ${e[0]} Fee`,
}))

export const subCategoriesMenuItems: MenuItem[] = arr.map((e) => ({
  id: e[1],
  value: e[1],
  label: e[0],
}))
