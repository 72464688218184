import {
  SurveyStoreState,
  CONSTANTS,
  SurveyAction,
  TInfo,
} from './survey-store.types'
import { Reducer } from 'redux'
import { AgentSurvey } from '../../screens/agent-survey/agent-survey-types'
import {
  ThunkResult,
  StateAwareThunkDispatch,
  TKitSize,
  TIcemakerAddon,
} from 'src/utils/shared-types'

export const surveyInitialState: SurveyStoreState = {
  agentSurvey: {
    dispatchID: '',
    dispatch: null,
    item: null,
    partRequestId: '',
    dcov: false,
  },
  info: null,
  newProductCategory: '',
  acceptedCategories: [],
  trimKitSize: '',
  icemakerAddon: '',
  // add customerSurvey here if needed
}

export const surveyReducer: Reducer<SurveyStoreState, SurveyAction> = (
  state = surveyInitialState,
  action: SurveyAction
): SurveyStoreState => {
  switch (action.type) {
    case CONSTANTS.SUBMIT_AGENT_SURVEY:
      return { ...state, agentSurvey: action.survey }
    case CONSTANTS.CHANGE_PRODUCT_CATEGORY:
      return { ...state, newProductCategory: action.product }
    case CONSTANTS.CHANGE_TRIM_KIT_SIZE:
      return { ...state, trimKitSize: action.trimKitSize }
    case CONSTANTS.CHANGE_ICEMAKER_ADDON:
      return { ...state, icemakerAddon: action.icemakerAddon }
    case CONSTANTS.CHANGE_ACCEPTED_CATEGORIES:
      return { ...state, acceptedCategories: action.acceptedCategories }
    case CONSTANTS.ADD_INFO:
      return { ...state, info: action.info }
    default:
      return state
  }
}

export const submitAgentSurvey = (
  survey: AgentSurvey.Survey
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  dispatch({
    type: CONSTANTS.SUBMIT_AGENT_SURVEY,
    survey,
  })
}

export const changeProductCategory = (product: string) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.CHANGE_PRODUCT_CATEGORY, product })
}

export const changeTrimKitSize = (trimKitSize: TKitSize) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.CHANGE_TRIM_KIT_SIZE, trimKitSize })
}

export const addInfo = (info: TInfo) => (dispatch: StateAwareThunkDispatch) => {
  dispatch({ type: CONSTANTS.ADD_INFO, info })
}
export const changeIcemakerAddon = (icemakerAddon: TIcemakerAddon) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.CHANGE_ICEMAKER_ADDON, icemakerAddon })
}

export const ChangeAcceptedCategories = (acceptedCategories: string[]) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.CHANGE_ACCEPTED_CATEGORIES, acceptedCategories })
}
