import React from 'react'
import MobileNav from '../MobileNav/mobile-nav'
import { useMenuContext } from '../../hooks/use-menu-context'
import { isHSA } from 'src/utils/tenant-helper'
import CompareMobileButton from '../compare-mobile-button/compare-mobile-button'

const Hamburger: React.FC = () => {
  const { open, toggleMenu, linkToggle } = useMenuContext()

  return (
    <>
      <div className="flex items-center justify-center bg-transparent mx-6 lg:hidden">
        <CompareMobileButton />

        <button
          id="hamburger-open"
          className="text-gray-500 w-5 h-4 relative focus:outline-none bg-transparent"
          onClick={() => {
            toggleMenu(!open)
          }}
        >
          <div className="block w-5 relative h-full w-full transform">
            <span
              id="hamburger-transform-1"
              className={`block  absolute w-full ${
                isHSA() ? 'bg-primary-800' : 'bg-interactive-500'
              } transition duration-500 ease-in-out  left-0`}
              style={{
                top: '0px',
                transform: open ? 'translateY(8px) rotate(45deg)' : '',
                height: '2px',
              }}
            ></span>

            <span
              id="hamburger-transform-2"
              className={`block absolute w-full ${
                isHSA() ? 'bg-primary-800' : 'bg-interactive-500'
              } transform transition duration-500 ease-in-out left-0`}
              style={{
                top: 'calc(50% - 1px)',
                opacity: open ? 0 : 1,
                height: '2px',
              }}
            ></span>

            <span
              id="hamburger-transform-3"
              className={`block absolute w-full ${
                isHSA() ? 'bg-primary-800' : 'bg-interactive-500'
              } transform  transition duration-500 ease-in-out  left-0`}
              style={{
                bottom: '0px',
                transform: open ? 'translateY(-7px) rotate(-45deg)' : '',
                height: '2px',
              }}
            ></span>
          </div>
        </button>
      </div>
      {open && <MobileNav setClose={linkToggle} />}
    </>
  )
}

export default Hamburger
