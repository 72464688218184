import { todayAtMidnight } from 'src/utils/helper'
import yup, { valueSelected } from '../../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { MenuItem } from 'src/utils/shared-types'

const { DEFAULT_ERROR } = textTemplatesEnglish

export const continueReplacementSchema = yup.object().shape({
  contractID: yup.string().contractID(),
  firstName: yup.string().onlyLetters(),
  lastName: yup.string().onlyLetters(),
  billingState: valueSelected(yup),
  vendor: valueSelected(yup),
  sku: yup.string().modelNumber(true),
  date: yup
    .date()
    .required(DEFAULT_ERROR)
    .min(todayAtMidnight(), 'Min date is today'),
  agreements: yup.bool().when('vendor', {
    is: (vendor: MenuItem) => vendor.id === 'PC',
    then: yup.bool().notRequired(),
    otherwise: yup.bool().oneOf([true], 'Required'),
  }),
  tenantAbbreviation: valueSelected(yup),
  amount: yup.string().when('vendor', {
    is: (vendor: MenuItem) => vendor.id === 'PC',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
})
