import React from 'react'
import {
  ProgressIndicatorProps,
  ProgressIndicator,
  ProgressIndicatorColorOption,
} from '@ftdr/blueprint-components-react'
import { isAHS } from 'src/utils/tenant-helper'

export const ProgressIndicatorComponent: React.FC<ProgressIndicatorProps> = (
  props
) => {
  const defaultProps = {
    color: isAHS()
      ? ('interactive' as ProgressIndicatorColorOption)
      : ('primary' as ProgressIndicatorColorOption),
    ...props,
  }
  return <ProgressIndicator {...defaultProps} />
}
