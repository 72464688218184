import { useAppContext } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import React from 'react'

interface OrderProgressProps {
  status: number
}

const OrderProgress = ({ status }: OrderProgressProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  return (
    <div className='w-full' id='order-progress-wrapper'>
      <div
        className='w-full flex flex-row items-center'
        id='order-progress-wrapper'
      >
        <div
          className={`rounded-full h-8 w-8 ${
            status >= 1 ? 'bg-interactive-500' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`flex-grow h-2 ${
            status >= 2 ? 'bg-interactive-500' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`rounded-full h-8 w-8 ${
            status >= 2 ? 'bg-interactive-500' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`flex-grow h-2 ${
            status >= 3 ? 'bg-interactive-500' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`rounded-full h-8 w-8 ${
            status >= 3 ? 'bg-interactive-500' : 'bg-gray-300'
          }`}
        ></div>
      </div>
      <div className='w-full flex flex-row justify-between'>
        <div className='flex flex-col items-center transform -translate-x-2 mt-5'>
          <Text variant='label'>
            {localizedText('TRACKING_DETAILS_PROGRESS_ORDERED_LABEL')}
          </Text>
        </div>

        <div className='flex flex-col items-center transform translate-x-1 mt-5'>
          <Text variant='label'>
            {localizedText('TRACKING_DETAILS_PROGRESS_SHIPPED_LABEL')}
          </Text>
        </div>
        <div className='flex flex-col items-center transform translate-x-2 mt-5'>
          <Text variant='label'>
            {localizedText('TRACKING_DETAILS_PROGRESS_DELIVERED_LABEL')}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default OrderProgress
