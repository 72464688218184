import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, Input, Notification, Select, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from '../../../components/custom-fdr-components'
import { DispatchSearch } from '../dispatch-search'
import { AgentSurvey } from '../agent-survey-types'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { formatPhone } from 'src/utils/internationalization-helper'
import { ServiceItemSelection } from './service-item-selection'
import { itempb } from 'src/services/protobuf-models/item-ms-protobuf-models'
import { PartRequestSelection } from './part-request-selection'
import { api } from 'src/utils/api'
import { AppState, MenuItem, Tenant } from 'src/utils/shared-types'
import { addInfo, ChangeAcceptedCategories, changeProductCategory } from 'src/store/survey-store'
import { useDispatch, useSelector } from 'react-redux'
import { clearProducts } from 'src/store/compare-store'
import TrimKitSizeField from 'src/components/trim-kit-size-field/trim-kit-size-field'
import IcemakerAddonField from 'src/components/icemaker-addon/icemaker-addon'
import { useDecision } from '@optimizely/react-sdk'
import { useHistory } from 'react-router'
import { removeReplacement } from 'src/store/replacemnt-survey-store'
import { isHSA } from 'src/utils/tenant-helper'

interface BasicInfoSurveyStepProps {
  survey: AgentSurvey.Survey

  onNext(stepInfo: AgentSurvey.IBasicInfoSurveyStep): void

  goToMakeAndModelSearch(stepInfo: AgentSurvey.IBasicInfoSurveyStep): void
}

export const BasicInfoSurveyStep = ({
                                      survey,
                                      onNext,
                                      goToMakeAndModelSearch,
                                    }: BasicInfoSurveyStepProps) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const history = useHistory()
  const reduxDispatch = useDispatch()
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon,
  )
  const [dispatchID, setDispatchID] = useState(survey.dispatchID || '')
  const [dcov, setDcov] = useState(false)
  const [item, setItem] = useState<itempb.IItem | null>(null)
  const [itemOptions, setItemOptions] = useState<itempb.IItem[]>([])
  const [selectedIndex, setIndexOfSelected] = useState<number>(-1)
  const [partRequestId, setPartRequestId] = useState('')

  const [applianceCategories, setApplianceCategories] = useState<MenuItem[]>([])
  const [change, setChange] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const dispatchReduxInfo = useDispatch()
  const [
    dispatch,
    setDispatch,
  ] = useState<appliancepb.IDispatchInfoResponse | null>(
    survey.dispatch || null,
  )

  const [isIcemakerAddonOn] = useDecision('icemaker_flag')
  const [isPaymentButtonOn] = useDecision('payment_button')

  const getItem = async (id: string | undefined, name: string) => {
    const [item] = await api.getItems(id)
    return item || { id, name }
  }

  useEffect(() => {
    const inputButton = ref.current?.querySelector(
      '#basic-info-step-cat-select-toggle-button',
    ) as HTMLButtonElement
    if (inputButton) {
      change && inputButton.click()
    }
  }, [change])

  useEffect(() => {
    async function getCategories() {
      try {
        const { mappedItemIDs } = await api.getApplianceCategories()

        const categoriesArray = mappedItemIDs.map((category) => {
          const categoryOption = {
            id: category.ID as string,
            value: category.ID as string,
            label: category.name as string,
          }

          return categoryOption
        })
        setApplianceCategories(
          categoriesArray.filter(
            ({ id }) =>
              id !== 'e384ffec-dd94-44be-90a7-69c1ed7d7166' &&
              id !== '01546c0a-63b9-4093-ba69-8bd3d4cb6940',
          ),
        )
      } catch (err) {
        console.error('get categories failed', err)
      }
    }

    getCategories()
  }, [])

  const onDispatchSearch = (
    dispatchID: string,
    dispatch: appliancepb.IDispatchInfoResponse,
  ) => {
    //Uncoment for testing multiple serviceItem and / or multiple partRequestId

    // dispatch.serviceItems = [...dispatch.serviceItems!, {
    //   itemID: '3111d8ae-3a49-48d4-ae34-b5483de473eb',

    //   /** ServiceItem partRequestIDs */
    //   partRequestIDs: ['38838298', '11111000'],

    //   /** ServiceItem serviceLimit */
    //   serviceLimit: 40000
    // }]

    setDispatchID(dispatchID)
    setDispatch(dispatch)
    setItem(null)
    setPartRequestId('')
  }

  let validStep: boolean

  if (item?.name?.toLowerCase() === 'microwave') {
    validStep = Boolean(
      dispatch && item && partRequestId && typeof trimKitSize === 'string',
    )
  }
  if (item?.name?.toLowerCase() === 'refrigerator') {
    validStep = Boolean(
      dispatch && item && partRequestId && typeof icemakerAddon === 'string',
    )
  } else {
    validStep = Boolean(dispatch && item && partRequestId)
  }

  const customerName = useMemo(() => {
    if (!dispatch) return ''
    return `${dispatch.firstName} ${dispatch.lastName}`
  }, [dispatch])

  const contractId = (dispatch && dispatch.contractID) || ''

  const resetDispatchInfo = () => {
    setDispatch(null)
    setItem(null)
    setPartRequestId('')
  }
  const goToPayment = () => {
    if (dispatch) {
      dispatchReduxInfo(removeReplacement())
      dispatchReduxInfo(
        addInfo({
          customerName: [dispatch.firstName || '', dispatch.lastName || ''],
          contractId,
          customerPhoneNumber: dispatch.phone || '',
          dispatchId: dispatchID,
          billingState: dispatch.address?.state || '',
          adressID: dispatch.addressID || '',
          tenantAbbreviation: dispatch?.tenantAbbreviation || '',
        }),
      )
    }
    history.push('/payment')
  }

  const nextBtnHandler = () => {
    if (dispatch && item) {
      onNext({ dispatchID, dispatch, item, partRequestId, dcov })
      reduxDispatch(clearProducts())
    }
  }

  return (
    <div className='max-w-sm md:max-w-full m-auto'>
      <div className='mb-6'>
        <DispatchSearch
          survey={survey}
          onDispatchSearch={onDispatchSearch}
          resetDispatchInfo={resetDispatchInfo}
        />
        {dispatch?.tenantAbbreviation === Tenant.HSA && (
          <Notification
            id='basic-info-step-notification'
            className='mt-4 inline-block'
            status='info'
          >
            {localizedText('HSA_DISPATCH_TEXT')}
          </Notification>
        )}
      </div>
      <Input
        id='basic-info-step-name'
        formFieldClassName='w-full sm:w-auto'
        className='w-full mb-6 md:w-56 max-w-full'
        formField
        disabled
        label={localizedText('SURVEY_CUSTOMER_NAME_LABEL')}
        value={customerName}
      />
      <Input
        id='basic-inf-step-phone'
        formFieldClassName='w-full sm:w-auto'
        className='w-full mb-6 md:w-56 max-w-full'
        formField
        disabled
        label={localizedText('SURVEY_CUSTOMER_PHONE_LABEL')}
        value={formatPhone(dispatch?.phone)}
      />
      <Input
        id='basic-info-step-contract-id'
        formField
        disabled
        label={localizedText('CONTRACT_ID_LABEL')}
        formFieldClassName='w-full sm:w-auto'
        className='w-full mb-6 md:w-56 max-w-full'
        value={contractId}
      />
      <Checkbox
        id='basic-info-step-dcov'
        color={color}
        label={localizedText('SURVEY_DCOV')}
        className='mb-6'
        checked={dcov}
        onChange={() => setDcov(!dcov)}
      />
      {dispatch && (
        <>
          <div className='lg:flex relative z-10'>
            <div className='w-56'>
              <ServiceItemSelection
                serviceItems={dispatch?.serviceItems || []}
                itemOptions={itemOptions}
                setItemOptions={setItemOptions}
                selected={item}
                onSelect={(item, index) => {
                  setPartRequestId('') // cleanup
                  setItem(item)
                  setIndexOfSelected(index)
                }}
              />
            </div>
            {item && (
              <div className='relative'>
                <Button
                  id='basic-info-step-button-type'
                  label={localizedText('SURVEY_CHANGE_TYPE_BUTTON_LABEL')}
                  variant='outlined'
                  className='mt-5 lg:mt-0 mb-5'
                  onClick={() => setChange(true)}
                />
                {change && (
                  <div
                    ref={ref}
                    id='basic-info-step-container'
                    className='mb-5 lg:mb-0 lg:h-0 absolute left-0 bottom-0'
                  >
                    <Select
                      id='basic-info-step-cat-select'
                      className='w-56'
                      label='Test'
                      placeholder='Select new category'
                      options={applianceCategories.filter(
                        (cat) =>
                          !itemOptions.map((v) => v.id).includes(cat.value),
                      )}
                      onBlur={() => setChange(false)}
                      onSelect={async (item) => {
                        const option = { ...item } as MenuItem
                        const newItem = await getItem(
                          option.value,
                          option.label,
                        )

                        setItem(
                          newItem || {
                            name: option.label,
                            id: option.id,
                            value: option.value,
                          },
                        )
                        reduxDispatch(changeProductCategory(option.value))
                        reduxDispatch(ChangeAcceptedCategories([option.value]))
                        setPartRequestId('')
                        setChange(false)

                        const itemOptionsTemp = itemOptions.map((v, i) =>
                          i === selectedIndex ? newItem : v,
                        )

                        setItemOptions(itemOptionsTemp)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <TrimKitSizeField
            isRequired={item?.name?.toLowerCase() === 'microwave'}
          />
          {isIcemakerAddonOn.enabled && (
            <IcemakerAddonField
              isRequired={item?.name?.toLowerCase() === 'refrigerator'}
            />
          )}
          {item && (
            <PartRequestSelection
              serviceItem={dispatch?.serviceItems?.[selectedIndex]}
              selected={partRequestId}
              onSelect={(partRequestId) => setPartRequestId(partRequestId)}
            />
          )}
        </>
      )}
      <div className='flex mt-6'>
        <Button
          id='basic-info-step-button-options'
          label={localizedText('SURVEY_SEE_OPTIONS_BTN')}
          disabled={!validStep}
          onClick={nextBtnHandler}
          className='w-full flex justify-center md:w-auto md:justify-start'
        />
        {isPaymentButtonOn.enabled ? (
          <Button
            id='basic-info-step-button-options-1'
            label={localizedText('SURVEY_PAYMENT_BTN')}
            disabled={!validStep}
            onClick={goToPayment}
            className='w-full flex justify-center md:w-auto md:justify-start ml-6'
          />
        ) : null}
        {/* CM-1212 disabling model search for the moment */}
        <Button
          id='basic-info-step-button-model-search'
          variant='ghost'
          className='hidden lg:ml-2'
          label={localizedText('SURVEY_MODEL_SEARCH_BTN')}
          disabled={!validStep}
          onClick={() =>
            goToMakeAndModelSearch({
              dispatchID,
              dispatch,
              item,
              partRequestId,
              dcov,
            })
          }
        />
      </div>
    </div>
  )
}
