import * as yup from 'yup'
import {
  regexContractID,
  regexDecimal,
  regexDigit,
  regexEmail,
  regexLetters,
  regexModelNumber,
  regexNegativeDigit,
  regexOrderID,
  regexPartialEmail,
  regexPaymentID,
  regexPriceDecimalNegative,
} from '../validation-utils'

import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { today } from '../internationalization-helper'
import { IPaymentFormData, IQueueFormData } from '../shared-types'

declare module 'yup' {
  interface StringSchema {
    orderID(isRequired?: boolean): this

    contractID(isRequired?: boolean): this

    dispatchID(isRequired?: boolean): this

    partRequestID(isRequired?: boolean): this

    paymentID(isRequired?: boolean): this

    modelNumber(isRequired?: boolean): this

    onlyLetters(isRequired?: boolean): this

    decimalAmount(isRequired?: boolean): this

    decimalAmountMaxRange(values: IQueueFormData): this

    decimalAmountWithNegative(isRequired?: boolean): this

    rangeNumber(min: number, max: number, isRequired?: boolean): this

    email(isRequired?: boolean): this

    partialEmail(isRequired?: boolean): this
  }
}
type TYup = typeof yup
const {
  UUID_ERROR,
  ORDER_ID_ERROR,
  CONTRACT_ID_ERROR,
  DISPATCH_ID_ERROR,
  MODEL_ID_ERROR,
  DEFAULT_ERROR,
  MAX_ERROR,
  ONLY_LETTERS_ERROR,
  AMOUNT_ERROR,
  AMOUNT_ERROR1,
  PART_REQUEST_ERROR,
  MAX_DATE_TODAY_ERROR,
  MAX_AMOUNT_ERROR,
  PAYMENT_ID_ERROR,
  TEXT_MESSAGE_MIN_ERROR,
  TEXT_MESSAGE_MAX_ERROR,
} = textTemplatesEnglish

// isRequired is set for true by default in all functions
function rangeNumber(
  this: yup.StringSchema,
  min: number,
  max: number,
  isRequired: boolean = true,
) {
  const range = [min, max]
  const RANGE_ERROR = `Amount should be a number larger or equal than $${range[0]}$ and lower than $${range[1]}`
  if (isRequired) {
    return this.required(DEFAULT_ERROR).test(
      'numberRange',
      RANGE_ERROR,
      (val) => {
        if (!val) {
          return false
        }

        return !(+val < range[0] || +val >= range[1])
      },
    )
  } else {
    return this.test('numberRange', RANGE_ERROR, (val) => {
      if (!val) {
        return false
      }

      return !(+val < range[0] || +val >= range[1])
    })
  }
}

function orderID(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(regexOrderID, ORDER_ID_ERROR)
  } else {
    return this.matches(regexOrderID, {
      message: ORDER_ID_ERROR,
      excludeEmptyString: true,
    })
  }
}

function email(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(
      regexEmail,
      'Email is not valid',
    )
  } else {
    return this.matches(regexEmail, {
      message: 'Email is not valid',
      excludeEmptyString: true,
    })
  }
}

function partialEmail(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(
      regexPartialEmail,
      'Partial Email is not valid',
    )
  } else {
    return this.matches(regexPartialEmail, {
      message: 'Partial Email is not valid',
      excludeEmptyString: true,
    })
  }
}

function contractID(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(
      regexContractID,
      CONTRACT_ID_ERROR,
    )
  } else {
    return this.matches(regexContractID, {
      message: CONTRACT_ID_ERROR,
      excludeEmptyString: true,
    })
  }
}

function dispatchID(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(regexDigit, DISPATCH_ID_ERROR)
  } else {
    return this.matches(regexDigit, {
      message: DISPATCH_ID_ERROR,
      excludeEmptyString: true,
    })
  }
}

function partRequestID(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(regexDigit, PART_REQUEST_ERROR)
  } else {
    return this.matches(regexDigit, {
      message: PART_REQUEST_ERROR,
      excludeEmptyString: true,
    })
  }
}

function paymentID(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(
      regexPaymentID,
      PAYMENT_ID_ERROR,
    )
  } else {
    return this.matches(regexPaymentID, {
      message: PAYMENT_ID_ERROR,
      excludeEmptyString: true,
    })
  }
}

function modelNumber(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).matches(regexModelNumber, MODEL_ID_ERROR)
  } else {
    return this.matches(regexModelNumber, {
      message: MODEL_ID_ERROR,
      excludeEmptyString: true,
    })
  }
}

function onlyLetters(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR)
      .matches(regexLetters, ONLY_LETTERS_ERROR)
      .max(100, MAX_ERROR)
  } else {
    return this.matches(regexLetters, {
      message: ONLY_LETTERS_ERROR,
      excludeEmptyString: true,
    }).max(100, MAX_ERROR)
  }
}

function decimalAmount(this: yup.StringSchema, isRequired: boolean = true) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).test('decimal', AMOUNT_ERROR, (val) => {
      if (val && +val > 0) {
        return regexDecimal.test(val)
      } else {
        return false
      }
    })
  } else {
    return this.test('decimal', AMOUNT_ERROR, (val) => {
      if (val && +val > 0) {
        return regexDecimal.test(val)
      } else return !val
    })
  }
}

function decimalAmountWithNegative(
  this: yup.StringSchema,
  isRequired: boolean = true,
) {
  if (isRequired) {
    return this.required(DEFAULT_ERROR).test(
      'decimal',
      AMOUNT_ERROR1,
      (val) => {
        if (val && (+val >= 0 || +val < 0)) {
          return (
            regexPriceDecimalNegative.test(val) ||
            regexNegativeDigit.test(val) ||
            regexDigit.test(val)
          )
        } else {
          return false
        }
      },
    )
  } else {
    return this.test('decimal', AMOUNT_ERROR1, (val) => {
      if (val && (+val >= 0 || +val < 0)) {
        return regexPriceDecimalNegative.test(val)
      } else return !val
    })
  }
}

function decimalAmountMaxRange(
  this: yup.StringSchema,
  values: IPaymentFormData,
) {
  return this.decimalAmount(false).test(
    'grater than min',
    MAX_AMOUNT_ERROR,
    (value) =>
      +value! >= (values.priceMin ? +values.priceMin : 0) || value === '',
  )
}

export function customUuid(yup: TYup, isRequired: boolean = true) {
  if (isRequired) {
    return yup.string().required(DEFAULT_ERROR).uuid(UUID_ERROR)
  } else {
    return yup
      .string()
      .notRequired()
      .uuid(UUID_ERROR)
      .nullable()
      .transform((value: string) => (!!value ? value : null))
  }
}

export function valueSelected(yup: TYup, isRequired: boolean = true) {
  if (isRequired) {
    return yup
      .object()
      .test('id', DEFAULT_ERROR, (val: any) => (val?.value))
  } else {
    return yup.object().test('id', DEFAULT_ERROR, () => true)
  }
}

export function multiValueSelected(yup: TYup, isRequired: boolean = true) {
  if (isRequired) {
    return yup.array(
      yup
        .object()
        .test('value', DEFAULT_ERROR, (val: any) => (val.value)),
    )
  } else {
    return yup
      .array(yup.object().test('value', DEFAULT_ERROR, () => true))
      .nullable()
  }
}

export function maxToday(yup: TYup, isRequired: boolean = true) {
  if (isRequired) {
    return yup.date().max(today, MAX_DATE_TODAY_ERROR)
  } else {
    return yup.date().max(today, MAX_DATE_TODAY_ERROR).nullable()
  }
}

export function textMessage(yup: TYup, isRequired: boolean = true) {
  if (isRequired) {
    return yup
      .string()
      .required(DEFAULT_ERROR)
      .min(6, TEXT_MESSAGE_MIN_ERROR)
      .max(255, TEXT_MESSAGE_MAX_ERROR)
  } else {
    return yup
      .string()
      .min(6, TEXT_MESSAGE_MIN_ERROR)
      .max(255, TEXT_MESSAGE_MAX_ERROR)
      .nullable()
      .transform((value: string) => (!!value ? value : null))
  }
}

yup.addMethod(yup.string, 'orderID', orderID)
yup.addMethod(yup.string, 'contractID', contractID)
yup.addMethod(yup.string, 'dispatchID', dispatchID)
yup.addMethod(yup.string, 'paymentID', paymentID)
yup.addMethod(yup.string, 'modelNumber', modelNumber)
yup.addMethod(yup.string, 'onlyLetters', onlyLetters)
yup.addMethod(yup.string, 'decimalAmount', decimalAmount)
yup.addMethod(yup.string, 'partRequestID', partRequestID)
yup.addMethod(yup.string, 'decimalAmountMaxRange', decimalAmountMaxRange)
yup.addMethod(
  yup.string,
  'decimalAmountWithNegative',
  decimalAmountWithNegative,
)
yup.addMethod(yup.string, 'rangeNumber', rangeNumber)
yup.addMethod(yup.string, 'email', email)
yup.addMethod(yup.string, 'partialEmail', partialEmail)

export default yup
