import { emptyItem } from 'src/components/adminForms/initials'
export const defaultValues = {
  paymentType: emptyItem,
  dispatchID: '',
  contractID: '',
  orderID: '',
  firstName: '',
  lastName: '',
  billingState: emptyItem,
  vendor: emptyItem,
  modelNumber: '',
  date: new Date(),
  amount: '',
}
