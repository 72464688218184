import yup, { valueSelected } from '../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

const { DEFAULT_ERROR } = textTemplatesEnglish
export const addFeeFormSchema = yup.object().shape({
  tenant: yup.string().required(DEFAULT_ERROR),
  price: yup.string().rangeNumber(1, 1000, true),
  category: valueSelected(yup),
  subCategory: valueSelected(yup),
  description: yup.string().required(DEFAULT_ERROR),
})
