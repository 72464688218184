import React, { useState, useEffect } from 'react'
import Vendor from './vendor'
import { useAdminContext } from '../../hooks/use-admin-context'
import {
  Input,
  useAppContext,
  Notification,
} from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from '../custom-fdr-components'
import AddButton from './add-button'
import { isPrice, isSKU } from 'src/utils/validation-utils'
import Message from './message'
import './reset.css'
import { emptyCacheObject } from './initials'

interface IProps {
  showSKU?: boolean
  showZIP?: boolean
  showPrice?: boolean
  showRefreshMessage?: boolean
}
const AddForm: React.FC<IProps> = ({
  showSKU = true,
  showZIP = false,
  showPrice = false,
  showRefreshMessage = false,
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    sku,
    setSku,
    zip,
    setZip,
    price,
    setPrice,
    addFileMessage: { error, message },
    setAddFileMessage,
  } = useAdminContext()
  const [skuError, setError] = useState<string>('')
  useEffect(() => {
    if (!sku) {
      setError('')
    } else {
      isSKU(sku) ? setError('') : setError('Invalid value.')
    }
  }, [sku])
  return (
    <div>
      <Text className="py-4" textTemplateKey="ADMIN_INFO_TEXT_2" />
      <Vendor />
      {showSKU && (
        <Input
          id="add-form-sku"
          required
          formField
          formFieldClassName="w-full sm:w-auto"
          label={localizedText('ADMIN_LABEL_SKU')}
          className={`w-full md:w-56 mb-6`}
          inputClassName={`${skuError ? 'error' : ''}`}
          value={sku}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAddFileMessage(emptyCacheObject)
            setSku(e.target.value.toUpperCase())
          }}
        />
      )}
      {showZIP && (
        <Input
          id="add-form-zip"
          required
          formField
          formFieldClassName="w-full sm:w-auto"
          label={localizedText('ADMIN_LABEL_ZIP')}
          className="w-full md:w-56 mb-6"
          value={zip}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAddFileMessage(emptyCacheObject)
            setZip(e.target.value)
          }}
        />
      )}
      {showPrice && (
        <Input
          id="add-form-price"
          required
          formField
          formFieldClassName="w-full sm:w-auto"
          label={localizedText('ADMIN_LABEL_PRICE')}
          className="w-full md:w-56 mb-6"
          value={price}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAddFileMessage(emptyCacheObject)
            isPrice(e.target.value) && setPrice(e.target.value)
          }}
        />
      )}
      {showRefreshMessage === true ? (
        <Notification
          status="warning"
          size="small"
          showStatusLabel={false}
          className="mb-6"
        >
          {localizedText('ADMIN_REFRESH_MESSAGE')}
        </Notification>
      ) : null}
      <AddButton />
      <Message id="add-form" error={error} message={message} />
    </div>
  )
}

export default AddForm
