import React from 'react'
import Form from '../form'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import OutrightPaymentSuccess from '../success-screen/outright-payment-success'

const Screen = () => {
  const { status } = usePaymentContext()

  return status === 'success' ? <OutrightPaymentSuccess /> : <Form />
}

export default Screen
