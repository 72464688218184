import React from 'react'
import AddToCompareButton from '../add-to-compare-button/add-to-compare-button'
import { TextComponent as Text } from '../custom-fdr-components'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import { isHSA } from 'src/utils/tenant-helper'
interface IProps {
  link: string
}

const Compareproducts: React.FC<IProps> = ({ link }): JSX.Element | null => {
  const { replacementId, data: compareProducts } = useSelector(
    (state: AppState) => state.compareProducts
  )

  const history = useHistory()

  if (compareProducts.length === 0) {
    return null
  }
  return (
    <section className="bg-white p-4">
      <ul className="flex flex-row w-full flex-wrap mb-8 items-center">
        <Text className="pr-2">Added</Text>
        {compareProducts.map((product, i) => (
          <li
            id={`compare-products-box-description-${product.ID}`}
            className="text-1 pr-2 text-primary"
            key={product.ID}
          >
            {product.description}
            {i < compareProducts.length - 1 && ' vs'}
          </li>
        ))}
        <Text>to compare </Text>
      </ul>

      <div className="flex items-center">
        {compareProducts.map((product) => {
          const [image] = product.images || []
          return (
            <div
              id={`compare-products-box-product-wrapper-${product.ID}`}
              className="px-4 flex flex-col items-center overflow-hidden"
              key={product.ID}
              style={{ flex: '0 0 140px', maxWidth: 140 }}
            >
              <Link
                id={`compare-products-box-product-link-${product.ID}`}
                to={
                  replacementId
                    ? `/${replacementId}/shop/${product.ID}`
                    : `/browse/${product.ID}`
                }
              >
                <figure
                  className="flex-shrink-0 flex items-center"
                  style={{ maxWidth: 120 }}
                >
                  <img
                    id={`compare-products-box-product-image-${product.ID}`}
                    src={image?.url || '/noimage.jpg'}
                    alt="Product"
                    className="object-contain max-h-full max-w-full m-auto"
                  />
                </figure>
              </Link>
            </div>
          )
        })}
        <button
          id="compare-products-box-product-button-redirect"
          type="button"
          className={
            isHSA()
              ? 'bg-primary-800 hover:bg-primary-600 block  transition duration-300 ease-in-out ml-4 text-white shadow-raised'
              : 'bg-interactive-500 text-white hover:bg-white hover:text-interactive hover:border-interactive border-1 border-transparent block  transition duration-300 ease-in-out ml-4 shadow-raised'
          }
          disabled={compareProducts.length < 2}
          onClick={() => history.push(link)}
          style={{
            width: 80,
            height: 80,
            borderRadius: '100%',
            opacity: compareProducts.length < 2 ? 0.5 : 1,
            backgroundColor:
              compareProducts.length < 2
                ? isHSA()
                  ? '#182AF0'
                  : '#202945'
                : '',
            cursor: compareProducts.length < 2 ? 'not-allowed' : 'pointer',
          }}
        >
          See All
        </button>
      </div>
      <div className="flex items-center">
        {compareProducts.map((product) => (
          <div
            className="-mt-2 px-4 flex flex-col items-center overflow-hidden"
            key={product.ID}
            style={{ flex: '0 0 140px', maxWidth: 140 }}
          >
            <AddToCompareButton product={product} id={product.ID || ''} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default Compareproducts
