import React from 'react'
import AdminFunctions from './admin-functions'
import { generateForm } from './generate-form'
import { useAdminContext } from 'src/hooks/use-admin-context'
import { TextComponent as Text } from '../custom-fdr-components'

const AdminForms: React.FC = () => {
  const {
    adminFunction: { value },
  } = useAdminContext()
  return (
    <div>
      <Text
        className="font-display font-bold text-5 lg:text-11 mb-8"
        textTemplateKey="ADMIN_TITLE"
      />
      <AdminFunctions />
      {generateForm(value)}
    </div>
  )
}

export default AdminForms
