export const dataDetailsInfoModal = [
  {
    status: 'Recreated Replacement Offer (OldStatus  ▶  NewStatus)',
    description:
      'There was an existing replacement offer which was resent to the customer. The same replacementID was used and the notes were persisted.',
  },
  {
    status: 'Create Replacement (CREATED)',
    description: 'New replacement offer was created',
  },
  {
    status: 'Shop Link email sent to: *@email.com',
    description:
      'Offer was sent to the customer email address on file. The email is partially hidden but should show domain (gmail, yahoo, etc).',
  },
  {
    status: 'Order Confirmation email sent to: *@email.com',
    description:
      'Order confirmation email was sent to the customer email address on file. The email is partially hidden but should show domain (gmail, yahoo, etc).',
  },
  {
    status: 'Installer Confirmation email sent to: *@email.com',
    description:
      'Installer confirmation email was sent to the customer email address on file. The email is partially hidden but should show domain (gmail, yahoo, etc).',
  },
  {
    status: 'Send Invoice Message (SENT/FAILED)',
    description:
      'Replacement information was reported to snowflake/kafka for payment reconciliation team to review.',
  },
  {
    status:
      'Notes entered from "Record External Order" flow may be present in notes.',
    description: '',
  },
  {
    status: 'Close CIL Offered (OldStatus ▶ CLOSED_CIL_OFFERED)',
    description:
      'Replacement record is put in a final state because CIL was offered externally of the appliance portal.',
  },
  {
    status: 'Close Ordered External (OldStatus ▶ ORDERED_EXTERNAL)',
    description:
      'Replacement record is put in a final state because it was ordered externally of the appliance portal.',
  },
  {
    status: 'Close Duplicate (OldStatus ▶ CLOSED_DUPLICATE)',
    description:
      'Replacement record is put in a final state because it is a duplicate replacement record (same partRequestID/dispatchID)',
  },
  {
    status: 'Update Installer (NEEDS_INSTALLER ▶ ORDERED)',
    description:
      'Agent has added installer information to the replacement record.',
  },
  {
    status: 'Patch Replacement (OldStatus ▶ NewStatus),',
    description: 'Replacement record has been modified through queue actions.',
  },
  {
    status: 'Get Order Status (OldStatus ▶ NewStatus)',
    description:
      'Replacement record status has been updated through check status functionality (tracking page).',
  },
  {
    status: 'Get Replacement Details (CREATED ▶ ACTIVATED)',
    description:
      'Customer/agent has clicked the shoplink and viewed the replacement options screen.',
  },
  {
    status: 'Confirm Replacement Product SKU (ACTIVATED ▶ CONFIRMED)',
    description:
      'Customer/agent has clicked view details in the replacement options screen.',
  },
  {
    status: 'Delivery Instructions: <instructions>',
    description:
      'Customer/agent has entered delivery instructions in the place order page.',
  },
  {
    status: 'Process Payment Failed: max customer payment attempts reached (7)',
    description:
      'Customer has attempted payment the max number of times. An agent must go through the portal and order for them or resend the offer. This is to prevent fraud and the ability to test stolen credit cards.',
  },
  {
    status:
      'Payment Attempt: <attemptNumber>, Processor: STRIPE, Decline Reason: Insufficient funds',
    description: 'A customer/agent has attempted payment but it failed.',
  },
  {
    status: 'Process Payment (CONFIRMED ▶ PURCHASED)',
    description:
      'Customer/agent has successfully gone through the order screen. Either payment was successfully processed or a 0-cost replacement went through.',
  },
  {
    status: 'Place Order (PURCHASED ▶ ORDERED)',
    description:
      'Customer/agent has gone through the order page and it was successfully ordered from our vendors.',
  },
  {
    status: 'Successful Order Retry (OldStatus ▶ ORDERED)',
    description: 'An order that failed previously was successfully retried.',
  },
  {
    status: 'Order Failed (ORDER_ERROR)',
    description: 'Order failed',
  },
]
