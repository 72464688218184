import { Reducer } from 'redux'
import { api } from 'src/utils/api'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { StateAwareThunkDispatch, ThunkResult } from 'src/utils/shared-types'
import { AdminStoreState, AdminActions, CONSTANTS } from './admin-store.types'
import { textTemplatesEnglish } from '../../i18n/text-templates-english'

type AdminReducer = Reducer<AdminStoreState, AdminActions>

export const AdminInitialState: AdminStoreState = {
  getLoading: false,
  getError: '',
  saveLoading: false,
  saveError: '',
  editLoading: false,
  isBannerEdited: false,
  editError: '',
  deleteLoading: false,
  isBannerDeleted: false,
  deleteError: '',
  banners: [],
}

export const adminReducer: AdminReducer = (
  state = AdminInitialState,
  action
): AdminStoreState => {
  switch (action.type) {
    case CONSTANTS.GET_BANNERS:
      return { ...state, banners: [...action.banners] }
    case CONSTANTS.GET_LOADING:
      return { ...state, getLoading: action.getLoading }
    case CONSTANTS.GET_ERROR:
      return { ...state, getError: action.getError }
    case CONSTANTS.SAVE_LOADING:
      return { ...state, saveLoading: action.saveLoading }
    case CONSTANTS.SAVE_ERROR:
      return { ...state, saveError: action.saveError }
    case CONSTANTS.EDIT_BANNER:
      return {
        ...state,
        banners: [
          ...state.banners.map((item) =>
            item.id !== action.banner.id ? item : action.banner
          ),
        ],
      }
    case CONSTANTS.EDIT_ERROR:
      return {
        ...state,
        editError: action.editError,
      }
    case CONSTANTS.GET_IS_EDITED:
      return {
        ...state,
        isBannerEdited: action.isBannerEdited,
      }
    case CONSTANTS.DELETE_BANNER:
      return {
        ...state,

        banners: state.banners.filter((item) => item.id !== action.id),
      }
    case CONSTANTS.GET_IS_DELETED:
      return {
        ...state,
        isBannerDeleted: action.isBannerDeleted,
      }
    case CONSTANTS.DELETE_ERROR:
      return {
        ...state,
        deleteError: action.deleteError,
      }
    default:
      return state
  }
}

const getBannersCallback = async (dispatch: StateAwareThunkDispatch) => {
  try {
    dispatch({ type: CONSTANTS.GET_LOADING, getLoading: true })
    dispatch({
      type: CONSTANTS.GET_ERROR,
      getError: '',
    })
    const banners = await api.getBanners()

    dispatch({
      type: CONSTANTS.GET_BANNERS,
      banners,
    })
    dispatch({ type: CONSTANTS.GET_LOADING, getLoading: false })
  } catch (error: any) {
    dispatch({ type: CONSTANTS.GET_LOADING, getLoading: false })
    dispatch({
      type: CONSTANTS.GET_ERROR,
      getError: error.message || 'Something went wrong.',
    })
    console.log(error.message)
  }
}

export const getBanners = (): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  getBannersCallback(dispatch)
}

export const sendBanner = (
  banner: appliancepb.IBanner
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  try {
    dispatch({ type: CONSTANTS.SAVE_LOADING, saveLoading: true })
    dispatch({
      type: CONSTANTS.SAVE_ERROR,
      saveError: '',
    })
    await api.addBanner({ banners: [banner] })
    dispatch({ type: CONSTANTS.SAVE_LOADING, saveLoading: false })
    getBannersCallback(dispatch)
  } catch (error: any) {
    dispatch({ type: CONSTANTS.SAVE_LOADING, saveLoading: false })
    dispatch({
      type: CONSTANTS.SAVE_ERROR,
      saveError: error.message || 'Something went wrong.',
    })
    console.log(error.message)
  }
}

export const editBanner = (
  banner: appliancepb.IBanner
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  try {
    dispatch({
      type: CONSTANTS.EDIT_ERROR,
      editError: '',
    })
    dispatch({ type: CONSTANTS.EDIT_LOADING, editLoading: true })
    await api.addBanner({ banners: [banner] })
    dispatch({ type: CONSTANTS.GET_IS_EDITED, isBannerEdited: true })
    dispatch({ type: CONSTANTS.EDIT_LOADING, editLoading: false })
    dispatch({ type: CONSTANTS.EDIT_BANNER, banner })
  } catch (error: any) {
    dispatch({ type: CONSTANTS.EDIT_LOADING, editLoading: false })
    dispatch({
      type: CONSTANTS.EDIT_ERROR,
      editError: textTemplatesEnglish.UPDATE_ERROR,
    })
    console.log(error.message)
  }
}

export const deleteBanner = (id: string): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  try {
    dispatch({
      type: CONSTANTS.DELETE_ERROR,
      deleteError: '',
    })
    dispatch({ type: CONSTANTS.DELETE_LOADING, deleteLoading: true })

    await api.removeBanner(id)
    dispatch({ type: CONSTANTS.GET_IS_DELETED, isBannerDeleted: true })
    dispatch({ type: CONSTANTS.DELETE_LOADING, deleteLoading: false })
    dispatch({
      type: CONSTANTS.DELETE_BANNER,
      id,
    })
  } catch (error: any) {
    dispatch({
      type: CONSTANTS.DELETE_ERROR,
      deleteError: error.message || 'Something went wrong.',
    })
    dispatch({ type: CONSTANTS.DELETE_LOADING, deleteLoading: false })
    console.log(error.message)
  }
}

export const clearEditError = () => (dispatch: StateAwareThunkDispatch) =>
  dispatch({ type: CONSTANTS.EDIT_ERROR, editError: '' })

export const clearDeleteError = () => (dispatch: StateAwareThunkDispatch) =>
  dispatch({ type: CONSTANTS.DELETE_ERROR, deleteError: '' })

export const clearBannerDeleted = () => (dispatch: StateAwareThunkDispatch) =>
  dispatch({ type: CONSTANTS.GET_IS_DELETED, isBannerDeleted: false })

export const clearBannerEdited = () => (dispatch: StateAwareThunkDispatch) =>
  dispatch({ type: CONSTANTS.GET_IS_EDITED, isBannerEdited: false })

export const clearSaveError = () => (dispatch: StateAwareThunkDispatch) =>
  dispatch({
    type: CONSTANTS.SAVE_ERROR,
    saveError: '',
  })
