import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'

export enum CONSTANTS {
  GET_REPLACEMENT = 'GET_REPLACEMENT',
  REPLACEMENT_ERROR = 'REPLACEMENT_ERROR',
  VERIFIED = 'VERIFIED',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
}

export interface ReplacementStoreState {
  data: appliancepb.IReplacementResponse | null
  replacementError: object | null
  verified: boolean
  verificationError: object | null
}

export type ReplacementActions = GetReplacementAction | VerifyReplacementAction

type GetReplacementAction =
  | {
      type: CONSTANTS.GET_REPLACEMENT
      replacement: appliancepb.IReplacementResponse
    }
  | { type: CONSTANTS.REPLACEMENT_ERROR; error: any }

type VerifyReplacementAction =
  | { type: CONSTANTS.VERIFIED }
  | { type: CONSTANTS.VERIFICATION_ERROR; error: any }
