import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import {
  IAnyObject,
  ITabsProps,
  TPartialFormParams,
} from 'src/utils/shared-types'
import qs from 'query-string'
import { api } from 'src/utils/api'
import { useHistory } from 'react-router'
import axios from 'axios'

type TTabsTableContext = {
  children?: React.ReactNode
}

const TabsTableContext = createContext<ITabsProps>({} as ITabsProps)

const TabsTableProvider: React.FC<TTabsTableContext> = ({ children }) => {
  const cancelTokenQueueRef = useRef<any>(axios.CancelToken)
  const sourceQueueRef = useRef<any>(cancelTokenQueueRef.current.source())

  const cancelTokenPaymentRef = useRef<any>(axios.CancelToken)
  const sourcePaymentRef = useRef<any>(cancelTokenPaymentRef.current.source())

  const history = useHistory()
  const [activeTab, setActiveTab] = useState<number>(1)

  // Payment

  const [paymentTableData, setPaymentTableData] = useState<
    appliancepb.IOutrightPaymentRecord[] | null
  >([])

  const [paymentTableDataError, setPaymentTableDataError] = useState<string>('')

  const [paymentTableDataIsLoading, setPaymentTableDataIsLoading] = useState<
    boolean
  >(false)

  const [paymentCurrentPagination, setPaymentCurrentPagination] = useState<
    appliancepb.IPagination | null | undefined
  >({ itemsPerPage: 9, page: 1, totalItems: 0 })

  const [paymentParams, setPaymentParams] = useState<IAnyObject>({})
  const [paymentPrevParamsString, setPaymentPrevParamsString] = useState<
    string | null
  >(null)

  // Queue

  const [queueTableData, setQueueTableData] = useState<
    appliancepb.IFullReplacementDetails[] | null
  >([])

  const [queueTableDataError, setQueueTableDataError] = useState<string>('')

  const [queueTableDataIsLoading, setQueueTableDataIsLoading] = useState<
    boolean
  >(false)

  const [queueCurrentPagination, setQueueCurrentPagination] = useState<
    appliancepb.IPagination | null | undefined
  >({ itemsPerPage: 9, page: 1, totalItems: 0 })

  const [queueParams, setQueueParams] = useState<IAnyObject>({})

  const [queuePrevParamsString, setQueuePrevParamsString] = useState<
    string | null
  >(null)

  const [shouldQueueDataReload, setShouldQueueDataReload] = useState<boolean>(
    false
  )

  const [isQueueFormMounted, setIsQueueFormMounted] = useState<boolean>(false)

  const [isPaymentFormMounted, setIsPaymentFormMounted] = useState<boolean>(
    false
  )

  const getPaymentsData = async (
    formDataFromParamsObject: TPartialFormParams,
    updateHistory: boolean
  ) => {
    const stringParams = qs.stringify(formDataFromParamsObject)

    setPaymentTableDataIsLoading(true)
    setPaymentTableDataError('')

    try {
      setPaymentPrevParamsString(
        `#tab2${qs.stringify(formDataFromParamsObject)}`
      )

      if (!!stringParams) {
        setPaymentParams(formDataFromParamsObject)
        updateHistory && history.push(`/queue#tab${activeTab}?${stringParams}`)
      } else {
        setPaymentParams({})
        updateHistory && history.push(`/queue#tab${activeTab}`)
      }

      const { outrightPayments, pagination } = await api.getPayments(
        {
          page: 1,
          items_per_page: paymentCurrentPagination?.itemsPerPage,
          ...formDataFromParamsObject,
        },
        sourcePaymentRef.current.token
      )
      setPaymentTableDataIsLoading(false)

      if (outrightPayments?.length > 0) {
        setPaymentCurrentPagination(pagination || { itemsPerPage: 9, page: 1 })
      }

      if (outrightPayments?.length === 0) {
        setPaymentTableDataError('No results')
        setPaymentTableData([])
      } else {
        setPaymentTableDataError('')
        setPaymentTableData(outrightPayments)
      }
    } catch (error: any) {
      console.log(error.message)
      setPaymentTableDataIsLoading(false)
      setPaymentTableDataError(error.message || 'Something went wrong.')
    }
  }

  const getQueueData = async (
    formDataFromParamsObject: TPartialFormParams,
    updateHistory: boolean
  ) => {
    const stringParams = qs.stringify(formDataFromParamsObject)
    setQueueTableDataIsLoading(true)
    setQueueTableDataError('')

    try {
      setQueuePrevParamsString(`#tab1${qs.stringify(formDataFromParamsObject)}`)

      if (!!stringParams) {
        setQueueParams(formDataFromParamsObject)
        updateHistory && history.push(`/queue#tab${activeTab}?${stringParams}`)
      } else {
        setQueueParams({})
        updateHistory && history.push(`/queue#tab${activeTab}`)
      }

      const { replacements, pagination } = await api.getReplacements(
        {
          page: queueCurrentPagination?.page || 1,
          items_per_page: queueCurrentPagination?.itemsPerPage,
          ...formDataFromParamsObject,
        },
        sourceQueueRef.current.token
      )
      setQueueTableDataIsLoading(false)

      if (replacements?.length > 0) {
        setQueueCurrentPagination(pagination || { itemsPerPage: 9, page: 1 })
      }

      if (replacements?.length === 0) {
        setQueueTableDataError('No results')
        setQueueTableData([])
      } else {
        setQueueTableDataError('')
        setQueueTableData(replacements)
      }
    } catch (error: any) {
      console.log(error.message)
      setQueueTableDataIsLoading(false)

      setQueueTableDataError(error.message || 'Something went wrong.')
    }
  }
  useEffect(() => {
    if (!isQueueFormMounted && queueTableDataIsLoading) {
      sourceQueueRef.current.cancel()
      sourceQueueRef.current = cancelTokenQueueRef.current.source()
      setQueueTableData([])
    }
  }, [isQueueFormMounted, queueTableDataIsLoading])

  useEffect(() => {
    if (!isPaymentFormMounted && paymentTableDataIsLoading) {
      sourcePaymentRef.current.cancel('')
      sourcePaymentRef.current = cancelTokenQueueRef.current.source()
      setPaymentTableData([])
    }
  }, [isPaymentFormMounted, paymentTableDataIsLoading])

  return (
    <TabsTableContext.Provider
      value={{
        activeTab,
        setActiveTab,

        // Payment Table State

        paymentTableData,
        setPaymentTableData,

        paymentTableDataIsLoading,
        setPaymentTableDataIsLoading,

        paymentTableDataError,
        setPaymentTableDataError,

        paymentCurrentPagination,
        setPaymentCurrentPagination,

        // Payment previous params

        paymentPrevParamsString,
        setPaymentPrevParamsString,

        // Payment memorized Params
        // Params are loaded  after tab change

        paymentParams,
        setPaymentParams,

        // Payments get table data function

        getPaymentsData,

        isPaymentFormMounted,
        setIsPaymentFormMounted,

        // Queue Table State

        queueTableData,
        setQueueTableData,

        queueTableDataIsLoading,
        setQueueTableDataIsLoading,

        queueTableDataError,
        setQueueTableDataError,

        queueCurrentPagination,
        setQueueCurrentPagination,

        // Payment previous params

        queuePrevParamsString,
        setQueuePrevParamsString,

        // Queue memorized params
        // Params are loaded  after tab change

        queueParams,
        setQueueParams,

        // Queue get table data function

        getQueueData,
        shouldQueueDataReload,
        setShouldQueueDataReload,

        isQueueFormMounted,
        setIsQueueFormMounted,
      }}
    >
      {children}
    </TabsTableContext.Provider>
  )
}

const useTabsTableContext = () => useContext(TabsTableContext)

export { TabsTableProvider, useTabsTableContext }
