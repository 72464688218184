import React, { useCallback } from 'react'
import ScheduledBanners from './sheduled-banners'
import EditModalBanner from './edit-modal-banner'
import { emptyItem } from '../initials'
import BannerForm from './form/banner-form'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { sendBanner } from 'src/store/admin-store'
import { useDispatch } from 'react-redux'

const Banners = () => {
  const dispatch = useDispatch()
  const callBack = useCallback(
    (banner: appliancepb.IBanner) => dispatch(sendBanner(banner)),
    //eslint-disable-next-line
    []
  )
  return (
    <>
      <ScheduledBanners />

      <BannerForm
        title="ADMIN_TITLE_BANNERS_0"
        callBack={callBack}
        type="save"
        initValues={{
          severity: emptyItem,
          message: emptyItem,
          customMessage: '',
          start: new Date(),
          end: null,
        }}
      />
      <EditModalBanner />
    </>
  )
}

export default Banners
