import React from 'react'
import {
  IconCheckCircledCheckedFilled,
  IconFormClearField,
  IconWarningTriangleFull,
  Tag,
  Text,
} from '@ftdr/blueprint-components-react'

export const TableStatusColorCodes = (value: any) => {
  switch (value) {
    case 'ORDERED':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconCheckCircledCheckedFilled}
          color={'success'}
          iconColor={'success'}
        >
          {value}
        </Tag>
      )
    case 'ORDERED_EXTERNAL':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconCheckCircledCheckedFilled}
          color={'success'}
          iconColor={'success'}
        >
          {value}
        </Tag>
      )
    case 'DELIVERED':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconCheckCircledCheckedFilled}
          color={'success'}
          iconColor={'success'}
        >
          {value}
        </Tag>
      )
    case 'NEEDS_INSTALLER':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconWarningTriangleFull}
          color={'warning'}
          iconColor={'warning'}
        >
          {value}
        </Tag>
      )

    case 'ORDER_ERROR':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconFormClearField}
          color={'secondary'}
          iconColor={'secondary'}
        >
          {value}
        </Tag>
      )
    case 'PAYMENT_ERROR':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconFormClearField}
          color={'secondary'}
          iconColor={'secondary'}
        >
          {value}
        </Tag>
      )
    case 'E_CARD_ERROR':
      return (
        <Tag
          size='small'
          textClassName='font-bold'
          startIcon={IconFormClearField}
          color={'secondary'}
          iconColor={'secondary'}
        >
          {value}
        </Tag>
      )
    default:
      return <Text variant='caption'>{value}</Text>
  }
}
