import React from 'react'
import { TextComponent as Text } from '../custom-fdr-components'
interface IMessage {
  error: boolean
  message: string | null
  id: string
}
const Message = ({ error, message, id }: IMessage): JSX.Element | null => {
  if (message) {
    return (
      <div className="py-4">
        <Text
          id={`message-error-4${id}`}
          className={error ? 'text-error-500 mt-2' : 'text-success-500 mt-2'}
        >
          {message}
        </Text>
      </div>
    )
  } else {
    return null
  }
}

export default Message
