import React, { useEffect, useState } from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import ProductCard from './product-card'
import { api } from 'src/utils/api'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import { ProgressIndicatorComponent as ProgressIndicator } from 'src/components/custom-fdr-components'

interface SearchResultsProps {
  results: appliancepb.IProduct[]
  replacementId: string
  isCustomer: boolean
}

export const SearchResults = ({
  results,
  replacementId,
  isCustomer,
}: SearchResultsProps) => {
  const addressId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.dispatch?.addressID ||
      state.replacement.data?.customer?.address?.ID
  )
  const partRequestId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.partRequestId ||
      state.replacement.data?.replacementDetails?.partRequestID
  )
  const dcov = useSelector((state: AppState) => state.survey.agentSurvey.dcov)

  const [products, setProducts] = useState<appliancepb.Product[]>([])

  useEffect(() => {
    const fetchResults = async () => {
      const promises: Promise<appliancepb.Product>[] = []
      results.forEach((product) => {
        promises.push(
          api.getReplacementProduct(
            replacementId,
            product.ID as string,
            addressId || '',
            partRequestId || '',
            dcov
          )
        )
      })
      try {
        const results = await Promise.all(promises)
        setProducts(results)
      } catch (e) {
        console.error(e)
      }
    }
    fetchResults()
  }, [partRequestId, addressId, results, dcov, replacementId])

  if (products.length) {
    return (
      <>
        {products.map((product, i) => (
          <ProductCard
            key={product.ID || i}
            product={product}
            isAgent={!isCustomer}
          />
        ))}
      </>
    )
  } else {
    return <ProgressIndicator data-testid="spinner" />
  }
}
