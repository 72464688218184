import yup, { valueSelected } from '../../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

const { DEFAULT_ERROR } = textTemplatesEnglish

export const outrightPaymentSchema = yup
  .object()
  .shape({
    paymentType: valueSelected(yup),
    contractID: yup.string().contractID(),
    firstName: yup.string().onlyLetters(),
    lastName: yup.string().onlyLetters(),
    billingState: valueSelected(yup),
    vendor: valueSelected(yup),
    modelNumber: yup.string().modelNumber(),
    date: yup.date().required(DEFAULT_ERROR),
    amount: yup.string().decimalAmount(),
  })
  .when((values, schema) => {
    if (values.paymentType.id !== '1') {
      if (values.paymentType.id === '3') {
        return schema.shape({
          dispatchID: yup.string().dispatchID(),
          orderID: yup.string().orderID(),
        })
      } else {
        return schema.shape({
          dispatchID: yup.string().dispatchID(),
        })
      }
    }
  })
