import React, { useMemo, createRef, useEffect, useState } from 'react'
import { Text } from '@ftdr/blueprint-components-react'
import { useMedia } from '../../../hooks/use-media'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import DeliveryDate from 'src/components/delivery-date/delivery-date'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'
import {
  stylesRowElem,
  stylesRow,
  stylesRowElemMobile,
  stylesRowMobile,
} from '../helpers'

const TitleRow: React.FC = () => {
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )
  const { replacementId } = useSelector(
    (state: AppState) => state.compareProducts
  )
  const refs = useMemo(
    () =>
      Array.from({ length: compareProducts.length }).map(() =>
        createRef<HTMLDivElement | HTMLHeadingElement>()
      ),

    [compareProducts]
  )
  const [height, setHeight] = useState<number>(0)
  const isMobile = useMedia('(max-width:767px)')

  useEffect(() => {
    refs.forEach((ref) => {
      if (ref.current!.clientHeight > height)
        setHeight(ref.current!.clientHeight)
    })
    //eslint-disable-next-line
  }, [refs])

  return (
    <div
      className={`flex mb-4 md:justify-center`}
      style={isMobile ? stylesRowMobile : stylesRow}
    >
      {compareProducts.map((product, i) => {
        return (
          <div
            key={product.ID}
            className="flex flex-col justify-between px-6"
            style={isMobile ? stylesRowElemMobile : stylesRowElem}
          >
            <div style={{ minHeight: height }} className="mb-2">
              <Text variant="heading-05" ref={refs[i]} color="primary">
                {product.description}
              </Text>
            </div>

            <Text variant="heading-06" className="mb-auto" color="primary">
              Model number:{' '}
              <span className="block font-normal">{product.modelNumber}</span>
            </Text>

            <DeliveryDate product={product} isHeader={true} />

            <Text variant="heading-06" className="lg:mb-6" color="primary">
              {formatCentsCurrency(
                (!replacementId
                  ? product.defaultPriceWithTax || product.customerPrice
                  : product.customerPrice) || 0
              )}
            </Text>
          </div>
        )
      })}
    </div>
  )
}

export default TitleRow
