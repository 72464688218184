import React from 'react'
import View from './view/view'
import { PaymentProvider } from 'src/hooks/use-payment-context'
import { ShoppingCartProvider } from 'src/hooks/use-shopping-cart-context'
import { FormsProvider } from 'src/hooks/use-forms-context'
import { CILPriceModalProvider } from 'src/hooks/use-cil-price-modal'
// Temporaty path =  /payment
export const Payments = () => (
  <PaymentProvider>
    <CILPriceModalProvider>
      <ShoppingCartProvider>
        <FormsProvider>
          <View />
        </FormsProvider>
      </ShoppingCartProvider>
    </CILPriceModalProvider>
  </PaymentProvider>
)
