export enum CONSTANTS {
    SET_STATUSES = 'SET_STATUSES',
    SET_STATUSES_LOADING = 'SET_STATUSES_LOADING',
    SET_STATUSES_ERROR = "SET_STATUSES_ERROR"
  }
  
  export interface IStatus {
      id:string
      value:string
      description:string
  }
  export interface StatusesStoreState {
    statuses: IStatus[]|null
    error:string|null 
    isLoading:boolean
  }
  
  type SetStatusesErrorAction ={
      type: CONSTANTS.SET_STATUSES_ERROR
      error:string|null
  }
  type SetStatusesAction ={
      type: CONSTANTS.SET_STATUSES
      statuses: IStatus[]|null
  }
  type SetStatusesLoadingAction = {
    type: CONSTANTS.SET_STATUSES_LOADING
    isLoading:boolean
  }
  
  
  export type StatusesAction = SetStatusesAction|SetStatusesErrorAction|SetStatusesLoadingAction
  
  