import React, { useEffect, useMemo } from 'react'
import {
  Input,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { useForm, Controller } from 'react-hook-form'
import { emptyItem } from 'src/components/adminForms/initials'
import { MenuItem } from 'src/utils/shared-types'
//import { api } from 'src/utils/api'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { addFeeFormSchema } from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { subCategories, subCategoriesMenuItems } from './sub-categories'
import { genereteServiceFeePriceCode } from '../utils/helpers'

interface IProps {
  onClose: () => void
  tenant: string
}
interface IForm {
  tenant: string
  price: string
  category: MenuItem
  subCategory: MenuItem
  description: string
}
const AddFeeForm: React.FC<IProps> = ({ onClose, tenant }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { addItemToShoppingCart } = useShoppingCartContext()

  const initValues = useMemo(
    () => ({
      tenant: tenant || '',
      description: '',
      price: '',
      category: {
        id: 'SERVICE FEE',
        label: 'SERVICE FEE',
        value: 'SERVICE FEE',
      },
      subCategory: emptyItem,
    }),
    [tenant]
  )
  const {
    watch,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(addFeeFormSchema),
    defaultValues: initValues,
  })
  const submitForm = (data: IForm) => {
    addItemToShoppingCart({
      sku: `${data.subCategory.id}${genereteServiceFeePriceCode(
        Math.floor(Number(data.price)) * 100
      )}`,
      vendor: data.tenant,
      unitPrice: Number(data.price) * 100,
      isTax: false,
      category: data?.category?.label,
      subcategory: data?.subCategory?.label?.toLowerCase(),
      description: data.description,
      isAvailableForZip: true,
    })

    onClose()
  }

  const description = watch('description')

  useEffect(() => {
    !!description && clearErrors('description')
    //eslint-disable-next-line
  }, [description])

  return (
    <div className="max-w-md">
      <Controller
        name="tenant"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-sku-1"
            error={errors.tenant?.message}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_SKU')}
            className="w-full"
            disabled={true}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              field.onChange(e)
            }}
            onBlur={field.onBlur}
          />
        )}
      />
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <Select
            id="category-outright-payment-form-category"
            autoComplete={false}
            disabled={true}
            error={errors.category?.message}
            selected={field.value}
            formField
            label={localizedText('LABEL_CATEGORY')}
            className="w-full md:w-56 mb-6"
            options={[
              { id: 'SERVICE FEE', label: 'SERVICE FEE', value: 'SERVICE FEE' },
            ]}
            onSelect={field.onChange as SelectHandler<SelectOption>}
            onBlur={field.onBlur}
            placeholder="Select an option"
          />
        )}
      />
      <Controller
        name="subCategory"
        control={control}
        render={({ field }) => (
          <Select
            id="category-outright-payment-form-subcategory"
            autoComplete={false}
            error={errors.subCategory?.message}
            selected={field.value}
            formField
            label={localizedText('LABEL_SUBCATEGORY')}
            className="w-full md:w-56 mb-6"
            options={subCategoriesMenuItems}
            onSelect={(e) => {
              const event = e as MenuItem
              field.onChange(e)
              setValue(
                'description',
                subCategories
                  .find((v) => v.prefix === event.id)
                  ?.description(tenant) || ''
              )
            }}
            onBlur={field.onBlur}
            placeholder="Select an option"
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-descritpion"
            error={errors.description?.message}
            value={description}
            formField
            disabled={true}
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_DESCRIPTION')}
            className="w-full"
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-price"
            error={errors.price?.message}
            inputMode="decimal"
            startEnhancer={() => <Text>$</Text>}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_PRICE')}
            className="w-full"
            onChange={(e) => {
              const val = e.target.value
              const step1Regex = /^\d+\.?$/
              const step2Regex = /^\d+(\.\d{1,2})?$/

              if (val === '' || step1Regex.test(val) || step2Regex.test(val)) {
                field.onChange(e)
              }
            }}
            onBlur={(e) => {
              e.target.value = Number(e.target.value).toFixed(2)
              field.onChange(e)
              field.onBlur()
            }}
          />
        )}
      />

      <div className="flex mt-8">
        <Button
          label={localizedText('BUTTON_ADD_TO_CART')}
          size="medium"
          onClick={() => {
            handleSubmit(submitForm)()
          }}
        />
        <Button
          className="ml-6"
          label={localizedText('BUTTON_CANCEL')}
          size="medium"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default AddFeeForm
