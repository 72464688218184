import React, { useEffect, useState } from 'react'
import { useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from '../custom-fdr-components'
import { useAdminContext } from '../../hooks/use-admin-context'
import { api } from 'src/utils/api'
import { createData, createdEndpoint } from './helpers'
import { isSKU } from 'src/utils/validation-utils'

const AddButton: React.FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    setAddFileMessage,
    isLoading,
    setIsLoading,
    selectedVendor,
    setSku,
    setZip,
    setPrice,
    sku,
    zip,
    price,
    adminFunction: { value },
  } = useAdminContext()
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  const onIsDisabled = () => {
    if (value === 'special_prices') {
      setIsDisabled(!isSKU(sku) || !price)
    } else if (value === 'no_delivery') {
      setIsDisabled(!zip)
    } else if (value) {
      setIsDisabled(!isSKU(sku))
    } else {
      setIsDisabled(true)
    }
  }
  const onClickHandler = async () => {
    const data = createData(value, {
      sku,
      zip,
      price,
      vendor: selectedVendor.id,
    })
    const path =
      value === 'preferred'
        ? createdEndpoint(value, '')
        : createdEndpoint(
            value === 'no_delivery' ? value.replace('_', '-') : value,
            selectedVendor.id
          )
    setIsLoading(true)

    const res = await api.pushAdminFileFromInputs(path, data)
    setIsLoading(false)
    setAddFileMessage(res)
    setSku('')
    setZip('')
    setPrice('')
  }
  useEffect(onIsDisabled, [value, sku, price, zip])
  return (
    <Button
      id="add-button"
      className="mt-6"
      size="medium"
      label={localizedText('ADMIN_BUTTON_ADD')}
      onClick={onClickHandler}
      disabled={isDisabled}
      loading={isLoading}
    />
  )
}

export default AddButton
