import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { IAttributeGroups } from './compare-store.types'

export const setUniqAttributes = (
  data: appliancepb.IProduct[]
): IAttributeGroups => {
  const initialAttributeGroups: IAttributeGroups = {}

  let initialAttributeNames: string[] = []

  data.forEach((_, i) =>
    data[i].attributeGroups?.forEach(({ attributeGroupName: key }) => {
      if (typeof key === 'string') {
        initialAttributeGroups[key] = []
        !initialAttributeNames.includes(key) && initialAttributeNames.push(key)
      }
    })
  )

  data.forEach((elem) => {
    elem.attributeGroups?.forEach((group) => {
      const key = group.attributeGroupName
      if (typeof key === 'string' && group.attributes?.length) {
        if (initialAttributeNames.includes(key)) {
          initialAttributeGroups[key] = Array.from(
            new Set([
              ...initialAttributeGroups[key],
              ...group.attributes.map((elem) => elem.attribute as string),
            ])
          )
        }
      }
    })
  })

  return initialAttributeGroups
}
