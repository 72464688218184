import axios, { AxiosResponse } from "axios"
import { isErrorHandlerObject } from "./helpers"
import { ErrorHandler, ErrorHandlerMany, THttpError } from "./types"


export default class ErrorHandlerRegistry {
    private handlers = new Map<string | number, ErrorHandler>()

    private parent: ErrorHandlerRegistry | null | undefined

    constructor(
        parent: ErrorHandlerRegistry | null | undefined,
        input?: ErrorHandlerMany
    ) {
        if (typeof parent !== "undefined") this.parent = parent
        if (typeof input !== "undefined") this.registerMany(input)
    }

    // allow to register an handler
    public register(key: string, handler: ErrorHandler) {
        this.handlers.set(key, handler)
        return this
    }

    // unregister a handler
    public unregister(key: string) {
        this.handlers.delete(key)
        return this
    }

    // pass an object and register all keys/value pairs as handler.
    public registerMany(input: ErrorHandlerMany) {
        for (const [key, value] of Object.entries(input)) {
            this.register(key, value)
        }
        return this
    }

    //handle error seeking for key
    private handleError(
        seek: string[],
        error: THttpError
    ) {
        seek.forEach((key) => {
            const handlers = [
                this.handlers.get(key),
                this.parent?.handlers.get(key),
            ].filter((v) => v !== undefined)

            handlers.forEach((handler) => {
                if (handler) {
                    if (typeof handler === "string") throw new Error(handler)
                    if (typeof handler === "function") {
                        const result = handler(error)
                        if (isErrorHandlerObject(result)) {
                            throw new Error(result.message)
                        } else {
                            throw new Error(error?.message || "No message here !!")
                        }
                    }
                    if (isErrorHandlerObject(handler)) {
                        throw new Error(handler.message)
                    }
                }
            })
        })
    }

    public responseHandler(response: AxiosResponse<any>) {
        const config = response?.config
        if (config.raw) {
            return response
        }
        //Uncomment if needed
        if (response.status === 200) {
            const data = response?.data
            // if (!data) {
            //     throw new Error("API Error. No data!")
            // }
            return data
        }
        throw new Error("API Error! Invalid status code!")
    }

    public resposeErrorHandler(error: THttpError) {

        if (error === null){
            throw new Error("Unrecoverrable error!! Error is null!")
        }
            
        if (axios.isAxiosError(error)) {
            const response = error?.response
           const config = error?.config
    
            if (config?.raw) throw error
            const set = new Set([
                String(response?.status),
                String(response?.statusText)
            ])

            const uniqeSeekers:string[] = Array.from(set)
            const seekers = uniqeSeekers.filter(
                (v) => v !== "undefined"
            )

            this.handleError(seekers, error)

        } else if (error instanceof Error) {
            return this.handleError([error.name], error)
        }
        //if nothings works, throw away
        throw error
    }
}