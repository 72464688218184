export const reasons = [
  {
    id: '1',
    label: 'Price Lowered: Price Lower in Vendor Portal',
    value: 'Price Lowered: Price Lower in Vendor Portal',
  },
  {
    id: '2',
    label: 'Price Increase: Price Higher in Vendor Portal',
    value: 'Price Increase: Price Higher in Vendor Portal',
  },
  {
    id: '3',
    label: 'Price Lowered: Missing Covered Install Code',
    value: 'Price Lowered: Missing Covered Install Code',
  },
  {
    id: '4',
    label: 'Price Lowered: Order for Frontdoor Employee',
    value: 'Price Lowered: Order for Frontdoor Employee',
  },
  {
    id: '5',
    label: 'Price Lowered: Deleted Install Code',
    value: 'Price Lowered: Deleted Install Code',
  },
]
