import React, { Dispatch } from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { api } from 'src/utils/api'

interface IProps {
  status: string
  shopLink: string
  id: string
  callback: Dispatch<boolean>
}
const LinkGenarator: React.FC<IProps> = ({
  status,
  shopLink,
  id,
  callback,
}) => {
  const isDevOrTest =
    api.config.ENV === 'development' || api.config.ENV === 'test'
  //For now,  only in dev and test
  if (isDevOrTest) {
    if (status === 'CREATED' || status === 'ACTIVATED') {
      return (
        <a
          className="text-caption text-interactive hover:text-interactive-900 inline-block cursor-pointer"
          href={shopLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {id}
        </a>
      )
    } else if (status === 'CONFIRMED' || status === 'ORDERED') {
      return (
        <Text
          variant="caption"
          className="text-interactive hover:text-interactive-900 inline-block cursor-pointer"
          onClick={() => callback(true)}
        >
          {id}
        </Text>
      )
    } else {
      return <Text>{id}</Text>
    }
  } else {
    return <Text>{id}</Text>
  }
}

export default LinkGenarator
