import React from 'react'
import { isAHS } from '../../../utils/tenant-helper'
import { TextColorOption, TextProps } from '@ftdr/blueprint-components-react'

const Icon: React.FC<TextProps<any>> = (props) => {
  const defaultProps = isAHS() ? { color: 'primary' as TextColorOption, ...props } : props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='100%' width='100%' viewBox='0 0 200 200'>
      <path fill={defaultProps.color} d='M104.167 156.035c-6.602.567-12.736 1.143-18.877 1.643-2.653.217-5.321.42-7.98.387-18.403-.234-25.828-11.777-18.739-28.69.917-2.187 1.097-5.183.433-7.457-9.685-33.152-19.572-66.245-29.581-99.924-5.674 0-11.461.032-17.247-.008-6.378-.044-10.235-3.276-10.163-8.43.07-4.922 3.85-8.155 9.917-8.229 7.496-.091 14.994-.01 22.49-.025 4.984-.009 8.118 2.338 9.507 7.154 2.061 7.15 4.214 14.274 6.396 21.637h5.633c44.148 0 88.297-.003 132.446.002 9.67.001 12.591 3.501 10.777 12.946-4.327 22.532-8.756 45.044-12.957 67.599-1.007 5.403-3.761 7.973-9.23 8.624-34.538 4.112-69.05 8.445-103.59 12.542-2.88.341-4.073 1.318-4.42 4.081-.401 3.198 1.596 4.557 3.996 4.534 7.96-.073 15.917-.54 23.873-.907a83646.33 83646.33 0 0 0 78.326-3.655c3.503-.164 6.294.96 7.573 4.414 1.605 4.335-1.683 8.489-6.762 8.673-18.968.69-37.938 1.33-56.904 2.067-4.823.187-9.634.66-14.917 1.022m33.86-50.69V85.409H104.23v29.945l33.799-4.08v-5.927M87.485 77.284h8.338V51.139H55.42c2.484 8.327 4.744 16.29 7.345 24.141.323.975 2.438 1.877 3.75 1.915 6.652.193 13.313.089 20.97.089m26.044-26.436h-9.198v26.13h33.386v-26.13h-24.188m59.94-.002H146.25v26.022h30.408l4.872-26.022h-8.06M73.496 111.96l2.146 6.897 20.135-2.496V85.306H65.642c2.577 8.832 5.07 17.372 7.854 26.654m98.842-13.127 2.57-13.376h-28.686v24.842l24.38-2.96c.494-2.152.917-3.92 1.297-5.697.139-.648.173-1.318.439-2.809z' />
      <path fill={defaultProps.color} d='M53.454 174.734c4.656-8.464 12.043-11.874 20.105-9.522 7.477 2.182 12.26 9.513 11.55 17.702-.662 7.614-6.633 13.815-14.646 14.637-13.172 1.352-21.738-12.006-17.009-22.817zM179.46 181.058c-1.299 10.336-7.458 16.475-16.323 16.663-8.003.17-14.981-5.7-16.473-13.856-1.553-8.488 4.106-17.09 12.412-18.868 9.105-1.948 17.928 3.446 19.91 12.207.256 1.132.32 2.307.475 3.854z' />
    </svg>
  )
}

export default Icon
