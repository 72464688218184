import { Input, Select, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button, TextComponent as Text } from 'src/components/custom-fdr-components'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { api } from '../../utils/api'
import { MenuItem } from 'src/utils/shared-types'
import qs from 'query-string'
import { TrackingOrderDetails } from '../tracking-order-details'
import { isAlphanumberic, isNumber } from '../../utils/validation-utils'

export type TTrackingParams = {
  purchaseOrderID?: string
  outrightPaymentID?: string
}
export const Tracking = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const history = useHistory()
  const location = history.location
  const params = qs.parse(location.search)
  const vendorParam = params?.vendor as string
  const orderIdParam = params?.order_id as string
  const purchaseOrderParam = params?.purchase_order as string
  const outrightPaymentIDParam = params?.outright_payment_id as string
  const [loading, setLoading] = useState(false)
  const [vendorOptions, setVendorOptions] = useState<MenuItem[]>([])
  const [selectedVendor, setSelectedVendor] = useState<string>(
    vendorParam || '',
  )

  const [orderID, setOrderId] = useState<string>(orderIdParam || '')
  const [purchaseOrder, setPurchaseOrder] = useState<string | null>(
    purchaseOrderParam,
  )

  const [search, setSearch] = useState<boolean>(false)

  const pageloadedWithParams =
    vendorParam !== undefined &&
    (orderIdParam !== undefined ||
      purchaseOrderParam !== undefined ||
      outrightPaymentIDParam !== undefined)

  const submit = async () => {
    setSearch(true)
    history.push('/status')
  }

  useEffect(() => {
    purchaseOrderParam && setPurchaseOrder(purchaseOrderParam || '')
    //eslint-disable-next-line
  }, [search])

  const isButtonDisabled = () => {
    if (selectedVendor && selectedVendor === 'GE') {
      return !(orderID || purchaseOrder)
    } else if (selectedVendor) {
      return !orderID
    } else {
      return true
    }
  }
  useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        setOrderId('')
        setPurchaseOrder('')
        setSelectedVendor('')
        search && setSearch(false)
      }
    })
    //eslint-disable-next-line
  }, [history, search, location.pathname])

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        setLoading(true)
        const vendors = await api.getVendors()
        const vendorsArray: MenuItem[] = []
        vendors.vendors.forEach((vendor) => {
          vendor.ID !== 'PC' &&
          vendor.ID !== 'BH' &&
          vendorsArray.push({
            id: vendor.ID as string,
            value: vendor.ID as string,
            label: vendor.name as string,
          })
        })
        setVendorOptions(vendorsArray)
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    }
    fetchVendors()
  }, [])

  if (pageloadedWithParams || search) {
    return (
      <TrackingOrderDetails
        vendor={selectedVendor as string}
        orderID={orderID as string}
        params={{
          purchaseOrderID: purchaseOrder || undefined,
          outrightPaymentID: outrightPaymentIDParam || undefined,
        }}
      />
    )
  }

  return (
    <div className='-mt-6 lg:-mt-0 flex flex-col items-center sm:block max-w-sm md:max-w-md m-auto lg:m-0'>
      <Text variant='heading-03' className='mb-8 text-center md:text-left'>
        {localizedText('TRACKING_LOOKUP_TITLE')}
      </Text>
      <div className='grid gap-4 w-full sm:w-auto'>
        <Select
          id='tracking-select-vendor'
          required
          formField
          formFieldClassName='w-full sm:w-auto'
          className='w-full md:w-56 max-w-full'
          label={localizedText('TRACKING_VENDOR_LABEL')}
          placeholder='Select an option'
          options={vendorOptions}
          onSelect={(item) => {
            const option = { ...item } as MenuItem
            setSelectedVendor(option.value)
            option.value === 'WP' && setPurchaseOrder('')
          }}
          disabled={loading}
        />
        <Input
          id='tracking-oi'
          required
          formField
          formFieldClassName='w-full sm:w-auto'
          className='w-full md:w-56 max-w-full'
          label={localizedText('TRACKING_ORDER_NUMBER_LABEL')}
          placeholder='Order Number'
          value={orderID as string}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            isAlphanumberic(e.target.value) && setOrderId(e.target.value)
          }
          disabled={!!(selectedVendor === 'GE' && purchaseOrder)}
        />
        {selectedVendor === 'GE' && (
          <Input
            id='tracking-purchase'
            required
            formField
            formFieldClassName='w-full sm:w-auto'
            className='w-full md:w-56 max-w-full'
            label={localizedText('TRACKING_PURCHASE_ORDER_LABEL')}
            placeholder={localizedText('TRACKING_PURCHASE_ORDER_LABEL')}
            value={purchaseOrder as string}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              isNumber(e.target.value) && setPurchaseOrder(e.target.value)
            }
            disabled={!!orderID}
          />
        )}
      </div>
      <Button
        id='tracking-submit'
        label='Track'
        className='mt-8 w-full md:w-auto flex justify-center md:inline-block'
        onClick={submit}
        disabled={isButtonDisabled()}
      />
    </div>
  )
}
