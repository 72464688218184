import React from 'react'
import { useAdminContext } from '../../hooks/use-admin-context'
import { Select, useAppContext } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from '../custom-fdr-components'
import { MenuItem } from 'src/utils/shared-types'
import Vendor from './vendor'
import AddForm from './add-form'
import Edit from './edit'
import { emptyCacheObject, emptyItem, initialActions } from './initials'
import DownloadButton from './download-button'

const Actions: React.FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    error,
    setError,
    adminFunction,
    selectedAction,
    setSelectedAction,
    setSelectedVendor,
    setAddFileMessage,
    setIsFileDownloaded,
    setSku,
    setZip,
    setPrice,
  } = useAdminContext()

  return (
    <div>
      <Select
        id="actions-select-type"
        required
        formField
        label={localizedText('ADMIN_LABEL_ACTION')}
        className="w-full md:w-56 mb-6"
        options={
          adminFunction.value === 'special_prices'
            ? initialActions
            : initialActions.filter((v) => v.id !== '4')
        }
        selected={selectedAction}
        onSelect={(item) => {
          const option = { ...item } as MenuItem
          setSelectedVendor(emptyItem)
          setSku('')
          setZip('')
          setPrice('')
          setSelectedAction(option)
          setAddFileMessage(emptyCacheObject)
          setError(null)
          setIsFileDownloaded(false)
        }}
      />
      {selectedAction.value === 'download' &&
        adminFunction.value !== 'preferred' && <Vendor showButton={true} />}

      {selectedAction.value === 'download' &&
        adminFunction.value === 'preferred' && (
          <>
            <DownloadButton disabledOption={false} className="m-0" />
          </>
        )}

      {selectedAction.value === 'add' && (
        <AddForm
          showPrice={adminFunction.value === 'special_prices'}
          showZIP={adminFunction.value === 'no_delivery'}
          showSKU={
            adminFunction.value === 'special_prices' ||
            adminFunction.value !== 'no_delivery'
          }
          showRefreshMessage={
            adminFunction.value === 'special_prices' &&
            (selectedAction.value === 'add' ||
              selectedAction.value === 'replace')
          }
        />
      )}
      {(selectedAction.value === 'replace' ||
        selectedAction.value === 'update') && (
        <Edit
          type={selectedAction.value}
          showRefreshMessage={
            adminFunction.value === 'special_prices' &&
            (selectedAction.value === 'replace' ||
              selectedAction.value === 'update')
          }
        />
      )}
      {error && (
        <Text id="action-select-error" className="text-error-500 mt-2">
          {error}
        </Text>
      )}
    </div>
  )
}

export default Actions
