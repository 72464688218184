import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeIcemakerAddon } from 'src/store/survey-store'
import { RadioGroup } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from '../custom-fdr-components'
import { AppState, TIcemakerAddon } from 'src/utils/shared-types'

interface IProps {
  isRequired: boolean
}

const IcemakerAddonField: React.FC<IProps> = ({ isRequired }) => {
  const dispatch = useDispatch()
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon
  )

  useEffect(() => {
    !isRequired && dispatch(changeIcemakerAddon(''))
    //eslint-disable-next-line
  }, [isRequired])

  const radios = useMemo(() => {
    return [
      { value: '', label: 'No icemaker add-on' },
      { value: '1', label: 'Authorized for icemaker add-on' },
      { value: '2', label: 'Authorized for optional second icemaker add-on' },
    ]
  }, [])

  return isRequired ? (
    <>
      <Text
        textTemplateKey="SURVEY_ICEMAKER_ADDON_LABEL"
        className="mb-2 font-bold"
      />
      <RadioGroup
        id="trim-kit-size-button-group"
        value={icemakerAddon}
        onChange={(value) =>
          dispatch(changeIcemakerAddon(value as TIcemakerAddon))
        }
        radios={radios}
        orientation="vertical"
        groupWidth="full"
        justify="start"
        label="Choose icemaker add-on"
        className="mb-5"
        color="interactive"
      />
    </>
  ) : null
}

export default IcemakerAddonField
