import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  AgregatedProcessPaymentResponse,
  PaymentsMethodMfeExposedApi,
} from '@ftdr/payment-method-micro-frontend'
import { useReplacement } from '../../hooks/use-replacement'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import {
  Checkbox,
  Image,
  Input,
  MaskedInput,
  TextArea,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  ProgressIndicatorComponent as ProgressIndicator,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { api } from '../../utils/api'
import { Message } from '../../components/message'
import {
  formatCentsCurrency,
  formatPhone,
} from '../../utils/internationalization-helper'
import { TermsAndConditionsDialog } from './terms-and-conditions-dialog'
import {
  AppState,
  RequestSpecificStatus,
  RequestStatus,
} from 'src/utils/shared-types'
import { PaymentMFE } from 'src/components/payment-mfe'
import { payment3_paymentmethodpb } from '@ftdr/payment3_paymentmethod_coordinator-js-client'
import { phoneValidation } from '../../utils/validation-utils'
import { addReplacement } from '../../store/compare-store'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { createShortWarrantyMessage } from 'src/utils/helper'
import { refrigeratorIcemakerList } from 'src/utils/refrigerator-icemaker-list'
import { isHSA } from 'src/utils/tenant-helper'
import { trimKitList } from 'src/utils/trim-kit-list'
import DeliveryDate from 'src/components/delivery-date/delivery-date'

interface CheckoutPageParams {
  replacementId: string
  productId: string
}

type NullableProduct = appliancepb.Product | null

export const CheckoutPage = () => {
  const color = isHSA() ? 'primary' : 'interactive'
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const isEnvStaging = api.config.ENV === 'staging'

  const submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi> = useRef(null)

  const history = useHistory()
  const dispatch = useDispatch()
  const { replacementId, productId } = useParams<CheckoutPageParams>()
  const { replacement } = useReplacement(replacementId)
  const id = replacementId || ''
  const partRequestId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.partRequestId ||
      state.replacement.data?.replacementDetails?.partRequestID
  )
  const dcov = useSelector((state: AppState) => state.survey.agentSurvey.dcov)
  const [productAvailability, setProductAvailability] = useState<
    NullableProduct
  >(null)
  const [product, setProduct] = useState<NullableProduct>(null)
  const [isGiftCard, setIsGiftCard] = useState<boolean>(false)
  const [productStatus, setProductStatus] = useState<RequestStatus>('pending')
  const submitCallbackRef = useRef<(() => void) | null>(null)
  const [submitStatus, setSubmitStatus] = useState<RequestSpecificStatus>({
    type: 'pending',
    message: '',
  })
  const [showingTermsAndConditions, showTermsAndConditions] = useState(false)
  const [editingPhone, setEditingPhone] = useState(false)
  const [editedPhone, setEditedPhone] = useState<string>(
    replacement?.customer?.phone || ''
  )
  const [currentPhone, setCurrentPhone] = useState<string>('')
  const [alternativePhone, setAlternativePhone] = useState<string>('')
  const [alternativeName, setAlternativeName] = useState<string>('')
  const [validationError, setValidationError] = useState<string>('')
  const deliveryInstructionsRef = useRef<HTMLTextAreaElement | null>(null)
  const [agreements, setAgreements] = useState({
    dimensions: false,
    termsAndConditions: false,
    emailConfirmation: false,
    cannotCancel: false,
    restockingFee: false,
    addons: false,
  })

  const isOver3k = product && product.customerPrice > 3000 * 100 // price is in cents

  const addressId = replacement?.customer?.address?.ID || ''

  const canSubmit = Boolean(
    !isGiftCard
      ? agreements.dimensions &&
          agreements.termsAndConditions &&
          agreements.restockingFee
      : agreements.dimensions &&
          agreements.termsAndConditions &&
          agreements.emailConfirmation &&
          agreements.cannotCancel
  )

  const parsedUnit =
    product?.dimensions?.unit === 'in'
      ? 'inches'
      : product?.dimensions?.unit || ''

  const measure = `${product?.dimensions?.height}h x ${product?.dimensions?.width}w x ${product?.dimensions?.depth}d`

  const cashInLieu = useSelector(
    (state: AppState) => state.replacement.data?.replacementDetails?.cashInLieu
  )

  const hasUnitInfo = Boolean(
    (replacement?.customer?.address?.unitType &&
      replacement?.customer?.address?.unitType.length > 0) ||
      (replacement?.customer?.address?.unitValue &&
        replacement?.customer?.address?.unitValue.length > 0)
  )
  const hasStreet2 = Boolean(
    replacement?.customer?.address?.street2 &&
      replacement?.customer?.address?.street2.length > 0
  )

  const submit = async () => {
    setSubmitStatus({ type: 'loading', message: '' })
    const submitCallback = submitCallbackRef.current
    if (!submitCallback) {
      if (product && product.customerPrice) {
        console.warn('There was an error initializing the submit callback')
        return
      }
      submitNoCost()
      return
    }
    submitCallback()
  }

  const submitNoCost = async () => {
    const isEditedPhone =
      !!currentPhone && currentPhone !== replacement?.customer?.phone
    const phone = isEditedPhone ? `\nEdited Phone: ${editedPhone}` : ''
    const alternativePhoneNumber = alternativePhone
      ? `\nAlternate Phone: ${alternativePhone}`
      : ''
    const alternativeDeliveryName = alternativeName
      ? `\nAlternate Name: ${alternativeName}`
      : ''
    const alternativeInfo =
      phone + alternativeDeliveryName + alternativePhoneNumber
    const deliveryInstructions = deliveryInstructionsRef.current?.value || ''
    const data = {
      deliveryInstructions: `${deliveryInstructions}${alternativeInfo}`,
      confirmedDimensions: true,
      agreesToTermsAndConditions: true,
    }

    try {
      await api.purchaseReplacement(replacementId, productId, data)
      setSubmitStatus({ type: 'ok', message: '' })
      history.push(`/${replacementId}/shop/${productId}/checkout/confirmation`)
    } catch (err) {
      setSubmitStatus({ type: 'error', message: err as string })
      return err
    }
  }
  const submitStripePayment = async () => {
    setSubmitStatus({ type: 'loading', message: '' })
    const stripeRef = submitPaymentRef.current
    if (!stripeRef) {
      if (product && product.customerPrice) {
        console.warn('There was an error initializing the submit callback')
        return
      }
      await submitNoCost()
      return
    }
    try {
      const result = await submitPaymentRef.current!.processPayment({
        stripe: {
          owner: {
            ownerIdentity: {
              type:
                payment3_paymentmethodpb.OwnerIdentityType
                  .ReplacementIdentityType,
              replacementIdentity: {
                replacementID: replacementId,
              },
            },
          },
        },
      })
      if (result) {
        await handleStripeResponse(result)
      }
    } catch (err) {
      setSubmitStatus({
        type: 'error',
        message: textTemplatesEnglish.ERROR_CUSTOMER,
      })
      console.log(err)
    }
  }

  const handleStripeResponse = async (res: AgregatedProcessPaymentResponse) => {
    const deliveryInstructions = deliveryInstructionsRef.current?.value || ''
    if (res.stripe?.type === 'stripe_success') {
      const cardDetails =
        res.stripe.stripeSuccessResponse?.paymentMethod?.details?.cc
      const ID =
        res.stripe.stripeSuccessResponse?.paymentMethod?.paymentMethodID
      const expirationMonth = cardDetails?.expirationDate?.month as number
      let expMonth: string

      if (expirationMonth < 10) {
        expMonth = `0${expirationMonth}`
      } else {
        expMonth = expirationMonth.toString()
      }

      const paymentDetails: appliancepb.IPaymentDetails = {
        ID,
        brand: cardDetails?.brandName,
        expMonth,
        expYear: cardDetails?.expirationDate?.year?.toString(),
        lastFour: cardDetails?.last4,
        transactionMessage: res.stripe.type,
        country: cardDetails?.billingDetails?.country,
        paymentProcessor: appliancepb.PaymentDetails.processor.STRIPE,
      }
      const isEditedPhone =
        !!currentPhone && currentPhone !== replacement?.customer?.phone
      const phone = isEditedPhone ? `\nEdited Phone: ${editedPhone}` : ''
      const data = {
        paymentDetails,
        deliveryInstructions: `${deliveryInstructions}${phone}`,
        confirmedDimensions: true,
        agreesToTermsAndConditions: true,
      }
      try {
        await api.purchaseReplacement(replacementId, productId, data)
        setSubmitStatus({ type: 'ok', message: '' })
        history.push(
          `/${replacementId}/shop/${productId}/checkout/confirmation`
        )
      } catch (err) {
        setSubmitStatus({ type: 'error', message: err as string })
      }
    } else if (res.stripe?.type === 'stripe_failure') {
      const paymentDetails: appliancepb.IPaymentDetails = {
        transactionMessage: res.stripe.type,
        paymentProcessor: appliancepb.PaymentDetails.processor.STRIPE,
        response: res.stripe.stripeFailureResponse?.message,
      }

      const isEditedPhone =
        !!currentPhone && currentPhone !== replacement?.customer?.phone
      const phone = isEditedPhone ? `\nEdited Phone: ${editedPhone}` : ''
      const data = {
        paymentDetails,
        deliveryInstructions: `${deliveryInstructions}${phone}`,
        confirmedDimensions: true,
        agreesToTermsAndConditions: true,
      }
      try {
        await api.purchaseReplacement(replacementId, productId, data)
        setSubmitStatus({ type: 'error', message: '' })
      } catch (err) {
        setSubmitStatus({ type: 'error', message: err as string })
      }
    }
  }
  useEffect(() => {
    validationError && phoneValidation(currentPhone) && setValidationError('')
    //eslint-disable-next-line
  }, [currentPhone])

  useEffect(() => {
    const getProduct = async () => {
      setProductStatus('loading')
      try {
        const product = await api.getReplacementProduct(
          replacementId,
          productId,
          addressId,
          replacement?.replacementDetails?.partRequestID || ''
        )
        setIsGiftCard(/^BH-/.test(product?.ID as string))
        setProduct(product)
        setProductStatus('ok')
      } catch (err) {
        console.error(err)
        setProductStatus('error')
      }
    }
    getProduct()
    //eslint-disable-next-line
  }, [replacementId, productId, addressId, replacement])

  const getProductAvailability = async () => {
    try {
      const productAvailability = await api.getReplacementProduct(
        id,
        productId,
        addressId || '',
        partRequestId || '',
        dcov
      )
      setProductAvailability(productAvailability)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    dispatch(addReplacement(replacementId))
    getProductAvailability()
    //eslint-disable-next-line
  }, [])

  return (
    <section className="-mt-8 lg:-mt-0">
      {showingTermsAndConditions && (
        <TermsAndConditionsDialog
          onClose={() => showTermsAndConditions(false)}
          isGiftCard={isGiftCard}
        />
      )}
      {product ? (
        <article className="grid gap-7">
          {/* checkout */}
          <div>
            {/* not using Text as we need breakpoint-based text variants and they're not available yet */}
            <Text
              className="font-display font-bold text-5 lg:text-11"
              textTemplateKey="CHECKOUT_TITLE"
            />
            <div
              className={`mt-8 flex ${
                isGiftCard ? ' flex-col md:flex-row' : ' flex-row'
              }`}
            >
              <figure className="md:mr-4 md:w-48" id="checkout-page-image-1">
                <Image
                  src={product.images[0]?.url || '/noimage.jpg'}
                  alt="Product Image"
                />
              </figure>
              <div className="flex-grow-0 mt-4">
                <Text
                  id="checkout-page-product-description-1-m"
                  variant="heading-06"
                  className="lg:hidden"
                >
                  {product.description}
                </Text>
                <Text
                  id="checkout-page-product-description-1-d"
                  variant="heading-04"
                  className="hidden lg:block"
                >
                  {product.description}
                </Text>
                {!isGiftCard && (
                  <div className="hidden lg:block">
                    <Text
                      id="checkout-page-product-dimension-1"
                      textTemplateKey="CHECKOUT_PRODUCT_DIMENSIONS_TITLE"
                      textTemplateData={{ unit: parsedUnit || '' }}
                    />
                    <Text id="checkout-page-product-measure-1">{measure}</Text>
                  </div>
                )}
                {!productAvailability
                  ? !product.IsAvailableForZip && (
                      <Text
                        id="product-detail-page-product-for-zip"
                        variant="heading-05"
                        className="mt-4"
                        color="error"
                        textTemplateKey="PRODUCT_UNAVAILABLE"
                      />
                    )
                  : !productAvailability.IsAvailableForZip && (
                      <Text
                        id="product-detail-page-product-for-zip"
                        variant="heading-05"
                        className="mt-4"
                        color="error"
                        textTemplateKey="PRODUCT_UNAVAILABLE"
                      />
                    )}
                {!productAvailability ? (
                  <DeliveryDate product={product} isHeader={true} />
                ) : (
                  <DeliveryDate product={productAvailability} isHeader={true} />
                )}
                <Text
                  id="checkout-page-product-customer-price-1-m"
                  variant="heading-06"
                  className="lg:hidden"
                >
                  {!isGiftCard
                    ? formatCentsCurrency(product.customerPrice)
                    : formatCentsCurrency(cashInLieu)}
                </Text>

                <Text
                  id="checkout-page-product-customer-price-1-d"
                  variant="heading-04"
                  className="hidden lg:block mt-6"
                >
                  {!isGiftCard
                    ? formatCentsCurrency(product.customerPrice)
                    : formatCentsCurrency(cashInLieu)}
                </Text>
                {!isGiftCard && (
                  <Text
                    id="checkout-page-product-warranty"
                    variant="caption"
                    className="lg:mt-2"
                  >
                    {isOver3k
                      ? localizedText('CHECKOUT_WARRANTY_OVER_3K')
                      : createShortWarrantyMessage(
                          product.warrantyCoverageMessage,
                          'warranty'
                        ) || localizedText('CHECKOUT_WARRANTY')}
                  </Text>
                )}
              </div>
            </div>
          </div>
          {/* contact */}
          <div>
            <Text
              variant="heading-04"
              className="mb-4"
              textTemplateKey="CHECKOUT_CONTACT_INFO_TITLE"
            />
            <div className="grid gap-4">
              <div className="inline-block">
                <Message id="checkout-page">
                  <Text
                    id="checkout-page-product-giftcard-info"
                    className="inline"
                    textTemplateKey={
                      !isGiftCard
                        ? 'CHECKOUT_WARNING_CONTACT_INFO'
                        : 'CHECKOUT_WARNING_CONTACT_GIFT_CARD_INFO'
                    }
                  />
                  {isGiftCard && <a href="tel:8003455535">800-345-5535.</a>}
                </Message>
              </div>
              <div>
                <Text id="checkout-page-customer-name">
                  {replacement?.customer?.name}
                </Text>
                <Text id="checkout-page-customer-email">
                  {replacement?.customer?.email}
                </Text>
                {editingPhone ? (
                  <div
                    id="checkout-page-editing-phone-1"
                    className="my-2"
                    style={{ maxWidth: '15rem' }}
                  >
                    {/* TODO: add this to the delivery instructions */}
                    <MaskedInput
                      id="checkout-page-phone-input"
                      mask="000-000-0000"
                      onChange={(
                        _: React.ChangeEvent<HTMLInputElement>,
                        value
                      ) => {
                        setCurrentPhone(value)
                      }}
                      formField
                      inputMode="tel"
                      value={currentPhone || editedPhone}
                      label={localizedText('CHECKOUT_PHONE_LABEL')}
                    />
                    {validationError && (
                      <Text
                        id="checkout-page-phone-error"
                        variant="label"
                        color="error"
                        className="py-2"
                      >
                        {validationError}
                      </Text>
                    )}
                  </div>
                ) : (
                  <Text id="checkout-page-edited-phone">
                    {formatPhone(editedPhone)}
                  </Text>
                )}
                {!isGiftCard && (
                  <>
                    <div className="flex items-center">
                      <Text
                        id="checkout-page-editing-phone-2"
                        variant="label"
                        color="interactive"
                        className="underline cursor-pointer"
                        textTemplateKey={
                          editingPhone
                            ? 'CHECKOUT_PHONE_CANCEL'
                            : 'CHECKOUT_PHONE_EDIT'
                        }
                        onClick={() => setEditingPhone(!editingPhone)}
                      />
                      {editingPhone && (
                        <Text
                          id="checkout-page-confiramtion-phone-2"
                          variant="label"
                          className="underline cursor-pointer text-blue ml-4"
                          textTemplateKey="CHECKOUT_PHONE_CONFIRM"
                          color="interactive"
                          onClick={() => {
                            if (phoneValidation(currentPhone)) {
                              setValidationError('')
                              setEditedPhone(currentPhone || editedPhone)
                              setEditingPhone(false)
                            } else {
                              setValidationError('Phone number is invalid')
                            }
                          }}
                        />
                      )}
                    </div>
                    <div style={{ maxWidth: '15rem' }} className="mt-2">
                      <br></br>
                      <Input
                        id="checkout-page-alterantive-name"
                        className="mb-2"
                        label={localizedText('CHECKOUT_NAME_LABEL_ALTERNATE')}
                        formField
                        value={alternativeName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAlternativeName(e.target.value)
                        }}
                      />
                      <MaskedInput
                        id="checkout-page-alternative-input"
                        mask="000-000-0000"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAlternativePhone(e.target.value)
                        }}
                        formField
                        inputMode="tel"
                        value={alternativePhone}
                        label={localizedText('CHECKOUT_PHONE_LABEL_ALTERNATE')}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* property address */}
          <div>
            <Text
              variant="heading-04"
              className="mb-4"
              textTemplateKey="CHECKOUT_ADDRESS_TITLE"
            />
            <div>
              <Text id="checkout-page-address-name">
                {replacement?.customer?.name}
              </Text>
              <Text id="checkout-page-address-street">
                {replacement?.customer?.address?.street1}
                {hasUnitInfo && (
                  <>
                    {', '}
                    {replacement?.customer?.address?.unitType}{' '}
                    {replacement?.customer?.address?.unitValue}
                  </>
                )}
              </Text>
              {hasStreet2 && (
                <Text id="checkout-page-address-street2">
                  {replacement?.customer?.address?.street2}
                </Text>
              )}
              <Text id="checkout-page-address-more">
                {replacement?.customer?.address?.city}{' '}
                {replacement?.customer?.address?.state},{' '}
                {replacement?.customer?.address?.countryISO3}{' '}
                {replacement?.customer?.address?.zip}
              </Text>
            </div>
          </div>
          {/* agreements */}
          <div>
            <Text
              variant="heading-04"
              className="mb-4"
              textTemplateKey="CHECKOUT_AGREEMENTS_TITLE"
            />
            <div className="grid gap-4">
              {trimKitList[0][product.ID] ? (
                <Checkbox
                  id="checkout-page-box-addons-t"
                  color={color}
                  checked={agreements.addons}
                  className="items-start"
                  onChange={() =>
                    setAgreements({
                      ...agreements,
                      addons: !agreements.addons,
                    })
                  }
                  label={localizedText(
                    'CHECKOUT_TERMS_AND_CONDITION_TRIM_KIT_SIZE'
                  )}
                />
              ) : null}
              {refrigeratorIcemakerList[0][product.ID] ? (
                <Checkbox
                  id="checkout-page-box-addons-i"
                  color={color}
                  checked={agreements.addons}
                  className="items-start"
                  onChange={() =>
                    setAgreements({
                      ...agreements,
                      addons: !agreements.addons,
                    })
                  }
                  label={localizedText('CHECKOUT_TERMS_AND_CONDITION_ICEMAKER')}
                />
              ) : null}
              {isGiftCard && (
                <div className="flex">
                  <Checkbox
                    id="checkout-page-gifcard-box-email"
                    color={color}
                    checked={agreements.emailConfirmation}
                    className="items-start"
                    onChange={() =>
                      setAgreements({
                        ...agreements,
                        emailConfirmation: !agreements.emailConfirmation,
                      })
                    }
                    label={localizedText('CHECKOUT_TERMS_AND_CONDITIONS_0')}
                  />
                </div>
              )}
              <Checkbox
                id="checkout-page-box-dim"
                color={color}
                checked={agreements.dimensions}
                className="items-start"
                onChange={() =>
                  setAgreements({
                    ...agreements,
                    dimensions: !agreements.dimensions,
                  })
                }
                label={
                  !isGiftCard
                    ? localizedText('CHECKOUT_AGREEMENTS_DIMENSIONS_LABEL', {
                        measure,
                        parsedUnit,
                      })
                    : localizedText('CHECKOUT_TERMS_AND_CONDITIONS_2')
                }
              />
              {isGiftCard ? (
                <div className="flex">
                  <Checkbox
                    id="checkout-page-gifcard-box-not-cancel"
                    color={color}
                    checked={agreements.cannotCancel}
                    className="items-start"
                    onChange={() =>
                      setAgreements({
                        ...agreements,
                        cannotCancel: !agreements.cannotCancel,
                      })
                    }
                    label={localizedText('CHECKOUT_TERMS_AND_CONDITIONS_3')}
                  />
                </div>
              ) : (
                <Checkbox
                  id="checkout-page-box-fee"
                  color={color}
                  checked={agreements.restockingFee}
                  className="items-start"
                  onChange={() =>
                    setAgreements({
                      ...agreements,
                      restockingFee: !agreements.restockingFee,
                    })
                  }
                  label={localizedText('CHECKOUT_TERMS_AND_CONDITIONS_4')}
                />
              )}
              <div className="flex">
                <Checkbox
                  id="checkout-page-box-conditions"
                  color={color}
                  checked={agreements.termsAndConditions}
                  className="items-start"
                  onChange={() =>
                    setAgreements({
                      ...agreements,
                      termsAndConditions: !agreements.termsAndConditions,
                    })
                  }
                />
                <Text
                  id="checkout-page-terms-and-con"
                  className="checkbox-label-text cursor-pointer"
                  onClick={() =>
                    setAgreements({
                      ...agreements,
                      termsAndConditions: !agreements.termsAndConditions,
                    })
                  }
                >
                  {localizedText('CHECKOUT_TERMS_AND_CONDITIONS_1')}
                  <button
                    id="checkout-page-condition-link"
                    className="underline ml-1 text-interactive"
                    onClick={() => showTermsAndConditions(true)}
                  >
                    {localizedText('CHECKOUT_TERMS_AND_CONDITIONS_LINK')}
                  </button>
                </Text>
                .
              </div>
            </div>
          </div>
          {/* delivery instructions */}
          {!isGiftCard && (
            <div className="lg:max-w-3xl">
              <TextArea
                id="checkout-page-giftcard-instructions-area"
                formField
                label={localizedText('CHECKOUT_DELIVERY_INSTRUCTIONS_LABEL')}
                ref={deliveryInstructionsRef}
              />
            </div>
          )}
          {/* payment */}
          {product.customerPrice > 0 && (
            <div>
              <Text
                variant="heading-04"
                className="mb-4"
                textTemplateKey="CHECKOUT_PAYMENT_INFO_TITLE"
              />
              <div className="lg:max-w-md">
                <PaymentMFE ref={submitPaymentRef} />
              </div>
            </div>
          )}
          {/* order summary -mx-4 removed*/}
          <div className="px-4 py-6 bg-pale-50 lg:max-w-3xl">
            <Text
              variant="heading-04"
              className="mb-4"
              textTemplateKey="CHECKOUT_SUMMARY_TITLE"
            />
            <div>
              {!isGiftCard ? (
                <div className="mb-6">
                  <Text className="font-bold">{product.modelNumber}</Text>
                  <Text id="checkout-page-gifcard-product-description">
                    {product.description}
                  </Text>
                </div>
              ) : (
                <div className="mb-6">
                  <Text
                    id="checkout-page-product-description-2"
                    className="font-bold"
                  >
                    {product.description}
                  </Text>
                  <Text id="checkout-page-gifcard-product-ID-2">
                    {product.ID}
                  </Text>
                </div>
              )}
              <Text
                className="font-bold mb-3"
                id="checkout-page-gifcard-gift-card-values"
              >
                {!isGiftCard
                  ? localizedText('CHECKOUT_YOU_PAY_TEXT')
                  : localizedText('CHECKOUT_E_CARD_VALUE')}
                :{' '}
                {!isGiftCard
                  ? formatCentsCurrency(product.customerPrice)
                  : formatCentsCurrency(cashInLieu)}
              </Text>
              <Button
                id="checkout-page-order-button"
                className="mt-4"
                label={localizedText('CHECKOUT_PLACE_ORDER_BTN')}
                onClick={!isEnvStaging ? submitStripePayment : submit}
                disabled={!canSubmit || !product.IsAvailableForZip}
                loading={submitStatus.type === 'loading'}
              />

              {submitStatus.type === 'error' && (
                <Text
                  id="checkout-page-error-message"
                  className="font-bold mt-2"
                  variant="helper-text"
                  color="error"
                  data-error={submitStatus.message}
                >
                  {localizedText(submitStatus.message || '')}
                </Text>
              )}
            </div>
          </div>
        </article>
      ) : productStatus === 'error' ? (
        <Text
          id="checkout-page-status-message"
          className="text-center"
          variant="heading-06"
        >
          {localizedText(submitStatus.message || '')}
        </Text>
      ) : (
        /* loading */
        <div
          className="mt-8 flex justify-center"
          id="checkout-page-spinner-wrapper"
        >
          <ProgressIndicator size="medium" data-testid="spinner" />
        </div>
      )}
    </section>
  )
}
