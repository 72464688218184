import React, { useMemo } from 'react'
import { TableColumn, Table } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from '../custom-fdr-components'
import { formatTimestampToDate } from 'src/utils/internationalization-helper'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'
import AddDispatchNote from '../add-dispatch-note/add-dispatch-note'
import { ProgressIndicatorComponent as ProgressIndicator } from 'src/components/custom-fdr-components'

const NotesInfoTable: React.FC = () => {
  const {
    dataInfoModal: data,
    isNotesTableRefreshing,
    notesTableError,
  } = useQueueModalContext()
  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_NOTE_MODAL_HEADER_MESSAGE"
          />
        ),
        accessor: 'message', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },

      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_NOTE_MODAL_HEADER_CREATOR"
          />
        ),
        accessor: 'creator', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_NOTE_MODAL_HEADER_DATE"
          />
        ),
        accessor: 'created', // accessor is the "key" in the data
        Cell: ({ value }: any) => {
          return (
            <Text variant="caption">
              {`${formatTimestampToDate(
                value,
                'MM/dd/yyyy HH:mm:ss',
                'America/Chicago'
              )}`}
            </Text>
          )
        },
      },
    ],
    []
  )
  return (
    <div className="pb-8 relative z-10">
      <div className="relative">
        <Table
          id="notes-info-table-table"
          data={data}
          columns={columns}
          striped="gray"
          variant="heavy"
          paginate={false}
          sortable={false}
          hover="gray"
        />
        {isNotesTableRefreshing ? (
          <div
            id="notes-info-table-spinner-notes-wrapper"
            className="w-full h-full absolute left-0 right-0 top-0 flex justify-center items-center bg-opacity-10 bg-black"
          >
            <ProgressIndicator
              size="medium"
              data-testid="spinner-notes"
              color="interactive"
            />
          </div>
        ) : null}
      </div>
      {notesTableError ? (
        <p id="notes-info-table-error" className="text-error">
          {notesTableError}
        </p>
      ) : null}
      <div className="flex justify-between mt-8 items-start">
        <AddDispatchNote />
      </div>
    </div>
  )
}

export default NotesInfoTable
