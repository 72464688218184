import React, { useMemo } from 'react'
import { TableColumn, Table } from '@ftdr/blueprint-components-react'
import { ProgressIndicatorComponent as ProgressIndicator } from '../custom-fdr-components'
import { TextComponent as Text } from '../custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from 'src/store/initial-state'

const StatusInfoTable: React.FC = () => {
  const { statuses: data, isLoading, error } = useSelector(
    (state: AppState) => state.statuses
  )

  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: <Text variant="heading-06">Status</Text>,
        accessor: 'value', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: <Text variant="heading-06">Description</Text>,
        accessor: 'description', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
    ],
    []
  )

  return (
    <>
      {isLoading ? (
        <div
          className="absolute"
          style={{ left: '50%', top: '30%', transform: 'translate(-50%, -50%' }}
        >
          <ProgressIndicator data-testid="spinner" />
        </div>
      ) : null}
      {error ? (
        <Text
          color="error"
          id="status-info-table-error"
          className="mt-8 text-center"
        >
          {error}
        </Text>
      ) : null}
      <div className="pb-8 relative z-10">
        {!isLoading && !error ? (
          <Table
            id="status-info-table-table"
            data={data}
            columns={columns}
            striped="gray"
            variant="heavy"
            paginate={false}
            sortable={false}
            hover="gray"
          />
        ) : null}
      </div>
    </>
  )
}

export default StatusInfoTable
