import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionSection, Pagination, SelectOption, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  ProgressIndicatorComponent as ProgressIndicator,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import qs from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { AppState, RequestStatus } from '../../utils/shared-types'
import ProductCard from './product-card'
import { useHistory, useLocation, useParams } from 'react-router'
import { api } from '../../utils/api'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { FilterOption } from './filter-option'
import { clearAllFilters, getFilters, SelectedFilers, selectFilters } from '../../store/filters-store'
import { addReplacement } from '../../store/compare-store'
import { useReplacement } from '../../hooks/use-replacement'
import { MobileFilters } from './mobile-filters'
import { CilDialog } from 'src/components/cil-dialog'
import { DispatchIdCard } from '../../components/dispatch-id-card'
import { ModelSearch } from './model-search'
import { SearchResults } from './search-results'
import { useMedia } from '../../hooks/use-media'
import { useCILModalContext } from 'src/hooks/use-cil-modal-context'
import { useAuth } from '@ftdr/use-auth'
import { objectKeysToParams, paramsToObjectKeys, qsConfig, updateFilters, updatePagination } from './helpers'
import { isNumber } from 'src/utils/validation-utils'
import { isAHS } from 'src/utils/tenant-helper'
import { useActiveFilters } from 'src/hooks/use-active-filters-context'
import { useScrolledContext } from 'src/hooks/use-isScrolled-context'
import axios, { CancelTokenSource } from 'axios'
import { capitalize } from 'src/utils/helper'
import { useDispatchInfoContext } from 'src/hooks/use-is-disptach-info'

const customerServicePhone = '1-800-345-5535'

interface BrowseParams {
  replacementId?: string
}

export const View = () => {
  //const initialPagination = useSelector((state:AppState)=>state.pagination.pagination)

  const color = isAHS() ? 'interactive' : 'primary'
  const background = isAHS() ? 'bg-picket-white' : 'bg-white'
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon,
  )
  const location = useLocation()
  const history = useHistory()

  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { isDispatchInfoVisible } = useDispatchInfoContext()
  const [status, setStatus] = useState<RequestStatus>('loading')
  const [products, updateProducts] = useState<appliancepb.IProduct[]>([])
  const [searchResults, setSearchResults] = useState<appliancepb.IProduct[]>([])
  const [isModelSearched, setIsModelSearched] = useState(false)

  const [itemsPerPage, setItemsPerPage] = useState(9)
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { replacementId } = useParams<BrowseParams>()
  const { replacement } = useReplacement(replacementId)
  const { filters, selected: selectedFilters } = useSelector(
    (state: AppState) => state.filters,
  )

  const selectedModel = useSelector(
    (state: AppState) => state.selectedModel.data,
  )
  const agentSurvey = useSelector((state: AppState) => state.survey)
  const { open, setOpen } = useCILModalContext()
  const { activeFilters, setActiveFilters } = useActiveFilters()
  const { oidcUser } = useAuth()
  const isCustomer = oidcUser === null
  const { isScrolled, positionFromTop } = useScrolledContext()
  let itemId = ''
  if (agentSurvey.newProductCategory) {
    itemId = agentSurvey.newProductCategory
  } else {
    itemId = agentSurvey.agentSurvey.item?.id || ''
  }
  const isDesktop = useMedia('(min-width:1024px)')
  const cancelTokenSource = useRef<CancelTokenSource | null>(null)

  const getProducts = useCallback(
    async (
      page?: number,
      itemsPerPage?: number,
      pageFilters?: SelectedFilers,
    ) => {
      setStatus('loading')
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Previous request has been canceled')
      }
      cancelTokenSource.current = axios.CancelToken.source()
      let param: string
      if (trimKitSize) {
        param = qs.stringify(
          {
            ...pageFilters,
            items_per_page: itemsPerPage || 9,
            page: page || 1,
            dcov: agentSurvey.agentSurvey.dcov,
            trim_kit_size: trimKitSize,
          },
          qsConfig,
        )
      } else if (icemakerAddon) {
        param = qs.stringify(
          {
            ...pageFilters,
            items_per_page: itemsPerPage || 9,
            page: page || 1,
            dcov: agentSurvey.agentSurvey.dcov,
            oim: !!icemakerAddon,
          },
          qsConfig,
        )
      } else {
        param = qs.stringify(
          {
            ...pageFilters,
            items_per_page: itemsPerPage || 9,
            page: page || 1,
            dcov: agentSurvey.agentSurvey.dcov,
          },
          qsConfig,
        )
      }

      try {
        const { products, pagination } = replacementId
          ? await api.getReplacementProducts(
            replacementId,
            param,
            cancelTokenSource.current,
          )
          : await api.getProductsByItemTypeId(
            itemId,
            agentSurvey.agentSurvey.partRequestId,
            agentSurvey.agentSurvey.dispatch?.addressID as string,
            param,
            cancelTokenSource.current,
          )

        updateProducts(products)
        setTotalItems(pagination?.totalItems || 0)
        setStatus('ok')
      } catch (error: any) {
        if (axios.isCancel(error)) {
          console.log(error.message)
        } else {
          console.error('There was an error getting products', error)
          setStatus('error')
        }
      }
    },
    //eslint-disable-next-line
    [selectedFilters, itemId, replacementId, agentSurvey],
  )

  const refreshProducts = (
    pageNumber: number,
    postsPerPage: number,
    updatedFilters: SelectedFilers,
  ) => {
    if (pageNumber >= 1) {
      setPage(pageNumber)
      getProducts(pageNumber, postsPerPage, updatedFilters)
    } else {
      setPage(1)
      getProducts(1, postsPerPage, updatedFilters)
    }
  }

  const replaceHistory = (
    updatedSearchString: string,
    search: string,
    pathname: string,
  ) => {
    if (updatedSearchString !== qs.stringify(qs.parse(search), qsConfig)) {
      history.replace(`${pathname}?${updatedSearchString}`)
      return
    }
  }

  const onItemsPerPageChange = ({ value }: SelectOption) => {
    const searchObject = qs.parse(location.search, qsConfig)
    const searchString = qs.stringify(
      {
        ...searchObject,
        items_per_page: value,
      },
      qsConfig,
    )
    const { pathname } = location
    history.push(`${pathname}?${searchString}`)
  }

  const onPageChange = (_page: number) => {
    const searchObject = qs.parse(location.search, qsConfig)
    const searchString = qs.stringify(
      { ...searchObject, page: _page },
      qsConfig,
    )
    const { pathname } = location
    window.scrollTo({ top: 0, behavior: 'smooth' })
    history.push(`${pathname}?${searchString}`)
  }

  useEffect(() => {
    if (filters.length) return
    if (itemId) {
      dispatch(getFilters(itemId, selectedModel))
    } else if (replacement) {
      const itemType = replacement.replacementDetails?.itemType
      if (itemType) {
        dispatch(getFilters(itemType))
      }
    }
  }, [dispatch, itemId, replacement, selectedModel, filters])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(addReplacement(replacementId || ''))

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (filters.length === 0) return
    const { pathname, search } = location
    const { page, items_per_page: itemsPerPage } = qs.parse(
      search.toLowerCase(),
      qsConfig,
    )
    const snakeSearch = qs.stringify(qs.parse(search), qsConfig)
    const formattedSearch = qs.stringify(
      paramsToObjectKeys(qs.parse(search)),
      qsConfig,
    )

    const searchObject = qs.parse(formattedSearch, qsConfig)

    const pageNumber = isNumber(page as string) ? +(page as string) : 1
    const postsPerPage = parseFloat(itemsPerPage as string)

    const updatedFilters = updateFilters(filters, searchObject)
    const updatedPagination = updatePagination(postsPerPage, pageNumber)

    const updatedSearchString = qs.stringify(
      {
        ...objectKeysToParams(updatedFilters),
        ...updatedPagination,
      },
      qsConfig,
    )

    let activeFiltersIndexes: number[] = []
    Object.keys(searchObject).forEach((filterName) => {
      activeFiltersIndexes.push(
        filters.findIndex((filter) => filter.name === filterName),
      )
    })

    setActiveFilters(activeFiltersIndexes)
    dispatch(clearAllFilters())

    dispatch(selectFilters(updatedFilters))
    setItemsPerPage(postsPerPage)

    replaceHistory(updatedSearchString, snakeSearch, pathname)

    refreshProducts(pageNumber, postsPerPage, updatedFilters)

    //eslint-disable-next-line
  }, [location.search, filters])

  return (
    /* Negative margins because we need to override the main layout */
    <div className='-mt-10 lg:py-16 lg:pr-16 -mx-4 sm:-mx-10 md:-mx-24 lg:-mx-48 lg:pl-64'>
      <div className='flex flex-col lg:h-auto justify-end items-center'>
        {replacement &&
          parseInt(replacement.replacementDetails?.cashInLieu, 10) && (
            <div
              className='hidden w-full md:max-w-3xl lg:max-w-5xl lg:flex justify-end lg:mb-4 pt-4 lg:pt-8'
              style={{
                transform: !isDesktop
                  ? 'translateY(48px)'
                  : 'translateY(-52px)',
              }}
            >
              <Button
                id='browse-open-button'
                size={`${isDesktop ? 'large' : 'small'}`}
                label={localizedText('BROWSE_CIL_BTN')}
                variant='outlined'
                onClick={() => setOpen(true)}
              />
            </div>
          )}
        <MobileFilters
          replacement={replacement}
          modelSearchEnabled={!isCustomer}
          itemId={itemId}
          setIsModelSearched={setIsModelSearched}
          setSearchResults={setSearchResults}
        />

        <div className='mt-7 lg:mt-0 flex justify-center lg:pt-20 px-5 lg:px-0 w-full'>
          {/* <div className="hidden lg:block w-32 xl:w-48 flex-shrink-0 mr-10 xl:mr-12">
            {selectedModel && (
              <Text
                id="browse-open-t-model-number"
                className="mb-4"
                textTemplateKey="BROWSE_BASED_MODEL_LABEL"
                textTemplateData={{ model: selectedModel.modelNumber || '' }}
              />
            )}
          </div> */}
          <div className='grow-1 w-full md:max-w-3xl lg:max-w-5xl'>
            {status === 'ok' && !isCustomer && (
              <div className='lg:hidden mb-6 max-w-full overflow-x-auto'>
                <DispatchIdCard
                  wrapperClasses='flex pb-3'
                  elemsClasses='pr-4 flex flex-col justify-between'
                />
              </div>
            )}
            <div className='hidden lg:block mb-4 lg:-mt-20'>
              <Text
                id='browse-t-replacement-id'
                variant={`${isDesktop ? 'heading-01' : 'heading-03'}`}
                color='primary'
              >
                {localizedText(
                  replacementId
                    ? 'BROWSE_OPTIONS_TITLE_CUSTOMER'
                    : 'BROWSE_OPTIONS_TITLE_AGENT',
                )}
              </Text>
              <Text id='browse-t-replacement-id-1' color='primary'>
                {localizedText(
                  replacementId
                    ? 'BROWSE_OPTIONS_SUBTITLE_CUSTOMER'
                    : 'BROWSE_OPTIONS_SUBTITLE_AGENT',
                )}
              </Text>
            </div>
            {status === 'error' ? (
              <div>
                <Text
                  variant='heading-04'
                  className='normal-case'
                  textTemplateKey='BROWSE_SOMETHING_WENT_WRONG'
                />
                {replacementId && (
                  <Text className='mb-4'>
                    If the issue persists, please call{' '}
                    <a
                      id='browse-inline-link'
                      className='whitespace-no-wrap text-primary underline'
                      href={`tel:${customerServicePhone}`}
                    >
                      {customerServicePhone}
                    </a>{' '}
                    and our customer service will help.
                  </Text>
                )}
              </div>
            ) : status === 'ok' && products.length === 0 ? (
              <Text
                id='browse-t-no-results'
                variant='heading-04'
                className='normal-case'
                textTemplateKey='BROWSE_NO_RESULTS'
              />
            ) : (
              status === 'ok' &&
              !isModelSearched && (
                <div>
                  <section
                    className='grid lg:grid-cols-2  xl:grid-cols-3 gap-7 row-gap-4'
                    id='browse-t-products-wrapper'
                  >
                    {/* Products */}
                    {products.map((product, i) => (
                      <ProductCard
                        key={product.ID || i}
                        product={product}
                        isAgent={!replacementId}
                      />
                    ))}
                  </section>
                </div>
              )
            )}
            {isModelSearched && searchResults && (
              <section className='grid lg:grid-cols-3 gap-7 row-gap-4'>
                {/* Products */}
                <SearchResults
                  results={searchResults}
                  replacementId={replacementId || itemId}
                  isCustomer={isCustomer}
                />
              </section>
            )}
            {status === 'loading' && (
              <div
                className='flex justify-center w-full'
                id='agent-replacement-confirmation-agent-error'
              >
                <ProgressIndicator
                  className='text-primary'
                  data-testid='spinner'
                  variant='circular'
                  label={localizedText('CHECKING_AVAILABILITY')}
                />
              </div>
            )}
            {/* Pagination */}
            {status === 'ok' && products.length > 0 && (
              <div
                className='mt-8 justify-end lg:-mb-16 min-w-full'
                style={{
                  background: '#FBFBFB',
                  borderTop: '1px solid #EEEEEE',
                  borderBottom: '1px solid #EEEEEE',
                }}
              >
                <Pagination
                  itemsPerPage={itemsPerPage || 9}
                  totalItems={totalItems || 0}
                  page={page || 0}
                  onItemsPerPageChange={onItemsPerPageChange}
                  onPageChange={onPageChange}
                  hideViewAll={true}
                  className='px-2 ml-0 -mr-24'
                  itemsPerPageOptions={[
                    { value: '9' },
                    { value: '30' },
                    { value: '60' },
                  ]}
                />
              </div>
            )}
            {/* Pagination */}
          </div>
        </div>

        {open && (
          <CilDialog
            cil={parseInt(replacement?.replacementDetails?.cashInLieu, 10)}
            close={() => setOpen(false)}
          />
        )}
      </div>
      {/* Filters */}
      <div
        className={`accordions-custom shadow-xl hidden lg:flex flex-col fixed left-0 bottom-0 pl-3 pr-2 overflow-y-auto pt-4 ${background}`}
        style={{
          width: '15rem',
          transition: 'all 0.3s ease 0s',
          height: `calc(100% - ${
            isScrolled
              ? isDispatchInfoVisible
                ? '5rem'
                : '3rem'
              : isDispatchInfoVisible
                ? '8rem'
                : '6rem'
          } - ${positionFromTop}px)`,
        }}
      >
        <Text
          variant='heading-04'
          className='mb-4'
          textTemplateKey={'filters'}
        />
        <Accordion
          color={color}
          mode='multiple'
          activeIndex={activeFilters}
          onActivate={(_, current) => {
            setActiveFilters(current)
          }}
          defaultActiveIndex={activeFilters}
        >
          {filters.map((filter) => (
            <AccordionSection
              label={capitalize(filter.name)}
              key={filter.name}
              id={`mobile-filters-accordions-filters-${filter.name.replace(
                /\s/g,
                '_',
              )}`}
            >
              <FilterOption
                filter={filter}
                setActiveIndexes={setActiveFilters}
              />
            </AccordionSection>
          ))}
          <AccordionSection label='Model Search'>
            {
              <ModelSearch
                itemId={itemId}
                setIsModelSearched={setIsModelSearched}
                setSearchResults={setSearchResults}
              />
            }
          </AccordionSection>
        </Accordion>
      </div>

      {/* Filters */}
    </div>
  )
}
