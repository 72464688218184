import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  PropsWithChildren,
  Dispatch,
} from 'react'
import { useMedia } from './use-media'

type Props = {
  isScrolled: boolean
  setIsScrolled(isOpen: boolean): void
  positionFromTop: number
  setPositionFromTop: Dispatch<number>
}
interface TUseIsScrolled extends PropsWithChildren {}
const ScrolledContext = createContext<Props>({} as Props)

const ScrolledProvider: React.FC<TUseIsScrolled> = ({ children }) => {
  const isDesktop = useMedia('(min-width:1024px)')
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [positionFromTop, setPositionFromTop] = useState<number>(0)

  const onScrollHandler = () => {
    window.pageYOffset > 96 ? setIsScrolled(true) : setIsScrolled(false)
  }

  useEffect(() => {
    window.pageYOffset > 96 && isDesktop && setIsScrolled(true)
    window.pageYOffset > 80 && !isDesktop && setIsScrolled(true)

    window.addEventListener('scroll', onScrollHandler)
    return () => window.removeEventListener('scroll', onScrollHandler)
    //eslint-disable-next-line
  }, [])

  return (
    <ScrolledContext.Provider
      value={{ isScrolled, setIsScrolled, positionFromTop, setPositionFromTop }}
    >
      {children}
    </ScrolledContext.Provider>
  )
}

const useScrolledContext = () => useContext(ScrolledContext)

export { ScrolledProvider, useScrolledContext }
