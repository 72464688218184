import { MenuItem } from 'src/utils/shared-types'
import yup, { valueSelected } from '../../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

const { DEFAULT_ERROR, RANGE_DATE_ERROR } = textTemplatesEnglish

export const bannersSchema = yup.object().shape({
  severity: valueSelected(yup),
  message: valueSelected(yup),
  customMessage: yup.string().when('message', {
    is: (message: MenuItem) => message.id === '2',
    then: yup.string().required(DEFAULT_ERROR),
    otherwise: yup.string().notRequired(),
  }),
  start: yup.date().required(DEFAULT_ERROR),
  end: yup
    .date()
    .required(DEFAULT_ERROR)
    .typeError(DEFAULT_ERROR)
    .min(yup.ref('start'), RANGE_DATE_ERROR),
})
