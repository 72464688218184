import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { AppState } from 'src/utils/shared-types'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { api } from 'src/utils/api'
import { PaymentMFE } from 'src/components/payment-mfe'
import { payment3_paymentmethodpb } from '@ftdr/payment3_paymentmethod_coordinator-js-client'
import { Image, Input, RadioGroup, TextArea, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  ProgressIndicatorComponent as ProgressIndicator,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { AgregatedProcessPaymentResponse, PaymentsMethodMfeExposedApi } from '@ftdr/payment-method-micro-frontend'
import { Link } from 'react-router-dom'
import { formatInputToDollars, formatPriceToCents } from 'src/utils/internationalization-helper'

import { validateConfirmationNumber } from './agent-purchase-validators'
import { generateProductAccesories, stripeMinPaymentCents } from 'src/utils/helper'
import AgentReplacementConfirmationInfoList
  from '../../components/agent-replacement-confiramation-info-list/agent-replacement-confirmation-info-list'

interface Params {
  productId: string
}

export const AgentPurchase = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon,
  )
  const [product, setProduct] = useState<appliancepb.Product | null>(null)
  const [status, setStatus] = useState('loading')
  const [radioValue, setRadioValue] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [agentPrice, setAgentPrice] = useState('0.00')
  const [notes, setNotes] = useState('')
  const [notesError, setNotesError] = useState('')
  const [replacementId, setReplacementId] = useState('')
  const [minPaymentError, setMinPaymentError] = useState('')
  const [confNumberError, setConfNumberError] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const { productId } = useParams<Params>()
  const survey = useSelector((store: AppState) => store.survey)
  const surveyDispatch = survey.agentSurvey.dispatch
  const addressId = surveyDispatch?.addressID || ''
  const submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi> = useRef(null)

  const parsedUnit =
    product?.dimensions?.unit === 'in'
      ? 'inches'
      : product?.dimensions?.unit || ''

  const measure = `${product?.dimensions?.height}h x ${product?.dimensions?.width}w x ${product?.dimensions?.depth}d`

  let itemId = ''

  const paymentValueOptions = [
    localizedText('CHECKOUT_AGENT_RADIO_NO_CHARGE_LABEL'),
    localizedText('CHECKOUT_AGENT_RADIO_CHARGE_LABEL'),
  ]

  if (survey.newProductCategory) {
    itemId = survey.newProductCategory
  } else {
    itemId = survey.agentSurvey.item?.id || ''
  }

  const createAgentPurchase = async () => {
    let replacementResponse: appliancepb.ICreateReplacementResponse
    if (!replacementId) {
      try {
        setStatus('replacement loading')
        const data = {
          dispatchId: survey.agentSurvey.dispatch?.dispatchID,
          partRequestId: survey.agentSurvey.partRequestId,
          contract: {
            id: survey.agentSurvey.dispatch?.contractID,
            hasDisposalCoverage: survey.agentSurvey.dcov,
          },
          itemId,
          customer: {
            id: survey.agentSurvey.dispatch?.customerID,
            deliveryAddress: { id: survey.agentSurvey.dispatch?.addressID },
          },
          notification: { customerShopLink: true },
          order: { vendorOrderNumber: orderNumber },
          shoppingCart: {
            shoppingCartItems: [
              {
                product,
                quantity: 1,
                finalPrice: {
                  apply: true,
                  price:
                    radioValue === 'Customer charge'
                      ? formatPriceToCents(agentPrice)
                      : 0,
                },
              },
            ],
          },
          note: notes,
          ...generateProductAccesories(trimKitSize || '', icemakerAddon || ''),
        }
        if (radioValue === 'Customer charge') {
          if (formatPriceToCents(agentPrice) < stripeMinPaymentCents) {
            setErrorMsg(localizedText('STRIPE_PAYMENT_MIN_DOLLAR_ERROR'))
            setStatus('payment error')
            return
          }
        }

        replacementResponse = await api.putReplacement(
          data,
          trimKitSize,
          icemakerAddon,
        )
        setReplacementId(replacementResponse.replacementId as string)
        if (radioValue !== 'Customer charge') {
          setStatus('success')
        }
      } catch (err: any) {
        if (err.response.status === 409) {
          setStatus('ordered error')
          return
        } else {
          setStatus('replacement error')
          console.log(err)
          return
        }
      }
    } else {
      replacementResponse = { replacementId }
    }
    if (radioValue === 'Customer charge') {
      if (formatPriceToCents(agentPrice) >= stripeMinPaymentCents) {
        setMinPaymentError('')
        try {
          await submitStripePayment(
            replacementResponse?.replacementId as string,
          )
        } catch (err) {
          setStatus('payment error')
          console.log(err)
        }
      } else {
        setMinPaymentError(localizedText('STRIPE_PAYMENT_MIN_DOLLAR_ERROR'))
        setStatus('payment error')
      }
    }
  }

  const submitStripePayment = async (newReplacementId: string) => {
    try {
      const result = await submitPaymentRef.current!.processPayment({
        stripe: {
          owner: {
            ownerIdentity: {
              type:
              payment3_paymentmethodpb.OwnerIdentityType
                .ReplacementIdentityType,
              replacementIdentity: {
                replacementID: newReplacementId,
              },
            },
          },
        },
      })

      if (result) {
        await handleStripeResponse(result, newReplacementId)
      }
    } catch (err) {
      setStatus('payment error')
      console.log(err)
    }
  }

  const handleStripeResponse = async (
    res: AgregatedProcessPaymentResponse,
    newReplacementId: string,
  ) => {
    if (res.stripe?.type === 'stripe_success') {
      const responseTime = new Date()
      const cardDetails =
        res.stripe.stripeSuccessResponse?.paymentMethod?.details?.cc
      const ID =
        res.stripe.stripeSuccessResponse?.paymentMethod?.paymentMethodID
      const expirationMonth = cardDetails?.expirationDate?.month as number
      let expMonth: string

      if (expirationMonth < 10) {
        expMonth = `0${expirationMonth}`
      } else {
        expMonth = expirationMonth.toString()
      }

      const paymentDetails: appliancepb.IPaymentDetails = {
        ID,
        brand: cardDetails?.brandName,
        expMonth,
        expYear: cardDetails?.expirationDate?.year?.toString(),
        lastFour: cardDetails?.last4,
        responseTime: responseTime.toISOString(),

        country: cardDetails?.billingDetails?.country,
        paymentProcessor: appliancepb.PaymentDetails.processor.STRIPE,
      }
      const data: appliancepb.IPostPaymentRequest = {
        paymentDetails,
        replacementId: newReplacementId,
      }

      try {
        await api.purchaseAgentReplacement(data)
        setStatus('success')
      } catch (err) {
        console.log(err)
        let stripeErrMsg = stripeErrMsgConversion(
          res.stripe.stripeFailureResponse?.message,
        )
        setErrorMsg(stripeErrMsg)
        setStatus('payment error')
        return
      }
    }
    if (res.stripe?.type === 'stripe_failure') {
      let stripeErrMsg = stripeErrMsgConversion(
        res.stripe.stripeFailureResponse?.message,
      )
      setErrorMsg(stripeErrMsg)
      setStatus('payment error')
      return
    }
  }

  const stripeErrMsgConversion = (error: string | null | undefined): string => {
    switch (error) {
      case 'Your card\'s security code is incorrect.': {
        return localizedText('STRIPE_PAYMENT_FAILED_INVALID_CVV')
      }
      case 'Your card number is invalid.': {
        return localizedText('STRIPE_PAYMENT_FAILED_INVALID_CARD')
      }
      default: {
        return localizedText('STRIPE_PAYMENT_FAILED_GENERIC')
      }
    }
  }

  useEffect(() => {
    const getProduct = async () => {
      try {
        const product = await api.getReplacementProduct(
          itemId,
          productId,
          addressId,
          survey.agentSurvey.partRequestId,
          survey.agentSurvey.dcov,
          trimKitSize,
          icemakerAddon,
        )
        setProduct(product)
        setStatus('ok')
      } catch (err) {
        console.error(err)
        setStatus('loading error')
      }
    }
    getProduct()
    //eslint-disable-next-line
  }, [itemId, productId, addressId, survey])

  const onRadioChange = (value: string) => {
    setRadioValue(value)
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value
    e.target.value = value !== '0.00' ? value : ''
    setAgentPrice(e.target.value)
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = formatInputToDollars(e.target.value)
    setAgentPrice(value)
  }

  const onConfNumberBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = localizedText(
      validateConfirmationNumber(e.target.value),
    )
    setConfNumberError(errorMessage)
  }

  const onCommentsBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 500) {
      setNotesError(localizedText('AGENT_CONFIRMATION_COMMENTS_ERROR'))
      return
    }
    setNotesError('')
  }

  if (status === 'loading') {
    return (
      <div className='mt-8 flex justify-center' id='agent-purchace-progress'>
        <ProgressIndicator size='medium' />
      </div>
    )
  }

  if (status === 'ordered error') {
    return (
      <div className='h-full flex justify-center items-center'>
        <article className='flex flex-col justify-center items-center'>
          <Text
            className='mb-4'
            variant='heading-05'
            textTemplateKey='AGENT_CONFIRMATION_ERROR_TITLE'
          />

          <Link to='/' id='agent-purchase-link-to-home'>
            <Button
              id='agent-purchace-button-home'
              label={localizedText('AGENT_CONFIRMATION_CREATE_ANOTHER')}
            />
          </Link>
        </article>
      </div>
    )
  }

  if (status === 'loading error') {
    return (
      <div
        id='agent-purchase-product-description-1'
        className='h-full flex justify-center items-center'
      >
        <article className='flex flex-col justify-center items-center'>
          <Text
            id='agent-purchase-error-title'
            className='mb-4'
            variant='heading-04'
            textTemplateKey='AGENT_CONFIRMATION_PRODUCT_ERROR_TITLE'
          />
        </article>
      </div>
    )
  }

  if (status === 'success') {
    return (
      <div
        id='agent-purchase-success-title-wrapper'
        className='h-full flex justify-center items-center'
      >
        <article className='flex flex-col justify-center items-center'>
          <AgentReplacementConfirmationInfoList messageIndex={2} />
          <Link to='/'>
            <Button
              id='agent-purchase-create-another'
              label={localizedText('AGENT_CONFIRMATION_CREATE_ANOTHER')}
            />
          </Link>
        </article>
      </div>
    )
  }
  return (
    <section className='-mt-8 lg:mt-0'>
      <article className='grid gap-7'>
        {/* checkout */}
        <div>
          {/* not using Text as we need breakpoint-based text variants and they're not available yet */}
          <Text
            className='font-display font-bold text-5 lg:text-11'
            textTemplateKey='CHECKOUT_TITLE'
          />
          <div className='mt-8 flex'>
            <figure className='mr-4 w-48' id='agent-purchase-image'>
              <Image
                src={product?.images[0].url || '/noimage.jpg'}
                alt='Product Image'
              />
            </figure>
            <div>
              <Text
                id='agent-purchase-product-description-m'
                variant='heading-06'
                className='lg:hidden'
              >
                {product?.description}
              </Text>
              <Text
                id='agent-purchase-product-description-d'
                variant='heading-04'
                className='hidden lg:block'
              >
                {product?.description}
              </Text>
              <div className='hidden lg:block'>
                <Text
                  id='agent-purchase-product-unit'
                  textTemplateKey='CHECKOUT_PRODUCT_DIMENSIONS_TITLE'
                  textTemplateData={{ unit: parsedUnit || '' }}
                />
                <Text id='agent-purchase-product-measure'>{measure}</Text>
              </div>
            </div>
          </div>
          <div className='mt-5 w-2/5'>
            <Text variant='label'>
              {localizedText('CHECKOUT_AGENT_COMMENTS_LABEL')}
            </Text>
            <TextArea
              id='agent-purchase-comments-area'
              value={notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setNotes(e.target.value)
              }
              label={localizedText('CHECKOUT_AGENT_COMMENTS_LABEL')}
              textLength={{ current: notes.length, max: 500 }}
              error={notesError}
              onBlur={onCommentsBlur}
              maxLength={500}
            />
          </div>
          <div className='mt-4 w-2/5'>
            <Input
              id='agent-purchase-confirmation-input'
              formField
              required
              value={orderNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOrderNumber(e.target.value.trim())
              }
              label={localizedText('CHECKOUT_AGENT_CONFIRMATION_LABEL')}
              error={confNumberError}
              onBlur={onConfNumberBlur}
            />
          </div>
          <div>
            <RadioGroup
              id='agent-purchase-radios'
              color='primary'
              className='mt-4'
              orientation='vertical'
              label='test'
              name='test'
              onChange={onRadioChange}
              value={radioValue}
              radios={paymentValueOptions.map((opt) => ({
                id: opt,
                value: opt,
                label: opt,
              }))}
            />
          </div>
          {radioValue === 'Customer charge' && (
            <div className='mt-4 w-2/5'>
              <Input
                id='agent-purchase-amount'
                formField
                error={minPaymentError}
                startEnhancer={() => <Text>$</Text>}
                inputMode='decimal'
                className='mb-4'
                label={localizedText('CHECKOUT_AGENT_AMOUNT_LABEL')}
                value={agentPrice}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAgentPrice(e.target.value)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              <PaymentMFE ref={submitPaymentRef} />
            </div>
          )}
          {status === 'replacement error' && (
            <Text
              id='agent-purchase-replacement-error'
              className='font-bold mt-2'
              variant='helper-text'
              color='error'
            >
              {localizedText('AGENT_CONFIRMATION_REPLACEMENT_ERROR')}
            </Text>
          )}
          {status === 'payment error' && (
            <Text
              id='agent-purchase-payment-error'
              className='font-bold mt-2'
              variant='helper-text'
              color='error'
            >
              {localizedText(errorMsg)}
            </Text>
          )}
          <Button
            id='agent-purchase-order-button'
            className='mt-4'
            label={localizedText('CHECKOUT_AGENT_ORDER_BUTTON')}
            loading={status === 'replacement loading'}
            onClick={createAgentPurchase}
            disabled={
              !radioValue ||
              !orderNumber ||
              confNumberError !== '' ||
              notesError !== ''
            }
          />
        </div>
      </article>
    </section>
  )
}
