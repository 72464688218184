import React, { useCallback } from 'react'
import QueueInfoDialog from 'src/components/queue-dialog/queue-dialog'
import QueueOrderDetailDialog from '../queue-order-detail-dialog/queue-order-detail-dialog'
import NotesInfoTable from 'src/components/notes-info-table/notes-info-table'
import StatusInfoTable from 'src/components/status-info-table/status-info-table'
import MoreDetailsInfoTable from '../more-details-info-table/more-details-info-table'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'
import CILInfoTable from '../cil-queue-modal/cil-queue-modal'

const QueueDialogsGroup = () => {
  const {
    statusModalOpen,
    infoModalOpen,
    setInfoModalOpen,
    setDataInfoModal,
    setStatusModalOpen,
    orderDetailsModalOpen,
    setOrderDetailModalOpen,
    detailsInfoModalOpen,
    setDetailsInfoModalOpen,
    CILModalOpen,
    setCILModalOpen,
    setDataCILModal,
  } = useQueueModalContext()

  const closeDialogInfo = useCallback(() => {
    setInfoModalOpen(false)
    setDataInfoModal([])
    //eslint-disable-next-line
  }, [])

  const closeCILDialogInfo = useCallback(() => {
    setCILModalOpen(false)
    setDataCILModal([])
    //eslint-disable-next-line
  }, [])

  const closeDialogDetailsInfo = useCallback(() => {
    setDetailsInfoModalOpen(false)

    //eslint-disable-next-line
  }, [])

  const closeDialogStatus = useCallback(
    () => setStatusModalOpen(false),
    //eslint-disable-next-line
    []
  )

  const closeOrderDetails = useCallback(
    () => setOrderDetailModalOpen(false),
    //eslint-disable-next-line
    []
  )

  return (
    <>
      <QueueInfoDialog isOpen={infoModalOpen} onClose={closeDialogInfo}>
        <NotesInfoTable />
      </QueueInfoDialog>
      <QueueInfoDialog isOpen={statusModalOpen} onClose={closeDialogStatus}>
        <StatusInfoTable />
      </QueueInfoDialog>
      <QueueInfoDialog isOpen={CILModalOpen} onClose={closeCILDialogInfo}>
        <CILInfoTable />
      </QueueInfoDialog>
      <QueueOrderDetailDialog
        isOpen={orderDetailsModalOpen}
        onClose={closeOrderDetails}
      ></QueueOrderDetailDialog>
      <QueueInfoDialog
        isOpen={detailsInfoModalOpen}
        onClose={closeDialogDetailsInfo}
        showBackdrop={false}
      >
        <MoreDetailsInfoTable />
      </QueueInfoDialog>
    </>
  )
}

export default QueueDialogsGroup
