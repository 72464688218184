import React from 'react'
import {
  TextProps,
  Text,
  TextColorOption,
} from '@ftdr/blueprint-components-react'
import { isAHS } from 'src/utils/tenant-helper'

export const TextComponent: React.FC<TextProps<any>> = (props) => {
  const defaultProps = isAHS()
    ? { color: 'primary' as TextColorOption, ...props }
    : props
  return props.children ? (
    <Text {...defaultProps}>{props.children}</Text>
  ) : (
    <Text {...defaultProps} />
  )
}
