import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import { getReplacement, verifyReplacement } from '../store/replacement-store'
import { appliancepb } from '../services/protobuf-models/appliance-ms-protobuf-models'
import { useHistory } from 'react-router-dom'

interface ReplacementHook {
  loading: boolean
  verified: boolean
  replacement: appliancepb.IReplacementResponse | null
}

export const useReplacement = (replacementId?: string): ReplacementHook => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const verified = useSelector((state: AppState) => state.replacement.verified)
  const replacement = useSelector((state: AppState) => state.replacement.data)
  const error = useSelector(
    (state: AppState) =>
      state.replacement.replacementError || state.replacement.verificationError
  )

  useEffect(() => {
    const getInfo = async () => {
      if (!replacementId) return
      setLoading(true)
      const promises = [
        dispatch(getReplacement(replacementId)),
        dispatch(verifyReplacement(replacementId)),
      ]
      await Promise.all(promises)
      setLoading(false)
    }
    if (error) {
      history.push(`/${replacementId}/verification-failure`)
    }
    if (!verified && !replacement) {
      getInfo()
    }
  }, [dispatch, history, error, replacement, replacementId, verified])

  return { loading, verified, replacement }
}
