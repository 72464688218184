import { Reducer } from 'redux'
import { StateAwareThunkDispatch, ThunkResult } from 'src/utils/shared-types'
import { api } from '../../utils/api'
import {
  ReplacementActions,
  ReplacementStoreState,
  CONSTANTS,
} from './replacement-store.types'

export const replacementInitialState: ReplacementStoreState = {
  data: null,
  replacementError: null,
  verified: false,
  verificationError: null,
}

type ReplacementReducer = Reducer<ReplacementStoreState, ReplacementActions>
export const replacementReducer: ReplacementReducer = (
  state = replacementInitialState,
  action
): ReplacementStoreState => {
  switch (action.type) {
    case CONSTANTS.GET_REPLACEMENT:
      return { ...state, data: action.replacement }

    case CONSTANTS.REPLACEMENT_ERROR:
      return { ...state, data: null, replacementError: action.error }

    case CONSTANTS.VERIFIED:
      return { ...state, verified: true }

    case CONSTANTS.VERIFICATION_ERROR:
      return { ...state, verified: false, verificationError: action.error }

    default:
      return state
  }
}

export const getReplacement = (
  replacementID: string
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  try {
    const replacement = await api.getReplacementDetails(replacementID)
    dispatch({
      type: CONSTANTS.GET_REPLACEMENT,
      replacement,
    })
  } catch (error: any) {
    dispatch({
      type: CONSTANTS.REPLACEMENT_ERROR,
      error: error.response || error.toString(),
    })
  }
}

export const verifyReplacement = (
  replacementID: string
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  try {
    await api.getReplacementVerification(replacementID)
    dispatch({
      type: CONSTANTS.VERIFIED,
    })
  } catch (error: any) {
    dispatch({
      type: CONSTANTS.VERIFICATION_ERROR,
      error: error.response || error.toString(),
    })
  }
}
