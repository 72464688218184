import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeTrimKitSize } from 'src/store/survey-store'
import { RadioGroup } from '@ftdr/blueprint-components-react'
import { TKitSize, AppState } from 'src/utils/shared-types'
import { TextComponent as Text } from '../custom-fdr-components'

interface IProps {
  isRequired: boolean
}

const TrimKitSizeField: React.FC<IProps> = ({ isRequired }) => {
  const dispatch = useDispatch()
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)

  useEffect(() => {
    !isRequired && dispatch(changeTrimKitSize(''))
    //eslint-disable-next-line
  }, [isRequired])

  const radios = useMemo(() => {
    return [
      {
        value: '',
        label:
          'No, hide Countertop Microwaves with Trim Kits as replacement options',
      },
      {
        value: '27',
        label:
          'Yes, display countertop microwave replacement options with 27” trim kits included in order and price',
      },
      {
        value: '30',
        label:
          'Yes, display countertop microwave replacement options with 30” trim kits included in order and price',
      },
    ]
  }, [])

  return isRequired ? (
    <>
      <Text
        textTemplateKey="SURVEY_KIT_SIZE_LABEL"
        className="mb-2 font-bold"
      />
      <RadioGroup
        id="trim-kit-size-button-group"
        value={trimKitSize}
        onChange={(value) => dispatch(changeTrimKitSize(value as TKitSize))}
        radios={radios}
        orientation="vertical"
        groupWidth="full"
        justify="start"
        label="Choose trim kit size"
        className="mb-5"
        color="interactive"
      />
    </>
  ) : null
}

export default TrimKitSizeField
