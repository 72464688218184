import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import React, { useEffect, useState } from 'react'
import { MenuItem } from 'src/utils/shared-types'
import { emptyItem } from 'src/components/adminForms/initials'
import AddFeeForm from '../add-fee-form/add-fee-form'

interface IProps {
  propTenant?: MenuItem
  vendor?: string
}
const AddFee: React.FC<IProps> = ({ propTenant, vendor }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [tenant, setTenant] = useState<MenuItem>(emptyItem)

  useEffect(() => {
    if (propTenant) {
      setTenant(propTenant)
    }
  }, [propTenant])

  return (
    <>
      <Button
        id="add-fee-button"
        label={localizedText('BUTTON_ADD_FEE')}
        className="custom-button w-full md:w-auto flex justify-center lg:inline-block h-9"
        disabled={!vendor || !tenant.id}
        onClick={() => setIsModalOpen(true)}
        style={{ lineHeight: 1 }}
      />

      <Dialog
        id="add-fee-dialog"
        open={isModalOpen}
        modal={true}
        onClose={() => setIsModalOpen(false)}
        className=""
        usePortal={true}
      >
        <AddFeeForm tenant={tenant.id} onClose={() => setIsModalOpen(false)} />
      </Dialog>
    </>
  )
}

export default AddFee
