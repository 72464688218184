import React, { useEffect } from 'react'
import {
  IconNavArrowLeft,
  useAppContext,
  AccordionSection,
  Accordion,
} from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from '../../../utils/shared-types'
import { FilterOption } from '../filter-option'
import { ModelSearch } from '../model-search'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { isAHS } from 'src/utils/tenant-helper'
import { useActiveFilters } from 'src/hooks/use-active-filters-context'
import { capitalize } from 'src/utils/helper'

interface MobileFiltersMenuProps {
  close: () => void
  modelSearchEnabled: boolean
  itemId: string
  setIsModelSearched: (isSearched: boolean) => void
  setSearchResults: (searchResults: appliancepb.IProduct[]) => void
}

export const MobileFiltersMenu = ({
  close,
  modelSearchEnabled,
  itemId,
  setIsModelSearched,
  setSearchResults,
}: MobileFiltersMenuProps) => {
  const color = isAHS() ? 'interactive' : 'primary'
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const filters = useSelector((state: AppState) => state.filters.filters)
  const { activeFilters, setActiveFilters } = useActiveFilters()
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    window.addEventListener('resize', close)
    return () => {
      window.removeEventListener('resize', close)
      document.body.classList.remove('overflow-hidden')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="lg:hidden overflow-auto fixed mt-76px z-20 top-0 bottom-0 right-0 left-0 px-4 sm:px-10 md:px-24 bg-picket-white">
      <Text
        variant="label"
        className="py-4 inline-flex items-center cursor-pointer"
        onClick={close}
      >
        <IconNavArrowLeft size="1.25rem" id="mobile-filters-menu-back" />
        <span className="ml-1">
          {localizedText('BROWSE_MOBILE_FILTERS_BACK')}
        </span>
      </Text>
      {/* Filters */}
      <div className="accordions-custom flex flex-col">
        <Accordion
          hideFirstSeparator
          key={'Mobile Filters'}
          color={color}
          mode="multiple"
          activeIndex={activeFilters}
          onActivate={(_, current) => {
            setActiveFilters(current)
          }}
          defaultActiveIndex={activeFilters}
        >
          {filters.map((filter) => (
            <AccordionSection
              key={filter.name}
              id={`mobile-filters-accordions-filters-${filter.name.replace(
                /\s/g,
                ''
              )}`}
              label={capitalize(filter.name)}
            >
              <FilterOption
                filter={filter}
                setActiveIndexes={setActiveFilters}
              />
            </AccordionSection>
          ))}
          {modelSearchEnabled && (
            <AccordionSection
              id="mobile-filters-accordions-model-search"
              label="Model Search"
            >
              <ModelSearch
                itemId={itemId}
                setIsModelSearched={setIsModelSearched}
                setSearchResults={setSearchResults}
              />
            </AccordionSection>
          )}
        </Accordion>
      </div>
    </div>
  )
}
