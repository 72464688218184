export const states = [
  { id: 'AL', value: 'Alabama', label: 'Alabama' },
  { id: 'AK', value: 'Alaska', label: 'Alaska' },
  { id: 'AZ', value: 'Arizona', label: 'Arizona' },
  { id: 'AR', value: 'Arkansas', label: 'Arkansas' },
  { id: 'CA', value: 'California', label: 'California' },
  { id: 'CO', value: 'Colorado', label: 'Colorado' },
  { id: 'CT', value: 'Connecticut', label: 'Connecticut' },
  { id: 'DE', value: 'Delaware', label: 'Delaware' },
  { id: 'DC', value: 'District of Columbia', label: 'District of Columbia' },
  { id: 'FL', value: 'Florida', label: 'Florida' },
  { id: 'GA', value: 'Georgia', label: 'Georgia' },
  { id: 'HI', value: 'Hawaii', label: 'Hawaii' },
  { id: 'ID', value: 'Idaho', label: 'Idaho' },
  { id: 'IL', value: 'Illinois', label: 'Illinois' },
  { id: 'IN', value: 'Indiana', label: 'Indiana' },
  { id: 'IA', value: 'Iowa', label: 'Iowa' },
  { id: 'KS', value: 'Kansas', label: 'Kansas' },
  { id: 'KY', value: 'Kentucky', label: 'Kentucky' },
  { id: 'LA', value: 'Louisiana', label: 'Louisiana' },
  { id: 'ME', value: 'Maine', label: 'Maine' },
  { id: 'MD', value: 'Maryland', label: 'Maryland' },
  { id: 'MA', value: 'Massachusetts', label: 'Massachusetts' },
  { id: 'MI', value: 'Michigan', label: 'Michigan' },
  { id: 'MN', value: 'Minnesota', label: 'Minnesota' },
  { id: 'MS', value: 'Mississippi', label: 'Mississippi' },
  { id: 'MO', value: 'Missouri', label: 'Missouri' },
  { id: 'MT', value: 'Montana', label: 'Montana' },
  { id: 'NE', value: 'Nebraska', label: 'Nebraska' },
  { id: 'NV', value: 'Nevada', label: 'Nevada' },
  { id: 'NH', value: 'New Hampshire', label: 'New Hampshire' },
  { id: 'NJ', value: 'New Jersey', label: 'New Jersey' },
  { id: 'NM', value: 'New Mexico', label: 'New Mexico' },
  { id: 'NY', value: 'New York', label: 'New York' },
  { id: 'NC', value: 'North Carolina', label: 'North Carolina' },
  { id: 'ND', value: 'North Dakota', label: 'North Dakota' },
  { id: 'OH', value: 'Ohio', label: 'Ohio' },
  { id: 'OK', value: 'Oklahoma', label: 'Oklahoma' },
  { id: 'OR', value: 'Oregon', label: 'Oregon' },
  { id: 'PA', value: 'Pennsylvania', label: 'Pennsylvania' },
  { id: 'RI', value: 'Rhode Island', label: 'Rhode Island' },
  { id: 'SC', value: 'South Carolina', label: 'South Carolina' },
  { id: 'SD', value: 'South Dakota', label: 'South Dakota' },
  { id: 'TN', value: 'Tennessee', label: 'Tennessee' },
  { id: 'TX', value: 'Texas', label: 'Texas' },
  { id: 'UT', value: 'Utah', label: 'Utah' },
  { id: 'VT', value: 'Vermont', label: 'Vermont' },
  { id: 'VA', value: 'Virginia', label: 'Virginia' },
  { id: 'WA', value: 'Washington', label: 'Washington' },
  { id: 'WV', value: 'West Virginia', label: 'West Virginia' },
  { id: 'WI', value: 'Wisconsin', label: 'Wisconsin' },
  { id: 'WY', value: 'Wyoming', label: 'Wyoming' },
  { id: 'AA', value: 'Armed Forces Americas', label: 'Armed Forces Americas' },
  { id: 'AE', value: 'Armed Forces Europe', label: 'Armed Forces Europe' },
  { id: 'AP', value: 'Armed Forces Pacific', label: 'Armed Forces Pacific' },
  { id: 'AS', value: 'American Samoa', label: 'American Samoa' },
  {
    id: 'FM',
    value: 'Federated States of Micronesia',
    label: 'Federated States of Micronesia',
  },
  { id: 'GU', value: 'Guam', label: 'Guam' },
  { id: 'MH', value: 'Marshall Islands', label: 'Marshall Islands' },
  {
    id: 'MP',
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
  },
  { id: 'PR', value: 'Puerto Rico', label: 'Puerto Rico' },
  { id: 'PW', value: 'Republic of Palau', label: 'Republic of Palau' },
  {
    id: 'UM',
    value: 'US Minor Outlying Islands',
    label: 'US Minor Outlying Islands',
  },
  { id: 'VI', value: 'US Virgin Islands', label: 'US Virgin Islands' },
]
