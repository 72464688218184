// @ts-nocheck
import React from 'react'
import CustomTabs from '../tabs/tabs'
import { TabsTableProvider } from 'src/hooks/use-tabs-table-context'
import { QueueModalProvider } from 'src/hooks/use-queue-modal-context'
import { PaymentModalProvider } from 'src/hooks/use-payment-modal-context'

const QueueTabsLayout: React.FC = () => {
  return (
    <TabsTableProvider>
      <QueueModalProvider>
        <PaymentModalProvider>
          <CustomTabs />
        </PaymentModalProvider>
      </QueueModalProvider>
    </TabsTableProvider>
  )
}

export default QueueTabsLayout
