import React from 'react'
import { AgentConfiramtionProvider } from 'src/hooks/use-agent-confirmation-modal-context'
import { AgentReplacementConfirmation } from './agent-replacement-confirmation'

export const AgentReplacementConfirmationView = () => {
  return (
    <AgentConfiramtionProvider>
      <AgentReplacementConfirmation />
    </AgentConfiramtionProvider>
  )
}
