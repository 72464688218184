import React from 'react'
import { Dialog } from '@ftdr/blueprint-components-react'
import './reset.css'

interface IStatusInfoDialog {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  showBackdrop?: boolean
}

const QueueInfoDialog: React.FC<IStatusInfoDialog> = ({
  isOpen,
  onClose,
  children,
  showBackdrop = true,
}) => {
  return (
    <Dialog
      id="queue-dialog"
      open={isOpen}
      onClose={onClose}
      modal={false}
      closeOnOutsideClick={true}
      showBackdrop={showBackdrop}
      className="my-dialog w-full overflow-auto max-h-full h-full relative"
      usePortal={true}
    >
      <>{children}</>
    </Dialog>
  )
}

export default QueueInfoDialog
