import React, { useEffect } from 'react'
import { ProgressIndicatorComponent as ProgressIndicator } from 'src/components/custom-fdr-components'
import { useAuth } from '@ftdr/use-auth'

export const Callback = () => {
  const { callback } = useAuth()

  useEffect(() => {
    callback()
  }, [callback])

  return (
    <div
      className="h-full w-full flex justify-center items-center"
      id="callback-spinner-wrapper"
    >
      <ProgressIndicator size="medium" data-testid="spinner" />
    </div>
  )
}
