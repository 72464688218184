import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

export const defaultValues = {
  dispatchID: '',
  vendor: { id: '', value: '', label: 'All vendors' },
  adressID: '',
  partRequestID: '',
  status: [],
  replacementID: '',
}
export const defaultStatus = [
  {
    value: textTemplatesEnglish.STATUS_NEEDS_INSTALLER_TITLE,
    id: textTemplatesEnglish.STATUS_NEEDS_INSTALLER_TITLE,
  },
  {
    value: textTemplatesEnglish.STATUS_ORDER_ERROR_TITLE,
    id: textTemplatesEnglish.STATUS_ORDER_ERROR_TITLE,
  },
  {
    value: textTemplatesEnglish.STATUS_ORDERED_EXTERNAL_UNPAID_TITLE,
    id: textTemplatesEnglish.STATUS_ORDERED_EXTERNAL_UNPAID_TITLE,
  },
]
