import React, { useState } from 'react'
import { useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from 'src/components/custom-fdr-components'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useMedia } from '../../../hooks/use-media'
import { api } from 'src/utils/api'
import { useSelector } from 'react-redux'
import { AppState, RequestStatus } from 'src/utils/shared-types'
import {
  stylesRowElem,
  stylesRow,
  stylesRowElemMobile,
  stylesRowMobile,
} from '../helpers'
import './reset.css'

interface ProductDetailParams {
  productId: string
  replacementId: string
}

const ButtonRow: React.FC = () => {
  const isDesktop = useMedia('(min-width:1024px)')
  const isMobile = useMedia('(max-width:767px)')
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )
  const itemId = useSelector(
    (state: AppState) => state.survey.agentSurvey.item?.id
  )
  const history = useHistory()

  const { replacementId } = useParams<ProductDetailParams>()
  const [confirmStatus, setConfirmStatus] = useState<RequestStatus>('pending')
  const id = replacementId || itemId || ''

  const orderNow = async (productId: string) => {
    setConfirmStatus('loading')
    try {
      const confirmation = await api.confirmReplacement(
        replacementId,
        productId
      )
      console.log('Successful confirmation', confirmation)
      history.push(`/${replacementId}/shop/${productId}/checkout`)
      setConfirmStatus('ok')
    } catch (err) {
      console.error(
        `There was an error confirming product '${productId}' for replacement '${replacementId}'`,
        err
      )
      setConfirmStatus('error')
    }
  }
  const {
    appSettings: { localizedText },
  } = useAppContext()

  return (
    <div
      className={`button-reseted-in flex mb-0  md:justify-center`}
      style={isMobile ? stylesRowMobile : stylesRow}
    >
      {compareProducts.map((product) => (
        <div
          key={product.ID}
          className="flex flex-col px-6"
          style={isMobile ? stylesRowElemMobile : stylesRowElem}
        >
          {replacementId ? (
            <div>
              <Button
                id="compare-button-order"
                className="mb-2"
                size={`${isDesktop ? 'small' : 'medium'}`}
                label={localizedText('PRODUCT_DETAIL_PROCEED_TO_CHECKOUT_BTN')}
                onClick={() => orderNow(product.ID as string)}
                loading={confirmStatus === 'loading'}
              />

              {confirmStatus === 'error' && (
                <Text
                  id="compare-error"
                  className="font-bold break-all"
                  variant="helper-text"
                  color="error"
                  textTemplateKey="ERROR_CUSTOMER"
                />
              )}
            </div>
          ) : (
            <Link
              id="compare-link"
              to={{
                pathname: `browse/${product.ID}/confirm`,
                state: { confirmationId: id },
              }}
            >
              <Button
                id="compare-button-select"
                className="mb-2"
                size={`${isDesktop ? 'small' : 'medium'}`}
                label={
                  isMobile
                    ? localizedText(
                        'PRODUCT_DETAIL_SELECT_RECOMMENDATION_BTN_SHORT'
                      )
                    : localizedText('PRODUCT_DETAIL_SELECT_RECOMMENDATION_BTN')
                }
              />
            </Link>
          )}
        </div>
      ))}
    </div>
  )
}

export default ButtonRow
