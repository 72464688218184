import React, { useCallback } from 'react'
import DownloadButton from './download-button'
import { useAppContext } from '@ftdr/blueprint-components-react'
import { useAdminContext } from 'src/hooks/use-admin-context'
import Vendor from './vendor'
import { emptyCacheObject } from './initials'
import { api } from 'src/utils/api'
import Message from './message'

const Refresh: React.FC = () => {
  const {
    setIsLoading,
    selectedVendor,
    refreshMessage: { error, message },
    setRefreshMessage,
  } = useAdminContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const onClickHandler = useCallback(async () => {
    setRefreshMessage({ ...emptyCacheObject })
    setIsLoading(true)
    const data = await api.adminRefreshCatalog(selectedVendor.id.toLowerCase())
    setRefreshMessage(data)
    setIsLoading(false)
    //eslint-disable-next-line
  }, [selectedVendor.value])

  return (
    <div>
      <Vendor showButton={false} includedVendors={['WP', 'GE', 'BH']} />
      <DownloadButton
        className="m-0"
        label={localizedText('ADMIN_BUTTON_REFRESH')}
        onClick={onClickHandler}
      />
      <Message id="refresh" error={error} message={message} />
    </div>
  )
}

export default Refresh
