import yup, {
  valueSelected,
  customUuid,
  multiValueSelected,
} from '../../../../utils/yup'

export const queueSchema = yup.object().shape({
  dispatchID: yup.string().dispatchID(false),
  status: multiValueSelected(yup, false),
  vendor: valueSelected(yup, false),
  adressID: customUuid(yup, false),
  partRequestID: yup.string().partRequestID(false),
  replacementID: customUuid(yup, false),
})
