import yup, { customUuid, valueSelected } from '../../../../utils/yup'

export const continueReplacementSchema = yup.object().shape({
  replacementID: customUuid(yup),
  contractID: yup.string().contractID(),
  dispatchID: yup.string().dispatchID(),
  firstName: yup.string().onlyLetters(),
  lastName: yup.string().onlyLetters(),
  billingState: valueSelected(yup),
  vendor: valueSelected(yup),
  sku: yup.string().modelNumber(true),
  agreements: yup.bool().oneOf([true], 'Required'),
})
