import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'

interface ProductFeaturesProps {
  features: appliancepb.Product.IFeature[]
}
export const ProductFeatures = ({ features }: ProductFeaturesProps) => (
  <ul className="pt-2" id="product-features-wrapper">
    {features.map(({ feature }) => (
      <li key={uuidv4()}>
        <Text color="primary">{feature}</Text>
      </li>
    ))}
  </ul>
)
