import React from 'react'
import { Input, Image } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'

// this file may not be needed
export const Login = () => {
  return (
    <section className="w-full flex justify-center">
      <article className="max-w-xs w-full grid gap-6">
        <figure className="flex justify-center items-center">
          <Image
            src="https://cdn.frontdoorhome.com/atg/prod-febd93db/static-srvm/svmroot-war/ahs/redesign2017/img/globalHeader/lockup.png"
            alt="Logo"
          />
          <Text variant="heading-03" className="ml-2">
            Appliance Portal
          </Text>
        </figure>
        <Input
          type="text"
          formField
          label="Username"
          placeholder="jsmith"
          required
          hint="Your network ID, usually your first initial and last name"
        />
        <Input
          type="password"
          formField
          label="Password"
          required
          placeholder="********"
        />
      </article>
    </section>
  )
}
