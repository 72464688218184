import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { TextArea } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from '../custom-fdr-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultValues } from './add-dispatch-note-default-values'
import { noteSchema } from './note-validation'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { api } from 'src/utils/api'
import { IAddDispatchFormData } from 'src/utils/shared-types'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import {
  generateFormData,
  arrOfQueueParamsKeys,
} from 'src/screens/exception-queue/helpers'
import { defaultValues as queueDefaultValues } from '../../screens/exception-queue/forms/queue/queue-default-values'

// import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'

const AddDispatchNote: React.FC = () => {
  const timer = useRef<any>()
  const {
    setDetailsInfoModalOpen,
    currentReplacementId,
    noteIsSending,
    setNoteIsSending,
    noteSendingError,
    setNoteSendingError,
    setDataInfoModal,
    setIsNotesTableRefreshing,
    setNotesTableError,
  } = useQueueModalContext()

  const { setQueueTableData } = useTabsTableContext()
  const location = useLocation()

  const [formIsOpened, setFormIsOpened] = useState<boolean>(false)
  const [noteIsSend, setNoteIsSend] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IAddDispatchFormData>({
    mode: 'onTouched',
    resolver: yupResolver(noteSchema),
    defaultValues,
  })

  const params = generateFormData(
    location.hash,
    queueDefaultValues,
    arrOfQueueParamsKeys,
    []
  )

  const setReplacements = async () => {
    setIsNotesTableRefreshing(true)
    setNotesTableError(null)
    try {
      const { replacements } = await api.getReplacements(params.allParamsObject)
      setQueueTableData(replacements)

      setIsNotesTableRefreshing(false)
      replacements.length &&
        setDataInfoModal(
          replacements.find((elem) => elem.ID === currentReplacementId)
            ?.notes || []
        )
    } catch (error: any) {
      console.log(error.message)
      setNotesTableError(error.message)
    }
  }
  const setDispatchNote = async (data: IAddDispatchFormData) => {
    setNoteSendingError(null)
    setNoteIsSending(true)
    setNoteIsSend(false)

    try {
      await api.setDispatchNote(
        data as appliancepb.CreateReplacementNoteRequest,
        currentReplacementId
      )
      reset()
      setNoteIsSend(true)
      setNoteSendingError(null)
      setNoteIsSending(false)

      timer.current = setTimeout(() => {
        setNoteIsSend(false)
      }, 10000)
    } catch (error: any) {
      setNoteSendingError(error.message)
      setNoteIsSending(false)
      setNoteIsSend(false)
    }
  }

  const submitForm = async (data: IAddDispatchFormData) => {
    setNoteSendingError(null)

    await setDispatchNote(data)
    if (!noteSendingError) {
      await setReplacements()
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
      setNoteIsSend(false)
    }
  }, [])

  return (
    <div className="w-full flex flex-wrap">
      <div className="max-w-1/2 w-1/2">
        <Button
          id="add-dispatch-notes-button"
          size="small"
          label="Add Message"
          onClick={() => setFormIsOpened(true)}
        />
      </div>
      <div className="max-w-1/2 w-1/2 flex justify-end">
        <Button
          className="self-start"
          id="notes-info-table-more-details-button"
          label="Messages details"
          labelAlign="center"
          size="small"
          onClick={() => setDetailsInfoModalOpen(true)}
        />
      </div>
      {formIsOpened ? (
        <form
          onSubmit={handleSubmit(submitForm)}
          className="mt-8 mb-8 w-full xl:w-1/2"
          id="add-dispatch-note-form"
        >
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextArea
                className="w-full block"
                label="Add new message."
                id="add-dispatch-note-area"
                rows={3}
                error={errors.message?.message}
                success={noteIsSend ? 'Message has been sent' : undefined}
                formField
                value={field.value as string}
                placeholder="Message..."
                textAlign={'left'}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          {noteSendingError && (
            <p id="add-dispatch-note-error-sending" className="text-error mt-2">
              {noteSendingError}
            </p>
          )}
          <Button
            id="add-dispatch-note-form-submit"
            type="submit"
            label="Submit"
            className="mt-4 w-full md:w-auto flex justify-center lg:inline-block"
            size="small"
            loading={noteIsSending}
            disabled={noteIsSending}
          />
        </form>
      ) : null}
    </div>
  )
}

export default AddDispatchNote
