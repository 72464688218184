import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { MakeAndModelSearch } from './make-and-model-search'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { ModelCard } from './model-card'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'

type NullableProducts = appliancepb.IProduct[] | null

export const MakeAndModel = () => {
  const [modelOptions, setModelOptions] = useState<NullableProducts>(null)

  const dispatch = useSelector(
    (state: AppState) => state.survey.agentSurvey.dispatch
  )

  if (!dispatch?.dispatchID) {
    console.warn('No dispatch, redirecting back to landing page')
    return <Redirect to="/" />
  }
  return (
    <section>
      <Text variant="heading-04" textTemplateKey="MAKE_AND_MODEL_TITLE" />
      <div className="mt-8">
        <MakeAndModelSearch setModelOptions={setModelOptions} />
        {modelOptions?.map((product, i) => (
          <div className="mt-8" key={product.ID || i}>
            <ModelCard id={`${i}`} model={product} />
          </div>
        ))}
      </div>
    </section>
  )
}
