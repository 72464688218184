import { Reducer } from 'redux'
import { CONSTANTS, StatusesStoreState, StatusesAction } from './statuses.store.types'
import { StateAwareThunkDispatch, ThunkResult } from 'src/utils/shared-types'
import { api } from 'src/utils/api'

export const statusesInitialState: StatusesStoreState = {
  statuses: null,
  error:null,
  isLoading:false
}

type StatusesReducer = Reducer<
StatusesStoreState,
StatusesAction
>
export const statusesReducer: StatusesReducer  = (
  state = statusesInitialState,
  action
): StatusesStoreState => {
  switch (action.type) {
    case CONSTANTS.SET_STATUSES:
      return { ...state, statuses: action.statuses }
    case CONSTANTS.SET_STATUSES_LOADING:
        return { ...state, isLoading:action.isLoading }
    case CONSTANTS.SET_STATUSES_ERROR:
        return { ...state, error:action.error }
    default:
      return state
  }
}

export const setStatuses = ():ThunkResult<void>=> async  (
  dispatch: StateAwareThunkDispatch
) => {
    try{
        dispatch({ type: CONSTANTS.SET_STATUSES_ERROR, error:null }) 
        dispatch({ type: CONSTANTS.SET_STATUSES_LOADING, isLoading: true }) 

        const {statuses} = await api.getStatuses()
        dispatch({ type: CONSTANTS.SET_STATUSES_LOADING, isLoading: false }) 
        dispatch({ type: CONSTANTS.SET_STATUSES, statuses }) 
    }catch(error:any){
        dispatch({ type: CONSTANTS.SET_STATUSES_ERROR, error:error.message }) 
        dispatch({ type: CONSTANTS.SET_STATUSES_LOADING, isLoading: false }) 
    }
}

