import React, { useState } from 'react'
import { IconFilterAlt, useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from 'src/components/custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from '../../../utils/shared-types'
import { MobileFiltersMenu } from './mobile-filters-menu'
import { useMedia } from '../../../hooks/use-media'
import { useCILModalContext } from 'src/hooks/use-cil-modal-context'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

interface MobileFiltersProps {
  replacement: appliancepb.IReplacementResponse | null
  modelSearchEnabled: boolean
  itemId: string
  setIsModelSearched: (isSearched: boolean) => void
  setSearchResults: (searchResults: appliancepb.IProduct[]) => void
}

export const MobileFilters = ({
  modelSearchEnabled,
  itemId,
  setIsModelSearched,
  setSearchResults,
  replacement,
}: MobileFiltersProps) => {
  const { setOpen: setOpenModal } = useCILModalContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const isDesktop = useMedia('(min-width:1024px)')
  const [opened, setMenuOpen] = useState(false)
  const selectedFilters = useSelector(
    (state: AppState) => state.filters.selected
  )

  const open = () => {
    setMenuOpen(true)
  }

  const close = () => {
    setMenuOpen(false)
  }

  return (
    <>
      <div className="lg:hidden flex -mx-4 sm:mx-0 md:-mx-24 px-4 sm:px-10 md:px-24 shadow-raised justify-between w-full">
        <div className="flex items-center py-4 cursor-pointer" onClick={open}>
          <Text
            id="mobile-filters-count"
            variant="label"
            className="mr-2"
            textTemplateKey="BROWSE_MOBILE_FILTERS_TITLE"
            textTemplateData={{
              count: Object.keys(selectedFilters).length.toString(),
            }}
          />
          <IconFilterAlt
            id="mobile-filters-filter-icon"
            color="interactive"
            style={{ transform: 'scaleX(-1)', cursor: 'pointer' }}
          />
        </div>
        {replacement &&
          parseInt(replacement.replacementDetails?.cashInLieu, 10) && (
            <Button
              id="mobile-filters-modal-open"
              size={`${isDesktop ? 'large' : 'small'}`}
              label={localizedText('BROWSE_CIL_BTN')}
              variant="outlined"
              onClick={() => setOpenModal(true)}
            />
          )}
      </div>
      {opened && (
        <MobileFiltersMenu
          close={close}
          modelSearchEnabled={modelSearchEnabled}
          itemId={itemId}
          setIsModelSearched={setIsModelSearched}
          setSearchResults={setSearchResults}
        />
      )}
    </>
  )
}
