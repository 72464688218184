// @ts-nocheck
// Tabs component doesn't work with Typescript !!
import React, { useEffect, useLayoutEffect } from 'react'
import { Tabs, Tab } from '@ftdr/blueprint-components-react'
import PaymentForm from '../forms/payment/payment-form'
import QueueForm from '../forms/queue/queue-form'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { useHistory } from 'react-router'
import PaymentTable from '../tables/payment/payments-table'
import QueueTable from '../tables/queue/queue-table'
import QueueDialogsGroup from 'src/components/queue-dialogs-group/queue-dialogs-group'
import qs from 'query-string'
import { IAnyObject } from 'src/utils/shared-types'

import { useDispatch } from 'react-redux'
import { setStatuses } from 'src/store/statuses-store/statuses-store'
import PaymentDialogsGroup from 'src/components/payment-dialogs-group/payment-dialogs-group'

const CustomTabs = (): React.FC => {
  const history = useHistory()
  const dispatch = useDispatch()
  const pushParams = (params: IAnyObject, index) => {
    const stringParams = qs.stringify(params)

    if (!!stringParams) {
      history.push(`/queue#tab${index}?${stringParams}`)
    } else {
      history.push(`/queue#tab${index}`)
    }
  }

  const {
    setActiveTab,
    paymentParams,
    queueParams,
    activeTab,
  } = useTabsTableContext()

  useLayoutEffect(() => {
    history.replace(`/queue${history.location.hash || '#tab1'}`)
    dispatch(setStatuses())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newIndex = parseFloat(history.location.hash.slice(4, 5)) || 1
    setActiveTab(newIndex)
    //eslint-disable-next-line
  }, [history.location.hash])

  return (
    <div className="queue-tabs-body">
      <Tabs
        id="tabs-tabs-wrapper"
        activeIndex={parseFloat(history.location.hash.slice(4, 5)) - 1 || 0}
        defaultActiveIndex={
          parseFloat(history.location.hash.slice(4, 5)) - 1 || 0
        }
        onActivate={(e, selectedIndex, previousIndex) => {
          const index = selectedIndex + 1
          setActiveTab(index)
          switch (index) {
            case 1:
              pushParams(queueParams, index)
              break
            case 2:
              pushParams(paymentParams, index)
              break
          }
        }}
      >
        <Tab
          id="tabs-tsb-1"
          label="Queue Search"
          color="primary"
          className={`${activeTab === 1 ? 'shadow-xl ' : ''}rounded-t-lg`}
        >
          <QueueForm />
          <QueueTable />
        </Tab>
        <Tab
          id="tabs-tsb-2"
          label="Payment Search"
          color="primary"
          className={`${activeTab === 2 ? 'shadow-xl ' : ''}rounded-t-lg`}
        >
          <PaymentForm />
          <PaymentTable />
        </Tab>
      </Tabs>
      <QueueDialogsGroup />
      <PaymentDialogsGroup />
    </div>
  )
}

export default CustomTabs
