import React from 'react'
import { TextComponent as Text } from '../custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from '../../utils/shared-types'
import { getServiceLimit } from 'src/utils/helper'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'

interface IDispatchCard {
  wrapperClasses?: string
  elemsClasses?: string
  style?: any
}

export const DispatchIdCard: React.FC<IDispatchCard> = ({
                                                          wrapperClasses,
                                                          elemsClasses,
                                                          style,
                                                        }) => {
  const survey = useSelector((store: AppState) => store.survey.agentSurvey)
  const wrapperProps = wrapperClasses
    ? { className: wrapperClasses }
    : undefined
  const elemProps = elemsClasses ? { className: elemsClasses } : undefined
  const serviceLimit = getServiceLimit(survey)

  return (
    <div {...wrapperProps} style={style}>
      <div {...elemProps}>
        <Text
          variant='label'
          textTemplateKey='DISPATCH_ID_LABEL'
          className='whitespace-no-wrap'
        />
        <Text id='agent-info-banner-dispatch-id'>{survey.dispatchID}</Text>
      </div>
      <div {...elemProps}>
        <Text
          variant='label'
          textTemplateKey='PART_REQUEST_ID_LABEL'
          className='whitespace-no-wrap'
        />
        <Text id='agent-info-banner-part-request-id'>{survey.partRequestId}</Text>
      </div>
      <div {...elemProps}>
        <Text
          variant='label'
          textTemplateKey='SERVICE_ITEM_LABEL'
          className='whitespace-no-wrap'
        />
        <Text id='agent-info-banner-service-item'>{survey.item?.name}</Text>
      </div>

      <div {...elemProps}>
        <Text
          variant='label'
          textTemplateKey='DISPOSAL_COVERAGE_LABEL'
          className='whitespace-no-wrap'
        />
        <Text id='agent-info-banner-disposal-coverage'>{survey.dcov ? 'Yes' : 'No'}</Text>
      </div>
      {serviceLimit != null && (
        <div {...elemProps}>
          <Text
            variant='label'
            textTemplateKey='SERVICE_LIMIT_LABEL'
            className='whitespace-no-wrap'
          />
          <Text id='agent-info-banner-service-limit'>
            {formatCentsCurrency(serviceLimit)}
          </Text>
        </div>
      )}
    </div>
  )
}
