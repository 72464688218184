import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { useMedia } from '../../../hooks/use-media'
import {
  stylesRowElem,
  stylesRow,
  stylesRowElemMobile,
  stylesRowMobile,
} from '../helpers'

const DimensionRow: React.FC = () => {
  const isMobile = useMedia('(max-width:767px)')
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )

  return (
    <div
      className={`flex mb-4 md:justify-center`}
      style={isMobile ? stylesRowMobile : stylesRow}
    >
      {compareProducts.map((product) => {
        const { height, width, depth } = product?.dimensions || {}

        return (
          <div
            key={product.ID}
            className="flex flex-col justify-between px-6"
            style={isMobile ? stylesRowElemMobile : stylesRowElem}
          >
            <Text
              variant="heading-05"
              className="font-normal break-all"
            >{`${height}h x ${width}w x ${depth}d`}</Text>
          </div>
        )
      })}
    </div>
  )
}

export default DimensionRow
