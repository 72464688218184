export const initialOrder = [
  'WEIGHTS & DIMENSIONS',
  'APPEARANCE',
  'WARRANTY',
  'FEATURES',
  'POWER / RATING',
  'CAPACITY',
  'FILTER',
]

export const stylesRowElem = {
  flex: '1 1 300px',
  maxWidth: '300px',
}
export const stylesRowElemMobile = {
  flex: '0 0 33.33%',
  maxWidth: '33.33%',
}
export const stylesRow = {
  width: '100%',
}
export const stylesRowMobile = {
  width: '150%',
}
export const setRowsWithOrder = (
  order: string[],
  arrToSort: string[]
): string[] => [
  ...order.filter((title) => arrToSort.includes(title)),
  ...arrToSort.filter((title) => !order.includes(title)),
]
