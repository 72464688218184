import { ParsedFilter } from 'src/utils/shared-types'
import { isNumber, isRange } from 'src/utils/validation-utils'
import { IAnyObject } from 'src/utils/shared-types'
import { StringifyOptions } from 'query-string'

export const stringToSnakeCase = (str: string) => {
  return str.replace(/ /g, `_`)
}

export const snakeCaseToString = (str: string) => {
  return str.replace(/_/g, ` `)
}

export const paramsToObjectKeys = (obj: IAnyObject) => {
  const state: IAnyObject = {}
  for (const key in obj) {
    state[snakeCaseToString(key)] = Array.isArray(obj[key])
      ? obj[key]
      : [obj[key]]
  }
  return state
}

export const objectKeysToParams = (obj: IAnyObject): any => {
  const state: IAnyObject = {}
  for (const key in obj) {
    state[stringToSnakeCase(key)] = Array.isArray(obj[key])
      ? obj[key]
      : [obj[key]]
  }
  return state
}

export const updatePagination = (postsPerPage: number, pageNumber: number) => {
  const updatedPagination: { items_per_page?: number; page?: number } = {}
  if (postsPerPage === 30 || postsPerPage === 60) {
    updatedPagination['items_per_page'] = postsPerPage
  }

  if (pageNumber !== 1 && isNumber(`${pageNumber}`)) {
    updatedPagination['page'] = pageNumber
  }
  return updatedPagination
}

export const updateFilters = (
  filters: ParsedFilter[],
  searchObject: IAnyObject
) => {
  const updatedFilters: IAnyObject = {}

  Object.entries(searchObject).forEach(([key, value]) => {
    if (filters.find((filter) => filter.name === key)?.type === 'enum') {
      updatedFilters[key] = (Array.isArray(value) ? value : [value]) || []
    } else if (
      filters.find((filter) => filter.name === key)?.type === 'range' &&
      isRange(value)
    ) {
      const min = +value.split('-')[0]
      const max = +value.split('-')[1]
      if (max >= min || (min === 0 && max !== 0) || (min !== 0 && max === 0)) {
        updatedFilters[key] = [value]
      }
    }
  })

  return updatedFilters
}

export const qsConfig: StringifyOptions = {
  arrayFormat: 'none',
  encode: false,
}
