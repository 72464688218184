import React from 'react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import { Link } from 'react-router-dom'
import AgentReplacementConfiramtionInfoList from 'src/components/agent-replacement-confiramation-info-list/agent-replacement-confirmation-info-list'
import { useAppContext } from '@ftdr/blueprint-components-react'

const OutrightPaymentSuccess = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  return (
    <div className="h-full flex justify-center items-center">
      <article className="flex flex-col justify-center items-center">
        <AgentReplacementConfiramtionInfoList messageIndex={0} />

        <Link to="/">
          <Button
            id="outrigth-payment-success-button"
            label={localizedText('OUTRIGHT_PAYMENT_SUCCESS_REDIRECT')}
          />
        </Link>
      </article>
    </div>
  )
}

export default OutrightPaymentSuccess
