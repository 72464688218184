import React, { useState } from 'react'
import { IconButton, IconSearch, Input, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button, TextComponent as Text } from 'src/components/custom-fdr-components'
import { useDispatch } from 'react-redux'
import { api } from '../../../utils/api'
import { AgentSurvey } from '../agent-survey-types'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { changeProductCategory } from 'src/store/survey-store'
import { isNumber } from 'src/utils/validation-utils'
import './dispatch-search.css' //Search icon styles -- not possible by taillwind

interface DispatchSearchProps {
  survey: AgentSurvey.Survey

  onDispatchSearch(
    dispatchID: string,
    dispatch: appliancepb.IDispatchInfoResponse,
  ): void

  resetDispatchInfo(): void
}

export const DispatchSearch = ({
                                 survey,
                                 onDispatchSearch,
                                 resetDispatchInfo,
                               }: DispatchSearchProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [dispatchId, setDispatchId] = useState(survey.dispatchID || '')
  const [messages, setMessages] = useState(survey.dispatch?.messages || [])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const dispatchErrors = {
    '400': localizedText('SURVEY_DISPATCH_SEARCH_BAD_REQUEST'),
    '401': localizedText('SURVEY_DISPATCH_SEARCH_EXPIRED'),
    '404': localizedText('SURVEY_DISPATCH_SEARCH_NOT_FOUND'),
  }

  const searchDispatchId = async () => {
    if (!dispatchId) {
      setError(localizedText('SURVEY_DISPATCH_SEARCH_EMPTY'))
      return
    }
    setMessages([])
    setLoading(true)
    try {
      const info = await api.getDispatchInfo(dispatchId)
      onDispatchSearch(dispatchId, info)
      setMessages(info.messages || [])
      setError('')
    } catch (err: any) {
      const statusCode = err?.response?.status.toString()
      setError(dispatchErrors[statusCode])
      resetDispatchInfo()
    }
    dispatch(changeProductCategory(''))
    setLoading(false)
  }

  const onDispatchIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isNumber(e.target.value) && setDispatchId(e.target.value)
    resetDispatchInfo()
  }

  return (
    <div>
      <div className='md:flex items-start relative'>
        <Input
          formField
          disabled={loading}
          label={localizedText('DISPATCH_ID_LABEL')}
          formFieldClassName='w-full sm:w-auto'
          className='w-full md:w-56 flex justify-center md:inline-block'
          error={error}
          value={dispatchId}
          onChange={onDispatchIdChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              searchDispatchId()
              dispatch(changeProductCategory(''))
            }
          }}
        />
        <Button
          className={`md:ml-4 md:mt-4 hidden md:inline-flex`}
          loading={loading}
          variant='outlined'
          size='medium'
          label={localizedText('SURVEY_DISPATCH_SEARCH_BTN')}
          endIcon={<IconSearch size='35' />}
          onClick={searchDispatchId}
        />
        <IconButton
          color='interactive'
          className='noHover absolute right-0 top-24px md:hidden'
          loading={loading}
          variant='ghost'
          size='medium'
          label={localizedText('SURVEY_DISPATCH_SEARCH_BTN')}
          icon={<IconSearch size='24' />}
          onClick={searchDispatchId}
        />
      </div>
      {messages.length > 0 && (
        <ul className='mt-2'>
          {messages.map((msg, k) => (
            <Text key={k} color='secondary'>
              {msg}
            </Text>
          ))}
        </ul>
      )}
    </div>
  )
}
