export const reasons = [
  {
    id: '1',
    label: 'Not required',
    value: 'Not required',
  },
  {
    id: '2',
    label: 'Added by accident',
    value: 'Added by accident',
  },
]
