import { useAppContext } from '@ftdr/blueprint-components-react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'src/store/initial-state'
import { Tenant } from 'src/utils/shared-types'

export interface IContent {
  id: string
  label: string
  description: string
}

export const useFaqContent = () => {
  const tenant = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.dispatch?.tenantAbbreviation || Tenant.AHS
  )
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const contentAgent: IContent[] = useMemo(
    () => [
      {
        id: '1',
        label: localizedText('FAQ_AGENT_TITLE_0'),
        description: localizedText('FAQ_AGENT_BODY_0'),
      },
      {
        id: '2',
        label: localizedText('FAQ_AGENT_TITLE_1'),
        description: localizedText('FAQ_AGENT_BODY_1'),
      },
      {
        id: '3',
        label: localizedText('FAQ_AGENT_TITLE_2'),
        description: localizedText('FAQ_AGENT_BODY_2'),
      },
      {
        id: '4',
        label: localizedText('FAQ_AGENT_TITLE_3'),
        description: localizedText('FAQ_AGENT_BODY_3'),
      },
      {
        id: '5',
        label: localizedText('FAQ_AGENT_TITLE_4'),
        description: localizedText('FAQ_AGENT_BODY_4'),
      },
      {
        id: '6',
        label: localizedText('FAQ_AGENT_TITLE_5'),
        description: localizedText('FAQ_AGENT_BODY_5'),
      },
      {
        id: '7',
        label: localizedText('FAQ_AGENT_TITLE_6'),
        description: localizedText('FAQ_AGENT_BODY_6'),
      },
      {
        id: '8',
        label: localizedText('FAQ_AGENT_TITLE_7'),
        description: localizedText('FAQ_AGENT_BODY_7'),
      },
      {
        id: '9',
        label: localizedText('FAQ_AGENT_TITLE_8'),
        description: localizedText('FAQ_AGENT_BODY_8'),
      },
      {
        id: '10',
        label: localizedText('FAQ_AGENT_TITLE_9'),
        description: localizedText('FAQ_AGENT_BODY_9'),
      },
      {
        id: '11',
        label: localizedText('FAQ_AGENT_TITLE_10'),
        description: localizedText('FAQ_AGENT_BODY_10'),
      },
      {
        id: '12',
        label: localizedText('FAQ_AGENT_TITLE_11'),
        description: localizedText('FAQ_AGENT_BODY_11'),
      },
      {
        id: '13',
        label: localizedText('FAQ_AGENT_TITLE_12'),
        description: localizedText('FAQ_AGENT_BODY_12'),
      },
      {
        id: '14',
        label: localizedText('FAQ_AGENT_TITLE_13'),
        description: localizedText('FAQ_AGENT_BODY_13'),
      },
      {
        id: '15',
        label: localizedText('FAQ_AGENT_TITLE_14'),
        description: localizedText('FAQ_AGENT_BODY_14'),
      },
      {
        id: '16',
        label: localizedText('FAQ_AGENT_TITLE_15'),
        description: localizedText('FAQ_AGENT_BODY_15'),
      },
      {
        id: '17',
        label: localizedText('FAQ_AGENT_TITLE_16'),
        description: localizedText('FAQ_AGENT_BODY_16'),
      },
      {
        id: '18',
        label: localizedText('FAQ_AGENT_TITLE_17'),
        description: localizedText('FAQ_AGENT_BODY_17'),
      },
    ],

    [localizedText]
  )
  const contentClient: IContent[] = useMemo(
    () => [
      {
        id: '1',
        label: localizedText('FAQ_0_TITLE'),

        description: localizedText('FAQ_0_BODY'),
      },
      {
        id: '2',
        label: localizedText('FAQ_1_TITLE'),

        description: localizedText('FAQ_1_BODY', { tenant }),
      },
      {
        id: '3',
        label: localizedText('FAQ_2_TITLE', { tenant }),

        description: localizedText('FAQ_2_BODY', {
          tenant: tenant.charAt(0).toUpperCase() + tenant.slice(1),
        }),
      },
      {
        id: '4',
        label: localizedText('FAQ_3_TITLE'),

        description: localizedText('FAQ_3_BODY'),
      },
    ],
    [localizedText, tenant]
  )
  return { contentClient, contentAgent }
}
