import React, { useState, useEffect } from 'react'
import {
  IconNavArrowLeft,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import { useParams, useHistory, Link } from 'react-router-dom'
import { api } from '../../utils/api'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, RequestStatus } from '../../utils/shared-types'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'
import { changeProductCategory } from 'src/store/survey-store'
import AgentReplacementConfiramtionDialog from 'src/components/agent-replacement-confirmation-dialog/agent-replacement-confiramtion-dialog'
import { clearProducts } from 'src/store/compare-store'
import {
  sendCustomerOfferNotification,
  orderForCustomerNotification,
  generateProductAccesories,
} from 'src/utils/helper'
import { useAgentConfiramtion } from 'src/hooks/use-agent-confirmation-modal-context'
import AgentReplacementConfirmationInfoList from '../../components/agent-replacement-confiramation-info-list/agent-replacement-confirmation-info-list'
import { getServiceLimit } from 'src/utils/helper'
import { isAHS } from 'src/utils/tenant-helper'

interface Params {
  productId: string
}
interface IReplacement {
  cil: number
  likeForLikeProductID: string
  partRequestId?: string | null
  addressId?: string | null
  id?: string | null
}
type TActions = 'send offer' | 'order for' | ''
export const AgentReplacementConfirmation = () => {
  const { setOpen: setModalOpen } = useAgentConfiramtion()
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon
  )
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const history = useHistory()
  const dispatch = useDispatch()
  const { productId } = useParams<Params>()
  const [product, setProduct] = useState<appliancepb.Product | null>(null)
  const [productError, setProductError] = useState<any>()
  const [createdReplacementId, setReplacementId] = useState('')
  const [shopLink, setShopLink] = useState('')
  const [status, setStatus] = useState<RequestStatus>('pending')
  const [response, setResponse] = useState('')
  const survey = useSelector((store: AppState) => store.survey)
  const [replacement, setReplacement] = useState<IReplacement>({
    cil: 0,
    likeForLikeProductID: '',
    partRequestId: '',
    addressId: '',
    id: '',
  })

  const [action, setAction] = useState<TActions>('')
  const surveyDispatch = survey.agentSurvey.dispatch
  const addressId = surveyDispatch?.addressID || ''
  const itemId = survey.newProductCategory || survey.agentSurvey.item?.id || ''
  const garbageDisposalItemID = '0517ccd6-79f6-4736-9c0a-a2545f202b46'
  const showSendOfferButton = itemId !== garbageDisposalItemID

  const decodeAndSetError = (err: any) => {
    const byteArray = new Uint8Array(err.response.data)
    const decoder = new TextDecoder()
    setResponse(decoder.decode(byteArray))
    setStatus('error')
  }

  const createReplacement = async () => {
    try {
      setStatus('loading')
      const replacementResponse = await api.createReplacement(
        {
          itemID: itemId,
          likeForLikeProductID: product?.ID,
          dispatchID: survey.agentSurvey.dispatchID,
          partRequestID: survey.agentSurvey.partRequestId,
          addressID: surveyDispatch?.addressID,
          customerID: surveyDispatch?.customerID,
          contractID: surveyDispatch?.contractID,
          hasDisposalCoverage: survey.agentSurvey.dcov,
          notification: sendCustomerOfferNotification,
          serviceLimit: getServiceLimit(survey.agentSurvey),
          ...generateProductAccesories(trimKitSize || '', icemakerAddon || ''),
        },
        trimKitSize,
        icemakerAddon
      )

      setStatus('ok')
      dispatch(changeProductCategory(''))
      dispatch(clearProducts())
      setReplacementId(replacementResponse.replacementId)
    } catch (err: any) {
      decodeAndSetError(err)
    }
  }

  const createPurchase = () => {
    dispatch(clearProducts())
    //TODO: Wait for backend ticket to complete new replacement endpoint and implement it
    history.push(`/browse/${productId}/confirm/purchase`)
  }
  const checkDispatchID = async (callback: () => void) => {
    try {
      const { replacements } = await api.getReplacements({
        dispatch_id: survey.agentSurvey.dispatchID,
        part_request_id: survey.agentSurvey.partRequestId,
      })

      if (
        replacements.length > 0 &&
        replacements[0].likeForLikeProductID !== productId
      ) {
        setReplacement({
          cil: replacements[0].cashInLieu,
          likeForLikeProductID: replacements[0].likeForLikeProductID || '',
          partRequestId: replacements[0].partRequestID,
          id: replacements[0].ID,
          addressId: replacements[0].addressID,
        })
        setModalOpen(true)
      } else {
        callback()
      }
    } catch (err: any) {
      decodeAndSetError(err)
    }
  }
  const OrderForCustomer = async () => {
    try {
      setStatus('loading')
      const createRes = await api.createReplacement(
        {
          itemID: itemId,
          likeForLikeProductID: product?.ID,
          dispatchID: survey.agentSurvey.dispatchID,
          partRequestID: survey.agentSurvey.partRequestId,
          addressID: surveyDispatch?.addressID,
          customerID: surveyDispatch?.customerID,
          contractID: surveyDispatch?.contractID,
          hasDisposalCoverage: survey.agentSurvey.dcov,
          notification: orderForCustomerNotification,
          serviceLimit: getServiceLimit(survey.agentSurvey),
          ...generateProductAccesories(trimKitSize || '', icemakerAddon || ''),
        },
        trimKitSize,
        icemakerAddon
      )

      dispatch(clearProducts())

      const uuid = createRes.replacementId
      setShopLink(createRes.shopLink)
      setReplacementId(uuid)
      setStatus('ok')
    } catch (err: any) {
      decodeAndSetError(err)
      console.log(err)
    }
  }

  useEffect(() => {
    const getProduct = async () => {
      try {
        const product = await api.getReplacementProduct(
          itemId,
          productId,
          addressId,
          survey.agentSurvey.partRequestId,
          survey.agentSurvey.dcov,
          trimKitSize,
          icemakerAddon
        )
        setProduct(product)
        setProductError(null)
      } catch (err) {
        console.error(err)
        setProductError(err)
      }
    }
    getProduct()
    //eslint-disable-next-line
  }, [itemId, productId, addressId, survey])

  return (
    <section className="m-auto -mt-8 lg:-mt-0 lg:-mx-24 xl:max-w-4xl xl:m-auto">
      {/* back button */}
      <Text
        id="agent-replacement-confirmation-t-left-icon"
        className="mb-8 font-bold flex items-center cursor-pointer"
        onClick={() => history.goBack()}
      >
        <IconNavArrowLeft
          id="agent-replacement-confirmation-left-icon"
          size="20"
        />
        <span className="ml-1 lg: ml-2">
          {localizedText('AGENT_CONFIRMATION_BACK_BTN')}
        </span>
      </Text>
      {product ? (
        status === 'ok' ? (
          <article id="agent-replacement-confirmation-article">
            {orderForCustomerNotification.customerShopLink ? (
              <AgentReplacementConfirmationInfoList messageIndex={1} />
            ) : null}
            <div className="mb-4">
              <Text
                variant="caption"
                className="font-bold inline"
                textTemplateKey="AGENT_CONFIRMATION_SUCCESS_SUBTITLE"
              />
              <Text
                id="agent-replacement-confirmation-create-replacement"
                variant="caption"
                className="inline ml-1"
              >
                {createdReplacementId}
              </Text>
            </div>
            {shopLink === '' ? (
              <Link to="/" id="agent-replacement-confirmation-link-another">
                <Button
                  id="agent-replacement-confirmation-button-another"
                  label={localizedText('AGENT_CONFIRMATION_CREATE_ANOTHER')}
                />
              </Link>
            ) : (
              <Link
                id="agent-replacement-confirmation-shop-link"
                to={{ pathname: `${shopLink}` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  id="agent-replacement-confirmation-shopping"
                  label="Go shopping for client"
                />
              </Link>
            )}
          </article>
        ) : status === 'error' ? (
          <article id="agent-replacement-confirmation-article-1">
            <Text variant="heading-06">{response}</Text>
          </article>
        ) : (
          <article>
            <Text
              className="mb-4"
              variant="heading-05"
              textTemplateKey="AGENT_CONFIRMATION_SELECTED_APPLIANCE_TITLE"
            />
            <div
              className={`sm:flex  rounded-3 px-4 py-7 relative ${
                isAHS() ? 'bg-white' : 'shadow-floating'
              }`}
            >
              <figure className="w-24 md:w-32 mx-auto mb-8 lg:w-48 flex-shrink-0 md:mb-0 md:ml-0 md:mr-4 lg:mr-8">
                <img
                  id="agent-replacement-confirmation-product-image"
                  src={product.images[0]?.url || '/noimage.jpg'}
                  alt={product.images[0]?.imageName || 'Product Image'}
                />
              </figure>
              <div className="lg:flex-grow flex flex-col">
                <Text
                  id="agent-replacement-confirmation-product-description"
                  variant="heading-06"
                  className="mb-2"
                >
                  {product.description}
                </Text>
                <Text
                  id="agent-replacement-confirmation-product-product-with-tax"
                  variant="heading-04"
                  className="mb-6"
                >
                  {formatCentsCurrency(product.defaultPriceWithTax)}
                </Text>
                <div className="sm:mt-auto lg:flex items-end">
                  {showSendOfferButton && (
                    <div className="mt-2 lg:mt-0 lg:ml-4">
                      <Button
                        id="agent-replacement-confirmation-send-offer-button"
                        size="small"
                        label={localizedText('AGENT_CONFIRMATION_SEND_BTN')}
                        loading={status === 'loading'}
                        onClick={() => {
                          setAction('send offer')
                          checkDispatchID(createReplacement)
                        }}
                      />
                    </div>
                  )}
                  <div className="sm:mt-auto lg:flex items-end">
                    <Button
                      id="agent-replacement-confirmation-order-for-customer-button"
                      className="mt-2 lg:mt-0 lg:ml-4"
                      size="small"
                      color={isAHS() ? 'interactive' : 'gray'}
                      label={localizedText(
                        'AGENT_CONFIRMATION_ORDER_FOR_CUSTOMER'
                      )}
                      loading={status === 'loading'}
                      onClick={() => {
                        setAction('order for')
                        checkDispatchID(OrderForCustomer)
                      }}
                    />
                  </div>
                  {
                    <div className="mt-2 lg:mt-0 lg:ml-4">
                      <Button
                        id="agent-replacement-confirmation-external-button"
                        size="small"
                        color={isAHS() ? 'interactive' : 'accent'}
                        label={localizedText(
                          'AGENT_CONFIRMATION_RECORD_EXTERNAL_ORDER'
                        )}
                        loading={status === 'loading'}
                        onClick={createPurchase}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </article>
        )
      ) : productError ? (
        <div>
          <div className="mt-8 flex justify-center">
            <Text
              id="agent-replacement-confirmation-agent-error"
              variant="heading-06"
              textTemplateKey="ERROR_AGENT"
            />
          </div>
        </div>
      ) : (
        /* loading */
        <div
          id="agent-replacement-confirmation-progress"
          className="mt-8 flex justify-center"
        >
          <ProgressIndicator size="medium" />
        </div>
      )}
      <AgentReplacementConfiramtionDialog
        replacement={replacement}
        product={product || null}
        callback={action === 'order for' ? OrderForCustomer : createReplacement}
      />
    </section>
  )
}
