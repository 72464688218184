import { IFileData } from './types'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

export const createdEndpoint = (actionType: string, vendor: string): string => {
  const lowerCaseVendor = vendor.toLowerCase()

  if (actionType === 'blocklist') {
    return `/${actionType}/${lowerCaseVendor}/skus`
  } else if (actionType === 'no-delivery') {
    return `/${actionType}/${lowerCaseVendor}/zips`
  } else if (actionType === 'special_prices') {
    return `/${actionType}/${lowerCaseVendor}`
  } else {
    return vendor ? `/${actionType}/${lowerCaseVendor}/skus` : `/${actionType}`
  }
}
export const createData = (actionType: string, data: IFileData): string[] => {
  const { zip, sku, price, vendor } = data

  if (actionType === 'blocklist') {
    return [sku!]
  } else if (actionType === 'preferred') {
    return [sku!, vendor!]
  } else if (actionType === 'no_delivery') {
    return [zip!]
  } else if (actionType === 'special_prices') {
    return [sku!, price!]
  } else {
    return []
  }
}

export const arrayToCSV = (array: string[]) =>
  new Blob([array.join(',')], { type: 'text/csv' })

export const reformatDate = (date: any): Date | null => {
  if (typeof date === 'object') {
    return date
  } else if (typeof date === 'string') {
    return new Date(date)
  } else {
    return null
  }
}

export const convertToServerTimeZone = (timestamp?: number) => {
  const clientDate = timestamp ? new Date(timestamp) : new Date()
  const utc = clientDate.getTime() + clientDate.getTimezoneOffset() * 60000

  const serverDate = new Date(utc)

  return serverDate
}

export const isCurrentRangeDate = (startDate: number, endDate: number) => {
  if (new Date(startDate) > new Date()) {
    return false
  } else if (
    new Date(startDate) <= new Date() &&
    new Date(endDate) >= new Date()
  ) {
    return true
  } else {
    return false
  }
}

export const dateValidation = (startDate: Date, endDate: Date) =>
  startDate.getTime() < endDate.getTime()

export const getStatus = (severity: appliancepb.Banner.Severity) => {
  switch (severity) {
    case 1:
      return 'info'
    case 2:
      return 'error'
    case 3:
      return 'warning'
    default:
      return 'info'
  }
}
