import React, { useEffect, useState } from 'react'
import { Dialog, Notification } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import { useAgentConfiramtion } from '../../hooks/use-agent-confirmation-modal-context'
import './reset.css'
import { api } from 'src/utils/api'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'

interface IReplacement {
  cil: number
  likeForLikeProductID: string
  partRequestId?: string | null
  addressId?: string | null
  id?: string | null
}
interface IProps {
  callback: () => void
  replacement: IReplacement
  product: appliancepb.Product | null
}

const AgentReplacementConfiramtionDialog: React.FC<IProps> = ({
  callback,
  replacement,
  product,
}) => {
  const { open, setOpen } = useAgentConfiramtion()
  const [prevProduct, setPrevproduct] = useState<appliancepb.Product | null>()
  const [productError, setProductError] = useState<string | null>(null)
  const [productIsLoading, setProductIsLoading] = useState<boolean>(false)

  const getProduct = async () => {
    setProductIsLoading(true)
    try {
      const prevProduct = await api.getReplacementProduct(
        replacement.id as string,
        replacement.likeForLikeProductID,
        replacement.addressId as string,
        replacement.partRequestId as string
      )

      setPrevproduct(prevProduct)
      setProductIsLoading(false)
      setProductError(null)
    } catch (err: any) {
      console.error(err)
      setProductError(err.message)
      setProductIsLoading(false)
    }
  }
  useEffect(() => {
    open && getProduct()
    //eslint-disable-next-line
  }, [open])

  return (
    <Dialog
      id="confirmation-dialog"
      className="w-full overflow-auto"
      open={open}
      usePortal={true}
      onClose={() => {
        setOpen(false)
      }}
      header={<Text variant="heading-04">Confirmation</Text>}
      actions={[
        <Button
          id="confirmation-dialog-continue"
          size="small"
          label="Continue"
          loading={false}
          onClick={() => {
            setOpen(false)
            callback()
          }}
        />,
        <Button
          id="confirmation-dialog-cancel"
          autoFocus={true}
          variant="ghost"
          color="gray"
          size="small"
          label="Cancel"
          onClick={() => {
            setOpen(false)
          }}
        />,
      ]}
      actionsAlign="right"
      closeOnOutsideClick={true}
      showBackdrop={true}
    >
      <div className="h-full">
        <Text
          textTemplateKey="AGENT_DIALOG_CONFIRMATION"
          className="mt-4 mb-8 text-black font-medium"
        />
        {productIsLoading && !prevProduct ? (
          <div
            id="confirmation-dialog-progress"
            className="flex items-center justify-center"
            style={{ minHeight: 95 }}
          >
            <ProgressIndicator data-testid="spinner" size="small" />
          </div>
        ) : null}
        {prevProduct ? (
          <div
            className="flex relative max-w-screen-sm"
            style={{ minHeight: 95 }}
          >
            <div
              className="absolute w-full h-full bg-gray-50 opacity-60"
              style={{ top: 0, left: 0 }}
            ></div>
            <figure
              className="flex-shrink-0 mr-4"
              style={{ maxHeight: 95, width: 95 }}
            >
              <img
                id="confirmation-dialog-product-image"
                className="w-auto max-h-full"
                src={prevProduct.images[0]?.url || '/noimage.jpg'}
                alt={prevProduct.images[0]?.imageName || 'Product Image'}
              />
            </figure>
            <div className="flex-grow flex flex-col">
              <Text
                id="confirmation-dialog-product-description"
                variant="heading-06"
                className="m-0"
              >
                {prevProduct.description}
              </Text>
              <Text id="confirmation-dialog-product-id" className="mb-2 text-1">
                Product ID : {prevProduct.ID}
              </Text>
              <Text variant="heading-05" className="m-0">
                {formatCentsCurrency(replacement.cil)}
              </Text>
            </div>
          </div>
        ) : productError ? (
          <Text id="confirmation-dialog-product-error">{productError}</Text>
        ) : null}

        <Notification
          id="confirmation-dialog-notification"
          className="my-8 min-w-full -mx-4"
          variant="inline"
          status="warning"
          size="medium"
          showStatusLabel={false}
          inline={true}
        >
          <Text
            textTemplateKey="AGENT_DIALOG_CONFIRMATION_DETAILS"
            className="font-medium text-black"
          />
        </Notification>

        {product && (
          <div className="flex relative max-w-screen-sm">
            <figure className="flex-shrink-0 mr-4" style={{ maxWidth: 160 }}>
              <img
                id="confirmation-dialog-product-image-1"
                className="max-w-full"
                src={product.images[0]?.url || '/noimage.jpg'}
                alt={product.images[0]?.imageName || 'Product Image'}
              />
            </figure>
            <div className="flex-grow flex flex-col">
              <Text
                id="confirmation-dialog-product-description-1"
                variant="heading-05"
                className="m-0"
              >
                {product.description}
              </Text>
              <Text
                id="confirmation-dialog-product-id-1"
                className="mb-2 text-1"
              >
                Product ID : {product.ID}
              </Text>
              <Text
                id="confirmation-dialog-price-with-tax-1"
                variant="heading-04"
                className="m-0"
              >
                {formatCentsCurrency(product.defaultPriceWithTax)}
              </Text>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default AgentReplacementConfiramtionDialog
