import React from 'react'
import { View } from './browse'
import { ActiveFiltersProvider } from 'src/hooks/use-active-filters-context'

export const Browse = () => {
  return (
    <ActiveFiltersProvider>
      <View />
    </ActiveFiltersProvider>
  )
}
