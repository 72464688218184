import { Reducer } from 'redux'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { StateAwareThunkDispatch } from 'src/utils/shared-types'
import {
  ProductStoreState,
  ProductActions,
  CONSTANTS,
} from './compare-store.types'
import { setUniqAttributes } from './compare-store-helpers'

type ProductReducer = Reducer<ProductStoreState, ProductActions>

export const ProductInitialState: ProductStoreState = {
  replacementId: '',
  data: [],
  attributeGroups: {},
}

export const compareProductReducer: ProductReducer = (
  state = ProductInitialState,
  action
): ProductStoreState => {
  switch (action.type) {
    case CONSTANTS.ADD_REPLACEMENT:
      return { ...state, replacementId: action.id }
    case CONSTANTS.ADD_PRODUCT:
      return { ...state, data: [...state.data, action.product] }

    case CONSTANTS.REMOVE_PRODUCT:
      return {
        ...state,
        data: state.data.filter((item) => item.ID !== action.productId),
      }
    case CONSTANTS.UPDATE_ATTRIBUTES:
      return { ...state, attributeGroups: setUniqAttributes(state.data) }

    case CONSTANTS.CLAER_PRODUCTS:
      return { ...state, data: [] }
    default:
      return state
  }
}

export const addProduct = (product: appliancepb.IProduct) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.ADD_PRODUCT, product })
}

export const removeProduct = (productId: string) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.REMOVE_PRODUCT, productId })
}

export const clearProducts = () => (dispatch: StateAwareThunkDispatch) =>
  dispatch({ type: CONSTANTS.CLAER_PRODUCTS })

export const createCompareAttributes = () => (
  dispatch: StateAwareThunkDispatch
) => dispatch({ type: CONSTANTS.UPDATE_ATTRIBUTES })

export const addReplacement = (id: string) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({ type: CONSTANTS.ADD_REPLACEMENT, id })
}
