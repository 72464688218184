import { useAuth } from '@ftdr/use-auth'
import axios from 'axios'
import { ErrorHandlerFunction, THttpError } from 'src/utils/error-registry/types'
import { IdentityProviders } from 'src/utils/shared-types'

//This hook is creating function can be use as error handler function.

export enum EStatuses {
    E401 = 401,

}
const useError = (status: EStatuses, message: string): ErrorHandlerFunction => {
    const { login } = useAuth()

    const errorHandler = (error?: THttpError) => {

        if (axios.isAxiosError(error)) {

            if (error?.response?.status === status) {

                if (status === EStatuses.E401) {
                    login(IdentityProviders.Azure)
                    return { message: error.message }

                }
                //Add more logic here ...
            }
        }
        return { message }

    }

    return errorHandler
}

export default useError