import { emptyItem } from 'src/components/adminForms/initials'

export const initValues = {
  dispatchID: '',
  contractID: '',
  tenantAbbreviation: emptyItem,
  firstName: '',
  lastName: '',
  billingState: emptyItem,
  date: new Date(),
  vendor: emptyItem,
  agreements: false,
}
