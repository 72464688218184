import React from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import { useMedia } from '../../../hooks/use-media'
import {
  stylesRowElem,
  stylesRow,
  stylesRowElemMobile,
  stylesRowMobile,
} from '../helpers'
import './reset.css'

interface ICompareRow {
  keyName: string
  keyValue: string
}
const CompareRow: React.FC<ICompareRow> = ({ keyName, keyValue }) => {
  const isMobile = useMedia('(max-width:767px)')
  const isDesktop = useMedia('(min-width:1024px)')
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )

  return (
    <>
      <div
        className={`hover-text flex mb-4 md:justify-center`}
        style={isMobile ? stylesRowMobile : stylesRow}
      >
        {compareProducts.map((product) => {
          const element = product.attributeGroups?.find(
            (v) => v.attributeGroupName === keyName
          )
          const elem2 = element?.attributes?.find(
            (v) => v.attribute === keyValue
          )

          return (
            <div
              key={product.ID}
              className="flex flex-col justify-start px-6"
              style={isMobile ? stylesRowElemMobile : stylesRowElem}
            >
              <Text
                variant={`${isDesktop ? 'heading-05' : 'heading-06'}`}
                className="mb-2 break-all"
              >
                {keyValue}
              </Text>
              <Text variant="heading-06" className="font-normal break-all">
                {elem2?.values?.length ? elem2.values.toString() : 'NA'}
              </Text>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CompareRow
