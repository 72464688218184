import {
  ReplacementSurveyStoreState,
  CONSTANTS,
  ReplacementSurveyAction,
} from './replacement-survey-store.types'
import { Reducer } from 'redux'
import { ThunkResult, StateAwareThunkDispatch } from 'src/utils/shared-types'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

export const replacementSurveyInitialState: ReplacementSurveyStoreState = {
  replacement: null,
}

export const replacementSurveyReducer: Reducer<
  ReplacementSurveyStoreState,
  ReplacementSurveyAction
> = (
  state = replacementSurveyInitialState,
  action: ReplacementSurveyAction
): ReplacementSurveyStoreState => {
  switch (action.type) {
    case CONSTANTS.ADD_REPLACEMENT:
      return { ...state, replacement: action.replacement }
    case CONSTANTS.REMOVE_REPLACEMENT:
      return { ...state, replacement: action.replacement }
    default:
      return state
  }
}

export const addReplacement = (
  replacement: appliancepb.IFullReplacementDetails | null
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  dispatch({
    type: CONSTANTS.ADD_REPLACEMENT,
    replacement,
  })
}

export const removeReplacement = (): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({
    type: CONSTANTS.REMOVE_REPLACEMENT,
    replacement: null,
  })
}
