import { appliancepb } from '../services/protobuf-models/appliance-ms-protobuf-models'

//New protobuff needed
export const paymentTypes = [
  {
    id:
      '' +
      appliancepb.OutrightPaymentRequest.PaymentType
        .PAYMENT_TYPE_OUTRIGHT_PAYMENT,
    value: 'Retail Payment',
    label: 'Retail Payment',
  },
  {
    id:
      '' +
      appliancepb.OutrightPaymentRequest.PaymentType.PAYMENT_TYPE_ACCESSORIES,
    label: 'Accessories',
    value: 'Accessories',
  },
  {
    id:
      '' +
      appliancepb.OutrightPaymentRequest.PaymentType
        .PAYMENT_TYPE_RESTOCKING_FEE,
    value: 'Restocking Fee',
    label: 'Restocking Fee',
  },
  //   {
  //     id: '5',
  //     value: 'Continue Replacement',
  //     label: 'Continue Replacement',
  //   },
]
