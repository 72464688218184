import React from 'react'
import {
  ButtonProps,
  Button,
  ButtonColorOption,
  ButtonShape,
} from '@ftdr/blueprint-components-react'
import { isAHS } from 'src/utils/tenant-helper'

export const ButtonComponent: React.FC<ButtonProps> = (props) => {
  const defaultProps = {
    color: isAHS()
      ? ('interactive' as ButtonColorOption)
      : ('primary' as ButtonColorOption),
    shape: isAHS() ? ('pill' as ButtonShape) : ('rounded' as ButtonShape),
    ...props,
  }
  return <Button {...defaultProps} />
}
