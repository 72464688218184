import React, { useCallback, useEffect } from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import {
  Overlay,
  IconButton,
  IconCancel,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import { useAdminContext } from '../../../hooks/use-admin-context'
import { useDispatch, useSelector } from 'react-redux'
import { editBanner } from 'src/store/admin-store'
import { AppState } from 'src/store/initial-state'
import BannerForm from './form/banner-form'
import {
  emptyItem,
  initialBannersMessage,
  initialBannersTypes,
} from '../initials'

const EditModalBanner: React.FC = () => {
  const {
    isEditBannerModalOpen,
    setIsEditBannerModalOpen,
    clearBanner,
    banner,
  } = useAdminContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { editError, isBannerEdited } = useSelector(
    (state: AppState) => state.admin
  )

  const dispatch = useDispatch()

  const closeModal = useCallback(() => {
    setIsEditBannerModalOpen(false)
    clearBanner()
    //eslint-disable-next-line
  }, [])

  const callBack = (banner: appliancepb.IBanner) => {
    dispatch(editBanner(banner))
  }

  useEffect(() => {
    if (!editError && isBannerEdited) {
      clearBanner()
      closeModal()
    }
    //eslint-disable-next-line
  }, [editError, isBannerEdited])

  return (
    <Overlay open={isEditBannerModalOpen} onClose={closeModal} usePortal={true}>
      <div
        id="edit-modal-wrapper"
        className="bg-white p-8 w-full rounded-1"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 620,
        }}
      >
        <span className="absolute" style={{ right: 15, top: 15 }}>
          <IconButton
            id="edit-modal-close"
            label={localizedText('ADMIN_LABEL_CLOSE')}
            color="primary"
            variant="outlined"
            icon={<IconCancel />}
            size="small"
            onClick={() => {
              clearBanner()
              closeModal()
            }}
          />
        </span>

        <BannerForm
          title="ADMIN_TITLE_BANNERS_2"
          callBack={callBack}
          type="edit"
          id={banner?.id || undefined}
          initValues={{
            severity:
              initialBannersTypes.find(
                (severity) => Number(severity.id) === banner?.severity
              ) || emptyItem,
            message:
              initialBannersMessage.find(
                (message) => message.label === banner?.message
              ) || initialBannersMessage[2],
            customMessage: banner?.message || '',
            start: new Date(banner?.activate?.seconds * 1000),
            end: new Date(banner?.expire?.seconds * 1000),
          }}
        />
      </div>
    </Overlay>
  )
}

export default EditModalBanner
