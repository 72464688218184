import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../store/initial-state'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { Dispatch, ReactElement, ReactPortal } from 'react'
import { ApiMessage } from 'src/components/adminForms/types'

export type RequestStatus = 'pending' | 'loading' | 'ok' | 'error'
export type RequestSpecificStatus = {
  type: RequestStatus
  message?: string
}

export type { AppState }

export enum IdentityProviders {
  Azure = 'AppliancePortalAgentAuth',
}

export enum Tenant {
  AHS = 'ahs',
  HSA = 'hsa',
}

export interface ParsedFilter {
  type: string
  name: string
  values?: string[]
}

/**
 * Sugar type on top of the `ThunkAction<R, S, E, A>` type from `redux-thunk`.
 * To be used as the return type for all Thunk Action Creators.
 * Having this avoids having to remember to pass the `AppState` and `undefined` for the
 * S and E generics types respectively in `ThunkAction<R, S, E, A>`.
 */
export type ThunkResult<R> = ThunkAction<R, AppState, undefined, Action>

/**
 * Sugar type on top ot he `ThunkDispatch<S, E, A>` type from `redux-thunk`.
 * To be used as the type for all thunk related references to the redux `dispatch` function.
 */
export type StateAwareThunkDispatch = ThunkDispatch<AppState, undefined, Action>

export interface MenuItem {
  id: string
  value: string
  label: string
}

export interface MenuItemWithSubCategory extends MenuItem {
  hasSubcategory?: boolean
}

export interface IMultiSelectItem {
  value: string
  id: string
}
export interface MenuItemType {
  id: string
  value: 'warning' | 'error' | 'success' | 'info' | ''
  label: string
}
export interface IInitialPFF {
  contractID?: string | null
  dispatchID?: string | null
  orderNumber?: string | null
  firstName: string
  lastName: string
  model: string
  date: Date | null
  amount: string
}

export interface IForm {
  paymentType: MenuItem
  dispatchID: string
  contractID: string
  orderID: string
  firstName: string
  lastName: string
  billingState: MenuItem
  vendor: MenuItem
  modelNumber: string
  date: Date | null
  amount: string
}

type ExactlyOne<T, K extends keyof T = keyof T> = K extends keyof T
  ? { [P in K]: T[K] } & Partial<Record<Exclude<keyof T, K>, never>>
  : never

interface CardQueryParams {
  address_id?: string
  contract_id?: string
  dispatch_id?: string
}
export type SingleKeyCardQueryParams = ExactlyOne<CardQueryParams>

export interface IStripeFormData {
  paymentType: string
  dispatchID: string
  contractID: string
  orderID?: string
  firstName: string
  lastName: string
  billingState: string
  vendor: string
  sku?: string
  date: Date | null
  cart?: appliancepb.ICart
  replacementID?: string
  amount: string
  customerID?: string
  tenantAbbreviation?: MenuItem
}
export interface IPaymentFormData {
  email: string
  emailWc: string
  isPartial: boolean
  dispatchID: string
  id: string
  status: any
  contractID: string
  paymentID: string
  companyCode: string
  vendor: MenuItem
  modelNumber: string
  priceMin: string
  priceMax: string
  orderDateMin: Date | null
  orderDateMax: Date | null
  orderID: string
  itemsPerPage?: string
  page?: string
}

export interface IQueueFormData {
  dispatchID: string
  status: IMultiSelectItem[]
  vendor: MenuItem
  adressID: string
  partRequestID: string
  replacementID: string
  itemsPerPage?: string
  page?: string
}

export interface IQueueFormParams {
  dispatch_id: string
  status: string | string[]
  vendor: string
  adress_id: string
  part_request_id: string
  replacement_id: string
  items_per_page?: string
  page?: string
}
export interface IPaymentFormParams {
  email: string
  emailWc: string
  dispatch_id: string
  id: string
  contract_id: string
  payment_id: string
  company_code: string
  vendor: string
  model_number: string
  price_min: string
  price_max: string
  order_date_min: Date | null
  order_date_max: Date | null
  order_id: string
  items_per_page?: string
  page?: string
}

export type TPartialFormParams =
  | Partial<IQueueFormParams>
  | Partial<IPaymentFormParams>

export type TPartialFormsData =
  | Partial<IQueueFormData>
  | Partial<IPaymentFormData>

export type TAdminContextProps = {
  banner?: appliancepb.IBanner
  saveBanner: (banner: appliancepb.IBanner) => void
  clearBanner: () => void
  bannerCustomMessage: string
  setBannerCustomMessage: Dispatch<string>
  isSaveBunnerDisabled: boolean
  isEditBannerModalOpen: boolean
  setIsEditBannerModalOpen: Dispatch<boolean>
  isDeleteBannerConfirmation: boolean
  setIsDeleteBannerConfirmation: Dispatch<boolean>
  isLoading: boolean
  setIsLoading: Dispatch<boolean>
  adminFunction: MenuItem
  setAdminFunction: Dispatch<MenuItem>
  selectedVendor: MenuItem
  setSelectedVendor: Dispatch<MenuItem>
  vendors: MenuItem[]
  vendorsAreLoading: boolean
  selectedAction: MenuItem
  setSelectedAction: Dispatch<MenuItem>
  sku: string
  setSku: Dispatch<string>
  zip: string
  setZip: Dispatch<string>
  price: string
  setPrice: Dispatch<string>
  clearContext: () => void
  selectedCacheDB: MenuItem
  setSelectedCacheDB: Dispatch<MenuItem>
  selectedCacheKey: MenuItem
  setSelectedCacheKey: Dispatch<MenuItem>
  file: any
  setFile: Dispatch<any>
  error: string | null
  setError: Dispatch<string | null>
  cacheMessage: ApiMessage
  setCacheMessage: Dispatch<ApiMessage>
  refreshMessage: ApiMessage
  setRefreshMessage: Dispatch<ApiMessage>
  addFileMessage: ApiMessage
  setAddFileMessage: Dispatch<ApiMessage>
  isfileDownloaded: boolean
  setIsFileDownloaded: Dispatch<boolean>
}

export enum EPurchaseStatus {
  REPLACEMENT_LOCK_NOT_FOUND_ERROR = 'REPLACEMENT_LOCK_NOT_FOUND_ERROR',
  REPLACEMENT_NOT_CONFIRMED_ERROR = 'REPLACEMENT_NOT_CONFIRMED_ERROR',
  GET_REPLACEMENT_FOR_PURCHASE_ERROR = 'GET_REPLACEMENT_FOR_PURCHASE_ERROR',
  NON_EXISTENT_ID_ERROR = 'NON_EXISTENT_ID_ERROR',
  INVALID_ID_ERROR = 'INVALID_ID_ERROR',
  PRODUCT_NOT_AVAILABLE = 'PRODUCT_NOT_AVAILABLE',
  MAXIMUM_PAYMENT_ATTEMPTS_ERROR = 'MAXIMUM_PAYMENT_ATTEMPTS_ERROR',
}

export interface IAnyObject {
  [key: string]: any
}

export interface ITabsProps {
  // Active tab is a number

  // Values :
  // 1 -  Queue Serach Tab
  // 2 -  Payment Search Tab

  activeTab: number
  setActiveTab: Dispatch<number>

  paymentTableData: appliancepb.IOutrightPaymentRecord[] | null
  setPaymentTableData: Dispatch<appliancepb.IOutrightPaymentRecord[] | null>

  paymentTableDataError: string
  setPaymentTableDataError: Dispatch<string>

  paymentTableDataIsLoading: boolean
  setPaymentTableDataIsLoading: Dispatch<boolean>

  paymentCurrentPagination: appliancepb.IPagination | null | undefined
  setPaymentCurrentPagination: Dispatch<
    appliancepb.IPagination | null | undefined
  >

  paymentPrevParamsString: string | null
  setPaymentPrevParamsString: Dispatch<string>

  paymentParams: TPartialFormParams
  setPaymentParams: Dispatch<TPartialFormParams>

  getPaymentsData: (
    obj: TPartialFormParams,
    updateHistory: boolean
  ) => Promise<void>

  isPaymentFormMounted: boolean
  setIsPaymentFormMounted: Dispatch<boolean>

  queueTableData: appliancepb.IFullReplacementDetails[] | null
  setQueueTableData: Dispatch<appliancepb.IFullReplacementDetails[] | null>

  queueTableDataError: string
  setQueueTableDataError: Dispatch<string>

  queueTableDataIsLoading: boolean
  setQueueTableDataIsLoading: Dispatch<boolean>

  queueCurrentPagination: appliancepb.IPagination | null | undefined
  setQueueCurrentPagination: Dispatch<
    appliancepb.IPagination | null | undefined
  >

  queuePrevParamsString: string | null
  setQueuePrevParamsString: Dispatch<string>

  queueParams: IAnyObject
  setQueueParams: Dispatch<IAnyObject>

  getQueueData: (
    obj: TPartialFormParams,
    updateHistory: boolean
    //  updatePageNumber?: boolean
  ) => Promise<void>

  shouldQueueDataReload: boolean
  setShouldQueueDataReload: Dispatch<boolean>

  isQueueFormMounted: boolean
  setIsQueueFormMounted: Dispatch<boolean>
}
export type TKitSize = '27' | '30' | ''
export type TIcemakerAddon = '' | '1' | '2'
export interface IUpdatedPagination {
  itemsPerPage?: number | null | undefined
  page?: number | null | undefined
}

export interface IAddDispatchFormData {
  message: string | null
}

type ReactText = string | number
type ReactChild = ReactElement | ReactText

interface ReactNodeArray extends Array<TReactNode> {}
type ReactFragment = {} | ReactNodeArray

export type TReactNode =
  | ReactChild
  | ReactFragment
  | ReactPortal
  | boolean
  | null
  | undefined

export interface ComponentWithChildren {
  children?: TReactNode
}
