import { appliancepb } from '../services/protobuf-models/appliance-ms-protobuf-models'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { EPurchaseStatus, TIcemakerAddon, TKitSize } from './shared-types'
import { AgentSurvey } from '../screens/agent-survey/agent-survey-types'

const vowelRegex = '^[aieouAIEOU].*'

export const keyToInternationalize = (
  value: string | null | undefined
): string => {
  return value ? value.toUpperCase().replace(/[^A-Z0-9.]+/g, '_') : ''
}

export const aOrAn = (value: string): string => {
  return value.match(vowelRegex) ? 'an' : 'a'
}

export const capitalize = (value: string | null | undefined): string => {
  return value
    ? value.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
    : ''
}

export const stripeMinPaymentCents = 100

export const sendCustomerOfferNotification: appliancepb.Notification = {
  customerShopLink: true,
  customerPaymentConfirmation: false,
  customerOrderConfirmation: true,
  customerInstallerConfirmation: true,
  toJSON: function () {
    return {
      customerShopLink: this.customerShopLink,
      customerPaymentConfirmation: this.customerPaymentConfirmation,
      customerOrderConfirmation: this.customerOrderConfirmation,
      customerInstallerConfirmation: this.customerInstallerConfirmation,
    }
  },
}

export const orderForCustomerNotification: appliancepb.Notification = {
  customerShopLink: false,
  customerPaymentConfirmation: true,
  customerOrderConfirmation: true,
  customerInstallerConfirmation: true,
  toJSON: function () {
    return {
      customerShopLink: this.customerShopLink,
      customerPaymentConfirmation: this.customerPaymentConfirmation,
      customerOrderConfirmation: this.customerOrderConfirmation,
      customerInstallerConfirmation: this.customerInstallerConfirmation,
    }
  },
}

export const lowesECard: string = 'BH-LOWES'

export const setPurchaseError = (
  status: EPurchaseStatus | string | undefined
): string => {
  switch (status) {
    case EPurchaseStatus.REPLACEMENT_LOCK_NOT_FOUND_ERROR:
      return textTemplatesEnglish.ERROR_CUSTOMER_REPLACEMENT_LOCK_NOT_FOUND_ERROR
    case EPurchaseStatus.REPLACEMENT_NOT_CONFIRMED_ERROR:
      return textTemplatesEnglish.ERROR_CUSTOMER_GET_REPLACEMENT_FOR_PURCHASE_ERROR
    case EPurchaseStatus.GET_REPLACEMENT_FOR_PURCHASE_ERROR:
      return textTemplatesEnglish.ERROR_CUSTOMER_GET_REPLACEMENT_FOR_PURCHASE_ERROR
    case EPurchaseStatus.NON_EXISTENT_ID_ERROR:
      return textTemplatesEnglish.ERROR_CUSTOMER_NON_EXISTENT_ID_ERROR
    case EPurchaseStatus.INVALID_ID_ERROR:
      return textTemplatesEnglish.ERROR_CUSTOMER_INVALID_ID_ERROR
    case EPurchaseStatus.PRODUCT_NOT_AVAILABLE:
      return textTemplatesEnglish.ERROR_CUSTOMER_PRODUCT_NOT_AVAILABLE
    case EPurchaseStatus.MAXIMUM_PAYMENT_ATTEMPTS_ERROR:
      return textTemplatesEnglish.MAXIMUM_PAYMENT_ATTEMPTS_ERROR
    default:
      return textTemplatesEnglish.ERROR_CUSTOMER
  }
}

export const getErrorData = (responseError: ArrayBuffer): string => {
  const decoder = new TextDecoder()
  return decoder.decode(responseError).split(/:/)[0]
}

export const getServiceLimit = (survey: AgentSurvey.IBasicInfoSurveyStep) => {
  const { dispatch } = survey
  const id = survey.item?.id
  const item = dispatch?.serviceItems?.find((item) => item.itemID === id)

  return item ? item.serviceLimit : null
}

export const generateProductAccesories = (
  trimKitSize: TKitSize,
  icemakerAddon: TIcemakerAddon
) => {
  if (trimKitSize) {
    return {
      productAccessories: [
        {
          productCategory:
            appliancepb.ProductAccessory.ProductCategory
              .PRODUCT_CATEGORY_MICROWAVE_ACCESSORIES,
          productSubcategory:
            appliancepb.ProductAccessory.ProductSubcategory
              .PRODUCT_SUBCATEGORY_TRIM_KIT,
          dimensions: new appliancepb.Dimensions({
            unit: 'in',
            width: trimKitSize,
          }),
        },
      ],
    }
  } else if (!!icemakerAddon) {
    return {
      productAccessories: [
        {
          productCategory:
            appliancepb.ProductAccessory.ProductCategory
              .PRODUCT_CATEGORY_REFRIGERATOR_ACCESSORIES,
          productSubcategory:
            appliancepb.ProductAccessory.ProductSubcategory
              .PRODUCT_SUBCATEGORY_ICEMAKER,
        },
      ],
    }
  } else {
    return {}
  }
}

export const createShortWarrantyMessage = (
  description: string,
  lastWord: string
): string => {
  const word = lastWord
  const regex = new RegExp(word)
  const searchIndex = description.search(regex)
  const lastIndex = searchIndex > 0 ? searchIndex + word.length : 0

  return `${description.slice(0, lastIndex)}.`
}

export const capitalizeText = (inputText: string) =>
  inputText.toLowerCase().replace(/\b\w/g, function (match) {
    return match.toUpperCase()
  })

export const todayAtMidnight = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

export const setColumnStyle = (
  wrapper: HTMLDivElement | null,
  style: string
) => {
  if (!wrapper) return
  wrapper.querySelectorAll('td')?.forEach((el) => {
    el.style.cssText = el.style.cssText ? `${el.style.cssText} ${style}` : style
  })
}
