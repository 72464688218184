import React, { useEffect, useState } from 'react'
import {
  PaymentMethodMicroFrontend,
  PaymentsMethodMfeExposedApi,
} from '@ftdr/payment-method-micro-frontend'
import { ProgressIndicator } from '@ftdr/blueprint-components-react'
import { GetGuestToken } from '@ftdr/crypto-js'
import '@ftdr/payment-method-micro-frontend/dist/assets/css/payment_method.css'
import { api } from 'src/utils/api'

interface PaymentMFEProps {
  MFEUrl?: string
}
const zipCodeValidation = (zipcode: string) =>
  /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipcode)

export const PaymentMFE = React.forwardRef<
  PaymentsMethodMfeExposedApi,
  PaymentMFEProps
>(({ MFEUrl = api.config.PAYMENT_MFE_URL }, ref) => {
  const [token, setToken] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getToken = async () => {
      GetGuestToken({
        handler: (guestToken) => {
          const newToken = guestToken as string
          setToken(newToken)
        },
      })
    }
    getToken()
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return <ProgressIndicator data-testid="spinner" />
  }

  return (
    <>
      {token && (
        <PaymentMethodMicroFrontend
          availablePaymentMethods={{
            CREDIT_CARD: true,
            BANK_ACCOUNT: false,
            PAYPAL: false,
          }}
          baseURL={MFEUrl}
          token={token}
          applicationName="Appliance Portal"
          ref={ref}
          defaultOpenPaymentMethod="CREDIT_CARD"
          stripeSetup={{
            cardNumber: {
              row: 1,
              label: 'Card Number',
              className:
                'w-full sm:col-start-1 sm:col-span-4 col-start-1 col-end-7 sm:pr-4',
            },
            expirationDate: {
              row: 1,
              label: 'Expiration Date',
              className:
                'w-full sm:col-span-2 sm:col-end-7 col-start-1 col-span-7',
            },
            postal_code: {
              row: 1,
              label: 'Postal code',
              className: 'w-full sm:col-span-2  col-start-1 col-end-4 pr-4',
              required: true,
              validate: zipCodeValidation,
              validationMessage: 'Postal code is invalid',
            },
          }}
        />
      )}
    </>
  )
})
