import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultStatus, defaultValues } from './payment-default-values'
import {
  DateTimeInput,
  Input,
  RadioGroup,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import { paymentSchema } from './payment-validation'
import { ButtonComponent as Button, TextComponent as Text } from 'src/components/custom-fdr-components'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import { AppState, IPaymentFormData } from 'src/utils/shared-types'
import { arrOfPaymentParamsKeys, formDataToParams, generateFormData, getPagination } from '../../helpers'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { useLocation } from 'react-router'
import qs from 'query-string'
import './reset.css'
import { today } from 'src/utils/internationalization-helper'
import { regexAllowPriceDecimal } from 'src/utils/validation-utils'
import { useSelector } from 'react-redux'

const PaymentForm = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const location = useLocation()

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm<IPaymentFormData>({
    mode: 'onTouched',
    resolver: yupResolver(paymentSchema),
    defaultValues,
  })
  const {
    setPaymentCurrentPagination,
    getPaymentsData,
    paymentPrevParamsString,
    paymentCurrentPagination,
    setIsPaymentFormMounted,
    setPaymentTableData,
  } = useTabsTableContext()

  const statuses = useSelector((state: AppState) => state.statuses.statuses)
  const statusError = useSelector((state: AppState) => state.statuses.error)
  const statusOptions = useMemo(
    () =>
      statuses
        ?.filter((elem) => elem.id === 'ORDERED' || elem.id === 'PURCHASED')
        .map((elem) => ({
          id: elem.id,
          value: elem.value,
        })),
    [statuses],
  )

  const submitForm = async (
    data: IPaymentFormData,
    updatedHistory: boolean = true,
  ) => {
    const { isPartial, email, emailWc, ...rest } = data
    const updatedData =
      isPartial || emailWc ? { emailWc: email, ...rest } : { email, ...rest }
    const params = formDataToParams(updatedData)

    getPaymentsData(params, updatedHistory)
  }

  const { isVendorsLoading, getVendors } = useGetVendors()

  const [priceMin, orderDateMin, orderDateMax] = watch([
    'priceMin',
    'orderDateMin',
    'orderDateMax',
  ])

  const triggerPriceMax = async () =>
    await trigger('priceMax', { shouldFocus: false })

  useEffect(() => {
    if (statuses?.length && statuses.length > 0) {
      const {
        data: updatedFormData,
        allParamsObject,
        pagination,
      } = generateFormData(
        location.hash,
        defaultValues,
        arrOfPaymentParamsKeys,
        statuses,
      )

      //Previous params string for comparing with current one
      const prevParamsString = qs.stringify(
        qs.parse(paymentPrevParamsString?.replace(/^#tab\d*/, '') || ''),
      )

      //Current params string for comparing with previous one
      const currentParamsString = qs.stringify(allParamsObject)

      for (let key in updatedFormData) {
        setValue(key as keyof IPaymentFormData, updatedFormData[key], {
          shouldValidate: true,
          shouldTouch: true,
        })
      }
      if (!currentParamsString && paymentPrevParamsString !== null) {
        setPaymentTableData([])
        setValue('status', defaultStatus, {
          shouldValidate: true,
          shouldTouch: true,
        })
      } else if (
        prevParamsString !== currentParamsString &&
        (paymentPrevParamsString !== null || location.hash !== '#tab2')
      ) {
        handleSubmit((data) => {
          const updatedData = {
            ...data,
            itemsPerPage: '9',
            page: '1',
            ...pagination,
          }
          submitForm(updatedData, false)
        })()
      } else if (paymentPrevParamsString == null) {
        setValue('status', defaultStatus, {
          shouldValidate: true,
          shouldTouch: true,
        })
      }
    }
    //eslint-disable-next-line
  }, [location.hash, statuses])

  useEffect(() => {
    setIsPaymentFormMounted(true)
    return () => {
      setIsPaymentFormMounted(false)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    triggerPriceMax()
    //eslint-disable-next-line
  }, [priceMin])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const itemsPerPage = paymentCurrentPagination?.itemsPerPage
        const pagination = getPagination({
          items_per_page: `${itemsPerPage}`,
          page: '1',
        })

        submitForm({
          ...data,
          ...pagination,
        })
      })}
      className='mt-8 mb-8'
    >
      <div
        id='queue-form-payment-wrapper'
        className='-mt-6 lg:-mt-3 md:p-8 md:border'
      >
        <div className='lg:grid grid-cols-2 gap-2 items-start w-full 3xl:w-1/2 mt-8 mb-4'>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-email'
                error={errors.email?.message}
                value={field.value}
                formField
                label={localizedText('Email')}
                className='w-full'
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name='isPartial'
            control={control}
            render={({ field }) => (
              <div className='pt-1 md:pt-7 md:pb-1 mt-auto'>
                <RadioGroup
                  orientation='horizontal'
                  formField
                  radios={[
                    { value: 'full', label: 'Full' },
                    { value: 'partial', label: 'Partial' },
                  ]}
                  label=''
                  value={field.value ? 'partial' : 'full'}
                  onChange={() => {
                    field.onChange(!field.value)
                    trigger('email', { shouldFocus: true })
                  }}
                />
              </div>
            )}
          />
        </div>
        <div className=' grid lg:grid-cols-2 gap-4 mb-4'>
          <Controller
            name='dispatchID'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-di'
                error={errors.dispatchID?.message}
                value={field.value}
                formField
                label={localizedText('DISPATCH_ID_LABEL')}
                className='w-full'
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name='vendor'
            control={control}
            render={({ field }) => (
              <Select
                id='queue-form-payment-vendor'
                autoComplete={true}
                error={errors.vendor?.message}
                selected={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_VENDOR_LABEL')}
                options={getVendors(['BH'], true)}
                onSelect={field.onChange as SelectHandler<SelectOption>}
                onBlur={field.onBlur}
                placeholder='Select an option'
                disabled={isVendorsLoading}
              />
            )}
          />
          <Controller
            name='id'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-id'
                error={errors.id?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_ID_LABEL')}
                className='w-full'
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name='contractID'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-cid'
                error={errors.contractID?.message}
                value={field.value}
                formField
                label={localizedText('CONTRACT_ID_LABEL')}
                className='w-full'
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name='paymentID'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-pi'
                error={errors.paymentID?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_PAYMENT_ID_LABEL')}
                className='w-full'
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name='companyCode'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-cc'
                error={errors.companyCode?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_COMPANY_CODE_LABEL')}
                className='w-full'
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase().trim())
                }}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name='modelNumber'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-mn'
                error={errors.modelNumber?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_MODEL_NUMBER_LABEL')}
                className='w-full'
                onChange={(e) =>
                  field.onChange(e.target.value.toUpperCase().trim())
                }
                onBlur={field.onBlur}
              />
            )}
          />

          <div className='grid lg:grid-cols-2 gap-4'>
            <Controller
              name='orderDateMin'
              control={control}
              render={({ field }) => {
                return (
                  <DateTimeInput
                    id='queue-form-payment-date-in'
                    maxDate={orderDateMax || today}
                    error={errors.orderDateMin?.message}
                    label={localizedText(
                      'PAYMENT_SEARCH_FORM_ORDER_DATE_MIN_LABEL',
                    )}
                    formFieldClassName='w-full'
                    className='date-custom-input'
                    selectedDate={field.value}
                    onDateSelect={field.onChange}
                    onBlur={field.onBlur}
                    showMonths={1}
                    onlyDate
                    datePicker
                    showOutsideMonthDates={true}
                    allowSelectOutsideMonthDates
                  />
                )
              }}
            />
            <Controller
              name='orderDateMax'
              control={control}
              render={({ field }) => {
                return (
                  <DateTimeInput
                    id='queue-form-payment-date-out'
                    maxDate={today}
                    minDate={orderDateMin || undefined}
                    error={errors.orderDateMax?.message}
                    label={localizedText(
                      'PAYMENT_SEARCH_FORM_ORDER_DATE_MAX_LABEL',
                    )}
                    formFieldClassName='w-full'
                    className='date-custom-input'
                    selectedDate={field.value}
                    onDateSelect={field.onChange}
                    onBlur={field.onBlur}
                    showMonths={1}
                    onlyDate
                    datePicker
                    showOutsideMonthDates={true}
                    allowSelectOutsideMonthDates
                  />
                )
              }}
            />
          </div>
          <Controller
            name='orderID'
            control={control}
            render={({ field }) => (
              <Input
                id='queue-form-payment-oi'
                error={errors.orderID?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_ORDER_NUMBER_LABEL')}
                className='w-full'
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <div className='grid lg:grid-cols-2 gap-4'>
            <Controller
              name='priceMin'
              control={control}
              render={({ field }) => (
                <Input
                  id='queue-form-payment-min-amount'
                  error={errors.priceMin?.message}
                  value={field.value}
                  inputMode='decimal'
                  startEnhancer={() => <Text color='primary'>$</Text>}
                  formField
                  label={localizedText('PAYMENT_SEARCH_FORM_AMOUNT_MIN_LABEL')}
                  className='w-full'
                  onChange={(v) => {
                    if (regexAllowPriceDecimal.test(v.target.value)) {
                      field.onChange(v)
                    }
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />

            <Controller
              name='priceMax'
              control={control}
              render={({ field }) => (
                <Input
                  id='queue-form-payment-max-amount'
                  error={errors.priceMax?.message}
                  value={'' + field.value}
                  inputMode='decimal'
                  startEnhancer={() => <Text>$</Text>}
                  formField
                  label={localizedText('PAYMENT_SEARCH_FORM_AMOUNT_MAX_LABEL')}
                  className='w-full'
                  onChange={(v) => {
                    if (regexAllowPriceDecimal.test(v.target.value)) {
                      field.onChange(v)
                    }
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                id='queue-form-payment-status'
                error={
                  errors.status?.message || !statusError ? '' : statusError
                }
                selected={field.value}
                formField
                multiSelect
                options={statusOptions || []}
                label={localizedText('QUEUE_STATUS_LABEL')}
                className='w-full'
                onSelect={field.onChange as SelectHandler<SelectOption>}
                onBlur={field.onBlur}
                disabled={!statuses?.length || statuses.length === 0}
              />
            )}
          />
        </div>
        <Button
          id='queue-form-payment-search'
          type='submit'
          label='Search'
          disabled={!isValid}
          onClick={() => {
            setPaymentCurrentPagination({
              itemsPerPage: paymentCurrentPagination?.itemsPerPage || 9,
              page: 1,
            })
          }}
        />
      </div>
    </form>
  )
}

export default PaymentForm
