import React from 'react'
import { Notification } from '@ftdr/blueprint-components-react'

interface INotificationMessage {
  status: 'warning' | 'error' | 'success' | 'info'
  message: string
}
const NotificationMessage: React.FC<INotificationMessage> = ({
  status,
  message,
}) => {
  return (
    <Notification
      className="mb-4 w-full"
      variant="inline"
      status={status}
      size="medium"
      showStatusLabel={false}
      inline={true}
    >
      <p>{message as string}</p>
    </Notification>
  )
}

export default NotificationMessage
