import React, { createContext, useContext, useState, Dispatch } from 'react'


type Props = {
selectedSlide:number
setSelectedSlide: Dispatch<number>
}
type TSelectedSlideContext= {
  children?: React.ReactNode
}
const SelectedSlideContext = createContext<Props>({} as Props)

const SelectedSlideProvider: React.FC<TSelectedSlideContext> = ({ children }) => {
  const [selectedSlide, setSelectedSlide] = useState<number>(0)


  return (
    <SelectedSlideContext.Provider value={{ selectedSlide, setSelectedSlide}}>
      {children}
    </SelectedSlideContext.Provider>
  )
}

const useSelectedSlideContext = () => useContext(SelectedSlideContext)

export { SelectedSlideProvider, useSelectedSlideContext }