import React, { useMemo } from 'react'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { useQueueColumns } from './use-queue-columns'
import { SelectOption } from '@ftdr/blueprint-components-react'
import { Table, Pagination } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import {
  formatTimestampToDate,
  formatCentsCurrency,
} from 'src/utils/internationalization-helper'
import { v4 } from 'uuid'
import qs from 'query-string'
import { useHistory } from 'react-router'
import { IUpdatedPagination } from 'src/utils/shared-types'

const QueueTable: React.FC = () => {
  const history = useHistory()
  const {
    queueTableDataIsLoading,
    queueCurrentPagination,
    setQueueCurrentPagination,
    queueTableData,
    queueTableDataError,
    queueParams,
    activeTab,
  } = useTabsTableContext()

  const { columns } = useQueueColumns()

  const onItemsPerPageChange = ({ value }: SelectOption) => {
    const updatedPagination: {
      itemsPerPage?: number | null | undefined
      page?: number | null | undefined
    } = {
      // ...queueCurrentPagination,
      page: 1,
      itemsPerPage: Number(value),
    }

    setQueueCurrentPagination(updatedPagination)

    const stringParams = qs.stringify({
      ...queueParams,
      page: updatedPagination.page,
      items_per_page: updatedPagination.itemsPerPage,
    })

    history.push(`/queue#tab${activeTab}?${stringParams}`)
  }

  // Trigers on click on pagination number
  const onPageChange = (page: number) => {
    const updatedPagination: IUpdatedPagination = {
      itemsPerPage: queueCurrentPagination?.itemsPerPage,
      page,
    }

    if (queueCurrentPagination?.page === page) {
      return
    }

    setQueueCurrentPagination(updatedPagination)

    const stringParams = qs.stringify({
      ...queueParams,
      page: updatedPagination.page,
      items_per_page: updatedPagination.itemsPerPage,
    })

    history.push(
      `/queue#tab${activeTab}${stringParams ? '?' + stringParams : ''}`
    )
  }

  const data = useMemo(() => {
    return queueTableData?.map((queueItem) => {
      const {
        dispatchID,
        partRequestID,
        vendorOrderNumber,
        createdAt,
        lastUpdated,
        customer,
        status,
        product,
        purchase,
        ID,
        notes,
        shopLink,
        cashInLieu,
        likeForLikePrice,
      } = queueItem
      return [
        {
          key: v4(),
          action: queueItem,
          dispatchID,
          partRequestID,
          vendorOrderNumber,
          createdAt: formatTimestampToDate(createdAt),
          lastUpdated: formatTimestampToDate(lastUpdated),
          customerFirstName: customer?.firstName,
          customerLastName: customer?.lastName,
          status,
          model: product?.ID?.slice(3),
          basePrice: formatCentsCurrency(queueItem.purchase?.basePrice),
          vendor: product?.ID?.slice(0, 2),
          paid: purchase?.total ? formatCentsCurrency(purchase.total) : '',
          ID: { ID, shopLink, status, item: queueItem },
          notes: { notes, ID },
          LFL: { likeForLikePrice, ID, cashInLieu },
        },
      ]
    })
  }, [queueTableData])

  return (
    <>
      <div id="queue-table-wrapper">
        {queueTableDataError && !queueTableDataIsLoading && (
          <Text
            id="queue-table-error"
            color={queueTableDataError !== 'No results' ? `error` : 'primary'}
          >
            {queueTableDataError}
          </Text>
        )}
        {queueTableDataIsLoading && (
          <div className="flex justify-center" id="queue-table-spinner-wrapper">
            <ProgressIndicator size="medium" data-testid="spinner" />
          </div>
        )}

        {!queueTableDataIsLoading && !!queueTableData?.length && (
          <Table
            id="queue-table-table"
            data={data?.flat()}
            columns={columns}
            variant="heavy"
            striped="gray"
            paginate={false}
          />
        )}
      </div>
      {/* Pagination */}
      {!!queueTableData?.length && !queueTableDataIsLoading ? (
        <div
          id="queue-table-wrapper"
          className="-mx-4 sm:-mx-10 md:-mx-24 lg:-mx-48 lg:px-16 mt-8 justify-end lg:-mb-16 min-w-full"
          style={{
            background: '#FBFBFB',
            borderTop: '1px solid #EEEEEE',
            borderBottom: '1px solid #EEEEEE',
          }}
        >
          <Pagination
            id="queue-table-pagination"
            itemsPerPage={queueCurrentPagination?.itemsPerPage || 9}
            hideViewAll
            totalItems={queueCurrentPagination?.totalItems || 0}
            page={queueCurrentPagination?.page || 0}
            onItemsPerPageChange={onItemsPerPageChange}
            onPageChange={onPageChange}
            itemsPerPageOptions={[
              { value: '9' },
              { value: '30' },
              { value: '60' },
            ]}
          />
        </div>
      ) : null}
    </>
  )
}

export default QueueTable
