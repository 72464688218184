import React from 'react'
import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'

interface IWarning {
  isOpen: boolean
  setIsOpen: React.Dispatch<boolean>
  submitForm: () => void
}

const Warning: React.FC<IWarning> = ({ isOpen, setIsOpen, submitForm }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  return (
    <Dialog
      id="outright-payment-warning-dialog"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      modal={false}
      closeOnOutsideClick={true}
      showBackdrop={true}
      className="my-dialog overflow-auto"
    >
      <div>
        <Text
          className="mb-6"
          variant="heading-03"
          textTemplateKey="OUTRIGHT_PAYMENT_DIALOG_TITLE"
        />
        <Text textTemplateKey="OUTRIGHT_PAYMENT_DIALOG_TEXT1" />
        <Text
          className="mb-6"
          textTemplateKey="OUTRIGHT_PAYMENT_DIALOG_TEXT2"
        />
        <Button
          id="outright-payment-warning-cancel"
          color="gray"
          variant="outlined"
          label={localizedText('BUTTON_CANCEL')}
          onClick={() => setIsOpen(false)}
        />
        <Button
          id="outright-payment-warning-continue"
          label={localizedText('BUTTON_CONTINUE')}
          className="ml-3"
          onClick={() => {
            submitForm()
            setIsOpen(false)
          }}
        />
      </div>
    </Dialog>
  )
}

export default Warning
