export const refrigeratorIcemakerList = [{
    "GE-GBE21DYKFS":  "GE-IM4D",
	"GE-GFE28GELDS":  "GE-IM5D", // 2nd Icemaker
	"GE-GFE28GMKES":  "GE-IM5D", // 2nd Icemaker
	"GE-GFE28GYNFS":  "GE-IM5D", // 2nd Icemaker
	"GE-GLE12HSPSS":  "GE-IM4D",
	"GE-GPE12FGKBB":  "GE-IM4D",
	"GE-GPE12FGKWW":  "GE-IM4D",
	"GE-GPE12FSKSB":  "GE-IM4D",
	"GE-GPE17CTNRWW": "GE-IM4D",
	"GE-GTE16DTNLWW": "GE-IM4D",
	"GE-GTE16DTNRCC": "GE-IM4D",
	"GE-GTE16DTNRWW": "GE-IM4D",
	"GE-GTE17DTNRBB": "GE-IM4D",
	"GE-GTE17DTNRCC": "GE-IM4D",
	"GE-GTE17DTNRWW": "GE-IM4D",
	"GE-GTE17GSNRSS": "GE-IM4D",
	"GE-GTE17GTNRBB": "GE-IM4D",
	"GE-GTE17GTNRWW": "GE-IM4D",
	"GE-GTE18DCNRSA": "GE-IM4D",
	"GE-GTE18DTNRBB": "GE-IM4D",
	"GE-GTE18DTNRWW": "GE-IM4D",
	"GE-GTE18GMNRES": "GE-IM4D",
	"GE-GTE18GSNRSS": "GE-IM4D",
	"GE-GTE18GTNRBB": "GE-IM4D",
	"GE-GTE18GTNRCC": "GE-IM4D",
	"GE-GTE18GTNRWW": "GE-IM4D",
	"GE-GTE19DTNRBB": "GE-IM4D",
	"GE-GTE19DTNRWW": "GE-IM4D",
	"GE-GTE19JSNRSS": "GE-IM4D",
	"GE-GTE19JTNRBB": "GE-IM4D",
	"GE-GTE19JTNRWW": "GE-IM4D",
	"GE-GTE21GSHSS":  "GE-IM4D",
	"GE-GTE21GTHBB":  "GE-IM4D",
	"GE-GTE21GTHWW":  "GE-IM4D",
	"GE-GTE22JSNRSS": "GE-IM4D",
	"GE-GTE22JTNRBB": "GE-IM4D",
	"GE-GTE22JTNRWW": "GE-IM4D",
	"GE-GTS16DTNRWW": "GE-IM4D",
	"GE-GTS17DTNRBB": "GE-IM4D",
	"GE-GTS17DTNRWW": "GE-IM4D",
	"GE-GTS17GSNRSS": "GE-IM4D",
	"GE-GTS17GTNRWW": "GE-IM4D",
	"GE-GTS18DTNRBB": "GE-IM4D",
	"GE-GTS18DTNRWW": "GE-IM4D",
	"GE-GTS18GSNRSS": "GE-IM4D",
	"GE-GTS18GTNRBB": "GE-IM4D",
	"GE-GTS18GTNRWW": "GE-IM4D",
	"GE-GTS18HGNRBB": "GE-IM4D",
	"GE-GTS18HGNRWW": "GE-IM4D",
	"GE-GTS18HMNRES": "GE-IM4D",
	"GE-GTS18HYNRFS": "GE-IM4D",
	"GE-GTS19KGNRBB": "GE-IM4D",
	"GE-GTS19KGNRWW": "GE-IM4D",
	"GE-GTS19KMNRES": "GE-IM4D",
	"GE-GTS19KYNRFS": "GE-IM4D",
	"GE-GTS22KGNRBB": "GE-IM4D",
	"GE-GTS22KGNRWW": "GE-IM4D",
	"GE-GTS22KMNRDS": "GE-IM4D",
	"GE-GTS22KMNRES": "GE-IM4D",
	"GE-GTS22KYNRFS": "GE-IM4D",
	"GE-GYE22GENDS":  "GE-IM5D", // 2nd Icemaker
	"GE-GYE22GMNES":  "GE-IM5D", // 2nd Icemaker
	"GE-GYE22GYNFS":  "GE-IM5D", // 2nd Icemaker
	"GE-HPE16BTNLWW": "GE-IM4D",
	"GE-HPE16BTNRWW": "GE-IM4D",
	"GE-HPS16BTNLWW": "GE-IM4D",
	"GE-HPS16BTNRBB": "GE-IM4D",
	"GE-HPS16BTNRWW": "GE-IM4D",
	"GE-HPS18BTNRBB": "GE-IM4D",
	"GE-HPS18BTNRWW": "GE-IM4D",
	"GE-PFD28KYNFS":  "GE-IM5D", // 2nd Icemaker
	"GE-PFE28KBLTS":  "GE-IM5D", // 2nd Icemaker
	"GE-PFE28KMKES":  "GE-IM5D", // 2nd Icemaker
	"GE-PFE28KYNFS":  "GE-IM5D", // 2nd Icemaker
	"GE-PYD22KYNFS":  "GE-IM5D", // 2nd Icemaker
	"GE-PYE22KBLTS":  "GE-IM5D", // 2nd Icemaker
	"GE-PYE22KELDS":  "GE-IM5D", // 2nd Icemaker
	"GE-PYE22KMKES":  "GE-IM5D", // 2nd Icemaker
	"GE-PYE22KYNFS":  "GE-IM5D", // 2nd Icemaker
	"GE-QHE16HYPFS":  "GE-IM4D",
	"GE-QJS15HYRFS":  "GE-IM4D",
	"WP-ART104TFDB":  "WP-W11510803",
	"WP-ART104TFDW":  "WP-W11510803",
	"WP-ART308FFDB":  "WP-W11510803",
	"WP-ART308FFDM":  "WP-W11510803",
	"WP-ART308FFDW":  "WP-W11510803",
	"WP-ART318FFDB":  "WP-W11510803",
	"WP-ART318FFDS":  "WP-W11510803",
	"WP-ART318FFDW":  "WP-W11510803",
	"WP-MRT118FFFE":  "WP-W11510803",
	"WP-MRT118FFFH":  "WP-W11510803",
	"WP-MRT118FFFZ":  "WP-W11510803",
	"WP-MRT311FFFE":  "WP-W11510803",
	"WP-MRT311FFFH":  "WP-W11510803",
	"WP-MRT311FFFZ":  "WP-W11510803",
	"WP-MSS25N4MKZ":  "WP-W11510803",
	"WP-WRB533CZJZ":  "WP-W11416493",
	"WP-WRS315SNHM":  "WP-W11510803",
	"WP-WRT104TFDB":  "WP-W11510803",
	"WP-WRT104TFDT":  "WP-W11510803",
	"WP-WRT104TFDW":  "WP-W11510803",
	"WP-WRT106TFDB":  "WP-W11510803",
	"WP-WRT106TFDT":  "WP-W11510803",
	"WP-WRT106TFDW":  "WP-W11510803",
	"WP-WRT108FFDB":  "WP-W11510803",
	"WP-WRT108FFDM":  "WP-W11510803",
	"WP-WRT108FFDW":  "WP-W11510803",
	"WP-WRT112CZJB":  "WP-W11424126",
	"WP-WRT112CZJW":  "WP-W11424126",
	"WP-WRT112CZJZ":  "WP-W11424126",
	"WP-WRT134TFDB":  "WP-W11510803",
	"WP-WRT134TFDM":  "WP-W11510803",
	"WP-WRT134TFDW":  "WP-W11510803",
	"WP-WRT138FFDB":  "WP-W11510803",
	"WP-WRT138FFDM":  "WP-W11510803",
	"WP-WRT138FFDW":  "WP-W11510803",
	"WP-WRT148FZDB":  "WP-W11517113",
	"WP-WRT148FZDM":  "WP-W11517113",
	"WP-WRT148FZDW":  "WP-W11517113",
	"WP-WRT311FZDB":  "WP-W11517113",
	"WP-WRT311FZDM":  "WP-W11517113",
	"WP-WRT311FZDT":  "WP-W11517113",
	"WP-WRT311FZDW":  "WP-W11517113",
	"WP-WRT311FZDZ":  "WP-W11517113",
	"WP-WRT312CZJB":  "WP-W11424126", // or W11416493
	"WP-WRT312CZJV":  "WP-W11424126", // or W11416493
	"WP-WRT312CZJW":  "WP-W11424126", // or W11416493
	"WP-WRT312CZJZ":  "WP-W11424126", // or W11416493
	"WP-WRT313CZLB":  "WP-W11424126",
	"WP-WRT313CZLW":  "WP-W11424126",
	"WP-WRT313CZLZ":  "WP-W11424126",
	"WP-WRT314TFDB":  "WP-W11510803",
	"WP-WRT314TFDW":  "WP-W11510803",
	"WP-WRT316SFDM":  "WP-W11510803",
	"WP-WRT318FZDB":  "WP-W11517113",
	"WP-WRT318FZDM":  "WP-W11517113",
	"WP-WRT318FZDW":  "WP-W11517113",
	"WP-WRT511SZDB":  "WP-W11517113",
	"WP-WRT511SZDM":  "WP-W11517113",
	"WP-WRT511SZDW":  "WP-W11517113",
	"WP-WRT518SZFM":  "WP-W11517113",
	"WP-WRT518SZKV":  "WP-W11517113",
	"WP-WRT519SZDB":  "WP-W11517113",
	"WP-WRT519SZDG":  "WP-W11517113",
	"WP-WRT519SZDM":  "WP-W11517113",
	"WP-WRT519SZDT":  "WP-W11517113",
	"WP-WRT519SZDW":  "WP-W11517113",
	"WP-WRT541SZDB":  "WP-W11517113",
	"WP-WRT541SZDW":  "WP-W11517113",
	"WP-WRT541SZDZ":  "WP-W11517113",
	"WP-WRT541SZHV":  "WP-W11517113",
	"WP-WRT549SZDB":  "WP-W11517113",
	"WP-WRT549SZDM":  "WP-W11517113",
	"WP-WRT549SZDW":  "WP-W11517113",
}]