import React from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { useMedia } from '../../../hooks/use-media'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
interface IPropertiesTitle {
  title: string
  scrollLeft: number
}
const PropertiesTitle: React.FC<IPropertiesTitle> = ({ title, scrollLeft }) => {
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )

  const isDesktop = useMedia('(min-width:1024px)')
  const isMobile = useMedia('(max-width:767px)')
  return (
    <div className={`md:flex mb-4 md:justify-center`}>
      <div
        className="px-6"
        style={{
          width: isMobile
            ? compareProducts.length > 2
              ? '150%'
              : '100%'
            : compareProducts.length > 2
            ? 900
            : 600,
        }}
      >
        <div
          className="mb-2 py-4 w-full border-b-1 border-b-800"
          style={{ paddingLeft: scrollLeft }}
        >
          <Text
            variant={`${isDesktop ? 'heading-04' : 'heading-05'}`}
            className="break-all"
          >
            {title.toLowerCase()}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default PropertiesTitle
