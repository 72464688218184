import React, { Dispatch, createContext, useContext, useState } from 'react'

type TStatus = 'payment error' | 'loading' | 'success' | null

type Props = {
  status: TStatus
  setStatus: (status: TStatus) => void
  errorMsg: string
  setErrorMsg: (errorMsg: string) => void
  savedReplacementId: string | null
  setSavedReplacementId: Dispatch<string | null>
  clearSavedReplacementId: () => void
  savedTenant: string | null
  setSavedTenant: Dispatch<string | null>
  clearSavedTenant: () => void
}
type TPaymentContext = {
  children?: React.ReactNode
}
const PaymentContext = createContext<Props>({} as Props)

const PaymentProvider: React.FC<TPaymentContext> = ({ children }) => {
  const [status, setStatus] = useState<TStatus>(null)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [savedReplacementId, setSavedReplacementId] = useState<string | null>(
    null
  )
  const [savedTenant, setSavedTenant] = useState<string | null>(null)
  const clearSavedReplacementId = () => setSavedReplacementId(null)
  const clearSavedTenant = () => setSavedTenant(null)

  return (
    <PaymentContext.Provider
      value={{
        status,
        setStatus,
        errorMsg,
        setErrorMsg,
        savedReplacementId,
        setSavedReplacementId,
        clearSavedReplacementId,
        savedTenant,
        setSavedTenant,
        clearSavedTenant,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

const usePaymentContext = () => useContext(PaymentContext)

export { PaymentProvider, usePaymentContext }
