import React from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'

interface IDescriptionForm {
  payment: string
}

const DescriptionForm: React.FC<IDescriptionForm> = ({ payment }) => {
  switch (payment) {
    case 'Retail Payment':
      return (
        <>
          <Text
            id='outright-payment-description-type'
            className='mb-6 w-full'
            variant='heading-03'
          >
            {payment}
          </Text>

          <Text
            id='outright-payment-description-text'
            className='mb-6'
            variant='body-long'
            textTemplateKey='OUTRIGHT_PAYMENT_DESCRIPTION_OUTRIGHT_PAYMENT'
          />
        </>
      )
    case 'Restocking Fee':
      return (
        <>
          <Text
            id='outright-payment-description-type'
            className='mb-6 w-full'
            variant='heading-03'
          >
            {payment}
          </Text>
          <Text
            id='outright-payment-description-text'
            className='mb-6'
            variant='body-long'
            textTemplateKey='OUTRIGHT_PAYMENT_DESCRIPTION_RESTOCKING_FEE'
          />
        </>
      )
    case 'Accessories':
      return (
        <>
          <Text
            id='outright-payment-description-type'
            className='mb-6 w-full'
            variant='heading-03'
          >
            {payment}
          </Text>
          <Text
            id='outright-payment-description-text'
            className='mb-6'
            variant='body-long'
            textTemplateKey='OUTRIGHT_PAYMENT_DESCRIPTION_ACCESSORIES'
          />
        </>
      )
    default:
      return (
        <Text className='mb-6 w-full' variant='heading-03'>
          Payments
        </Text>
      )
  }
}

export default DescriptionForm
