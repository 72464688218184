import {
  IconButton,
  IconNavArrowDown,
  IconNavArrowUp,
  Input,
} from '@ftdr/blueprint-components-react'
import React, { useState } from 'react'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'

interface Props {
  sku: string
  quantity: number
}
export const QuantityCounterInput: React.FC<Props> = ({ sku, quantity }) => {
  const [q, setQ] = useState<number>(quantity)
  const { setShouldConfirm } = useShoppingCartContext()

  //   useEffect(() => {
  //     //q !== quantity && updateCurrentItemQuantity(sku, q)
  //     q !== quantity && setShouldConfirm({ sku, q })
  //     //eslint-disable-next-line
  //   }, [q])

  return (
    <div className="relative flex items-center">
      <div style={{ width: 56 }}>
        <Input
          textAlign="center"
          className="w-full"
          readOnly
          id={sku}
          value={quantity}
          formField
          formFieldClassName="w-full"
          label={''}
          onChange={(e) => {
            setQ(+e.currentTarget.value)
          }}
        />
      </div>
      <div
        id="counter"
        className="overflow-hidden mt-2"
        style={{
          width: '24px',
          height: '48px',
          marginLeft: '2px',
        }}
      >
        <IconButton
          color="interactive"
          shape="rounded"
          className="border border-gray-300 counter-icon"
          variant="outlined"
          size="small"
          label={''}
          icon={<IconNavArrowUp size="14" />}
          onClick={() => setShouldConfirm({ sku, q: q + 1 })}
          disabled={/^[A-Z]{3}-CIL$/.test(sku)}
        />
        <IconButton
          color="interactive"
          className="border border-gray-300 counter-icon"
          shape="rounded"
          variant="outlined"
          size="small"
          label={''}
          disabled={q === 0 || /^[A-Z]{3}-CIL$/.test(sku)}
          icon={<IconNavArrowDown size="14" />}
          onClick={() => setShouldConfirm({ sku, q: q - 1 })}
        />
      </div>
    </div>
  )
}
