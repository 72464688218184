import React, { useLayoutEffect, useEffect, useRef, useState } from 'react'
import ImageRow from './image-row/image-row'
import ButtonRow from './button-row/button-row'
import PropetiesRow from './properties-row/properties-row'
import TitleRow from './title-row/title-row'
import PropertiesTitle from './properties-title/properties-title'
import DimensionRow from './dimension-row/dimension-row'
import { useSelector, useDispatch } from 'react-redux'
import { createCompareAttributes } from 'src/store/compare-store'
import { AppState } from 'src/utils/shared-types'
import { useHistory, useLocation } from 'react-router'
import { addReplacement } from 'src/store/compare-store'
import { useParams } from 'react-router'
import { initialOrder, setRowsWithOrder } from './helpers'

interface BrowseParams {
  replacementId: string
}

const CompareProduct: React.FC = () => {
  const [scrollLeft, setScrollLeft] = useState<number>(0)
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { replacementId } = useParams<BrowseParams>()
  const ref = useRef<HTMLDivElement>(null)
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )

  const { attributeGroups } = useSelector(
    (state: AppState) => state.compareProducts
  )

  useLayoutEffect(() => {
    dispatch(createCompareAttributes())
    if (compareProducts.length < 2) {
      replacementId
        ? history.push(`/${replacementId}/shop`)
        : history.push('/browse')
    }
    if (compareProducts.length === 2) {
      ref.current?.scrollTo(0, 0)
    }
    //eslint-disable-next-line
  }, [compareProducts])

  useEffect(() => {
    dispatch(addReplacement(replacementId || ''))
    const element = ref.current
    const onScroll = () => setScrollLeft(element?.scrollLeft || 0)
    element?.addEventListener('scroll', onScroll)
    return () => element?.removeEventListener('scroll', onScroll)

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [pathname])

  return (
    <section
      ref={ref}
      className={`flex -mx-4 md:-mx-16 lg:-mx-40 ${
        compareProducts.length > 2 ? 'overflow-auto' : 'overflow-hidden'
      } lg:overflow-visible`}
    >
      <div className="min-w-full">
        <div
          className="lg:sticky z-10 bg-picket-white pb-4 md:pt-4 top-9"
          style={{
            top: '3rem',
          }}
        >
          <TitleRow />
          <ButtonRow />
        </div>
        <div className="relative z-0">
          <ImageRow />
          {setRowsWithOrder(initialOrder, Object.keys(attributeGroups)).map(
            (key: string) => {
              return (
                <div key={key}>
                  {attributeGroups[key] && attributeGroups[key].length > 0 && (
                    <PropertiesTitle title={key} scrollLeft={scrollLeft} />
                  )}
                  {key === 'WEIGHTS & DIMENSIONS' && <DimensionRow />}
                  {attributeGroups[key] &&
                    attributeGroups[key].map((name) => {
                      return (
                        <PropetiesRow
                          key={name}
                          keyName={key}
                          keyValue={name}
                        />
                      )
                    })}
                </div>
              )
            }
          )}
        </div>
      </div>
    </section>
  )
}

export default CompareProduct
