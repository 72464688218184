/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal'

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

export const itempb = ($root.itempb = (() => {
  /**
   * Namespace itempb.
   * @exports itempb
   * @namespace
   */
  const itempb = {}

  itempb.Trade = (function () {
    /**
     * Properties of a Trade.
     * @memberof itempb
     * @interface ITrade
     * @property {string|null} [TradeName] Trade TradeName
     * @property {string|null} [TradeShortKey] Trade TradeShortKey
     * @property {string|null} [TradeID] Trade TradeID
     */

    /**
     * Constructs a new Trade.
     * @memberof itempb
     * @classdesc Represents a Trade.
     * @implements ITrade
     * @constructor
     * @param {itempb.ITrade=} [properties] Properties to set
     */
    function Trade(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Trade TradeName.
     * @member {string} TradeName
     * @memberof itempb.Trade
     * @instance
     */
    Trade.prototype.TradeName = ''

    /**
     * Trade TradeShortKey.
     * @member {string} TradeShortKey
     * @memberof itempb.Trade
     * @instance
     */
    Trade.prototype.TradeShortKey = ''

    /**
     * Trade TradeID.
     * @member {string} TradeID
     * @memberof itempb.Trade
     * @instance
     */
    Trade.prototype.TradeID = ''

    /**
     * Creates a new Trade instance using the specified properties.
     * @function create
     * @memberof itempb.Trade
     * @static
     * @param {itempb.ITrade=} [properties] Properties to set
     * @returns {itempb.Trade} Trade instance
     */
    Trade.create = function create(properties) {
      return new Trade(properties)
    }

    /**
     * Encodes the specified Trade message. Does not implicitly {@link itempb.Trade.verify|verify} messages.
     * @function encode
     * @memberof itempb.Trade
     * @static
     * @param {itempb.ITrade} message Trade message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Trade.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.TradeName != null &&
        Object.hasOwnProperty.call(message, 'TradeName')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.TradeName)
      if (
        message.TradeShortKey != null &&
        Object.hasOwnProperty.call(message, 'TradeShortKey')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.TradeShortKey)
      if (
        message.TradeID != null &&
        Object.hasOwnProperty.call(message, 'TradeID')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.TradeID)
      return writer
    }

    /**
     * Encodes the specified Trade message, length delimited. Does not implicitly {@link itempb.Trade.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.Trade
     * @static
     * @param {itempb.ITrade} message Trade message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Trade.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Trade message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.Trade
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.Trade} Trade
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Trade.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.Trade()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.TradeName = reader.string()
            break
          case 2:
            message.TradeShortKey = reader.string()
            break
          case 3:
            message.TradeID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Trade message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.Trade
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.Trade} Trade
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Trade.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Trade message.
     * @function verify
     * @memberof itempb.Trade
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Trade.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.TradeName != null && message.hasOwnProperty('TradeName'))
        if (!$util.isString(message.TradeName))
          return 'TradeName: string expected'
      if (
        message.TradeShortKey != null &&
        message.hasOwnProperty('TradeShortKey')
      )
        if (!$util.isString(message.TradeShortKey))
          return 'TradeShortKey: string expected'
      if (message.TradeID != null && message.hasOwnProperty('TradeID'))
        if (!$util.isString(message.TradeID)) return 'TradeID: string expected'
      return null
    }

    /**
     * Creates a Trade message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.Trade
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.Trade} Trade
     */
    Trade.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.Trade) return object
      let message = new $root.itempb.Trade()
      if (object.TradeName != null) message.TradeName = String(object.TradeName)
      if (object.TradeShortKey != null)
        message.TradeShortKey = String(object.TradeShortKey)
      if (object.TradeID != null) message.TradeID = String(object.TradeID)
      return message
    }

    /**
     * Creates a plain object from a Trade message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.Trade
     * @static
     * @param {itempb.Trade} message Trade
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Trade.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.TradeName = ''
        object.TradeShortKey = ''
        object.TradeID = ''
      }
      if (message.TradeName != null && message.hasOwnProperty('TradeName'))
        object.TradeName = message.TradeName
      if (
        message.TradeShortKey != null &&
        message.hasOwnProperty('TradeShortKey')
      )
        object.TradeShortKey = message.TradeShortKey
      if (message.TradeID != null && message.hasOwnProperty('TradeID'))
        object.TradeID = message.TradeID
      return object
    }

    /**
     * Converts this Trade to JSON.
     * @function toJSON
     * @memberof itempb.Trade
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Trade.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Trade
  })()

  itempb.Brand = (function () {
    /**
     * Properties of a Brand.
     * @memberof itempb
     * @interface IBrand
     * @property {string|null} [Name] Brand Name
     * @property {string|null} [ID] Brand ID
     * @property {string|null} [Category] Brand Category
     */

    /**
     * Constructs a new Brand.
     * @memberof itempb
     * @classdesc Represents a Brand.
     * @implements IBrand
     * @constructor
     * @param {itempb.IBrand=} [properties] Properties to set
     */
    function Brand(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Brand Name.
     * @member {string} Name
     * @memberof itempb.Brand
     * @instance
     */
    Brand.prototype.Name = ''

    /**
     * Brand ID.
     * @member {string} ID
     * @memberof itempb.Brand
     * @instance
     */
    Brand.prototype.ID = ''

    /**
     * Brand Category.
     * @member {string} Category
     * @memberof itempb.Brand
     * @instance
     */
    Brand.prototype.Category = ''

    /**
     * Creates a new Brand instance using the specified properties.
     * @function create
     * @memberof itempb.Brand
     * @static
     * @param {itempb.IBrand=} [properties] Properties to set
     * @returns {itempb.Brand} Brand instance
     */
    Brand.create = function create(properties) {
      return new Brand(properties)
    }

    /**
     * Encodes the specified Brand message. Does not implicitly {@link itempb.Brand.verify|verify} messages.
     * @function encode
     * @memberof itempb.Brand
     * @static
     * @param {itempb.IBrand} message Brand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Brand.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.Name != null && Object.hasOwnProperty.call(message, 'Name'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.Name)
      if (message.ID != null && Object.hasOwnProperty.call(message, 'ID'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.ID)
      if (
        message.Category != null &&
        Object.hasOwnProperty.call(message, 'Category')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.Category)
      return writer
    }

    /**
     * Encodes the specified Brand message, length delimited. Does not implicitly {@link itempb.Brand.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.Brand
     * @static
     * @param {itempb.IBrand} message Brand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Brand.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Brand message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.Brand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.Brand} Brand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Brand.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.Brand()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.Name = reader.string()
            break
          case 2:
            message.ID = reader.string()
            break
          case 3:
            message.Category = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Brand message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.Brand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.Brand} Brand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Brand.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Brand message.
     * @function verify
     * @memberof itempb.Brand
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Brand.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.Name != null && message.hasOwnProperty('Name'))
        if (!$util.isString(message.Name)) return 'Name: string expected'
      if (message.ID != null && message.hasOwnProperty('ID'))
        if (!$util.isString(message.ID)) return 'ID: string expected'
      if (message.Category != null && message.hasOwnProperty('Category'))
        if (!$util.isString(message.Category))
          return 'Category: string expected'
      return null
    }

    /**
     * Creates a Brand message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.Brand
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.Brand} Brand
     */
    Brand.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.Brand) return object
      let message = new $root.itempb.Brand()
      if (object.Name != null) message.Name = String(object.Name)
      if (object.ID != null) message.ID = String(object.ID)
      if (object.Category != null) message.Category = String(object.Category)
      return message
    }

    /**
     * Creates a plain object from a Brand message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.Brand
     * @static
     * @param {itempb.Brand} message Brand
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Brand.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.Name = ''
        object.ID = ''
        object.Category = ''
      }
      if (message.Name != null && message.hasOwnProperty('Name'))
        object.Name = message.Name
      if (message.ID != null && message.hasOwnProperty('ID'))
        object.ID = message.ID
      if (message.Category != null && message.hasOwnProperty('Category'))
        object.Category = message.Category
      return object
    }

    /**
     * Converts this Brand to JSON.
     * @function toJSON
     * @memberof itempb.Brand
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Brand.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Brand
  })()

  itempb.getStyleRequest = (function () {
    /**
     * Properties of a getStyleRequest.
     * @memberof itempb
     * @interface IgetStyleRequest
     * @property {string|null} [styleID] getStyleRequest styleID
     */

    /**
     * Constructs a new getStyleRequest.
     * @memberof itempb
     * @classdesc Represents a getStyleRequest.
     * @implements IgetStyleRequest
     * @constructor
     * @param {itempb.IgetStyleRequest=} [properties] Properties to set
     */
    function getStyleRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * getStyleRequest styleID.
     * @member {string} styleID
     * @memberof itempb.getStyleRequest
     * @instance
     */
    getStyleRequest.prototype.styleID = ''

    /**
     * Creates a new getStyleRequest instance using the specified properties.
     * @function create
     * @memberof itempb.getStyleRequest
     * @static
     * @param {itempb.IgetStyleRequest=} [properties] Properties to set
     * @returns {itempb.getStyleRequest} getStyleRequest instance
     */
    getStyleRequest.create = function create(properties) {
      return new getStyleRequest(properties)
    }

    /**
     * Encodes the specified getStyleRequest message. Does not implicitly {@link itempb.getStyleRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.getStyleRequest
     * @static
     * @param {itempb.IgetStyleRequest} message getStyleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getStyleRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.styleID != null &&
        Object.hasOwnProperty.call(message, 'styleID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.styleID)
      return writer
    }

    /**
     * Encodes the specified getStyleRequest message, length delimited. Does not implicitly {@link itempb.getStyleRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.getStyleRequest
     * @static
     * @param {itempb.IgetStyleRequest} message getStyleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getStyleRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a getStyleRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.getStyleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.getStyleRequest} getStyleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getStyleRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.getStyleRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.styleID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a getStyleRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.getStyleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.getStyleRequest} getStyleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getStyleRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a getStyleRequest message.
     * @function verify
     * @memberof itempb.getStyleRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    getStyleRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.styleID != null && message.hasOwnProperty('styleID'))
        if (!$util.isString(message.styleID)) return 'styleID: string expected'
      return null
    }

    /**
     * Creates a getStyleRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.getStyleRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.getStyleRequest} getStyleRequest
     */
    getStyleRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.getStyleRequest) return object
      let message = new $root.itempb.getStyleRequest()
      if (object.styleID != null) message.styleID = String(object.styleID)
      return message
    }

    /**
     * Creates a plain object from a getStyleRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.getStyleRequest
     * @static
     * @param {itempb.getStyleRequest} message getStyleRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    getStyleRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.styleID = ''
      if (message.styleID != null && message.hasOwnProperty('styleID'))
        object.styleID = message.styleID
      return object
    }

    /**
     * Converts this getStyleRequest to JSON.
     * @function toJSON
     * @memberof itempb.getStyleRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    getStyleRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return getStyleRequest
  })()

  itempb.getStyleResponse = (function () {
    /**
     * Properties of a getStyleResponse.
     * @memberof itempb
     * @interface IgetStyleResponse
     * @property {string|null} [id] getStyleResponse id
     * @property {string|null} [name] getStyleResponse name
     */

    /**
     * Constructs a new getStyleResponse.
     * @memberof itempb
     * @classdesc Represents a getStyleResponse.
     * @implements IgetStyleResponse
     * @constructor
     * @param {itempb.IgetStyleResponse=} [properties] Properties to set
     */
    function getStyleResponse(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * getStyleResponse id.
     * @member {string} id
     * @memberof itempb.getStyleResponse
     * @instance
     */
    getStyleResponse.prototype.id = ''

    /**
     * getStyleResponse name.
     * @member {string} name
     * @memberof itempb.getStyleResponse
     * @instance
     */
    getStyleResponse.prototype.name = ''

    /**
     * Creates a new getStyleResponse instance using the specified properties.
     * @function create
     * @memberof itempb.getStyleResponse
     * @static
     * @param {itempb.IgetStyleResponse=} [properties] Properties to set
     * @returns {itempb.getStyleResponse} getStyleResponse instance
     */
    getStyleResponse.create = function create(properties) {
      return new getStyleResponse(properties)
    }

    /**
     * Encodes the specified getStyleResponse message. Does not implicitly {@link itempb.getStyleResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.getStyleResponse
     * @static
     * @param {itempb.IgetStyleResponse} message getStyleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getStyleResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
      return writer
    }

    /**
     * Encodes the specified getStyleResponse message, length delimited. Does not implicitly {@link itempb.getStyleResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.getStyleResponse
     * @static
     * @param {itempb.IgetStyleResponse} message getStyleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getStyleResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a getStyleResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.getStyleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.getStyleResponse} getStyleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getStyleResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.getStyleResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.name = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a getStyleResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.getStyleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.getStyleResponse} getStyleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getStyleResponse.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a getStyleResponse message.
     * @function verify
     * @memberof itempb.getStyleResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    getStyleResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.name != null && message.hasOwnProperty('name'))
        if (!$util.isString(message.name)) return 'name: string expected'
      return null
    }

    /**
     * Creates a getStyleResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.getStyleResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.getStyleResponse} getStyleResponse
     */
    getStyleResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.getStyleResponse) return object
      let message = new $root.itempb.getStyleResponse()
      if (object.id != null) message.id = String(object.id)
      if (object.name != null) message.name = String(object.name)
      return message
    }

    /**
     * Creates a plain object from a getStyleResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.getStyleResponse
     * @static
     * @param {itempb.getStyleResponse} message getStyleResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    getStyleResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.id = ''
        object.name = ''
      }
      if (message.id != null && message.hasOwnProperty('id'))
        object.id = message.id
      if (message.name != null && message.hasOwnProperty('name'))
        object.name = message.name
      return object
    }

    /**
     * Converts this getStyleResponse to JSON.
     * @function toJSON
     * @memberof itempb.getStyleResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    getStyleResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return getStyleResponse
  })()

  itempb.getTradeRequest = (function () {
    /**
     * Properties of a getTradeRequest.
     * @memberof itempb
     * @interface IgetTradeRequest
     * @property {string|null} [TradeID] getTradeRequest TradeID
     */

    /**
     * Constructs a new getTradeRequest.
     * @memberof itempb
     * @classdesc Represents a getTradeRequest.
     * @implements IgetTradeRequest
     * @constructor
     * @param {itempb.IgetTradeRequest=} [properties] Properties to set
     */
    function getTradeRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * getTradeRequest TradeID.
     * @member {string} TradeID
     * @memberof itempb.getTradeRequest
     * @instance
     */
    getTradeRequest.prototype.TradeID = ''

    /**
     * Creates a new getTradeRequest instance using the specified properties.
     * @function create
     * @memberof itempb.getTradeRequest
     * @static
     * @param {itempb.IgetTradeRequest=} [properties] Properties to set
     * @returns {itempb.getTradeRequest} getTradeRequest instance
     */
    getTradeRequest.create = function create(properties) {
      return new getTradeRequest(properties)
    }

    /**
     * Encodes the specified getTradeRequest message. Does not implicitly {@link itempb.getTradeRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.getTradeRequest
     * @static
     * @param {itempb.IgetTradeRequest} message getTradeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getTradeRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.TradeID != null &&
        Object.hasOwnProperty.call(message, 'TradeID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.TradeID)
      return writer
    }

    /**
     * Encodes the specified getTradeRequest message, length delimited. Does not implicitly {@link itempb.getTradeRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.getTradeRequest
     * @static
     * @param {itempb.IgetTradeRequest} message getTradeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getTradeRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a getTradeRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.getTradeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.getTradeRequest} getTradeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getTradeRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.getTradeRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.TradeID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a getTradeRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.getTradeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.getTradeRequest} getTradeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getTradeRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a getTradeRequest message.
     * @function verify
     * @memberof itempb.getTradeRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    getTradeRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.TradeID != null && message.hasOwnProperty('TradeID'))
        if (!$util.isString(message.TradeID)) return 'TradeID: string expected'
      return null
    }

    /**
     * Creates a getTradeRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.getTradeRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.getTradeRequest} getTradeRequest
     */
    getTradeRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.getTradeRequest) return object
      let message = new $root.itempb.getTradeRequest()
      if (object.TradeID != null) message.TradeID = String(object.TradeID)
      return message
    }

    /**
     * Creates a plain object from a getTradeRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.getTradeRequest
     * @static
     * @param {itempb.getTradeRequest} message getTradeRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    getTradeRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.TradeID = ''
      if (message.TradeID != null && message.hasOwnProperty('TradeID'))
        object.TradeID = message.TradeID
      return object
    }

    /**
     * Converts this getTradeRequest to JSON.
     * @function toJSON
     * @memberof itempb.getTradeRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    getTradeRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return getTradeRequest
  })()

  itempb.getTradeResponse = (function () {
    /**
     * Properties of a getTradeResponse.
     * @memberof itempb
     * @interface IgetTradeResponse
     * @property {Array.<itempb.ITrade>|null} [trades] getTradeResponse trades
     */

    /**
     * Constructs a new getTradeResponse.
     * @memberof itempb
     * @classdesc Represents a getTradeResponse.
     * @implements IgetTradeResponse
     * @constructor
     * @param {itempb.IgetTradeResponse=} [properties] Properties to set
     */
    function getTradeResponse(properties) {
      this.trades = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * getTradeResponse trades.
     * @member {Array.<itempb.ITrade>} trades
     * @memberof itempb.getTradeResponse
     * @instance
     */
    getTradeResponse.prototype.trades = $util.emptyArray

    /**
     * Creates a new getTradeResponse instance using the specified properties.
     * @function create
     * @memberof itempb.getTradeResponse
     * @static
     * @param {itempb.IgetTradeResponse=} [properties] Properties to set
     * @returns {itempb.getTradeResponse} getTradeResponse instance
     */
    getTradeResponse.create = function create(properties) {
      return new getTradeResponse(properties)
    }

    /**
     * Encodes the specified getTradeResponse message. Does not implicitly {@link itempb.getTradeResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.getTradeResponse
     * @static
     * @param {itempb.IgetTradeResponse} message getTradeResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getTradeResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.trades != null && message.trades.length)
        for (let i = 0; i < message.trades.length; ++i)
          $root.itempb.Trade.encode(
            message.trades[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified getTradeResponse message, length delimited. Does not implicitly {@link itempb.getTradeResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.getTradeResponse
     * @static
     * @param {itempb.IgetTradeResponse} message getTradeResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    getTradeResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a getTradeResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.getTradeResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.getTradeResponse} getTradeResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getTradeResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.getTradeResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.trades && message.trades.length)) message.trades = []
            message.trades.push(
              $root.itempb.Trade.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a getTradeResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.getTradeResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.getTradeResponse} getTradeResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    getTradeResponse.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a getTradeResponse message.
     * @function verify
     * @memberof itempb.getTradeResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    getTradeResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.trades != null && message.hasOwnProperty('trades')) {
        if (!Array.isArray(message.trades)) return 'trades: array expected'
        for (let i = 0; i < message.trades.length; ++i) {
          let error = $root.itempb.Trade.verify(message.trades[i])
          if (error) return 'trades.' + error
        }
      }
      return null
    }

    /**
     * Creates a getTradeResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.getTradeResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.getTradeResponse} getTradeResponse
     */
    getTradeResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.getTradeResponse) return object
      let message = new $root.itempb.getTradeResponse()
      if (object.trades) {
        if (!Array.isArray(object.trades))
          throw TypeError('.itempb.getTradeResponse.trades: array expected')
        message.trades = []
        for (let i = 0; i < object.trades.length; ++i) {
          if (typeof object.trades[i] !== 'object')
            throw TypeError('.itempb.getTradeResponse.trades: object expected')
          message.trades[i] = $root.itempb.Trade.fromObject(object.trades[i])
        }
      }
      return message
    }

    /**
     * Creates a plain object from a getTradeResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.getTradeResponse
     * @static
     * @param {itempb.getTradeResponse} message getTradeResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    getTradeResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.trades = []
      if (message.trades && message.trades.length) {
        object.trades = []
        for (let j = 0; j < message.trades.length; ++j)
          object.trades[j] = $root.itempb.Trade.toObject(
            message.trades[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this getTradeResponse to JSON.
     * @function toJSON
     * @memberof itempb.getTradeResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    getTradeResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return getTradeResponse
  })()

  itempb.ItemSymptomRequest = (function () {
    /**
     * Properties of an ItemSymptomRequest.
     * @memberof itempb
     * @interface IItemSymptomRequest
     * @property {string|null} [symptomID] ItemSymptomRequest symptomID
     */

    /**
     * Constructs a new ItemSymptomRequest.
     * @memberof itempb
     * @classdesc Represents an ItemSymptomRequest.
     * @implements IItemSymptomRequest
     * @constructor
     * @param {itempb.IItemSymptomRequest=} [properties] Properties to set
     */
    function ItemSymptomRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemSymptomRequest symptomID.
     * @member {string} symptomID
     * @memberof itempb.ItemSymptomRequest
     * @instance
     */
    ItemSymptomRequest.prototype.symptomID = ''

    /**
     * Creates a new ItemSymptomRequest instance using the specified properties.
     * @function create
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {itempb.IItemSymptomRequest=} [properties] Properties to set
     * @returns {itempb.ItemSymptomRequest} ItemSymptomRequest instance
     */
    ItemSymptomRequest.create = function create(properties) {
      return new ItemSymptomRequest(properties)
    }

    /**
     * Encodes the specified ItemSymptomRequest message. Does not implicitly {@link itempb.ItemSymptomRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {itempb.IItemSymptomRequest} message ItemSymptomRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemSymptomRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.symptomID != null &&
        Object.hasOwnProperty.call(message, 'symptomID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.symptomID)
      return writer
    }

    /**
     * Encodes the specified ItemSymptomRequest message, length delimited. Does not implicitly {@link itempb.ItemSymptomRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {itempb.IItemSymptomRequest} message ItemSymptomRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemSymptomRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemSymptomRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemSymptomRequest} ItemSymptomRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemSymptomRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemSymptomRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.symptomID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemSymptomRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemSymptomRequest} ItemSymptomRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemSymptomRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemSymptomRequest message.
     * @function verify
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemSymptomRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.symptomID != null && message.hasOwnProperty('symptomID'))
        if (!$util.isString(message.symptomID))
          return 'symptomID: string expected'
      return null
    }

    /**
     * Creates an ItemSymptomRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemSymptomRequest} ItemSymptomRequest
     */
    ItemSymptomRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemSymptomRequest) return object
      let message = new $root.itempb.ItemSymptomRequest()
      if (object.symptomID != null) message.symptomID = String(object.symptomID)
      return message
    }

    /**
     * Creates a plain object from an ItemSymptomRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemSymptomRequest
     * @static
     * @param {itempb.ItemSymptomRequest} message ItemSymptomRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemSymptomRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.symptomID = ''
      if (message.symptomID != null && message.hasOwnProperty('symptomID'))
        object.symptomID = message.symptomID
      return object
    }

    /**
     * Converts this ItemSymptomRequest to JSON.
     * @function toJSON
     * @memberof itempb.ItemSymptomRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemSymptomRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemSymptomRequest
  })()

  itempb.ItemSymptomResponse = (function () {
    /**
     * Properties of an ItemSymptomResponse.
     * @memberof itempb
     * @interface IItemSymptomResponse
     * @property {string|null} [id] ItemSymptomResponse id
     * @property {string|null} [name] ItemSymptomResponse name
     * @property {string|null} [itemID] ItemSymptomResponse itemID
     */

    /**
     * Constructs a new ItemSymptomResponse.
     * @memberof itempb
     * @classdesc Represents an ItemSymptomResponse.
     * @implements IItemSymptomResponse
     * @constructor
     * @param {itempb.IItemSymptomResponse=} [properties] Properties to set
     */
    function ItemSymptomResponse(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemSymptomResponse id.
     * @member {string} id
     * @memberof itempb.ItemSymptomResponse
     * @instance
     */
    ItemSymptomResponse.prototype.id = ''

    /**
     * ItemSymptomResponse name.
     * @member {string} name
     * @memberof itempb.ItemSymptomResponse
     * @instance
     */
    ItemSymptomResponse.prototype.name = ''

    /**
     * ItemSymptomResponse itemID.
     * @member {string} itemID
     * @memberof itempb.ItemSymptomResponse
     * @instance
     */
    ItemSymptomResponse.prototype.itemID = ''

    /**
     * Creates a new ItemSymptomResponse instance using the specified properties.
     * @function create
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {itempb.IItemSymptomResponse=} [properties] Properties to set
     * @returns {itempb.ItemSymptomResponse} ItemSymptomResponse instance
     */
    ItemSymptomResponse.create = function create(properties) {
      return new ItemSymptomResponse(properties)
    }

    /**
     * Encodes the specified ItemSymptomResponse message. Does not implicitly {@link itempb.ItemSymptomResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {itempb.IItemSymptomResponse} message ItemSymptomResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemSymptomResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
      if (
        message.itemID != null &&
        Object.hasOwnProperty.call(message, 'itemID')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.itemID)
      return writer
    }

    /**
     * Encodes the specified ItemSymptomResponse message, length delimited. Does not implicitly {@link itempb.ItemSymptomResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {itempb.IItemSymptomResponse} message ItemSymptomResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemSymptomResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemSymptomResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemSymptomResponse} ItemSymptomResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemSymptomResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemSymptomResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.name = reader.string()
            break
          case 3:
            message.itemID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemSymptomResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemSymptomResponse} ItemSymptomResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemSymptomResponse.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemSymptomResponse message.
     * @function verify
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemSymptomResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.name != null && message.hasOwnProperty('name'))
        if (!$util.isString(message.name)) return 'name: string expected'
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        if (!$util.isString(message.itemID)) return 'itemID: string expected'
      return null
    }

    /**
     * Creates an ItemSymptomResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemSymptomResponse} ItemSymptomResponse
     */
    ItemSymptomResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemSymptomResponse) return object
      let message = new $root.itempb.ItemSymptomResponse()
      if (object.id != null) message.id = String(object.id)
      if (object.name != null) message.name = String(object.name)
      if (object.itemID != null) message.itemID = String(object.itemID)
      return message
    }

    /**
     * Creates a plain object from an ItemSymptomResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemSymptomResponse
     * @static
     * @param {itempb.ItemSymptomResponse} message ItemSymptomResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemSymptomResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.id = ''
        object.name = ''
        object.itemID = ''
      }
      if (message.id != null && message.hasOwnProperty('id'))
        object.id = message.id
      if (message.name != null && message.hasOwnProperty('name'))
        object.name = message.name
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        object.itemID = message.itemID
      return object
    }

    /**
     * Converts this ItemSymptomResponse to JSON.
     * @function toJSON
     * @memberof itempb.ItemSymptomResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemSymptomResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemSymptomResponse
  })()

  itempb.Symptom = (function () {
    /**
     * Properties of a Symptom.
     * @memberof itempb
     * @interface ISymptom
     * @property {string|null} [itemTypeID] Symptom itemTypeID
     * @property {string|null} [symptom] Symptom symptom
     * @property {string|null} [symptomID] Symptom symptomID
     */

    /**
     * Constructs a new Symptom.
     * @memberof itempb
     * @classdesc Represents a Symptom.
     * @implements ISymptom
     * @constructor
     * @param {itempb.ISymptom=} [properties] Properties to set
     */
    function Symptom(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Symptom itemTypeID.
     * @member {string} itemTypeID
     * @memberof itempb.Symptom
     * @instance
     */
    Symptom.prototype.itemTypeID = ''

    /**
     * Symptom symptom.
     * @member {string} symptom
     * @memberof itempb.Symptom
     * @instance
     */
    Symptom.prototype.symptom = ''

    /**
     * Symptom symptomID.
     * @member {string} symptomID
     * @memberof itempb.Symptom
     * @instance
     */
    Symptom.prototype.symptomID = ''

    /**
     * Creates a new Symptom instance using the specified properties.
     * @function create
     * @memberof itempb.Symptom
     * @static
     * @param {itempb.ISymptom=} [properties] Properties to set
     * @returns {itempb.Symptom} Symptom instance
     */
    Symptom.create = function create(properties) {
      return new Symptom(properties)
    }

    /**
     * Encodes the specified Symptom message. Does not implicitly {@link itempb.Symptom.verify|verify} messages.
     * @function encode
     * @memberof itempb.Symptom
     * @static
     * @param {itempb.ISymptom} message Symptom message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Symptom.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemTypeID != null &&
        Object.hasOwnProperty.call(message, 'itemTypeID')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.itemTypeID)
      if (
        message.symptom != null &&
        Object.hasOwnProperty.call(message, 'symptom')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.symptom)
      if (
        message.symptomID != null &&
        Object.hasOwnProperty.call(message, 'symptomID')
      )
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.symptomID)
      return writer
    }

    /**
     * Encodes the specified Symptom message, length delimited. Does not implicitly {@link itempb.Symptom.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.Symptom
     * @static
     * @param {itempb.ISymptom} message Symptom message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Symptom.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Symptom message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.Symptom
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.Symptom} Symptom
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Symptom.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.Symptom()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 2:
            message.itemTypeID = reader.string()
            break
          case 3:
            message.symptom = reader.string()
            break
          case 4:
            message.symptomID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Symptom message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.Symptom
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.Symptom} Symptom
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Symptom.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Symptom message.
     * @function verify
     * @memberof itempb.Symptom
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Symptom.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        if (!$util.isString(message.itemTypeID))
          return 'itemTypeID: string expected'
      if (message.symptom != null && message.hasOwnProperty('symptom'))
        if (!$util.isString(message.symptom)) return 'symptom: string expected'
      if (message.symptomID != null && message.hasOwnProperty('symptomID'))
        if (!$util.isString(message.symptomID))
          return 'symptomID: string expected'
      return null
    }

    /**
     * Creates a Symptom message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.Symptom
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.Symptom} Symptom
     */
    Symptom.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.Symptom) return object
      let message = new $root.itempb.Symptom()
      if (object.itemTypeID != null)
        message.itemTypeID = String(object.itemTypeID)
      if (object.symptom != null) message.symptom = String(object.symptom)
      if (object.symptomID != null) message.symptomID = String(object.symptomID)
      return message
    }

    /**
     * Creates a plain object from a Symptom message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.Symptom
     * @static
     * @param {itempb.Symptom} message Symptom
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Symptom.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.itemTypeID = ''
        object.symptom = ''
        object.symptomID = ''
      }
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        object.itemTypeID = message.itemTypeID
      if (message.symptom != null && message.hasOwnProperty('symptom'))
        object.symptom = message.symptom
      if (message.symptomID != null && message.hasOwnProperty('symptomID'))
        object.symptomID = message.symptomID
      return object
    }

    /**
     * Converts this Symptom to JSON.
     * @function toJSON
     * @memberof itempb.Symptom
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Symptom.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Symptom
  })()

  itempb.Style = (function () {
    /**
     * Properties of a Style.
     * @memberof itempb
     * @interface IStyle
     * @property {string|null} [itemType] Style itemType
     * @property {string|null} [itemTypeID] Style itemTypeID
     * @property {string|null} [style] Style style
     * @property {string|null} [styleID] Style styleID
     */

    /**
     * Constructs a new Style.
     * @memberof itempb
     * @classdesc Represents a Style.
     * @implements IStyle
     * @constructor
     * @param {itempb.IStyle=} [properties] Properties to set
     */
    function Style(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Style itemType.
     * @member {string} itemType
     * @memberof itempb.Style
     * @instance
     */
    Style.prototype.itemType = ''

    /**
     * Style itemTypeID.
     * @member {string} itemTypeID
     * @memberof itempb.Style
     * @instance
     */
    Style.prototype.itemTypeID = ''

    /**
     * Style style.
     * @member {string} style
     * @memberof itempb.Style
     * @instance
     */
    Style.prototype.style = ''

    /**
     * Style styleID.
     * @member {string} styleID
     * @memberof itempb.Style
     * @instance
     */
    Style.prototype.styleID = ''

    /**
     * Creates a new Style instance using the specified properties.
     * @function create
     * @memberof itempb.Style
     * @static
     * @param {itempb.IStyle=} [properties] Properties to set
     * @returns {itempb.Style} Style instance
     */
    Style.create = function create(properties) {
      return new Style(properties)
    }

    /**
     * Encodes the specified Style message. Does not implicitly {@link itempb.Style.verify|verify} messages.
     * @function encode
     * @memberof itempb.Style
     * @static
     * @param {itempb.IStyle} message Style message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Style.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemType != null &&
        Object.hasOwnProperty.call(message, 'itemType')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemType)
      if (
        message.itemTypeID != null &&
        Object.hasOwnProperty.call(message, 'itemTypeID')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.itemTypeID)
      if (message.style != null && Object.hasOwnProperty.call(message, 'style'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.style)
      if (
        message.styleID != null &&
        Object.hasOwnProperty.call(message, 'styleID')
      )
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.styleID)
      return writer
    }

    /**
     * Encodes the specified Style message, length delimited. Does not implicitly {@link itempb.Style.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.Style
     * @static
     * @param {itempb.IStyle} message Style message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Style.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Style message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.Style
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.Style} Style
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Style.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.Style()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemType = reader.string()
            break
          case 2:
            message.itemTypeID = reader.string()
            break
          case 3:
            message.style = reader.string()
            break
          case 4:
            message.styleID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Style message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.Style
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.Style} Style
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Style.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Style message.
     * @function verify
     * @memberof itempb.Style
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Style.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemType != null && message.hasOwnProperty('itemType'))
        if (!$util.isString(message.itemType))
          return 'itemType: string expected'
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        if (!$util.isString(message.itemTypeID))
          return 'itemTypeID: string expected'
      if (message.style != null && message.hasOwnProperty('style'))
        if (!$util.isString(message.style)) return 'style: string expected'
      if (message.styleID != null && message.hasOwnProperty('styleID'))
        if (!$util.isString(message.styleID)) return 'styleID: string expected'
      return null
    }

    /**
     * Creates a Style message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.Style
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.Style} Style
     */
    Style.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.Style) return object
      let message = new $root.itempb.Style()
      if (object.itemType != null) message.itemType = String(object.itemType)
      if (object.itemTypeID != null)
        message.itemTypeID = String(object.itemTypeID)
      if (object.style != null) message.style = String(object.style)
      if (object.styleID != null) message.styleID = String(object.styleID)
      return message
    }

    /**
     * Creates a plain object from a Style message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.Style
     * @static
     * @param {itempb.Style} message Style
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Style.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.itemType = ''
        object.itemTypeID = ''
        object.style = ''
        object.styleID = ''
      }
      if (message.itemType != null && message.hasOwnProperty('itemType'))
        object.itemType = message.itemType
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        object.itemTypeID = message.itemTypeID
      if (message.style != null && message.hasOwnProperty('style'))
        object.style = message.style
      if (message.styleID != null && message.hasOwnProperty('styleID'))
        object.styleID = message.styleID
      return object
    }

    /**
     * Converts this Style to JSON.
     * @function toJSON
     * @memberof itempb.Style
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Style.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Style
  })()

  itempb.ItemAttributeListRequest = (function () {
    /**
     * Properties of an ItemAttributeListRequest.
     * @memberof itempb
     * @interface IItemAttributeListRequest
     * @property {string|null} [itemID] ItemAttributeListRequest itemID
     */

    /**
     * Constructs a new ItemAttributeListRequest.
     * @memberof itempb
     * @classdesc Represents an ItemAttributeListRequest.
     * @implements IItemAttributeListRequest
     * @constructor
     * @param {itempb.IItemAttributeListRequest=} [properties] Properties to set
     */
    function ItemAttributeListRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemAttributeListRequest itemID.
     * @member {string} itemID
     * @memberof itempb.ItemAttributeListRequest
     * @instance
     */
    ItemAttributeListRequest.prototype.itemID = ''

    /**
     * Creates a new ItemAttributeListRequest instance using the specified properties.
     * @function create
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {itempb.IItemAttributeListRequest=} [properties] Properties to set
     * @returns {itempb.ItemAttributeListRequest} ItemAttributeListRequest instance
     */
    ItemAttributeListRequest.create = function create(properties) {
      return new ItemAttributeListRequest(properties)
    }

    /**
     * Encodes the specified ItemAttributeListRequest message. Does not implicitly {@link itempb.ItemAttributeListRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {itempb.IItemAttributeListRequest} message ItemAttributeListRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeListRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemID != null &&
        Object.hasOwnProperty.call(message, 'itemID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemID)
      return writer
    }

    /**
     * Encodes the specified ItemAttributeListRequest message, length delimited. Does not implicitly {@link itempb.ItemAttributeListRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {itempb.IItemAttributeListRequest} message ItemAttributeListRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeListRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemAttributeListRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemAttributeListRequest} ItemAttributeListRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeListRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemAttributeListRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemAttributeListRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemAttributeListRequest} ItemAttributeListRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeListRequest.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemAttributeListRequest message.
     * @function verify
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemAttributeListRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        if (!$util.isString(message.itemID)) return 'itemID: string expected'
      return null
    }

    /**
     * Creates an ItemAttributeListRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemAttributeListRequest} ItemAttributeListRequest
     */
    ItemAttributeListRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemAttributeListRequest) return object
      let message = new $root.itempb.ItemAttributeListRequest()
      if (object.itemID != null) message.itemID = String(object.itemID)
      return message
    }

    /**
     * Creates a plain object from an ItemAttributeListRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemAttributeListRequest
     * @static
     * @param {itempb.ItemAttributeListRequest} message ItemAttributeListRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemAttributeListRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.itemID = ''
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        object.itemID = message.itemID
      return object
    }

    /**
     * Converts this ItemAttributeListRequest to JSON.
     * @function toJSON
     * @memberof itempb.ItemAttributeListRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemAttributeListRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemAttributeListRequest
  })()

  itempb.ItemAttributeListResponse = (function () {
    /**
     * Properties of an ItemAttributeListResponse.
     * @memberof itempb
     * @interface IItemAttributeListResponse
     * @property {Array.<itempb.IItemAttribute>|null} [attributes] ItemAttributeListResponse attributes
     */

    /**
     * Constructs a new ItemAttributeListResponse.
     * @memberof itempb
     * @classdesc Represents an ItemAttributeListResponse.
     * @implements IItemAttributeListResponse
     * @constructor
     * @param {itempb.IItemAttributeListResponse=} [properties] Properties to set
     */
    function ItemAttributeListResponse(properties) {
      this.attributes = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemAttributeListResponse attributes.
     * @member {Array.<itempb.IItemAttribute>} attributes
     * @memberof itempb.ItemAttributeListResponse
     * @instance
     */
    ItemAttributeListResponse.prototype.attributes = $util.emptyArray

    /**
     * Creates a new ItemAttributeListResponse instance using the specified properties.
     * @function create
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {itempb.IItemAttributeListResponse=} [properties] Properties to set
     * @returns {itempb.ItemAttributeListResponse} ItemAttributeListResponse instance
     */
    ItemAttributeListResponse.create = function create(properties) {
      return new ItemAttributeListResponse(properties)
    }

    /**
     * Encodes the specified ItemAttributeListResponse message. Does not implicitly {@link itempb.ItemAttributeListResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {itempb.IItemAttributeListResponse} message ItemAttributeListResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeListResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.attributes != null && message.attributes.length)
        for (let i = 0; i < message.attributes.length; ++i)
          $root.itempb.ItemAttribute.encode(
            message.attributes[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified ItemAttributeListResponse message, length delimited. Does not implicitly {@link itempb.ItemAttributeListResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {itempb.IItemAttributeListResponse} message ItemAttributeListResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeListResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemAttributeListResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemAttributeListResponse} ItemAttributeListResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeListResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemAttributeListResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.attributes && message.attributes.length))
              message.attributes = []
            message.attributes.push(
              $root.itempb.ItemAttribute.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemAttributeListResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemAttributeListResponse} ItemAttributeListResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeListResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemAttributeListResponse message.
     * @function verify
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemAttributeListResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.attributes != null && message.hasOwnProperty('attributes')) {
        if (!Array.isArray(message.attributes))
          return 'attributes: array expected'
        for (let i = 0; i < message.attributes.length; ++i) {
          let error = $root.itempb.ItemAttribute.verify(message.attributes[i])
          if (error) return 'attributes.' + error
        }
      }
      return null
    }

    /**
     * Creates an ItemAttributeListResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemAttributeListResponse} ItemAttributeListResponse
     */
    ItemAttributeListResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemAttributeListResponse)
        return object
      let message = new $root.itempb.ItemAttributeListResponse()
      if (object.attributes) {
        if (!Array.isArray(object.attributes))
          throw TypeError(
            '.itempb.ItemAttributeListResponse.attributes: array expected'
          )
        message.attributes = []
        for (let i = 0; i < object.attributes.length; ++i) {
          if (typeof object.attributes[i] !== 'object')
            throw TypeError(
              '.itempb.ItemAttributeListResponse.attributes: object expected'
            )
          message.attributes[i] = $root.itempb.ItemAttribute.fromObject(
            object.attributes[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from an ItemAttributeListResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemAttributeListResponse
     * @static
     * @param {itempb.ItemAttributeListResponse} message ItemAttributeListResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemAttributeListResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.attributes = []
      if (message.attributes && message.attributes.length) {
        object.attributes = []
        for (let j = 0; j < message.attributes.length; ++j)
          object.attributes[j] = $root.itempb.ItemAttribute.toObject(
            message.attributes[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this ItemAttributeListResponse to JSON.
     * @function toJSON
     * @memberof itempb.ItemAttributeListResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemAttributeListResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemAttributeListResponse
  })()

  itempb.ItemAttributeValue = (function () {
    /**
     * Properties of an ItemAttributeValue.
     * @memberof itempb
     * @interface IItemAttributeValue
     * @property {string|null} [ID] ItemAttributeValue ID
     * @property {string|null} [Name] ItemAttributeValue Name
     * @property {Array.<string>|null} [AttributeGroups] ItemAttributeValue AttributeGroups
     */

    /**
     * Constructs a new ItemAttributeValue.
     * @memberof itempb
     * @classdesc Represents an ItemAttributeValue.
     * @implements IItemAttributeValue
     * @constructor
     * @param {itempb.IItemAttributeValue=} [properties] Properties to set
     */
    function ItemAttributeValue(properties) {
      this.AttributeGroups = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemAttributeValue ID.
     * @member {string} ID
     * @memberof itempb.ItemAttributeValue
     * @instance
     */
    ItemAttributeValue.prototype.ID = ''

    /**
     * ItemAttributeValue Name.
     * @member {string} Name
     * @memberof itempb.ItemAttributeValue
     * @instance
     */
    ItemAttributeValue.prototype.Name = ''

    /**
     * ItemAttributeValue AttributeGroups.
     * @member {Array.<string>} AttributeGroups
     * @memberof itempb.ItemAttributeValue
     * @instance
     */
    ItemAttributeValue.prototype.AttributeGroups = $util.emptyArray

    /**
     * Creates a new ItemAttributeValue instance using the specified properties.
     * @function create
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {itempb.IItemAttributeValue=} [properties] Properties to set
     * @returns {itempb.ItemAttributeValue} ItemAttributeValue instance
     */
    ItemAttributeValue.create = function create(properties) {
      return new ItemAttributeValue(properties)
    }

    /**
     * Encodes the specified ItemAttributeValue message. Does not implicitly {@link itempb.ItemAttributeValue.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {itempb.IItemAttributeValue} message ItemAttributeValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeValue.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.ID != null && Object.hasOwnProperty.call(message, 'ID'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.ID)
      if (message.Name != null && Object.hasOwnProperty.call(message, 'Name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.Name)
      if (message.AttributeGroups != null && message.AttributeGroups.length)
        for (let i = 0; i < message.AttributeGroups.length; ++i)
          writer
            .uint32(/* id 3, wireType 2 =*/ 26)
            .string(message.AttributeGroups[i])
      return writer
    }

    /**
     * Encodes the specified ItemAttributeValue message, length delimited. Does not implicitly {@link itempb.ItemAttributeValue.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {itempb.IItemAttributeValue} message ItemAttributeValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeValue.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemAttributeValue message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemAttributeValue} ItemAttributeValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeValue.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemAttributeValue()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.ID = reader.string()
            break
          case 2:
            message.Name = reader.string()
            break
          case 3:
            if (!(message.AttributeGroups && message.AttributeGroups.length))
              message.AttributeGroups = []
            message.AttributeGroups.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemAttributeValue message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemAttributeValue} ItemAttributeValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeValue.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemAttributeValue message.
     * @function verify
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemAttributeValue.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.ID != null && message.hasOwnProperty('ID'))
        if (!$util.isString(message.ID)) return 'ID: string expected'
      if (message.Name != null && message.hasOwnProperty('Name'))
        if (!$util.isString(message.Name)) return 'Name: string expected'
      if (
        message.AttributeGroups != null &&
        message.hasOwnProperty('AttributeGroups')
      ) {
        if (!Array.isArray(message.AttributeGroups))
          return 'AttributeGroups: array expected'
        for (let i = 0; i < message.AttributeGroups.length; ++i)
          if (!$util.isString(message.AttributeGroups[i]))
            return 'AttributeGroups: string[] expected'
      }
      return null
    }

    /**
     * Creates an ItemAttributeValue message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemAttributeValue} ItemAttributeValue
     */
    ItemAttributeValue.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemAttributeValue) return object
      let message = new $root.itempb.ItemAttributeValue()
      if (object.ID != null) message.ID = String(object.ID)
      if (object.Name != null) message.Name = String(object.Name)
      if (object.AttributeGroups) {
        if (!Array.isArray(object.AttributeGroups))
          throw TypeError(
            '.itempb.ItemAttributeValue.AttributeGroups: array expected'
          )
        message.AttributeGroups = []
        for (let i = 0; i < object.AttributeGroups.length; ++i)
          message.AttributeGroups[i] = String(object.AttributeGroups[i])
      }
      return message
    }

    /**
     * Creates a plain object from an ItemAttributeValue message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemAttributeValue
     * @static
     * @param {itempb.ItemAttributeValue} message ItemAttributeValue
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemAttributeValue.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.AttributeGroups = []
      if (options.defaults) {
        object.ID = ''
        object.Name = ''
      }
      if (message.ID != null && message.hasOwnProperty('ID'))
        object.ID = message.ID
      if (message.Name != null && message.hasOwnProperty('Name'))
        object.Name = message.Name
      if (message.AttributeGroups && message.AttributeGroups.length) {
        object.AttributeGroups = []
        for (let j = 0; j < message.AttributeGroups.length; ++j)
          object.AttributeGroups[j] = message.AttributeGroups[j]
      }
      return object
    }

    /**
     * Converts this ItemAttributeValue to JSON.
     * @function toJSON
     * @memberof itempb.ItemAttributeValue
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemAttributeValue.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemAttributeValue
  })()

  itempb.ItemAttribute = (function () {
    /**
     * Properties of an ItemAttribute.
     * @memberof itempb
     * @interface IItemAttribute
     * @property {string|null} [ID] ItemAttribute ID
     * @property {string|null} [Name] ItemAttribute Name
     * @property {string|null} [ShortName] ItemAttribute ShortName
     * @property {string|null} [Description] ItemAttribute Description
     * @property {boolean|null} [IsEditableByCustomer] ItemAttribute IsEditableByCustomer
     * @property {Array.<itempb.IItemAttributeValue>|null} [ItemAttributeValues] ItemAttribute ItemAttributeValues
     */

    /**
     * Constructs a new ItemAttribute.
     * @memberof itempb
     * @classdesc Represents an ItemAttribute.
     * @implements IItemAttribute
     * @constructor
     * @param {itempb.IItemAttribute=} [properties] Properties to set
     */
    function ItemAttribute(properties) {
      this.ItemAttributeValues = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemAttribute ID.
     * @member {string} ID
     * @memberof itempb.ItemAttribute
     * @instance
     */
    ItemAttribute.prototype.ID = ''

    /**
     * ItemAttribute Name.
     * @member {string} Name
     * @memberof itempb.ItemAttribute
     * @instance
     */
    ItemAttribute.prototype.Name = ''

    /**
     * ItemAttribute ShortName.
     * @member {string} ShortName
     * @memberof itempb.ItemAttribute
     * @instance
     */
    ItemAttribute.prototype.ShortName = ''

    /**
     * ItemAttribute Description.
     * @member {string} Description
     * @memberof itempb.ItemAttribute
     * @instance
     */
    ItemAttribute.prototype.Description = ''

    /**
     * ItemAttribute IsEditableByCustomer.
     * @member {boolean} IsEditableByCustomer
     * @memberof itempb.ItemAttribute
     * @instance
     */
    ItemAttribute.prototype.IsEditableByCustomer = false

    /**
     * ItemAttribute ItemAttributeValues.
     * @member {Array.<itempb.IItemAttributeValue>} ItemAttributeValues
     * @memberof itempb.ItemAttribute
     * @instance
     */
    ItemAttribute.prototype.ItemAttributeValues = $util.emptyArray

    /**
     * Creates a new ItemAttribute instance using the specified properties.
     * @function create
     * @memberof itempb.ItemAttribute
     * @static
     * @param {itempb.IItemAttribute=} [properties] Properties to set
     * @returns {itempb.ItemAttribute} ItemAttribute instance
     */
    ItemAttribute.create = function create(properties) {
      return new ItemAttribute(properties)
    }

    /**
     * Encodes the specified ItemAttribute message. Does not implicitly {@link itempb.ItemAttribute.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemAttribute
     * @static
     * @param {itempb.IItemAttribute} message ItemAttribute message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttribute.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.ID != null && Object.hasOwnProperty.call(message, 'ID'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.ID)
      if (message.Name != null && Object.hasOwnProperty.call(message, 'Name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.Name)
      if (
        message.ShortName != null &&
        Object.hasOwnProperty.call(message, 'ShortName')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.ShortName)
      if (
        message.Description != null &&
        Object.hasOwnProperty.call(message, 'Description')
      )
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.Description)
      if (
        message.IsEditableByCustomer != null &&
        Object.hasOwnProperty.call(message, 'IsEditableByCustomer')
      )
        writer
          .uint32(/* id 5, wireType 0 =*/ 40)
          .bool(message.IsEditableByCustomer)
      if (
        message.ItemAttributeValues != null &&
        message.ItemAttributeValues.length
      )
        for (let i = 0; i < message.ItemAttributeValues.length; ++i)
          $root.itempb.ItemAttributeValue.encode(
            message.ItemAttributeValues[i],
            writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified ItemAttribute message, length delimited. Does not implicitly {@link itempb.ItemAttribute.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemAttribute
     * @static
     * @param {itempb.IItemAttribute} message ItemAttribute message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttribute.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemAttribute message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemAttribute
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemAttribute} ItemAttribute
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttribute.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemAttribute()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.ID = reader.string()
            break
          case 2:
            message.Name = reader.string()
            break
          case 3:
            message.ShortName = reader.string()
            break
          case 4:
            message.Description = reader.string()
            break
          case 5:
            message.IsEditableByCustomer = reader.bool()
            break
          case 6:
            if (
              !(
                message.ItemAttributeValues &&
                message.ItemAttributeValues.length
              )
            )
              message.ItemAttributeValues = []
            message.ItemAttributeValues.push(
              $root.itempb.ItemAttributeValue.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemAttribute message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemAttribute
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemAttribute} ItemAttribute
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttribute.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemAttribute message.
     * @function verify
     * @memberof itempb.ItemAttribute
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemAttribute.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.ID != null && message.hasOwnProperty('ID'))
        if (!$util.isString(message.ID)) return 'ID: string expected'
      if (message.Name != null && message.hasOwnProperty('Name'))
        if (!$util.isString(message.Name)) return 'Name: string expected'
      if (message.ShortName != null && message.hasOwnProperty('ShortName'))
        if (!$util.isString(message.ShortName))
          return 'ShortName: string expected'
      if (message.Description != null && message.hasOwnProperty('Description'))
        if (!$util.isString(message.Description))
          return 'Description: string expected'
      if (
        message.IsEditableByCustomer != null &&
        message.hasOwnProperty('IsEditableByCustomer')
      )
        if (typeof message.IsEditableByCustomer !== 'boolean')
          return 'IsEditableByCustomer: boolean expected'
      if (
        message.ItemAttributeValues != null &&
        message.hasOwnProperty('ItemAttributeValues')
      ) {
        if (!Array.isArray(message.ItemAttributeValues))
          return 'ItemAttributeValues: array expected'
        for (let i = 0; i < message.ItemAttributeValues.length; ++i) {
          let error = $root.itempb.ItemAttributeValue.verify(
            message.ItemAttributeValues[i]
          )
          if (error) return 'ItemAttributeValues.' + error
        }
      }
      return null
    }

    /**
     * Creates an ItemAttribute message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemAttribute
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemAttribute} ItemAttribute
     */
    ItemAttribute.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemAttribute) return object
      let message = new $root.itempb.ItemAttribute()
      if (object.ID != null) message.ID = String(object.ID)
      if (object.Name != null) message.Name = String(object.Name)
      if (object.ShortName != null) message.ShortName = String(object.ShortName)
      if (object.Description != null)
        message.Description = String(object.Description)
      if (object.IsEditableByCustomer != null)
        message.IsEditableByCustomer = Boolean(object.IsEditableByCustomer)
      if (object.ItemAttributeValues) {
        if (!Array.isArray(object.ItemAttributeValues))
          throw TypeError(
            '.itempb.ItemAttribute.ItemAttributeValues: array expected'
          )
        message.ItemAttributeValues = []
        for (let i = 0; i < object.ItemAttributeValues.length; ++i) {
          if (typeof object.ItemAttributeValues[i] !== 'object')
            throw TypeError(
              '.itempb.ItemAttribute.ItemAttributeValues: object expected'
            )
          message.ItemAttributeValues[
            i
          ] = $root.itempb.ItemAttributeValue.fromObject(
            object.ItemAttributeValues[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from an ItemAttribute message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemAttribute
     * @static
     * @param {itempb.ItemAttribute} message ItemAttribute
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemAttribute.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.ItemAttributeValues = []
      if (options.defaults) {
        object.ID = ''
        object.Name = ''
        object.ShortName = ''
        object.Description = ''
        object.IsEditableByCustomer = false
      }
      if (message.ID != null && message.hasOwnProperty('ID'))
        object.ID = message.ID
      if (message.Name != null && message.hasOwnProperty('Name'))
        object.Name = message.Name
      if (message.ShortName != null && message.hasOwnProperty('ShortName'))
        object.ShortName = message.ShortName
      if (message.Description != null && message.hasOwnProperty('Description'))
        object.Description = message.Description
      if (
        message.IsEditableByCustomer != null &&
        message.hasOwnProperty('IsEditableByCustomer')
      )
        object.IsEditableByCustomer = message.IsEditableByCustomer
      if (message.ItemAttributeValues && message.ItemAttributeValues.length) {
        object.ItemAttributeValues = []
        for (let j = 0; j < message.ItemAttributeValues.length; ++j)
          object.ItemAttributeValues[
            j
          ] = $root.itempb.ItemAttributeValue.toObject(
            message.ItemAttributeValues[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this ItemAttribute to JSON.
     * @function toJSON
     * @memberof itempb.ItemAttribute
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemAttribute.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemAttribute
  })()

  itempb.GetSymptomsByItemRequest = (function () {
    /**
     * Properties of a GetSymptomsByItemRequest.
     * @memberof itempb
     * @interface IGetSymptomsByItemRequest
     * @property {string|null} [itemID] GetSymptomsByItemRequest itemID
     */

    /**
     * Constructs a new GetSymptomsByItemRequest.
     * @memberof itempb
     * @classdesc Represents a GetSymptomsByItemRequest.
     * @implements IGetSymptomsByItemRequest
     * @constructor
     * @param {itempb.IGetSymptomsByItemRequest=} [properties] Properties to set
     */
    function GetSymptomsByItemRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetSymptomsByItemRequest itemID.
     * @member {string} itemID
     * @memberof itempb.GetSymptomsByItemRequest
     * @instance
     */
    GetSymptomsByItemRequest.prototype.itemID = ''

    /**
     * Creates a new GetSymptomsByItemRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {itempb.IGetSymptomsByItemRequest=} [properties] Properties to set
     * @returns {itempb.GetSymptomsByItemRequest} GetSymptomsByItemRequest instance
     */
    GetSymptomsByItemRequest.create = function create(properties) {
      return new GetSymptomsByItemRequest(properties)
    }

    /**
     * Encodes the specified GetSymptomsByItemRequest message. Does not implicitly {@link itempb.GetSymptomsByItemRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {itempb.IGetSymptomsByItemRequest} message GetSymptomsByItemRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSymptomsByItemRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemID != null &&
        Object.hasOwnProperty.call(message, 'itemID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemID)
      return writer
    }

    /**
     * Encodes the specified GetSymptomsByItemRequest message, length delimited. Does not implicitly {@link itempb.GetSymptomsByItemRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {itempb.IGetSymptomsByItemRequest} message GetSymptomsByItemRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSymptomsByItemRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetSymptomsByItemRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetSymptomsByItemRequest} GetSymptomsByItemRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSymptomsByItemRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetSymptomsByItemRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetSymptomsByItemRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetSymptomsByItemRequest} GetSymptomsByItemRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSymptomsByItemRequest.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetSymptomsByItemRequest message.
     * @function verify
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSymptomsByItemRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        if (!$util.isString(message.itemID)) return 'itemID: string expected'
      return null
    }

    /**
     * Creates a GetSymptomsByItemRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetSymptomsByItemRequest} GetSymptomsByItemRequest
     */
    GetSymptomsByItemRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetSymptomsByItemRequest) return object
      let message = new $root.itempb.GetSymptomsByItemRequest()
      if (object.itemID != null) message.itemID = String(object.itemID)
      return message
    }

    /**
     * Creates a plain object from a GetSymptomsByItemRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetSymptomsByItemRequest
     * @static
     * @param {itempb.GetSymptomsByItemRequest} message GetSymptomsByItemRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSymptomsByItemRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.itemID = ''
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        object.itemID = message.itemID
      return object
    }

    /**
     * Converts this GetSymptomsByItemRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetSymptomsByItemRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSymptomsByItemRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetSymptomsByItemRequest
  })()

  itempb.GetSymptomsByItemResponse = (function () {
    /**
     * Properties of a GetSymptomsByItemResponse.
     * @memberof itempb
     * @interface IGetSymptomsByItemResponse
     * @property {Array.<itempb.ISymptom>|null} [symptoms] GetSymptomsByItemResponse symptoms
     */

    /**
     * Constructs a new GetSymptomsByItemResponse.
     * @memberof itempb
     * @classdesc Represents a GetSymptomsByItemResponse.
     * @implements IGetSymptomsByItemResponse
     * @constructor
     * @param {itempb.IGetSymptomsByItemResponse=} [properties] Properties to set
     */
    function GetSymptomsByItemResponse(properties) {
      this.symptoms = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetSymptomsByItemResponse symptoms.
     * @member {Array.<itempb.ISymptom>} symptoms
     * @memberof itempb.GetSymptomsByItemResponse
     * @instance
     */
    GetSymptomsByItemResponse.prototype.symptoms = $util.emptyArray

    /**
     * Creates a new GetSymptomsByItemResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {itempb.IGetSymptomsByItemResponse=} [properties] Properties to set
     * @returns {itempb.GetSymptomsByItemResponse} GetSymptomsByItemResponse instance
     */
    GetSymptomsByItemResponse.create = function create(properties) {
      return new GetSymptomsByItemResponse(properties)
    }

    /**
     * Encodes the specified GetSymptomsByItemResponse message. Does not implicitly {@link itempb.GetSymptomsByItemResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {itempb.IGetSymptomsByItemResponse} message GetSymptomsByItemResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSymptomsByItemResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.symptoms != null && message.symptoms.length)
        for (let i = 0; i < message.symptoms.length; ++i)
          $root.itempb.Symptom.encode(
            message.symptoms[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetSymptomsByItemResponse message, length delimited. Does not implicitly {@link itempb.GetSymptomsByItemResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {itempb.IGetSymptomsByItemResponse} message GetSymptomsByItemResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSymptomsByItemResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetSymptomsByItemResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetSymptomsByItemResponse} GetSymptomsByItemResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSymptomsByItemResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetSymptomsByItemResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.symptoms && message.symptoms.length))
              message.symptoms = []
            message.symptoms.push(
              $root.itempb.Symptom.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetSymptomsByItemResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetSymptomsByItemResponse} GetSymptomsByItemResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSymptomsByItemResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetSymptomsByItemResponse message.
     * @function verify
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSymptomsByItemResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.symptoms != null && message.hasOwnProperty('symptoms')) {
        if (!Array.isArray(message.symptoms)) return 'symptoms: array expected'
        for (let i = 0; i < message.symptoms.length; ++i) {
          let error = $root.itempb.Symptom.verify(message.symptoms[i])
          if (error) return 'symptoms.' + error
        }
      }
      return null
    }

    /**
     * Creates a GetSymptomsByItemResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetSymptomsByItemResponse} GetSymptomsByItemResponse
     */
    GetSymptomsByItemResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetSymptomsByItemResponse)
        return object
      let message = new $root.itempb.GetSymptomsByItemResponse()
      if (object.symptoms) {
        if (!Array.isArray(object.symptoms))
          throw TypeError(
            '.itempb.GetSymptomsByItemResponse.symptoms: array expected'
          )
        message.symptoms = []
        for (let i = 0; i < object.symptoms.length; ++i) {
          if (typeof object.symptoms[i] !== 'object')
            throw TypeError(
              '.itempb.GetSymptomsByItemResponse.symptoms: object expected'
            )
          message.symptoms[i] = $root.itempb.Symptom.fromObject(
            object.symptoms[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from a GetSymptomsByItemResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetSymptomsByItemResponse
     * @static
     * @param {itempb.GetSymptomsByItemResponse} message GetSymptomsByItemResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSymptomsByItemResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.symptoms = []
      if (message.symptoms && message.symptoms.length) {
        object.symptoms = []
        for (let j = 0; j < message.symptoms.length; ++j)
          object.symptoms[j] = $root.itempb.Symptom.toObject(
            message.symptoms[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this GetSymptomsByItemResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetSymptomsByItemResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSymptomsByItemResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetSymptomsByItemResponse
  })()

  itempb.GetBrandRequest = (function () {
    /**
     * Properties of a GetBrandRequest.
     * @memberof itempb
     * @interface IGetBrandRequest
     * @property {string|null} [brandID] GetBrandRequest brandID
     */

    /**
     * Constructs a new GetBrandRequest.
     * @memberof itempb
     * @classdesc Represents a GetBrandRequest.
     * @implements IGetBrandRequest
     * @constructor
     * @param {itempb.IGetBrandRequest=} [properties] Properties to set
     */
    function GetBrandRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetBrandRequest brandID.
     * @member {string} brandID
     * @memberof itempb.GetBrandRequest
     * @instance
     */
    GetBrandRequest.prototype.brandID = ''

    /**
     * Creates a new GetBrandRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {itempb.IGetBrandRequest=} [properties] Properties to set
     * @returns {itempb.GetBrandRequest} GetBrandRequest instance
     */
    GetBrandRequest.create = function create(properties) {
      return new GetBrandRequest(properties)
    }

    /**
     * Encodes the specified GetBrandRequest message. Does not implicitly {@link itempb.GetBrandRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {itempb.IGetBrandRequest} message GetBrandRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBrandRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.brandID != null &&
        Object.hasOwnProperty.call(message, 'brandID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.brandID)
      return writer
    }

    /**
     * Encodes the specified GetBrandRequest message, length delimited. Does not implicitly {@link itempb.GetBrandRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {itempb.IGetBrandRequest} message GetBrandRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBrandRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetBrandRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetBrandRequest} GetBrandRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBrandRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetBrandRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.brandID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetBrandRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetBrandRequest} GetBrandRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBrandRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetBrandRequest message.
     * @function verify
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetBrandRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.brandID != null && message.hasOwnProperty('brandID'))
        if (!$util.isString(message.brandID)) return 'brandID: string expected'
      return null
    }

    /**
     * Creates a GetBrandRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetBrandRequest} GetBrandRequest
     */
    GetBrandRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetBrandRequest) return object
      let message = new $root.itempb.GetBrandRequest()
      if (object.brandID != null) message.brandID = String(object.brandID)
      return message
    }

    /**
     * Creates a plain object from a GetBrandRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetBrandRequest
     * @static
     * @param {itempb.GetBrandRequest} message GetBrandRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetBrandRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.brandID = ''
      if (message.brandID != null && message.hasOwnProperty('brandID'))
        object.brandID = message.brandID
      return object
    }

    /**
     * Converts this GetBrandRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetBrandRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetBrandRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetBrandRequest
  })()

  itempb.GetBrandResponse = (function () {
    /**
     * Properties of a GetBrandResponse.
     * @memberof itempb
     * @interface IGetBrandResponse
     * @property {string|null} [id] GetBrandResponse id
     * @property {string|null} [name] GetBrandResponse name
     * @property {string|null} [category] GetBrandResponse category
     */

    /**
     * Constructs a new GetBrandResponse.
     * @memberof itempb
     * @classdesc Represents a GetBrandResponse.
     * @implements IGetBrandResponse
     * @constructor
     * @param {itempb.IGetBrandResponse=} [properties] Properties to set
     */
    function GetBrandResponse(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetBrandResponse id.
     * @member {string} id
     * @memberof itempb.GetBrandResponse
     * @instance
     */
    GetBrandResponse.prototype.id = ''

    /**
     * GetBrandResponse name.
     * @member {string} name
     * @memberof itempb.GetBrandResponse
     * @instance
     */
    GetBrandResponse.prototype.name = ''

    /**
     * GetBrandResponse category.
     * @member {string} category
     * @memberof itempb.GetBrandResponse
     * @instance
     */
    GetBrandResponse.prototype.category = ''

    /**
     * Creates a new GetBrandResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {itempb.IGetBrandResponse=} [properties] Properties to set
     * @returns {itempb.GetBrandResponse} GetBrandResponse instance
     */
    GetBrandResponse.create = function create(properties) {
      return new GetBrandResponse(properties)
    }

    /**
     * Encodes the specified GetBrandResponse message. Does not implicitly {@link itempb.GetBrandResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {itempb.IGetBrandResponse} message GetBrandResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBrandResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
      if (
        message.category != null &&
        Object.hasOwnProperty.call(message, 'category')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.category)
      return writer
    }

    /**
     * Encodes the specified GetBrandResponse message, length delimited. Does not implicitly {@link itempb.GetBrandResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {itempb.IGetBrandResponse} message GetBrandResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBrandResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetBrandResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetBrandResponse} GetBrandResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBrandResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetBrandResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.name = reader.string()
            break
          case 3:
            message.category = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetBrandResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetBrandResponse} GetBrandResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBrandResponse.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetBrandResponse message.
     * @function verify
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetBrandResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.name != null && message.hasOwnProperty('name'))
        if (!$util.isString(message.name)) return 'name: string expected'
      if (message.category != null && message.hasOwnProperty('category'))
        if (!$util.isString(message.category))
          return 'category: string expected'
      return null
    }

    /**
     * Creates a GetBrandResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetBrandResponse} GetBrandResponse
     */
    GetBrandResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetBrandResponse) return object
      let message = new $root.itempb.GetBrandResponse()
      if (object.id != null) message.id = String(object.id)
      if (object.name != null) message.name = String(object.name)
      if (object.category != null) message.category = String(object.category)
      return message
    }

    /**
     * Creates a plain object from a GetBrandResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetBrandResponse
     * @static
     * @param {itempb.GetBrandResponse} message GetBrandResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetBrandResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.id = ''
        object.name = ''
        object.category = ''
      }
      if (message.id != null && message.hasOwnProperty('id'))
        object.id = message.id
      if (message.name != null && message.hasOwnProperty('name'))
        object.name = message.name
      if (message.category != null && message.hasOwnProperty('category'))
        object.category = message.category
      return object
    }

    /**
     * Converts this GetBrandResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetBrandResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetBrandResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetBrandResponse
  })()

  itempb.GetItemsRequest = (function () {
    /**
     * Properties of a GetItemsRequest.
     * @memberof itempb
     * @interface IGetItemsRequest
     * @property {string|null} [itemID] GetItemsRequest itemID
     * @property {string|null} [tradeID] GetItemsRequest tradeID
     */

    /**
     * Constructs a new GetItemsRequest.
     * @memberof itempb
     * @classdesc Represents a GetItemsRequest.
     * @implements IGetItemsRequest
     * @constructor
     * @param {itempb.IGetItemsRequest=} [properties] Properties to set
     */
    function GetItemsRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetItemsRequest itemID.
     * @member {string} itemID
     * @memberof itempb.GetItemsRequest
     * @instance
     */
    GetItemsRequest.prototype.itemID = ''

    /**
     * GetItemsRequest tradeID.
     * @member {string} tradeID
     * @memberof itempb.GetItemsRequest
     * @instance
     */
    GetItemsRequest.prototype.tradeID = ''

    /**
     * Creates a new GetItemsRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {itempb.IGetItemsRequest=} [properties] Properties to set
     * @returns {itempb.GetItemsRequest} GetItemsRequest instance
     */
    GetItemsRequest.create = function create(properties) {
      return new GetItemsRequest(properties)
    }

    /**
     * Encodes the specified GetItemsRequest message. Does not implicitly {@link itempb.GetItemsRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {itempb.IGetItemsRequest} message GetItemsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetItemsRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemID != null &&
        Object.hasOwnProperty.call(message, 'itemID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemID)
      if (
        message.tradeID != null &&
        Object.hasOwnProperty.call(message, 'tradeID')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.tradeID)
      return writer
    }

    /**
     * Encodes the specified GetItemsRequest message, length delimited. Does not implicitly {@link itempb.GetItemsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {itempb.IGetItemsRequest} message GetItemsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetItemsRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetItemsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetItemsRequest} GetItemsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetItemsRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetItemsRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemID = reader.string()
            break
          case 2:
            message.tradeID = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetItemsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetItemsRequest} GetItemsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetItemsRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetItemsRequest message.
     * @function verify
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetItemsRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        if (!$util.isString(message.itemID)) return 'itemID: string expected'
      if (message.tradeID != null && message.hasOwnProperty('tradeID'))
        if (!$util.isString(message.tradeID)) return 'tradeID: string expected'
      return null
    }

    /**
     * Creates a GetItemsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetItemsRequest} GetItemsRequest
     */
    GetItemsRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetItemsRequest) return object
      let message = new $root.itempb.GetItemsRequest()
      if (object.itemID != null) message.itemID = String(object.itemID)
      if (object.tradeID != null) message.tradeID = String(object.tradeID)
      return message
    }

    /**
     * Creates a plain object from a GetItemsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetItemsRequest
     * @static
     * @param {itempb.GetItemsRequest} message GetItemsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetItemsRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.itemID = ''
        object.tradeID = ''
      }
      if (message.itemID != null && message.hasOwnProperty('itemID'))
        object.itemID = message.itemID
      if (message.tradeID != null && message.hasOwnProperty('tradeID'))
        object.tradeID = message.tradeID
      return object
    }

    /**
     * Converts this GetItemsRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetItemsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetItemsRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetItemsRequest
  })()

  itempb.GetItemsResponse = (function () {
    /**
     * Properties of a GetItemsResponse.
     * @memberof itempb
     * @interface IGetItemsResponse
     * @property {Array.<itempb.IItem>|null} [items] GetItemsResponse items
     */

    /**
     * Constructs a new GetItemsResponse.
     * @memberof itempb
     * @classdesc Represents a GetItemsResponse.
     * @implements IGetItemsResponse
     * @constructor
     * @param {itempb.IGetItemsResponse=} [properties] Properties to set
     */
    function GetItemsResponse(properties) {
      this.items = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetItemsResponse items.
     * @member {Array.<itempb.IItem>} items
     * @memberof itempb.GetItemsResponse
     * @instance
     */
    GetItemsResponse.prototype.items = $util.emptyArray

    /**
     * Creates a new GetItemsResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {itempb.IGetItemsResponse=} [properties] Properties to set
     * @returns {itempb.GetItemsResponse} GetItemsResponse instance
     */
    GetItemsResponse.create = function create(properties) {
      return new GetItemsResponse(properties)
    }

    /**
     * Encodes the specified GetItemsResponse message. Does not implicitly {@link itempb.GetItemsResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {itempb.IGetItemsResponse} message GetItemsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetItemsResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.items != null && message.items.length)
        for (let i = 0; i < message.items.length; ++i)
          $root.itempb.Item.encode(
            message.items[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetItemsResponse message, length delimited. Does not implicitly {@link itempb.GetItemsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {itempb.IGetItemsResponse} message GetItemsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetItemsResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetItemsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetItemsResponse} GetItemsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetItemsResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetItemsResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.items && message.items.length)) message.items = []
            message.items.push(
              $root.itempb.Item.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetItemsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetItemsResponse} GetItemsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetItemsResponse.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetItemsResponse message.
     * @function verify
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetItemsResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.items != null && message.hasOwnProperty('items')) {
        if (!Array.isArray(message.items)) return 'items: array expected'
        for (let i = 0; i < message.items.length; ++i) {
          let error = $root.itempb.Item.verify(message.items[i])
          if (error) return 'items.' + error
        }
      }
      return null
    }

    /**
     * Creates a GetItemsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetItemsResponse} GetItemsResponse
     */
    GetItemsResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetItemsResponse) return object
      let message = new $root.itempb.GetItemsResponse()
      if (object.items) {
        if (!Array.isArray(object.items))
          throw TypeError('.itempb.GetItemsResponse.items: array expected')
        message.items = []
        for (let i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== 'object')
            throw TypeError('.itempb.GetItemsResponse.items: object expected')
          message.items[i] = $root.itempb.Item.fromObject(object.items[i])
        }
      }
      return message
    }

    /**
     * Creates a plain object from a GetItemsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetItemsResponse
     * @static
     * @param {itempb.GetItemsResponse} message GetItemsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetItemsResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.items = []
      if (message.items && message.items.length) {
        object.items = []
        for (let j = 0; j < message.items.length; ++j)
          object.items[j] = $root.itempb.Item.toObject(
            message.items[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this GetItemsResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetItemsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetItemsResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetItemsResponse
  })()

  itempb.Item = (function () {
    /**
     * Properties of an Item.
     * @memberof itempb
     * @interface IItem
     * @property {string|null} [id] Item id
     * @property {string|null} [name] Item name
     * @property {string|null} [shortName] Item shortName
     * @property {Array.<itempb.IBrand>|null} [brands] Item brands
     * @property {Array.<itempb.IStyle>|null} [styles] Item styles
     * @property {boolean|null} [isBrandRequired] Item isBrandRequired
     * @property {string|null} [locationInProperty] Item locationInProperty
     * @property {string|null} [tradeID] Item tradeID
     * @property {boolean|null} [displayModelSerial] Item displayModelSerial
     */

    /**
     * Constructs a new Item.
     * @memberof itempb
     * @classdesc Represents an Item.
     * @implements IItem
     * @constructor
     * @param {itempb.IItem=} [properties] Properties to set
     */
    function Item(properties) {
      this.brands = []
      this.styles = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Item id.
     * @member {string} id
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.id = ''

    /**
     * Item name.
     * @member {string} name
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.name = ''

    /**
     * Item shortName.
     * @member {string} shortName
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.shortName = ''

    /**
     * Item brands.
     * @member {Array.<itempb.IBrand>} brands
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.brands = $util.emptyArray

    /**
     * Item styles.
     * @member {Array.<itempb.IStyle>} styles
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.styles = $util.emptyArray

    /**
     * Item isBrandRequired.
     * @member {boolean} isBrandRequired
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.isBrandRequired = false

    /**
     * Item locationInProperty.
     * @member {string} locationInProperty
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.locationInProperty = ''

    /**
     * Item tradeID.
     * @member {string} tradeID
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.tradeID = ''

    /**
     * Item displayModelSerial.
     * @member {boolean} displayModelSerial
     * @memberof itempb.Item
     * @instance
     */
    Item.prototype.displayModelSerial = false

    /**
     * Creates a new Item instance using the specified properties.
     * @function create
     * @memberof itempb.Item
     * @static
     * @param {itempb.IItem=} [properties] Properties to set
     * @returns {itempb.Item} Item instance
     */
    Item.create = function create(properties) {
      return new Item(properties)
    }

    /**
     * Encodes the specified Item message. Does not implicitly {@link itempb.Item.verify|verify} messages.
     * @function encode
     * @memberof itempb.Item
     * @static
     * @param {itempb.IItem} message Item message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Item.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
      if (
        message.shortName != null &&
        Object.hasOwnProperty.call(message, 'shortName')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.shortName)
      if (message.brands != null && message.brands.length)
        for (let i = 0; i < message.brands.length; ++i)
          $root.itempb.Brand.encode(
            message.brands[i],
            writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
          ).ldelim()
      if (message.styles != null && message.styles.length)
        for (let i = 0; i < message.styles.length; ++i)
          $root.itempb.Style.encode(
            message.styles[i],
            writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
          ).ldelim()
      if (
        message.isBrandRequired != null &&
        Object.hasOwnProperty.call(message, 'isBrandRequired')
      )
        writer.uint32(/* id 6, wireType 0 =*/ 48).bool(message.isBrandRequired)
      if (
        message.locationInProperty != null &&
        Object.hasOwnProperty.call(message, 'locationInProperty')
      )
        writer
          .uint32(/* id 7, wireType 2 =*/ 58)
          .string(message.locationInProperty)
      if (
        message.tradeID != null &&
        Object.hasOwnProperty.call(message, 'tradeID')
      )
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.tradeID)
      if (
        message.displayModelSerial != null &&
        Object.hasOwnProperty.call(message, 'displayModelSerial')
      )
        writer
          .uint32(/* id 9, wireType 0 =*/ 72)
          .bool(message.displayModelSerial)
      return writer
    }

    /**
     * Encodes the specified Item message, length delimited. Does not implicitly {@link itempb.Item.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.Item
     * @static
     * @param {itempb.IItem} message Item message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Item.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Item message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.Item
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.Item} Item
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Item.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.Item()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.name = reader.string()
            break
          case 3:
            message.shortName = reader.string()
            break
          case 4:
            if (!(message.brands && message.brands.length)) message.brands = []
            message.brands.push(
              $root.itempb.Brand.decode(reader, reader.uint32())
            )
            break
          case 5:
            if (!(message.styles && message.styles.length)) message.styles = []
            message.styles.push(
              $root.itempb.Style.decode(reader, reader.uint32())
            )
            break
          case 6:
            message.isBrandRequired = reader.bool()
            break
          case 7:
            message.locationInProperty = reader.string()
            break
          case 8:
            message.tradeID = reader.string()
            break
          case 9:
            message.displayModelSerial = reader.bool()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Item message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.Item
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.Item} Item
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Item.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Item message.
     * @function verify
     * @memberof itempb.Item
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Item.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.name != null && message.hasOwnProperty('name'))
        if (!$util.isString(message.name)) return 'name: string expected'
      if (message.shortName != null && message.hasOwnProperty('shortName'))
        if (!$util.isString(message.shortName))
          return 'shortName: string expected'
      if (message.brands != null && message.hasOwnProperty('brands')) {
        if (!Array.isArray(message.brands)) return 'brands: array expected'
        for (let i = 0; i < message.brands.length; ++i) {
          let error = $root.itempb.Brand.verify(message.brands[i])
          if (error) return 'brands.' + error
        }
      }
      if (message.styles != null && message.hasOwnProperty('styles')) {
        if (!Array.isArray(message.styles)) return 'styles: array expected'
        for (let i = 0; i < message.styles.length; ++i) {
          let error = $root.itempb.Style.verify(message.styles[i])
          if (error) return 'styles.' + error
        }
      }
      if (
        message.isBrandRequired != null &&
        message.hasOwnProperty('isBrandRequired')
      )
        if (typeof message.isBrandRequired !== 'boolean')
          return 'isBrandRequired: boolean expected'
      if (
        message.locationInProperty != null &&
        message.hasOwnProperty('locationInProperty')
      )
        if (!$util.isString(message.locationInProperty))
          return 'locationInProperty: string expected'
      if (message.tradeID != null && message.hasOwnProperty('tradeID'))
        if (!$util.isString(message.tradeID)) return 'tradeID: string expected'
      if (
        message.displayModelSerial != null &&
        message.hasOwnProperty('displayModelSerial')
      )
        if (typeof message.displayModelSerial !== 'boolean')
          return 'displayModelSerial: boolean expected'
      return null
    }

    /**
     * Creates an Item message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.Item
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.Item} Item
     */
    Item.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.Item) return object
      let message = new $root.itempb.Item()
      if (object.id != null) message.id = String(object.id)
      if (object.name != null) message.name = String(object.name)
      if (object.shortName != null) message.shortName = String(object.shortName)
      if (object.brands) {
        if (!Array.isArray(object.brands))
          throw TypeError('.itempb.Item.brands: array expected')
        message.brands = []
        for (let i = 0; i < object.brands.length; ++i) {
          if (typeof object.brands[i] !== 'object')
            throw TypeError('.itempb.Item.brands: object expected')
          message.brands[i] = $root.itempb.Brand.fromObject(object.brands[i])
        }
      }
      if (object.styles) {
        if (!Array.isArray(object.styles))
          throw TypeError('.itempb.Item.styles: array expected')
        message.styles = []
        for (let i = 0; i < object.styles.length; ++i) {
          if (typeof object.styles[i] !== 'object')
            throw TypeError('.itempb.Item.styles: object expected')
          message.styles[i] = $root.itempb.Style.fromObject(object.styles[i])
        }
      }
      if (object.isBrandRequired != null)
        message.isBrandRequired = Boolean(object.isBrandRequired)
      if (object.locationInProperty != null)
        message.locationInProperty = String(object.locationInProperty)
      if (object.tradeID != null) message.tradeID = String(object.tradeID)
      if (object.displayModelSerial != null)
        message.displayModelSerial = Boolean(object.displayModelSerial)
      return message
    }

    /**
     * Creates a plain object from an Item message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.Item
     * @static
     * @param {itempb.Item} message Item
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Item.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) {
        object.brands = []
        object.styles = []
      }
      if (options.defaults) {
        object.id = ''
        object.name = ''
        object.shortName = ''
        object.isBrandRequired = false
        object.locationInProperty = ''
        object.tradeID = ''
        object.displayModelSerial = false
      }
      if (message.id != null && message.hasOwnProperty('id'))
        object.id = message.id
      if (message.name != null && message.hasOwnProperty('name'))
        object.name = message.name
      if (message.shortName != null && message.hasOwnProperty('shortName'))
        object.shortName = message.shortName
      if (message.brands && message.brands.length) {
        object.brands = []
        for (let j = 0; j < message.brands.length; ++j)
          object.brands[j] = $root.itempb.Brand.toObject(
            message.brands[j],
            options
          )
      }
      if (message.styles && message.styles.length) {
        object.styles = []
        for (let j = 0; j < message.styles.length; ++j)
          object.styles[j] = $root.itempb.Style.toObject(
            message.styles[j],
            options
          )
      }
      if (
        message.isBrandRequired != null &&
        message.hasOwnProperty('isBrandRequired')
      )
        object.isBrandRequired = message.isBrandRequired
      if (
        message.locationInProperty != null &&
        message.hasOwnProperty('locationInProperty')
      )
        object.locationInProperty = message.locationInProperty
      if (message.tradeID != null && message.hasOwnProperty('tradeID'))
        object.tradeID = message.tradeID
      if (
        message.displayModelSerial != null &&
        message.hasOwnProperty('displayModelSerial')
      )
        object.displayModelSerial = message.displayModelSerial
      return object
    }

    /**
     * Converts this Item to JSON.
     * @function toJSON
     * @memberof itempb.Item
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Item.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Item
  })()

  itempb.ItemBrand = (function () {
    /**
     * Properties of an ItemBrand.
     * @memberof itempb
     * @interface IItemBrand
     * @property {string|null} [itemTypeID] ItemBrand itemTypeID
     * @property {Array.<string>|null} [brandIDs] ItemBrand brandIDs
     */

    /**
     * Constructs a new ItemBrand.
     * @memberof itempb
     * @classdesc Represents an ItemBrand.
     * @implements IItemBrand
     * @constructor
     * @param {itempb.IItemBrand=} [properties] Properties to set
     */
    function ItemBrand(properties) {
      this.brandIDs = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemBrand itemTypeID.
     * @member {string} itemTypeID
     * @memberof itempb.ItemBrand
     * @instance
     */
    ItemBrand.prototype.itemTypeID = ''

    /**
     * ItemBrand brandIDs.
     * @member {Array.<string>} brandIDs
     * @memberof itempb.ItemBrand
     * @instance
     */
    ItemBrand.prototype.brandIDs = $util.emptyArray

    /**
     * Creates a new ItemBrand instance using the specified properties.
     * @function create
     * @memberof itempb.ItemBrand
     * @static
     * @param {itempb.IItemBrand=} [properties] Properties to set
     * @returns {itempb.ItemBrand} ItemBrand instance
     */
    ItemBrand.create = function create(properties) {
      return new ItemBrand(properties)
    }

    /**
     * Encodes the specified ItemBrand message. Does not implicitly {@link itempb.ItemBrand.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemBrand
     * @static
     * @param {itempb.IItemBrand} message ItemBrand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemBrand.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemTypeID != null &&
        Object.hasOwnProperty.call(message, 'itemTypeID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemTypeID)
      if (message.brandIDs != null && message.brandIDs.length)
        for (let i = 0; i < message.brandIDs.length; ++i)
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.brandIDs[i])
      return writer
    }

    /**
     * Encodes the specified ItemBrand message, length delimited. Does not implicitly {@link itempb.ItemBrand.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemBrand
     * @static
     * @param {itempb.IItemBrand} message ItemBrand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemBrand.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemBrand message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemBrand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemBrand} ItemBrand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemBrand.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemBrand()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemTypeID = reader.string()
            break
          case 2:
            if (!(message.brandIDs && message.brandIDs.length))
              message.brandIDs = []
            message.brandIDs.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemBrand message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemBrand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemBrand} ItemBrand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemBrand.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemBrand message.
     * @function verify
     * @memberof itempb.ItemBrand
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemBrand.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        if (!$util.isString(message.itemTypeID))
          return 'itemTypeID: string expected'
      if (message.brandIDs != null && message.hasOwnProperty('brandIDs')) {
        if (!Array.isArray(message.brandIDs)) return 'brandIDs: array expected'
        for (let i = 0; i < message.brandIDs.length; ++i)
          if (!$util.isString(message.brandIDs[i]))
            return 'brandIDs: string[] expected'
      }
      return null
    }

    /**
     * Creates an ItemBrand message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemBrand
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemBrand} ItemBrand
     */
    ItemBrand.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemBrand) return object
      let message = new $root.itempb.ItemBrand()
      if (object.itemTypeID != null)
        message.itemTypeID = String(object.itemTypeID)
      if (object.brandIDs) {
        if (!Array.isArray(object.brandIDs))
          throw TypeError('.itempb.ItemBrand.brandIDs: array expected')
        message.brandIDs = []
        for (let i = 0; i < object.brandIDs.length; ++i)
          message.brandIDs[i] = String(object.brandIDs[i])
      }
      return message
    }

    /**
     * Creates a plain object from an ItemBrand message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemBrand
     * @static
     * @param {itempb.ItemBrand} message ItemBrand
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemBrand.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.brandIDs = []
      if (options.defaults) object.itemTypeID = ''
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        object.itemTypeID = message.itemTypeID
      if (message.brandIDs && message.brandIDs.length) {
        object.brandIDs = []
        for (let j = 0; j < message.brandIDs.length; ++j)
          object.brandIDs[j] = message.brandIDs[j]
      }
      return object
    }

    /**
     * Converts this ItemBrand to JSON.
     * @function toJSON
     * @memberof itempb.ItemBrand
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemBrand.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemBrand
  })()

  itempb.ItemAttributeMap = (function () {
    /**
     * Properties of an ItemAttributeMap.
     * @memberof itempb
     * @interface IItemAttributeMap
     * @property {string|null} [itemTypeID] ItemAttributeMap itemTypeID
     * @property {Array.<string>|null} [attributeIDs] ItemAttributeMap attributeIDs
     */

    /**
     * Constructs a new ItemAttributeMap.
     * @memberof itempb
     * @classdesc Represents an ItemAttributeMap.
     * @implements IItemAttributeMap
     * @constructor
     * @param {itempb.IItemAttributeMap=} [properties] Properties to set
     */
    function ItemAttributeMap(properties) {
      this.attributeIDs = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemAttributeMap itemTypeID.
     * @member {string} itemTypeID
     * @memberof itempb.ItemAttributeMap
     * @instance
     */
    ItemAttributeMap.prototype.itemTypeID = ''

    /**
     * ItemAttributeMap attributeIDs.
     * @member {Array.<string>} attributeIDs
     * @memberof itempb.ItemAttributeMap
     * @instance
     */
    ItemAttributeMap.prototype.attributeIDs = $util.emptyArray

    /**
     * Creates a new ItemAttributeMap instance using the specified properties.
     * @function create
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {itempb.IItemAttributeMap=} [properties] Properties to set
     * @returns {itempb.ItemAttributeMap} ItemAttributeMap instance
     */
    ItemAttributeMap.create = function create(properties) {
      return new ItemAttributeMap(properties)
    }

    /**
     * Encodes the specified ItemAttributeMap message. Does not implicitly {@link itempb.ItemAttributeMap.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {itempb.IItemAttributeMap} message ItemAttributeMap message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeMap.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemTypeID != null &&
        Object.hasOwnProperty.call(message, 'itemTypeID')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemTypeID)
      if (message.attributeIDs != null && message.attributeIDs.length)
        for (let i = 0; i < message.attributeIDs.length; ++i)
          writer
            .uint32(/* id 2, wireType 2 =*/ 18)
            .string(message.attributeIDs[i])
      return writer
    }

    /**
     * Encodes the specified ItemAttributeMap message, length delimited. Does not implicitly {@link itempb.ItemAttributeMap.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {itempb.IItemAttributeMap} message ItemAttributeMap message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemAttributeMap.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemAttributeMap message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemAttributeMap} ItemAttributeMap
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeMap.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemAttributeMap()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemTypeID = reader.string()
            break
          case 2:
            if (!(message.attributeIDs && message.attributeIDs.length))
              message.attributeIDs = []
            message.attributeIDs.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemAttributeMap message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemAttributeMap} ItemAttributeMap
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemAttributeMap.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemAttributeMap message.
     * @function verify
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemAttributeMap.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        if (!$util.isString(message.itemTypeID))
          return 'itemTypeID: string expected'
      if (
        message.attributeIDs != null &&
        message.hasOwnProperty('attributeIDs')
      ) {
        if (!Array.isArray(message.attributeIDs))
          return 'attributeIDs: array expected'
        for (let i = 0; i < message.attributeIDs.length; ++i)
          if (!$util.isString(message.attributeIDs[i]))
            return 'attributeIDs: string[] expected'
      }
      return null
    }

    /**
     * Creates an ItemAttributeMap message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemAttributeMap} ItemAttributeMap
     */
    ItemAttributeMap.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemAttributeMap) return object
      let message = new $root.itempb.ItemAttributeMap()
      if (object.itemTypeID != null)
        message.itemTypeID = String(object.itemTypeID)
      if (object.attributeIDs) {
        if (!Array.isArray(object.attributeIDs))
          throw TypeError(
            '.itempb.ItemAttributeMap.attributeIDs: array expected'
          )
        message.attributeIDs = []
        for (let i = 0; i < object.attributeIDs.length; ++i)
          message.attributeIDs[i] = String(object.attributeIDs[i])
      }
      return message
    }

    /**
     * Creates a plain object from an ItemAttributeMap message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemAttributeMap
     * @static
     * @param {itempb.ItemAttributeMap} message ItemAttributeMap
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemAttributeMap.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.attributeIDs = []
      if (options.defaults) object.itemTypeID = ''
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        object.itemTypeID = message.itemTypeID
      if (message.attributeIDs && message.attributeIDs.length) {
        object.attributeIDs = []
        for (let j = 0; j < message.attributeIDs.length; ++j)
          object.attributeIDs[j] = message.attributeIDs[j]
      }
      return object
    }

    /**
     * Converts this ItemAttributeMap to JSON.
     * @function toJSON
     * @memberof itempb.ItemAttributeMap
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemAttributeMap.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemAttributeMap
  })()

  itempb.ItemTypeMapping = (function () {
    /**
     * Properties of an ItemTypeMapping.
     * @memberof itempb
     * @interface IItemTypeMapping
     * @property {string|null} [tradeName] ItemTypeMapping tradeName
     * @property {string|null} [tradeShortName] ItemTypeMapping tradeShortName
     * @property {string|null} [tradeID] ItemTypeMapping tradeID
     * @property {string|null} [itemType] ItemTypeMapping itemType
     * @property {string|null} [itemTypeID] ItemTypeMapping itemTypeID
     * @property {string|null} [itemTypeShortName] ItemTypeMapping itemTypeShortName
     * @property {boolean|null} [brand] ItemTypeMapping brand
     * @property {boolean|null} [displayModelSerial] ItemTypeMapping displayModelSerial
     * @property {Array.<string>|null} [asset] ItemTypeMapping asset
     * @property {Array.<string>|null} [attributes] ItemTypeMapping attributes
     */

    /**
     * Constructs a new ItemTypeMapping.
     * @memberof itempb
     * @classdesc Represents an ItemTypeMapping.
     * @implements IItemTypeMapping
     * @constructor
     * @param {itempb.IItemTypeMapping=} [properties] Properties to set
     */
    function ItemTypeMapping(properties) {
      this.asset = []
      this.attributes = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemTypeMapping tradeName.
     * @member {string} tradeName
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.tradeName = ''

    /**
     * ItemTypeMapping tradeShortName.
     * @member {string} tradeShortName
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.tradeShortName = ''

    /**
     * ItemTypeMapping tradeID.
     * @member {string} tradeID
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.tradeID = ''

    /**
     * ItemTypeMapping itemType.
     * @member {string} itemType
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.itemType = ''

    /**
     * ItemTypeMapping itemTypeID.
     * @member {string} itemTypeID
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.itemTypeID = ''

    /**
     * ItemTypeMapping itemTypeShortName.
     * @member {string} itemTypeShortName
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.itemTypeShortName = ''

    /**
     * ItemTypeMapping brand.
     * @member {boolean} brand
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.brand = false

    /**
     * ItemTypeMapping displayModelSerial.
     * @member {boolean} displayModelSerial
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.displayModelSerial = false

    /**
     * ItemTypeMapping asset.
     * @member {Array.<string>} asset
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.asset = $util.emptyArray

    /**
     * ItemTypeMapping attributes.
     * @member {Array.<string>} attributes
     * @memberof itempb.ItemTypeMapping
     * @instance
     */
    ItemTypeMapping.prototype.attributes = $util.emptyArray

    /**
     * Creates a new ItemTypeMapping instance using the specified properties.
     * @function create
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {itempb.IItemTypeMapping=} [properties] Properties to set
     * @returns {itempb.ItemTypeMapping} ItemTypeMapping instance
     */
    ItemTypeMapping.create = function create(properties) {
      return new ItemTypeMapping(properties)
    }

    /**
     * Encodes the specified ItemTypeMapping message. Does not implicitly {@link itempb.ItemTypeMapping.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {itempb.IItemTypeMapping} message ItemTypeMapping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemTypeMapping.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.tradeName != null &&
        Object.hasOwnProperty.call(message, 'tradeName')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.tradeName)
      if (
        message.tradeShortName != null &&
        Object.hasOwnProperty.call(message, 'tradeShortName')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.tradeShortName)
      if (
        message.tradeID != null &&
        Object.hasOwnProperty.call(message, 'tradeID')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.tradeID)
      if (
        message.itemType != null &&
        Object.hasOwnProperty.call(message, 'itemType')
      )
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.itemType)
      if (
        message.itemTypeID != null &&
        Object.hasOwnProperty.call(message, 'itemTypeID')
      )
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.itemTypeID)
      if (
        message.itemTypeShortName != null &&
        Object.hasOwnProperty.call(message, 'itemTypeShortName')
      )
        writer
          .uint32(/* id 6, wireType 2 =*/ 50)
          .string(message.itemTypeShortName)
      if (message.brand != null && Object.hasOwnProperty.call(message, 'brand'))
        writer.uint32(/* id 7, wireType 0 =*/ 56).bool(message.brand)
      if (
        message.displayModelSerial != null &&
        Object.hasOwnProperty.call(message, 'displayModelSerial')
      )
        writer
          .uint32(/* id 8, wireType 0 =*/ 64)
          .bool(message.displayModelSerial)
      if (message.asset != null && message.asset.length)
        for (let i = 0; i < message.asset.length; ++i)
          writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.asset[i])
      if (message.attributes != null && message.attributes.length)
        for (let i = 0; i < message.attributes.length; ++i)
          writer
            .uint32(/* id 10, wireType 2 =*/ 82)
            .string(message.attributes[i])
      return writer
    }

    /**
     * Encodes the specified ItemTypeMapping message, length delimited. Does not implicitly {@link itempb.ItemTypeMapping.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {itempb.IItemTypeMapping} message ItemTypeMapping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemTypeMapping.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemTypeMapping message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemTypeMapping} ItemTypeMapping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemTypeMapping.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemTypeMapping()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.tradeName = reader.string()
            break
          case 2:
            message.tradeShortName = reader.string()
            break
          case 3:
            message.tradeID = reader.string()
            break
          case 4:
            message.itemType = reader.string()
            break
          case 5:
            message.itemTypeID = reader.string()
            break
          case 6:
            message.itemTypeShortName = reader.string()
            break
          case 7:
            message.brand = reader.bool()
            break
          case 8:
            message.displayModelSerial = reader.bool()
            break
          case 9:
            if (!(message.asset && message.asset.length)) message.asset = []
            message.asset.push(reader.string())
            break
          case 10:
            if (!(message.attributes && message.attributes.length))
              message.attributes = []
            message.attributes.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemTypeMapping message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemTypeMapping} ItemTypeMapping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemTypeMapping.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemTypeMapping message.
     * @function verify
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemTypeMapping.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.tradeName != null && message.hasOwnProperty('tradeName'))
        if (!$util.isString(message.tradeName))
          return 'tradeName: string expected'
      if (
        message.tradeShortName != null &&
        message.hasOwnProperty('tradeShortName')
      )
        if (!$util.isString(message.tradeShortName))
          return 'tradeShortName: string expected'
      if (message.tradeID != null && message.hasOwnProperty('tradeID'))
        if (!$util.isString(message.tradeID)) return 'tradeID: string expected'
      if (message.itemType != null && message.hasOwnProperty('itemType'))
        if (!$util.isString(message.itemType))
          return 'itemType: string expected'
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        if (!$util.isString(message.itemTypeID))
          return 'itemTypeID: string expected'
      if (
        message.itemTypeShortName != null &&
        message.hasOwnProperty('itemTypeShortName')
      )
        if (!$util.isString(message.itemTypeShortName))
          return 'itemTypeShortName: string expected'
      if (message.brand != null && message.hasOwnProperty('brand'))
        if (typeof message.brand !== 'boolean') return 'brand: boolean expected'
      if (
        message.displayModelSerial != null &&
        message.hasOwnProperty('displayModelSerial')
      )
        if (typeof message.displayModelSerial !== 'boolean')
          return 'displayModelSerial: boolean expected'
      if (message.asset != null && message.hasOwnProperty('asset')) {
        if (!Array.isArray(message.asset)) return 'asset: array expected'
        for (let i = 0; i < message.asset.length; ++i)
          if (!$util.isString(message.asset[i]))
            return 'asset: string[] expected'
      }
      if (message.attributes != null && message.hasOwnProperty('attributes')) {
        if (!Array.isArray(message.attributes))
          return 'attributes: array expected'
        for (let i = 0; i < message.attributes.length; ++i)
          if (!$util.isString(message.attributes[i]))
            return 'attributes: string[] expected'
      }
      return null
    }

    /**
     * Creates an ItemTypeMapping message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemTypeMapping} ItemTypeMapping
     */
    ItemTypeMapping.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemTypeMapping) return object
      let message = new $root.itempb.ItemTypeMapping()
      if (object.tradeName != null) message.tradeName = String(object.tradeName)
      if (object.tradeShortName != null)
        message.tradeShortName = String(object.tradeShortName)
      if (object.tradeID != null) message.tradeID = String(object.tradeID)
      if (object.itemType != null) message.itemType = String(object.itemType)
      if (object.itemTypeID != null)
        message.itemTypeID = String(object.itemTypeID)
      if (object.itemTypeShortName != null)
        message.itemTypeShortName = String(object.itemTypeShortName)
      if (object.brand != null) message.brand = Boolean(object.brand)
      if (object.displayModelSerial != null)
        message.displayModelSerial = Boolean(object.displayModelSerial)
      if (object.asset) {
        if (!Array.isArray(object.asset))
          throw TypeError('.itempb.ItemTypeMapping.asset: array expected')
        message.asset = []
        for (let i = 0; i < object.asset.length; ++i)
          message.asset[i] = String(object.asset[i])
      }
      if (object.attributes) {
        if (!Array.isArray(object.attributes))
          throw TypeError('.itempb.ItemTypeMapping.attributes: array expected')
        message.attributes = []
        for (let i = 0; i < object.attributes.length; ++i)
          message.attributes[i] = String(object.attributes[i])
      }
      return message
    }

    /**
     * Creates a plain object from an ItemTypeMapping message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemTypeMapping
     * @static
     * @param {itempb.ItemTypeMapping} message ItemTypeMapping
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemTypeMapping.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) {
        object.asset = []
        object.attributes = []
      }
      if (options.defaults) {
        object.tradeName = ''
        object.tradeShortName = ''
        object.tradeID = ''
        object.itemType = ''
        object.itemTypeID = ''
        object.itemTypeShortName = ''
        object.brand = false
        object.displayModelSerial = false
      }
      if (message.tradeName != null && message.hasOwnProperty('tradeName'))
        object.tradeName = message.tradeName
      if (
        message.tradeShortName != null &&
        message.hasOwnProperty('tradeShortName')
      )
        object.tradeShortName = message.tradeShortName
      if (message.tradeID != null && message.hasOwnProperty('tradeID'))
        object.tradeID = message.tradeID
      if (message.itemType != null && message.hasOwnProperty('itemType'))
        object.itemType = message.itemType
      if (message.itemTypeID != null && message.hasOwnProperty('itemTypeID'))
        object.itemTypeID = message.itemTypeID
      if (
        message.itemTypeShortName != null &&
        message.hasOwnProperty('itemTypeShortName')
      )
        object.itemTypeShortName = message.itemTypeShortName
      if (message.brand != null && message.hasOwnProperty('brand'))
        object.brand = message.brand
      if (
        message.displayModelSerial != null &&
        message.hasOwnProperty('displayModelSerial')
      )
        object.displayModelSerial = message.displayModelSerial
      if (message.asset && message.asset.length) {
        object.asset = []
        for (let j = 0; j < message.asset.length; ++j)
          object.asset[j] = message.asset[j]
      }
      if (message.attributes && message.attributes.length) {
        object.attributes = []
        for (let j = 0; j < message.attributes.length; ++j)
          object.attributes[j] = message.attributes[j]
      }
      return object
    }

    /**
     * Converts this ItemTypeMapping to JSON.
     * @function toJSON
     * @memberof itempb.ItemTypeMapping
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemTypeMapping.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemTypeMapping
  })()

  itempb.ItemStyleComponentMapping = (function () {
    /**
     * Properties of an ItemStyleComponentMapping.
     * @memberof itempb
     * @interface IItemStyleComponentMapping
     * @property {string|null} [itemId] ItemStyleComponentMapping itemId
     * @property {string|null} [styleId] ItemStyleComponentMapping styleId
     * @property {string|null} [componentId] ItemStyleComponentMapping componentId
     * @property {string|null} [componentName] ItemStyleComponentMapping componentName
     */

    /**
     * Constructs a new ItemStyleComponentMapping.
     * @memberof itempb
     * @classdesc Represents an ItemStyleComponentMapping.
     * @implements IItemStyleComponentMapping
     * @constructor
     * @param {itempb.IItemStyleComponentMapping=} [properties] Properties to set
     */
    function ItemStyleComponentMapping(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemStyleComponentMapping itemId.
     * @member {string} itemId
     * @memberof itempb.ItemStyleComponentMapping
     * @instance
     */
    ItemStyleComponentMapping.prototype.itemId = ''

    /**
     * ItemStyleComponentMapping styleId.
     * @member {string} styleId
     * @memberof itempb.ItemStyleComponentMapping
     * @instance
     */
    ItemStyleComponentMapping.prototype.styleId = ''

    /**
     * ItemStyleComponentMapping componentId.
     * @member {string} componentId
     * @memberof itempb.ItemStyleComponentMapping
     * @instance
     */
    ItemStyleComponentMapping.prototype.componentId = ''

    /**
     * ItemStyleComponentMapping componentName.
     * @member {string} componentName
     * @memberof itempb.ItemStyleComponentMapping
     * @instance
     */
    ItemStyleComponentMapping.prototype.componentName = ''

    /**
     * Creates a new ItemStyleComponentMapping instance using the specified properties.
     * @function create
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {itempb.IItemStyleComponentMapping=} [properties] Properties to set
     * @returns {itempb.ItemStyleComponentMapping} ItemStyleComponentMapping instance
     */
    ItemStyleComponentMapping.create = function create(properties) {
      return new ItemStyleComponentMapping(properties)
    }

    /**
     * Encodes the specified ItemStyleComponentMapping message. Does not implicitly {@link itempb.ItemStyleComponentMapping.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {itempb.IItemStyleComponentMapping} message ItemStyleComponentMapping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemStyleComponentMapping.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemId != null &&
        Object.hasOwnProperty.call(message, 'itemId')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemId)
      if (
        message.styleId != null &&
        Object.hasOwnProperty.call(message, 'styleId')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.styleId)
      if (
        message.componentId != null &&
        Object.hasOwnProperty.call(message, 'componentId')
      )
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.componentId)
      if (
        message.componentName != null &&
        Object.hasOwnProperty.call(message, 'componentName')
      )
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.componentName)
      return writer
    }

    /**
     * Encodes the specified ItemStyleComponentMapping message, length delimited. Does not implicitly {@link itempb.ItemStyleComponentMapping.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {itempb.IItemStyleComponentMapping} message ItemStyleComponentMapping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemStyleComponentMapping.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemStyleComponentMapping message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemStyleComponentMapping} ItemStyleComponentMapping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemStyleComponentMapping.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemStyleComponentMapping()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemId = reader.string()
            break
          case 2:
            message.styleId = reader.string()
            break
          case 3:
            message.componentId = reader.string()
            break
          case 4:
            message.componentName = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemStyleComponentMapping message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemStyleComponentMapping} ItemStyleComponentMapping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemStyleComponentMapping.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemStyleComponentMapping message.
     * @function verify
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemStyleComponentMapping.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemId != null && message.hasOwnProperty('itemId'))
        if (!$util.isString(message.itemId)) return 'itemId: string expected'
      if (message.styleId != null && message.hasOwnProperty('styleId'))
        if (!$util.isString(message.styleId)) return 'styleId: string expected'
      if (message.componentId != null && message.hasOwnProperty('componentId'))
        if (!$util.isString(message.componentId))
          return 'componentId: string expected'
      if (
        message.componentName != null &&
        message.hasOwnProperty('componentName')
      )
        if (!$util.isString(message.componentName))
          return 'componentName: string expected'
      return null
    }

    /**
     * Creates an ItemStyleComponentMapping message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemStyleComponentMapping} ItemStyleComponentMapping
     */
    ItemStyleComponentMapping.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemStyleComponentMapping)
        return object
      let message = new $root.itempb.ItemStyleComponentMapping()
      if (object.itemId != null) message.itemId = String(object.itemId)
      if (object.styleId != null) message.styleId = String(object.styleId)
      if (object.componentId != null)
        message.componentId = String(object.componentId)
      if (object.componentName != null)
        message.componentName = String(object.componentName)
      return message
    }

    /**
     * Creates a plain object from an ItemStyleComponentMapping message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemStyleComponentMapping
     * @static
     * @param {itempb.ItemStyleComponentMapping} message ItemStyleComponentMapping
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemStyleComponentMapping.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.itemId = ''
        object.styleId = ''
        object.componentId = ''
        object.componentName = ''
      }
      if (message.itemId != null && message.hasOwnProperty('itemId'))
        object.itemId = message.itemId
      if (message.styleId != null && message.hasOwnProperty('styleId'))
        object.styleId = message.styleId
      if (message.componentId != null && message.hasOwnProperty('componentId'))
        object.componentId = message.componentId
      if (
        message.componentName != null &&
        message.hasOwnProperty('componentName')
      )
        object.componentName = message.componentName
      return object
    }

    /**
     * Converts this ItemStyleComponentMapping to JSON.
     * @function toJSON
     * @memberof itempb.ItemStyleComponentMapping
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemStyleComponentMapping.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemStyleComponentMapping
  })()

  itempb.ComponentDetails = (function () {
    /**
     * Properties of a ComponentDetails.
     * @memberof itempb
     * @interface IComponentDetails
     * @property {string|null} [componentId] ComponentDetails componentId
     * @property {string|null} [componentName] ComponentDetails componentName
     * @property {Array.<itempb.ICharacteristic>|null} [characteristics] ComponentDetails characteristics
     */

    /**
     * Constructs a new ComponentDetails.
     * @memberof itempb
     * @classdesc Represents a ComponentDetails.
     * @implements IComponentDetails
     * @constructor
     * @param {itempb.IComponentDetails=} [properties] Properties to set
     */
    function ComponentDetails(properties) {
      this.characteristics = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ComponentDetails componentId.
     * @member {string} componentId
     * @memberof itempb.ComponentDetails
     * @instance
     */
    ComponentDetails.prototype.componentId = ''

    /**
     * ComponentDetails componentName.
     * @member {string} componentName
     * @memberof itempb.ComponentDetails
     * @instance
     */
    ComponentDetails.prototype.componentName = ''

    /**
     * ComponentDetails characteristics.
     * @member {Array.<itempb.ICharacteristic>} characteristics
     * @memberof itempb.ComponentDetails
     * @instance
     */
    ComponentDetails.prototype.characteristics = $util.emptyArray

    /**
     * Creates a new ComponentDetails instance using the specified properties.
     * @function create
     * @memberof itempb.ComponentDetails
     * @static
     * @param {itempb.IComponentDetails=} [properties] Properties to set
     * @returns {itempb.ComponentDetails} ComponentDetails instance
     */
    ComponentDetails.create = function create(properties) {
      return new ComponentDetails(properties)
    }

    /**
     * Encodes the specified ComponentDetails message. Does not implicitly {@link itempb.ComponentDetails.verify|verify} messages.
     * @function encode
     * @memberof itempb.ComponentDetails
     * @static
     * @param {itempb.IComponentDetails} message ComponentDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ComponentDetails.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.componentId != null &&
        Object.hasOwnProperty.call(message, 'componentId')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.componentId)
      if (
        message.componentName != null &&
        Object.hasOwnProperty.call(message, 'componentName')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.componentName)
      if (message.characteristics != null && message.characteristics.length)
        for (let i = 0; i < message.characteristics.length; ++i)
          $root.itempb.Characteristic.encode(
            message.characteristics[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified ComponentDetails message, length delimited. Does not implicitly {@link itempb.ComponentDetails.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ComponentDetails
     * @static
     * @param {itempb.IComponentDetails} message ComponentDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ComponentDetails.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a ComponentDetails message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ComponentDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ComponentDetails} ComponentDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ComponentDetails.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ComponentDetails()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.componentId = reader.string()
            break
          case 2:
            message.componentName = reader.string()
            break
          case 3:
            if (!(message.characteristics && message.characteristics.length))
              message.characteristics = []
            message.characteristics.push(
              $root.itempb.Characteristic.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a ComponentDetails message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ComponentDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ComponentDetails} ComponentDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ComponentDetails.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a ComponentDetails message.
     * @function verify
     * @memberof itempb.ComponentDetails
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ComponentDetails.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.componentId != null && message.hasOwnProperty('componentId'))
        if (!$util.isString(message.componentId))
          return 'componentId: string expected'
      if (
        message.componentName != null &&
        message.hasOwnProperty('componentName')
      )
        if (!$util.isString(message.componentName))
          return 'componentName: string expected'
      if (
        message.characteristics != null &&
        message.hasOwnProperty('characteristics')
      ) {
        if (!Array.isArray(message.characteristics))
          return 'characteristics: array expected'
        for (let i = 0; i < message.characteristics.length; ++i) {
          let error = $root.itempb.Characteristic.verify(
            message.characteristics[i]
          )
          if (error) return 'characteristics.' + error
        }
      }
      return null
    }

    /**
     * Creates a ComponentDetails message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ComponentDetails
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ComponentDetails} ComponentDetails
     */
    ComponentDetails.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ComponentDetails) return object
      let message = new $root.itempb.ComponentDetails()
      if (object.componentId != null)
        message.componentId = String(object.componentId)
      if (object.componentName != null)
        message.componentName = String(object.componentName)
      if (object.characteristics) {
        if (!Array.isArray(object.characteristics))
          throw TypeError(
            '.itempb.ComponentDetails.characteristics: array expected'
          )
        message.characteristics = []
        for (let i = 0; i < object.characteristics.length; ++i) {
          if (typeof object.characteristics[i] !== 'object')
            throw TypeError(
              '.itempb.ComponentDetails.characteristics: object expected'
            )
          message.characteristics[i] = $root.itempb.Characteristic.fromObject(
            object.characteristics[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from a ComponentDetails message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ComponentDetails
     * @static
     * @param {itempb.ComponentDetails} message ComponentDetails
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ComponentDetails.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.characteristics = []
      if (options.defaults) {
        object.componentId = ''
        object.componentName = ''
      }
      if (message.componentId != null && message.hasOwnProperty('componentId'))
        object.componentId = message.componentId
      if (
        message.componentName != null &&
        message.hasOwnProperty('componentName')
      )
        object.componentName = message.componentName
      if (message.characteristics && message.characteristics.length) {
        object.characteristics = []
        for (let j = 0; j < message.characteristics.length; ++j)
          object.characteristics[j] = $root.itempb.Characteristic.toObject(
            message.characteristics[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this ComponentDetails to JSON.
     * @function toJSON
     * @memberof itempb.ComponentDetails
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ComponentDetails.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ComponentDetails
  })()

  itempb.Characteristic = (function () {
    /**
     * Properties of a Characteristic.
     * @memberof itempb
     * @interface ICharacteristic
     * @property {string|null} [id] Characteristic id
     * @property {string|null} [description] Characteristic description
     * @property {Array.<string>|null} [values] Characteristic values
     */

    /**
     * Constructs a new Characteristic.
     * @memberof itempb
     * @classdesc Represents a Characteristic.
     * @implements ICharacteristic
     * @constructor
     * @param {itempb.ICharacteristic=} [properties] Properties to set
     */
    function Characteristic(properties) {
      this.values = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Characteristic id.
     * @member {string} id
     * @memberof itempb.Characteristic
     * @instance
     */
    Characteristic.prototype.id = ''

    /**
     * Characteristic description.
     * @member {string} description
     * @memberof itempb.Characteristic
     * @instance
     */
    Characteristic.prototype.description = ''

    /**
     * Characteristic values.
     * @member {Array.<string>} values
     * @memberof itempb.Characteristic
     * @instance
     */
    Characteristic.prototype.values = $util.emptyArray

    /**
     * Creates a new Characteristic instance using the specified properties.
     * @function create
     * @memberof itempb.Characteristic
     * @static
     * @param {itempb.ICharacteristic=} [properties] Properties to set
     * @returns {itempb.Characteristic} Characteristic instance
     */
    Characteristic.create = function create(properties) {
      return new Characteristic(properties)
    }

    /**
     * Encodes the specified Characteristic message. Does not implicitly {@link itempb.Characteristic.verify|verify} messages.
     * @function encode
     * @memberof itempb.Characteristic
     * @static
     * @param {itempb.ICharacteristic} message Characteristic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Characteristic.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (
        message.description != null &&
        Object.hasOwnProperty.call(message, 'description')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.description)
      if (message.values != null && message.values.length)
        for (let i = 0; i < message.values.length; ++i)
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.values[i])
      return writer
    }

    /**
     * Encodes the specified Characteristic message, length delimited. Does not implicitly {@link itempb.Characteristic.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.Characteristic
     * @static
     * @param {itempb.ICharacteristic} message Characteristic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Characteristic.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Characteristic message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.Characteristic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.Characteristic} Characteristic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Characteristic.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.Characteristic()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.description = reader.string()
            break
          case 3:
            if (!(message.values && message.values.length)) message.values = []
            message.values.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Characteristic message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.Characteristic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.Characteristic} Characteristic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Characteristic.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Characteristic message.
     * @function verify
     * @memberof itempb.Characteristic
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Characteristic.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.description != null && message.hasOwnProperty('description'))
        if (!$util.isString(message.description))
          return 'description: string expected'
      if (message.values != null && message.hasOwnProperty('values')) {
        if (!Array.isArray(message.values)) return 'values: array expected'
        for (let i = 0; i < message.values.length; ++i)
          if (!$util.isString(message.values[i]))
            return 'values: string[] expected'
      }
      return null
    }

    /**
     * Creates a Characteristic message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.Characteristic
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.Characteristic} Characteristic
     */
    Characteristic.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.Characteristic) return object
      let message = new $root.itempb.Characteristic()
      if (object.id != null) message.id = String(object.id)
      if (object.description != null)
        message.description = String(object.description)
      if (object.values) {
        if (!Array.isArray(object.values))
          throw TypeError('.itempb.Characteristic.values: array expected')
        message.values = []
        for (let i = 0; i < object.values.length; ++i)
          message.values[i] = String(object.values[i])
      }
      return message
    }

    /**
     * Creates a plain object from a Characteristic message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.Characteristic
     * @static
     * @param {itempb.Characteristic} message Characteristic
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Characteristic.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.values = []
      if (options.defaults) {
        object.id = ''
        object.description = ''
      }
      if (message.id != null && message.hasOwnProperty('id'))
        object.id = message.id
      if (message.description != null && message.hasOwnProperty('description'))
        object.description = message.description
      if (message.values && message.values.length) {
        object.values = []
        for (let j = 0; j < message.values.length; ++j)
          object.values[j] = message.values[j]
      }
      return object
    }

    /**
     * Converts this Characteristic to JSON.
     * @function toJSON
     * @memberof itempb.Characteristic
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Characteristic.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Characteristic
  })()

  itempb.FailureReasonDetails = (function () {
    /**
     * Properties of a FailureReasonDetails.
     * @memberof itempb
     * @interface IFailureReasonDetails
     * @property {string|null} [failureReasonId] FailureReasonDetails failureReasonId
     * @property {string|null} [failureReasonDescription] FailureReasonDetails failureReasonDescription
     */

    /**
     * Constructs a new FailureReasonDetails.
     * @memberof itempb
     * @classdesc Represents a FailureReasonDetails.
     * @implements IFailureReasonDetails
     * @constructor
     * @param {itempb.IFailureReasonDetails=} [properties] Properties to set
     */
    function FailureReasonDetails(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * FailureReasonDetails failureReasonId.
     * @member {string} failureReasonId
     * @memberof itempb.FailureReasonDetails
     * @instance
     */
    FailureReasonDetails.prototype.failureReasonId = ''

    /**
     * FailureReasonDetails failureReasonDescription.
     * @member {string} failureReasonDescription
     * @memberof itempb.FailureReasonDetails
     * @instance
     */
    FailureReasonDetails.prototype.failureReasonDescription = ''

    /**
     * Creates a new FailureReasonDetails instance using the specified properties.
     * @function create
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {itempb.IFailureReasonDetails=} [properties] Properties to set
     * @returns {itempb.FailureReasonDetails} FailureReasonDetails instance
     */
    FailureReasonDetails.create = function create(properties) {
      return new FailureReasonDetails(properties)
    }

    /**
     * Encodes the specified FailureReasonDetails message. Does not implicitly {@link itempb.FailureReasonDetails.verify|verify} messages.
     * @function encode
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {itempb.IFailureReasonDetails} message FailureReasonDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FailureReasonDetails.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.failureReasonId != null &&
        Object.hasOwnProperty.call(message, 'failureReasonId')
      )
        writer
          .uint32(/* id 1, wireType 2 =*/ 10)
          .string(message.failureReasonId)
      if (
        message.failureReasonDescription != null &&
        Object.hasOwnProperty.call(message, 'failureReasonDescription')
      )
        writer
          .uint32(/* id 2, wireType 2 =*/ 18)
          .string(message.failureReasonDescription)
      return writer
    }

    /**
     * Encodes the specified FailureReasonDetails message, length delimited. Does not implicitly {@link itempb.FailureReasonDetails.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {itempb.IFailureReasonDetails} message FailureReasonDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FailureReasonDetails.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a FailureReasonDetails message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.FailureReasonDetails} FailureReasonDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FailureReasonDetails.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.FailureReasonDetails()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.failureReasonId = reader.string()
            break
          case 2:
            message.failureReasonDescription = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a FailureReasonDetails message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.FailureReasonDetails} FailureReasonDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FailureReasonDetails.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a FailureReasonDetails message.
     * @function verify
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FailureReasonDetails.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.failureReasonId != null &&
        message.hasOwnProperty('failureReasonId')
      )
        if (!$util.isString(message.failureReasonId))
          return 'failureReasonId: string expected'
      if (
        message.failureReasonDescription != null &&
        message.hasOwnProperty('failureReasonDescription')
      )
        if (!$util.isString(message.failureReasonDescription))
          return 'failureReasonDescription: string expected'
      return null
    }

    /**
     * Creates a FailureReasonDetails message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.FailureReasonDetails} FailureReasonDetails
     */
    FailureReasonDetails.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.FailureReasonDetails) return object
      let message = new $root.itempb.FailureReasonDetails()
      if (object.failureReasonId != null)
        message.failureReasonId = String(object.failureReasonId)
      if (object.failureReasonDescription != null)
        message.failureReasonDescription = String(
          object.failureReasonDescription
        )
      return message
    }

    /**
     * Creates a plain object from a FailureReasonDetails message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.FailureReasonDetails
     * @static
     * @param {itempb.FailureReasonDetails} message FailureReasonDetails
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FailureReasonDetails.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.failureReasonId = ''
        object.failureReasonDescription = ''
      }
      if (
        message.failureReasonId != null &&
        message.hasOwnProperty('failureReasonId')
      )
        object.failureReasonId = message.failureReasonId
      if (
        message.failureReasonDescription != null &&
        message.hasOwnProperty('failureReasonDescription')
      )
        object.failureReasonDescription = message.failureReasonDescription
      return object
    }

    /**
     * Converts this FailureReasonDetails to JSON.
     * @function toJSON
     * @memberof itempb.FailureReasonDetails
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FailureReasonDetails.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return FailureReasonDetails
  })()

  itempb.GetComponentFromItemIdRequest = (function () {
    /**
     * Properties of a GetComponentFromItemIdRequest.
     * @memberof itempb
     * @interface IGetComponentFromItemIdRequest
     * @property {string|null} [itemId] GetComponentFromItemIdRequest itemId
     * @property {string|null} [styleId] GetComponentFromItemIdRequest styleId
     */

    /**
     * Constructs a new GetComponentFromItemIdRequest.
     * @memberof itempb
     * @classdesc Represents a GetComponentFromItemIdRequest.
     * @implements IGetComponentFromItemIdRequest
     * @constructor
     * @param {itempb.IGetComponentFromItemIdRequest=} [properties] Properties to set
     */
    function GetComponentFromItemIdRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetComponentFromItemIdRequest itemId.
     * @member {string} itemId
     * @memberof itempb.GetComponentFromItemIdRequest
     * @instance
     */
    GetComponentFromItemIdRequest.prototype.itemId = ''

    /**
     * GetComponentFromItemIdRequest styleId.
     * @member {string} styleId
     * @memberof itempb.GetComponentFromItemIdRequest
     * @instance
     */
    GetComponentFromItemIdRequest.prototype.styleId = ''

    /**
     * Creates a new GetComponentFromItemIdRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {itempb.IGetComponentFromItemIdRequest=} [properties] Properties to set
     * @returns {itempb.GetComponentFromItemIdRequest} GetComponentFromItemIdRequest instance
     */
    GetComponentFromItemIdRequest.create = function create(properties) {
      return new GetComponentFromItemIdRequest(properties)
    }

    /**
     * Encodes the specified GetComponentFromItemIdRequest message. Does not implicitly {@link itempb.GetComponentFromItemIdRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {itempb.IGetComponentFromItemIdRequest} message GetComponentFromItemIdRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentFromItemIdRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemId != null &&
        Object.hasOwnProperty.call(message, 'itemId')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemId)
      if (
        message.styleId != null &&
        Object.hasOwnProperty.call(message, 'styleId')
      )
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.styleId)
      return writer
    }

    /**
     * Encodes the specified GetComponentFromItemIdRequest message, length delimited. Does not implicitly {@link itempb.GetComponentFromItemIdRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {itempb.IGetComponentFromItemIdRequest} message GetComponentFromItemIdRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentFromItemIdRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetComponentFromItemIdRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetComponentFromItemIdRequest} GetComponentFromItemIdRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentFromItemIdRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetComponentFromItemIdRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemId = reader.string()
            break
          case 2:
            message.styleId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetComponentFromItemIdRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetComponentFromItemIdRequest} GetComponentFromItemIdRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentFromItemIdRequest.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetComponentFromItemIdRequest message.
     * @function verify
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetComponentFromItemIdRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemId != null && message.hasOwnProperty('itemId'))
        if (!$util.isString(message.itemId)) return 'itemId: string expected'
      if (message.styleId != null && message.hasOwnProperty('styleId'))
        if (!$util.isString(message.styleId)) return 'styleId: string expected'
      return null
    }

    /**
     * Creates a GetComponentFromItemIdRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetComponentFromItemIdRequest} GetComponentFromItemIdRequest
     */
    GetComponentFromItemIdRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetComponentFromItemIdRequest)
        return object
      let message = new $root.itempb.GetComponentFromItemIdRequest()
      if (object.itemId != null) message.itemId = String(object.itemId)
      if (object.styleId != null) message.styleId = String(object.styleId)
      return message
    }

    /**
     * Creates a plain object from a GetComponentFromItemIdRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetComponentFromItemIdRequest
     * @static
     * @param {itempb.GetComponentFromItemIdRequest} message GetComponentFromItemIdRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetComponentFromItemIdRequest.toObject = function toObject(
      message,
      options
    ) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.itemId = ''
        object.styleId = ''
      }
      if (message.itemId != null && message.hasOwnProperty('itemId'))
        object.itemId = message.itemId
      if (message.styleId != null && message.hasOwnProperty('styleId'))
        object.styleId = message.styleId
      return object
    }

    /**
     * Converts this GetComponentFromItemIdRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetComponentFromItemIdRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetComponentFromItemIdRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetComponentFromItemIdRequest
  })()

  itempb.GetComponentFromItemIdResponse = (function () {
    /**
     * Properties of a GetComponentFromItemIdResponse.
     * @memberof itempb
     * @interface IGetComponentFromItemIdResponse
     * @property {Array.<itempb.IComponentDetails>|null} [componentDetails] GetComponentFromItemIdResponse componentDetails
     */

    /**
     * Constructs a new GetComponentFromItemIdResponse.
     * @memberof itempb
     * @classdesc Represents a GetComponentFromItemIdResponse.
     * @implements IGetComponentFromItemIdResponse
     * @constructor
     * @param {itempb.IGetComponentFromItemIdResponse=} [properties] Properties to set
     */
    function GetComponentFromItemIdResponse(properties) {
      this.componentDetails = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetComponentFromItemIdResponse componentDetails.
     * @member {Array.<itempb.IComponentDetails>} componentDetails
     * @memberof itempb.GetComponentFromItemIdResponse
     * @instance
     */
    GetComponentFromItemIdResponse.prototype.componentDetails = $util.emptyArray

    /**
     * Creates a new GetComponentFromItemIdResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {itempb.IGetComponentFromItemIdResponse=} [properties] Properties to set
     * @returns {itempb.GetComponentFromItemIdResponse} GetComponentFromItemIdResponse instance
     */
    GetComponentFromItemIdResponse.create = function create(properties) {
      return new GetComponentFromItemIdResponse(properties)
    }

    /**
     * Encodes the specified GetComponentFromItemIdResponse message. Does not implicitly {@link itempb.GetComponentFromItemIdResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {itempb.IGetComponentFromItemIdResponse} message GetComponentFromItemIdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentFromItemIdResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.componentDetails != null && message.componentDetails.length)
        for (let i = 0; i < message.componentDetails.length; ++i)
          $root.itempb.ComponentDetails.encode(
            message.componentDetails[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetComponentFromItemIdResponse message, length delimited. Does not implicitly {@link itempb.GetComponentFromItemIdResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {itempb.IGetComponentFromItemIdResponse} message GetComponentFromItemIdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentFromItemIdResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetComponentFromItemIdResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetComponentFromItemIdResponse} GetComponentFromItemIdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentFromItemIdResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetComponentFromItemIdResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.componentDetails && message.componentDetails.length))
              message.componentDetails = []
            message.componentDetails.push(
              $root.itempb.ComponentDetails.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetComponentFromItemIdResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetComponentFromItemIdResponse} GetComponentFromItemIdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentFromItemIdResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetComponentFromItemIdResponse message.
     * @function verify
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetComponentFromItemIdResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.componentDetails != null &&
        message.hasOwnProperty('componentDetails')
      ) {
        if (!Array.isArray(message.componentDetails))
          return 'componentDetails: array expected'
        for (let i = 0; i < message.componentDetails.length; ++i) {
          let error = $root.itempb.ComponentDetails.verify(
            message.componentDetails[i]
          )
          if (error) return 'componentDetails.' + error
        }
      }
      return null
    }

    /**
     * Creates a GetComponentFromItemIdResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetComponentFromItemIdResponse} GetComponentFromItemIdResponse
     */
    GetComponentFromItemIdResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetComponentFromItemIdResponse)
        return object
      let message = new $root.itempb.GetComponentFromItemIdResponse()
      if (object.componentDetails) {
        if (!Array.isArray(object.componentDetails))
          throw TypeError(
            '.itempb.GetComponentFromItemIdResponse.componentDetails: array expected'
          )
        message.componentDetails = []
        for (let i = 0; i < object.componentDetails.length; ++i) {
          if (typeof object.componentDetails[i] !== 'object')
            throw TypeError(
              '.itempb.GetComponentFromItemIdResponse.componentDetails: object expected'
            )
          message.componentDetails[
            i
          ] = $root.itempb.ComponentDetails.fromObject(
            object.componentDetails[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from a GetComponentFromItemIdResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetComponentFromItemIdResponse
     * @static
     * @param {itempb.GetComponentFromItemIdResponse} message GetComponentFromItemIdResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetComponentFromItemIdResponse.toObject = function toObject(
      message,
      options
    ) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.componentDetails = []
      if (message.componentDetails && message.componentDetails.length) {
        object.componentDetails = []
        for (let j = 0; j < message.componentDetails.length; ++j)
          object.componentDetails[j] = $root.itempb.ComponentDetails.toObject(
            message.componentDetails[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this GetComponentFromItemIdResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetComponentFromItemIdResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetComponentFromItemIdResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetComponentFromItemIdResponse
  })()

  itempb.GetComponentRequest = (function () {
    /**
     * Properties of a GetComponentRequest.
     * @memberof itempb
     * @interface IGetComponentRequest
     * @property {string|null} [componentId] GetComponentRequest componentId
     */

    /**
     * Constructs a new GetComponentRequest.
     * @memberof itempb
     * @classdesc Represents a GetComponentRequest.
     * @implements IGetComponentRequest
     * @constructor
     * @param {itempb.IGetComponentRequest=} [properties] Properties to set
     */
    function GetComponentRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetComponentRequest componentId.
     * @member {string} componentId
     * @memberof itempb.GetComponentRequest
     * @instance
     */
    GetComponentRequest.prototype.componentId = ''

    /**
     * Creates a new GetComponentRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {itempb.IGetComponentRequest=} [properties] Properties to set
     * @returns {itempb.GetComponentRequest} GetComponentRequest instance
     */
    GetComponentRequest.create = function create(properties) {
      return new GetComponentRequest(properties)
    }

    /**
     * Encodes the specified GetComponentRequest message. Does not implicitly {@link itempb.GetComponentRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {itempb.IGetComponentRequest} message GetComponentRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.componentId != null &&
        Object.hasOwnProperty.call(message, 'componentId')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.componentId)
      return writer
    }

    /**
     * Encodes the specified GetComponentRequest message, length delimited. Does not implicitly {@link itempb.GetComponentRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {itempb.IGetComponentRequest} message GetComponentRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetComponentRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetComponentRequest} GetComponentRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetComponentRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.componentId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetComponentRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetComponentRequest} GetComponentRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetComponentRequest message.
     * @function verify
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetComponentRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.componentId != null && message.hasOwnProperty('componentId'))
        if (!$util.isString(message.componentId))
          return 'componentId: string expected'
      return null
    }

    /**
     * Creates a GetComponentRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetComponentRequest} GetComponentRequest
     */
    GetComponentRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetComponentRequest) return object
      let message = new $root.itempb.GetComponentRequest()
      if (object.componentId != null)
        message.componentId = String(object.componentId)
      return message
    }

    /**
     * Creates a plain object from a GetComponentRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetComponentRequest
     * @static
     * @param {itempb.GetComponentRequest} message GetComponentRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetComponentRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.componentId = ''
      if (message.componentId != null && message.hasOwnProperty('componentId'))
        object.componentId = message.componentId
      return object
    }

    /**
     * Converts this GetComponentRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetComponentRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetComponentRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetComponentRequest
  })()

  itempb.GetComponentResponse = (function () {
    /**
     * Properties of a GetComponentResponse.
     * @memberof itempb
     * @interface IGetComponentResponse
     * @property {itempb.IComponentDetails|null} [componentDetails] GetComponentResponse componentDetails
     */

    /**
     * Constructs a new GetComponentResponse.
     * @memberof itempb
     * @classdesc Represents a GetComponentResponse.
     * @implements IGetComponentResponse
     * @constructor
     * @param {itempb.IGetComponentResponse=} [properties] Properties to set
     */
    function GetComponentResponse(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetComponentResponse componentDetails.
     * @member {itempb.IComponentDetails|null|undefined} componentDetails
     * @memberof itempb.GetComponentResponse
     * @instance
     */
    GetComponentResponse.prototype.componentDetails = null

    /**
     * Creates a new GetComponentResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {itempb.IGetComponentResponse=} [properties] Properties to set
     * @returns {itempb.GetComponentResponse} GetComponentResponse instance
     */
    GetComponentResponse.create = function create(properties) {
      return new GetComponentResponse(properties)
    }

    /**
     * Encodes the specified GetComponentResponse message. Does not implicitly {@link itempb.GetComponentResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {itempb.IGetComponentResponse} message GetComponentResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.componentDetails != null &&
        Object.hasOwnProperty.call(message, 'componentDetails')
      )
        $root.itempb.ComponentDetails.encode(
          message.componentDetails,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetComponentResponse message, length delimited. Does not implicitly {@link itempb.GetComponentResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {itempb.IGetComponentResponse} message GetComponentResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetComponentResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetComponentResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetComponentResponse} GetComponentResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetComponentResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.componentDetails = $root.itempb.ComponentDetails.decode(
              reader,
              reader.uint32()
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetComponentResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetComponentResponse} GetComponentResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetComponentResponse.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetComponentResponse message.
     * @function verify
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetComponentResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.componentDetails != null &&
        message.hasOwnProperty('componentDetails')
      ) {
        let error = $root.itempb.ComponentDetails.verify(
          message.componentDetails
        )
        if (error) return 'componentDetails.' + error
      }
      return null
    }

    /**
     * Creates a GetComponentResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetComponentResponse} GetComponentResponse
     */
    GetComponentResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetComponentResponse) return object
      let message = new $root.itempb.GetComponentResponse()
      if (object.componentDetails != null) {
        if (typeof object.componentDetails !== 'object')
          throw TypeError(
            '.itempb.GetComponentResponse.componentDetails: object expected'
          )
        message.componentDetails = $root.itempb.ComponentDetails.fromObject(
          object.componentDetails
        )
      }
      return message
    }

    /**
     * Creates a plain object from a GetComponentResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetComponentResponse
     * @static
     * @param {itempb.GetComponentResponse} message GetComponentResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetComponentResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.componentDetails = null
      if (
        message.componentDetails != null &&
        message.hasOwnProperty('componentDetails')
      )
        object.componentDetails = $root.itempb.ComponentDetails.toObject(
          message.componentDetails,
          options
        )
      return object
    }

    /**
     * Converts this GetComponentResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetComponentResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetComponentResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetComponentResponse
  })()

  itempb.GetCharacteristicRequest = (function () {
    /**
     * Properties of a GetCharacteristicRequest.
     * @memberof itempb
     * @interface IGetCharacteristicRequest
     * @property {string|null} [characteristicId] GetCharacteristicRequest characteristicId
     */

    /**
     * Constructs a new GetCharacteristicRequest.
     * @memberof itempb
     * @classdesc Represents a GetCharacteristicRequest.
     * @implements IGetCharacteristicRequest
     * @constructor
     * @param {itempb.IGetCharacteristicRequest=} [properties] Properties to set
     */
    function GetCharacteristicRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetCharacteristicRequest characteristicId.
     * @member {string} characteristicId
     * @memberof itempb.GetCharacteristicRequest
     * @instance
     */
    GetCharacteristicRequest.prototype.characteristicId = ''

    /**
     * Creates a new GetCharacteristicRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {itempb.IGetCharacteristicRequest=} [properties] Properties to set
     * @returns {itempb.GetCharacteristicRequest} GetCharacteristicRequest instance
     */
    GetCharacteristicRequest.create = function create(properties) {
      return new GetCharacteristicRequest(properties)
    }

    /**
     * Encodes the specified GetCharacteristicRequest message. Does not implicitly {@link itempb.GetCharacteristicRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {itempb.IGetCharacteristicRequest} message GetCharacteristicRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCharacteristicRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.characteristicId != null &&
        Object.hasOwnProperty.call(message, 'characteristicId')
      )
        writer
          .uint32(/* id 1, wireType 2 =*/ 10)
          .string(message.characteristicId)
      return writer
    }

    /**
     * Encodes the specified GetCharacteristicRequest message, length delimited. Does not implicitly {@link itempb.GetCharacteristicRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {itempb.IGetCharacteristicRequest} message GetCharacteristicRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCharacteristicRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetCharacteristicRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetCharacteristicRequest} GetCharacteristicRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCharacteristicRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetCharacteristicRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.characteristicId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetCharacteristicRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetCharacteristicRequest} GetCharacteristicRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCharacteristicRequest.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetCharacteristicRequest message.
     * @function verify
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetCharacteristicRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.characteristicId != null &&
        message.hasOwnProperty('characteristicId')
      )
        if (!$util.isString(message.characteristicId))
          return 'characteristicId: string expected'
      return null
    }

    /**
     * Creates a GetCharacteristicRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetCharacteristicRequest} GetCharacteristicRequest
     */
    GetCharacteristicRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetCharacteristicRequest) return object
      let message = new $root.itempb.GetCharacteristicRequest()
      if (object.characteristicId != null)
        message.characteristicId = String(object.characteristicId)
      return message
    }

    /**
     * Creates a plain object from a GetCharacteristicRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetCharacteristicRequest
     * @static
     * @param {itempb.GetCharacteristicRequest} message GetCharacteristicRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetCharacteristicRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.characteristicId = ''
      if (
        message.characteristicId != null &&
        message.hasOwnProperty('characteristicId')
      )
        object.characteristicId = message.characteristicId
      return object
    }

    /**
     * Converts this GetCharacteristicRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetCharacteristicRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetCharacteristicRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetCharacteristicRequest
  })()

  itempb.GetCharacteristicResponse = (function () {
    /**
     * Properties of a GetCharacteristicResponse.
     * @memberof itempb
     * @interface IGetCharacteristicResponse
     * @property {itempb.ICharacteristic|null} [characteristic] GetCharacteristicResponse characteristic
     */

    /**
     * Constructs a new GetCharacteristicResponse.
     * @memberof itempb
     * @classdesc Represents a GetCharacteristicResponse.
     * @implements IGetCharacteristicResponse
     * @constructor
     * @param {itempb.IGetCharacteristicResponse=} [properties] Properties to set
     */
    function GetCharacteristicResponse(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetCharacteristicResponse characteristic.
     * @member {itempb.ICharacteristic|null|undefined} characteristic
     * @memberof itempb.GetCharacteristicResponse
     * @instance
     */
    GetCharacteristicResponse.prototype.characteristic = null

    /**
     * Creates a new GetCharacteristicResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {itempb.IGetCharacteristicResponse=} [properties] Properties to set
     * @returns {itempb.GetCharacteristicResponse} GetCharacteristicResponse instance
     */
    GetCharacteristicResponse.create = function create(properties) {
      return new GetCharacteristicResponse(properties)
    }

    /**
     * Encodes the specified GetCharacteristicResponse message. Does not implicitly {@link itempb.GetCharacteristicResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {itempb.IGetCharacteristicResponse} message GetCharacteristicResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCharacteristicResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.characteristic != null &&
        Object.hasOwnProperty.call(message, 'characteristic')
      )
        $root.itempb.Characteristic.encode(
          message.characteristic,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetCharacteristicResponse message, length delimited. Does not implicitly {@link itempb.GetCharacteristicResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {itempb.IGetCharacteristicResponse} message GetCharacteristicResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCharacteristicResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetCharacteristicResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetCharacteristicResponse} GetCharacteristicResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCharacteristicResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetCharacteristicResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.characteristic = $root.itempb.Characteristic.decode(
              reader,
              reader.uint32()
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetCharacteristicResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetCharacteristicResponse} GetCharacteristicResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCharacteristicResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetCharacteristicResponse message.
     * @function verify
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetCharacteristicResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.characteristic != null &&
        message.hasOwnProperty('characteristic')
      ) {
        let error = $root.itempb.Characteristic.verify(message.characteristic)
        if (error) return 'characteristic.' + error
      }
      return null
    }

    /**
     * Creates a GetCharacteristicResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetCharacteristicResponse} GetCharacteristicResponse
     */
    GetCharacteristicResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetCharacteristicResponse)
        return object
      let message = new $root.itempb.GetCharacteristicResponse()
      if (object.characteristic != null) {
        if (typeof object.characteristic !== 'object')
          throw TypeError(
            '.itempb.GetCharacteristicResponse.characteristic: object expected'
          )
        message.characteristic = $root.itempb.Characteristic.fromObject(
          object.characteristic
        )
      }
      return message
    }

    /**
     * Creates a plain object from a GetCharacteristicResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetCharacteristicResponse
     * @static
     * @param {itempb.GetCharacteristicResponse} message GetCharacteristicResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetCharacteristicResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.characteristic = null
      if (
        message.characteristic != null &&
        message.hasOwnProperty('characteristic')
      )
        object.characteristic = $root.itempb.Characteristic.toObject(
          message.characteristic,
          options
        )
      return object
    }

    /**
     * Converts this GetCharacteristicResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetCharacteristicResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetCharacteristicResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetCharacteristicResponse
  })()

  itempb.GetFailureReasonRequest = (function () {
    /**
     * Properties of a GetFailureReasonRequest.
     * @memberof itempb
     * @interface IGetFailureReasonRequest
     * @property {string|null} [failureReasonId] GetFailureReasonRequest failureReasonId
     */

    /**
     * Constructs a new GetFailureReasonRequest.
     * @memberof itempb
     * @classdesc Represents a GetFailureReasonRequest.
     * @implements IGetFailureReasonRequest
     * @constructor
     * @param {itempb.IGetFailureReasonRequest=} [properties] Properties to set
     */
    function GetFailureReasonRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetFailureReasonRequest failureReasonId.
     * @member {string} failureReasonId
     * @memberof itempb.GetFailureReasonRequest
     * @instance
     */
    GetFailureReasonRequest.prototype.failureReasonId = ''

    /**
     * Creates a new GetFailureReasonRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {itempb.IGetFailureReasonRequest=} [properties] Properties to set
     * @returns {itempb.GetFailureReasonRequest} GetFailureReasonRequest instance
     */
    GetFailureReasonRequest.create = function create(properties) {
      return new GetFailureReasonRequest(properties)
    }

    /**
     * Encodes the specified GetFailureReasonRequest message. Does not implicitly {@link itempb.GetFailureReasonRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {itempb.IGetFailureReasonRequest} message GetFailureReasonRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.failureReasonId != null &&
        Object.hasOwnProperty.call(message, 'failureReasonId')
      )
        writer
          .uint32(/* id 1, wireType 2 =*/ 10)
          .string(message.failureReasonId)
      return writer
    }

    /**
     * Encodes the specified GetFailureReasonRequest message, length delimited. Does not implicitly {@link itempb.GetFailureReasonRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {itempb.IGetFailureReasonRequest} message GetFailureReasonRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetFailureReasonRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetFailureReasonRequest} GetFailureReasonRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetFailureReasonRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.failureReasonId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetFailureReasonRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetFailureReasonRequest} GetFailureReasonRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonRequest.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetFailureReasonRequest message.
     * @function verify
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetFailureReasonRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.failureReasonId != null &&
        message.hasOwnProperty('failureReasonId')
      )
        if (!$util.isString(message.failureReasonId))
          return 'failureReasonId: string expected'
      return null
    }

    /**
     * Creates a GetFailureReasonRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetFailureReasonRequest} GetFailureReasonRequest
     */
    GetFailureReasonRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetFailureReasonRequest) return object
      let message = new $root.itempb.GetFailureReasonRequest()
      if (object.failureReasonId != null)
        message.failureReasonId = String(object.failureReasonId)
      return message
    }

    /**
     * Creates a plain object from a GetFailureReasonRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetFailureReasonRequest
     * @static
     * @param {itempb.GetFailureReasonRequest} message GetFailureReasonRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetFailureReasonRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.failureReasonId = ''
      if (
        message.failureReasonId != null &&
        message.hasOwnProperty('failureReasonId')
      )
        object.failureReasonId = message.failureReasonId
      return object
    }

    /**
     * Converts this GetFailureReasonRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetFailureReasonRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetFailureReasonRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetFailureReasonRequest
  })()

  itempb.GetFailureReasonResponse = (function () {
    /**
     * Properties of a GetFailureReasonResponse.
     * @memberof itempb
     * @interface IGetFailureReasonResponse
     * @property {itempb.IFailureReasonDetails|null} [failureReasonDetails] GetFailureReasonResponse failureReasonDetails
     */

    /**
     * Constructs a new GetFailureReasonResponse.
     * @memberof itempb
     * @classdesc Represents a GetFailureReasonResponse.
     * @implements IGetFailureReasonResponse
     * @constructor
     * @param {itempb.IGetFailureReasonResponse=} [properties] Properties to set
     */
    function GetFailureReasonResponse(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetFailureReasonResponse failureReasonDetails.
     * @member {itempb.IFailureReasonDetails|null|undefined} failureReasonDetails
     * @memberof itempb.GetFailureReasonResponse
     * @instance
     */
    GetFailureReasonResponse.prototype.failureReasonDetails = null

    /**
     * Creates a new GetFailureReasonResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {itempb.IGetFailureReasonResponse=} [properties] Properties to set
     * @returns {itempb.GetFailureReasonResponse} GetFailureReasonResponse instance
     */
    GetFailureReasonResponse.create = function create(properties) {
      return new GetFailureReasonResponse(properties)
    }

    /**
     * Encodes the specified GetFailureReasonResponse message. Does not implicitly {@link itempb.GetFailureReasonResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {itempb.IGetFailureReasonResponse} message GetFailureReasonResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.failureReasonDetails != null &&
        Object.hasOwnProperty.call(message, 'failureReasonDetails')
      )
        $root.itempb.FailureReasonDetails.encode(
          message.failureReasonDetails,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetFailureReasonResponse message, length delimited. Does not implicitly {@link itempb.GetFailureReasonResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {itempb.IGetFailureReasonResponse} message GetFailureReasonResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetFailureReasonResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetFailureReasonResponse} GetFailureReasonResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetFailureReasonResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.failureReasonDetails = $root.itempb.FailureReasonDetails.decode(
              reader,
              reader.uint32()
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetFailureReasonResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetFailureReasonResponse} GetFailureReasonResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetFailureReasonResponse message.
     * @function verify
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetFailureReasonResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.failureReasonDetails != null &&
        message.hasOwnProperty('failureReasonDetails')
      ) {
        let error = $root.itempb.FailureReasonDetails.verify(
          message.failureReasonDetails
        )
        if (error) return 'failureReasonDetails.' + error
      }
      return null
    }

    /**
     * Creates a GetFailureReasonResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetFailureReasonResponse} GetFailureReasonResponse
     */
    GetFailureReasonResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetFailureReasonResponse) return object
      let message = new $root.itempb.GetFailureReasonResponse()
      if (object.failureReasonDetails != null) {
        if (typeof object.failureReasonDetails !== 'object')
          throw TypeError(
            '.itempb.GetFailureReasonResponse.failureReasonDetails: object expected'
          )
        message.failureReasonDetails = $root.itempb.FailureReasonDetails.fromObject(
          object.failureReasonDetails
        )
      }
      return message
    }

    /**
     * Creates a plain object from a GetFailureReasonResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetFailureReasonResponse
     * @static
     * @param {itempb.GetFailureReasonResponse} message GetFailureReasonResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetFailureReasonResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.failureReasonDetails = null
      if (
        message.failureReasonDetails != null &&
        message.hasOwnProperty('failureReasonDetails')
      )
        object.failureReasonDetails = $root.itempb.FailureReasonDetails.toObject(
          message.failureReasonDetails,
          options
        )
      return object
    }

    /**
     * Converts this GetFailureReasonResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetFailureReasonResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetFailureReasonResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetFailureReasonResponse
  })()

  itempb.GetFailureReasonByItemIdRequest = (function () {
    /**
     * Properties of a GetFailureReasonByItemIdRequest.
     * @memberof itempb
     * @interface IGetFailureReasonByItemIdRequest
     * @property {string|null} [itemId] GetFailureReasonByItemIdRequest itemId
     */

    /**
     * Constructs a new GetFailureReasonByItemIdRequest.
     * @memberof itempb
     * @classdesc Represents a GetFailureReasonByItemIdRequest.
     * @implements IGetFailureReasonByItemIdRequest
     * @constructor
     * @param {itempb.IGetFailureReasonByItemIdRequest=} [properties] Properties to set
     */
    function GetFailureReasonByItemIdRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetFailureReasonByItemIdRequest itemId.
     * @member {string} itemId
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @instance
     */
    GetFailureReasonByItemIdRequest.prototype.itemId = ''

    /**
     * Creates a new GetFailureReasonByItemIdRequest instance using the specified properties.
     * @function create
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {itempb.IGetFailureReasonByItemIdRequest=} [properties] Properties to set
     * @returns {itempb.GetFailureReasonByItemIdRequest} GetFailureReasonByItemIdRequest instance
     */
    GetFailureReasonByItemIdRequest.create = function create(properties) {
      return new GetFailureReasonByItemIdRequest(properties)
    }

    /**
     * Encodes the specified GetFailureReasonByItemIdRequest message. Does not implicitly {@link itempb.GetFailureReasonByItemIdRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {itempb.IGetFailureReasonByItemIdRequest} message GetFailureReasonByItemIdRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonByItemIdRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.itemId != null &&
        Object.hasOwnProperty.call(message, 'itemId')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.itemId)
      return writer
    }

    /**
     * Encodes the specified GetFailureReasonByItemIdRequest message, length delimited. Does not implicitly {@link itempb.GetFailureReasonByItemIdRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {itempb.IGetFailureReasonByItemIdRequest} message GetFailureReasonByItemIdRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonByItemIdRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetFailureReasonByItemIdRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetFailureReasonByItemIdRequest} GetFailureReasonByItemIdRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonByItemIdRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetFailureReasonByItemIdRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.itemId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetFailureReasonByItemIdRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetFailureReasonByItemIdRequest} GetFailureReasonByItemIdRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonByItemIdRequest.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetFailureReasonByItemIdRequest message.
     * @function verify
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetFailureReasonByItemIdRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.itemId != null && message.hasOwnProperty('itemId'))
        if (!$util.isString(message.itemId)) return 'itemId: string expected'
      return null
    }

    /**
     * Creates a GetFailureReasonByItemIdRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetFailureReasonByItemIdRequest} GetFailureReasonByItemIdRequest
     */
    GetFailureReasonByItemIdRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetFailureReasonByItemIdRequest)
        return object
      let message = new $root.itempb.GetFailureReasonByItemIdRequest()
      if (object.itemId != null) message.itemId = String(object.itemId)
      return message
    }

    /**
     * Creates a plain object from a GetFailureReasonByItemIdRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @static
     * @param {itempb.GetFailureReasonByItemIdRequest} message GetFailureReasonByItemIdRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetFailureReasonByItemIdRequest.toObject = function toObject(
      message,
      options
    ) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.itemId = ''
      if (message.itemId != null && message.hasOwnProperty('itemId'))
        object.itemId = message.itemId
      return object
    }

    /**
     * Converts this GetFailureReasonByItemIdRequest to JSON.
     * @function toJSON
     * @memberof itempb.GetFailureReasonByItemIdRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetFailureReasonByItemIdRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetFailureReasonByItemIdRequest
  })()

  itempb.GetFailureReasonByItemIdResponse = (function () {
    /**
     * Properties of a GetFailureReasonByItemIdResponse.
     * @memberof itempb
     * @interface IGetFailureReasonByItemIdResponse
     * @property {Array.<itempb.IFailureReasonDetails>|null} [failureReasonDetails] GetFailureReasonByItemIdResponse failureReasonDetails
     */

    /**
     * Constructs a new GetFailureReasonByItemIdResponse.
     * @memberof itempb
     * @classdesc Represents a GetFailureReasonByItemIdResponse.
     * @implements IGetFailureReasonByItemIdResponse
     * @constructor
     * @param {itempb.IGetFailureReasonByItemIdResponse=} [properties] Properties to set
     */
    function GetFailureReasonByItemIdResponse(properties) {
      this.failureReasonDetails = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * GetFailureReasonByItemIdResponse failureReasonDetails.
     * @member {Array.<itempb.IFailureReasonDetails>} failureReasonDetails
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @instance
     */
    GetFailureReasonByItemIdResponse.prototype.failureReasonDetails =
      $util.emptyArray

    /**
     * Creates a new GetFailureReasonByItemIdResponse instance using the specified properties.
     * @function create
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {itempb.IGetFailureReasonByItemIdResponse=} [properties] Properties to set
     * @returns {itempb.GetFailureReasonByItemIdResponse} GetFailureReasonByItemIdResponse instance
     */
    GetFailureReasonByItemIdResponse.create = function create(properties) {
      return new GetFailureReasonByItemIdResponse(properties)
    }

    /**
     * Encodes the specified GetFailureReasonByItemIdResponse message. Does not implicitly {@link itempb.GetFailureReasonByItemIdResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {itempb.IGetFailureReasonByItemIdResponse} message GetFailureReasonByItemIdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonByItemIdResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.failureReasonDetails != null &&
        message.failureReasonDetails.length
      )
        for (let i = 0; i < message.failureReasonDetails.length; ++i)
          $root.itempb.FailureReasonDetails.encode(
            message.failureReasonDetails[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified GetFailureReasonByItemIdResponse message, length delimited. Does not implicitly {@link itempb.GetFailureReasonByItemIdResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {itempb.IGetFailureReasonByItemIdResponse} message GetFailureReasonByItemIdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetFailureReasonByItemIdResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a GetFailureReasonByItemIdResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.GetFailureReasonByItemIdResponse} GetFailureReasonByItemIdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonByItemIdResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.GetFailureReasonByItemIdResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (
              !(
                message.failureReasonDetails &&
                message.failureReasonDetails.length
              )
            )
              message.failureReasonDetails = []
            message.failureReasonDetails.push(
              $root.itempb.FailureReasonDetails.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a GetFailureReasonByItemIdResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.GetFailureReasonByItemIdResponse} GetFailureReasonByItemIdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetFailureReasonByItemIdResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a GetFailureReasonByItemIdResponse message.
     * @function verify
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetFailureReasonByItemIdResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.failureReasonDetails != null &&
        message.hasOwnProperty('failureReasonDetails')
      ) {
        if (!Array.isArray(message.failureReasonDetails))
          return 'failureReasonDetails: array expected'
        for (let i = 0; i < message.failureReasonDetails.length; ++i) {
          let error = $root.itempb.FailureReasonDetails.verify(
            message.failureReasonDetails[i]
          )
          if (error) return 'failureReasonDetails.' + error
        }
      }
      return null
    }

    /**
     * Creates a GetFailureReasonByItemIdResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.GetFailureReasonByItemIdResponse} GetFailureReasonByItemIdResponse
     */
    GetFailureReasonByItemIdResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.GetFailureReasonByItemIdResponse)
        return object
      let message = new $root.itempb.GetFailureReasonByItemIdResponse()
      if (object.failureReasonDetails) {
        if (!Array.isArray(object.failureReasonDetails))
          throw TypeError(
            '.itempb.GetFailureReasonByItemIdResponse.failureReasonDetails: array expected'
          )
        message.failureReasonDetails = []
        for (let i = 0; i < object.failureReasonDetails.length; ++i) {
          if (typeof object.failureReasonDetails[i] !== 'object')
            throw TypeError(
              '.itempb.GetFailureReasonByItemIdResponse.failureReasonDetails: object expected'
            )
          message.failureReasonDetails[
            i
          ] = $root.itempb.FailureReasonDetails.fromObject(
            object.failureReasonDetails[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from a GetFailureReasonByItemIdResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @static
     * @param {itempb.GetFailureReasonByItemIdResponse} message GetFailureReasonByItemIdResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetFailureReasonByItemIdResponse.toObject = function toObject(
      message,
      options
    ) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.failureReasonDetails = []
      if (message.failureReasonDetails && message.failureReasonDetails.length) {
        object.failureReasonDetails = []
        for (let j = 0; j < message.failureReasonDetails.length; ++j)
          object.failureReasonDetails[
            j
          ] = $root.itempb.FailureReasonDetails.toObject(
            message.failureReasonDetails[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this GetFailureReasonByItemIdResponse to JSON.
     * @function toJSON
     * @memberof itempb.GetFailureReasonByItemIdResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetFailureReasonByItemIdResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return GetFailureReasonByItemIdResponse
  })()

  itempb.ItemByLegacyItemIdRequest = (function () {
    /**
     * Properties of an ItemByLegacyItemIdRequest.
     * @memberof itempb
     * @interface IItemByLegacyItemIdRequest
     * @property {string|null} [legacyItemId] ItemByLegacyItemIdRequest legacyItemId
     */

    /**
     * Constructs a new ItemByLegacyItemIdRequest.
     * @memberof itempb
     * @classdesc Represents an ItemByLegacyItemIdRequest.
     * @implements IItemByLegacyItemIdRequest
     * @constructor
     * @param {itempb.IItemByLegacyItemIdRequest=} [properties] Properties to set
     */
    function ItemByLegacyItemIdRequest(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemByLegacyItemIdRequest legacyItemId.
     * @member {string} legacyItemId
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @instance
     */
    ItemByLegacyItemIdRequest.prototype.legacyItemId = ''

    /**
     * Creates a new ItemByLegacyItemIdRequest instance using the specified properties.
     * @function create
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {itempb.IItemByLegacyItemIdRequest=} [properties] Properties to set
     * @returns {itempb.ItemByLegacyItemIdRequest} ItemByLegacyItemIdRequest instance
     */
    ItemByLegacyItemIdRequest.create = function create(properties) {
      return new ItemByLegacyItemIdRequest(properties)
    }

    /**
     * Encodes the specified ItemByLegacyItemIdRequest message. Does not implicitly {@link itempb.ItemByLegacyItemIdRequest.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {itempb.IItemByLegacyItemIdRequest} message ItemByLegacyItemIdRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemByLegacyItemIdRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (
        message.legacyItemId != null &&
        Object.hasOwnProperty.call(message, 'legacyItemId')
      )
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.legacyItemId)
      return writer
    }

    /**
     * Encodes the specified ItemByLegacyItemIdRequest message, length delimited. Does not implicitly {@link itempb.ItemByLegacyItemIdRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {itempb.IItemByLegacyItemIdRequest} message ItemByLegacyItemIdRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemByLegacyItemIdRequest.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemByLegacyItemIdRequest message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemByLegacyItemIdRequest} ItemByLegacyItemIdRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemByLegacyItemIdRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemByLegacyItemIdRequest()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.legacyItemId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemByLegacyItemIdRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemByLegacyItemIdRequest} ItemByLegacyItemIdRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemByLegacyItemIdRequest.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemByLegacyItemIdRequest message.
     * @function verify
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemByLegacyItemIdRequest.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (
        message.legacyItemId != null &&
        message.hasOwnProperty('legacyItemId')
      )
        if (!$util.isString(message.legacyItemId))
          return 'legacyItemId: string expected'
      return null
    }

    /**
     * Creates an ItemByLegacyItemIdRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemByLegacyItemIdRequest} ItemByLegacyItemIdRequest
     */
    ItemByLegacyItemIdRequest.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemByLegacyItemIdRequest)
        return object
      let message = new $root.itempb.ItemByLegacyItemIdRequest()
      if (object.legacyItemId != null)
        message.legacyItemId = String(object.legacyItemId)
      return message
    }

    /**
     * Creates a plain object from an ItemByLegacyItemIdRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @static
     * @param {itempb.ItemByLegacyItemIdRequest} message ItemByLegacyItemIdRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemByLegacyItemIdRequest.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.legacyItemId = ''
      if (
        message.legacyItemId != null &&
        message.hasOwnProperty('legacyItemId')
      )
        object.legacyItemId = message.legacyItemId
      return object
    }

    /**
     * Converts this ItemByLegacyItemIdRequest to JSON.
     * @function toJSON
     * @memberof itempb.ItemByLegacyItemIdRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemByLegacyItemIdRequest.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemByLegacyItemIdRequest
  })()

  itempb.ItemWithAttributes = (function () {
    /**
     * Properties of an ItemWithAttributes.
     * @memberof itempb
     * @interface IItemWithAttributes
     * @property {itempb.IItem|null} [item] ItemWithAttributes item
     * @property {Array.<itempb.IItemAttribute>|null} [attributes] ItemWithAttributes attributes
     */

    /**
     * Constructs a new ItemWithAttributes.
     * @memberof itempb
     * @classdesc Represents an ItemWithAttributes.
     * @implements IItemWithAttributes
     * @constructor
     * @param {itempb.IItemWithAttributes=} [properties] Properties to set
     */
    function ItemWithAttributes(properties) {
      this.attributes = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemWithAttributes item.
     * @member {itempb.IItem|null|undefined} item
     * @memberof itempb.ItemWithAttributes
     * @instance
     */
    ItemWithAttributes.prototype.item = null

    /**
     * ItemWithAttributes attributes.
     * @member {Array.<itempb.IItemAttribute>} attributes
     * @memberof itempb.ItemWithAttributes
     * @instance
     */
    ItemWithAttributes.prototype.attributes = $util.emptyArray

    /**
     * Creates a new ItemWithAttributes instance using the specified properties.
     * @function create
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {itempb.IItemWithAttributes=} [properties] Properties to set
     * @returns {itempb.ItemWithAttributes} ItemWithAttributes instance
     */
    ItemWithAttributes.create = function create(properties) {
      return new ItemWithAttributes(properties)
    }

    /**
     * Encodes the specified ItemWithAttributes message. Does not implicitly {@link itempb.ItemWithAttributes.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {itempb.IItemWithAttributes} message ItemWithAttributes message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemWithAttributes.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.item != null && Object.hasOwnProperty.call(message, 'item'))
        $root.itempb.Item.encode(
          message.item,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim()
      if (message.attributes != null && message.attributes.length)
        for (let i = 0; i < message.attributes.length; ++i)
          $root.itempb.ItemAttribute.encode(
            message.attributes[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified ItemWithAttributes message, length delimited. Does not implicitly {@link itempb.ItemWithAttributes.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {itempb.IItemWithAttributes} message ItemWithAttributes message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemWithAttributes.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemWithAttributes message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemWithAttributes} ItemWithAttributes
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemWithAttributes.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemWithAttributes()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.item = $root.itempb.Item.decode(reader, reader.uint32())
            break
          case 2:
            if (!(message.attributes && message.attributes.length))
              message.attributes = []
            message.attributes.push(
              $root.itempb.ItemAttribute.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemWithAttributes message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemWithAttributes} ItemWithAttributes
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemWithAttributes.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemWithAttributes message.
     * @function verify
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemWithAttributes.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.item != null && message.hasOwnProperty('item')) {
        let error = $root.itempb.Item.verify(message.item)
        if (error) return 'item.' + error
      }
      if (message.attributes != null && message.hasOwnProperty('attributes')) {
        if (!Array.isArray(message.attributes))
          return 'attributes: array expected'
        for (let i = 0; i < message.attributes.length; ++i) {
          let error = $root.itempb.ItemAttribute.verify(message.attributes[i])
          if (error) return 'attributes.' + error
        }
      }
      return null
    }

    /**
     * Creates an ItemWithAttributes message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemWithAttributes} ItemWithAttributes
     */
    ItemWithAttributes.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemWithAttributes) return object
      let message = new $root.itempb.ItemWithAttributes()
      if (object.item != null) {
        if (typeof object.item !== 'object')
          throw TypeError('.itempb.ItemWithAttributes.item: object expected')
        message.item = $root.itempb.Item.fromObject(object.item)
      }
      if (object.attributes) {
        if (!Array.isArray(object.attributes))
          throw TypeError(
            '.itempb.ItemWithAttributes.attributes: array expected'
          )
        message.attributes = []
        for (let i = 0; i < object.attributes.length; ++i) {
          if (typeof object.attributes[i] !== 'object')
            throw TypeError(
              '.itempb.ItemWithAttributes.attributes: object expected'
            )
          message.attributes[i] = $root.itempb.ItemAttribute.fromObject(
            object.attributes[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from an ItemWithAttributes message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemWithAttributes
     * @static
     * @param {itempb.ItemWithAttributes} message ItemWithAttributes
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemWithAttributes.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.attributes = []
      if (options.defaults) object.item = null
      if (message.item != null && message.hasOwnProperty('item'))
        object.item = $root.itempb.Item.toObject(message.item, options)
      if (message.attributes && message.attributes.length) {
        object.attributes = []
        for (let j = 0; j < message.attributes.length; ++j)
          object.attributes[j] = $root.itempb.ItemAttribute.toObject(
            message.attributes[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this ItemWithAttributes to JSON.
     * @function toJSON
     * @memberof itempb.ItemWithAttributes
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemWithAttributes.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemWithAttributes
  })()

  itempb.ItemByLegacyItemIdResponse = (function () {
    /**
     * Properties of an ItemByLegacyItemIdResponse.
     * @memberof itempb
     * @interface IItemByLegacyItemIdResponse
     * @property {Array.<itempb.IItem>|null} [items] ItemByLegacyItemIdResponse items
     * @property {Array.<itempb.IItemWithAttributes>|null} [itemListWithAttributes] ItemByLegacyItemIdResponse itemListWithAttributes
     */

    /**
     * Constructs a new ItemByLegacyItemIdResponse.
     * @memberof itempb
     * @classdesc Represents an ItemByLegacyItemIdResponse.
     * @implements IItemByLegacyItemIdResponse
     * @constructor
     * @param {itempb.IItemByLegacyItemIdResponse=} [properties] Properties to set
     */
    function ItemByLegacyItemIdResponse(properties) {
      this.items = []
      this.itemListWithAttributes = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * ItemByLegacyItemIdResponse items.
     * @member {Array.<itempb.IItem>} items
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @instance
     */
    ItemByLegacyItemIdResponse.prototype.items = $util.emptyArray

    /**
     * ItemByLegacyItemIdResponse itemListWithAttributes.
     * @member {Array.<itempb.IItemWithAttributes>} itemListWithAttributes
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @instance
     */
    ItemByLegacyItemIdResponse.prototype.itemListWithAttributes =
      $util.emptyArray

    /**
     * Creates a new ItemByLegacyItemIdResponse instance using the specified properties.
     * @function create
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {itempb.IItemByLegacyItemIdResponse=} [properties] Properties to set
     * @returns {itempb.ItemByLegacyItemIdResponse} ItemByLegacyItemIdResponse instance
     */
    ItemByLegacyItemIdResponse.create = function create(properties) {
      return new ItemByLegacyItemIdResponse(properties)
    }

    /**
     * Encodes the specified ItemByLegacyItemIdResponse message. Does not implicitly {@link itempb.ItemByLegacyItemIdResponse.verify|verify} messages.
     * @function encode
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {itempb.IItemByLegacyItemIdResponse} message ItemByLegacyItemIdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemByLegacyItemIdResponse.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.items != null && message.items.length)
        for (let i = 0; i < message.items.length; ++i)
          $root.itempb.Item.encode(
            message.items[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      if (
        message.itemListWithAttributes != null &&
        message.itemListWithAttributes.length
      )
        for (let i = 0; i < message.itemListWithAttributes.length; ++i)
          $root.itempb.ItemWithAttributes.encode(
            message.itemListWithAttributes[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified ItemByLegacyItemIdResponse message, length delimited. Does not implicitly {@link itempb.ItemByLegacyItemIdResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {itempb.IItemByLegacyItemIdResponse} message ItemByLegacyItemIdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ItemByLegacyItemIdResponse.encodeDelimited = function encodeDelimited(
      message,
      writer
    ) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an ItemByLegacyItemIdResponse message from the specified reader or buffer.
     * @function decode
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {itempb.ItemByLegacyItemIdResponse} ItemByLegacyItemIdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemByLegacyItemIdResponse.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.itempb.ItemByLegacyItemIdResponse()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.items && message.items.length)) message.items = []
            message.items.push(
              $root.itempb.Item.decode(reader, reader.uint32())
            )
            break
          case 2:
            if (
              !(
                message.itemListWithAttributes &&
                message.itemListWithAttributes.length
              )
            )
              message.itemListWithAttributes = []
            message.itemListWithAttributes.push(
              $root.itempb.ItemWithAttributes.decode(reader, reader.uint32())
            )
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an ItemByLegacyItemIdResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {itempb.ItemByLegacyItemIdResponse} ItemByLegacyItemIdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ItemByLegacyItemIdResponse.decodeDelimited = function decodeDelimited(
      reader
    ) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an ItemByLegacyItemIdResponse message.
     * @function verify
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ItemByLegacyItemIdResponse.verify = function verify(message) {
      if (typeof message !== 'object' || message === null)
        return 'object expected'
      if (message.items != null && message.hasOwnProperty('items')) {
        if (!Array.isArray(message.items)) return 'items: array expected'
        for (let i = 0; i < message.items.length; ++i) {
          let error = $root.itempb.Item.verify(message.items[i])
          if (error) return 'items.' + error
        }
      }
      if (
        message.itemListWithAttributes != null &&
        message.hasOwnProperty('itemListWithAttributes')
      ) {
        if (!Array.isArray(message.itemListWithAttributes))
          return 'itemListWithAttributes: array expected'
        for (let i = 0; i < message.itemListWithAttributes.length; ++i) {
          let error = $root.itempb.ItemWithAttributes.verify(
            message.itemListWithAttributes[i]
          )
          if (error) return 'itemListWithAttributes.' + error
        }
      }
      return null
    }

    /**
     * Creates an ItemByLegacyItemIdResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {itempb.ItemByLegacyItemIdResponse} ItemByLegacyItemIdResponse
     */
    ItemByLegacyItemIdResponse.fromObject = function fromObject(object) {
      if (object instanceof $root.itempb.ItemByLegacyItemIdResponse)
        return object
      let message = new $root.itempb.ItemByLegacyItemIdResponse()
      if (object.items) {
        if (!Array.isArray(object.items))
          throw TypeError(
            '.itempb.ItemByLegacyItemIdResponse.items: array expected'
          )
        message.items = []
        for (let i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== 'object')
            throw TypeError(
              '.itempb.ItemByLegacyItemIdResponse.items: object expected'
            )
          message.items[i] = $root.itempb.Item.fromObject(object.items[i])
        }
      }
      if (object.itemListWithAttributes) {
        if (!Array.isArray(object.itemListWithAttributes))
          throw TypeError(
            '.itempb.ItemByLegacyItemIdResponse.itemListWithAttributes: array expected'
          )
        message.itemListWithAttributes = []
        for (let i = 0; i < object.itemListWithAttributes.length; ++i) {
          if (typeof object.itemListWithAttributes[i] !== 'object')
            throw TypeError(
              '.itempb.ItemByLegacyItemIdResponse.itemListWithAttributes: object expected'
            )
          message.itemListWithAttributes[
            i
          ] = $root.itempb.ItemWithAttributes.fromObject(
            object.itemListWithAttributes[i]
          )
        }
      }
      return message
    }

    /**
     * Creates a plain object from an ItemByLegacyItemIdResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @static
     * @param {itempb.ItemByLegacyItemIdResponse} message ItemByLegacyItemIdResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ItemByLegacyItemIdResponse.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) {
        object.items = []
        object.itemListWithAttributes = []
      }
      if (message.items && message.items.length) {
        object.items = []
        for (let j = 0; j < message.items.length; ++j)
          object.items[j] = $root.itempb.Item.toObject(
            message.items[j],
            options
          )
      }
      if (
        message.itemListWithAttributes &&
        message.itemListWithAttributes.length
      ) {
        object.itemListWithAttributes = []
        for (let j = 0; j < message.itemListWithAttributes.length; ++j)
          object.itemListWithAttributes[
            j
          ] = $root.itempb.ItemWithAttributes.toObject(
            message.itemListWithAttributes[j],
            options
          )
      }
      return object
    }

    /**
     * Converts this ItemByLegacyItemIdResponse to JSON.
     * @function toJSON
     * @memberof itempb.ItemByLegacyItemIdResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ItemByLegacyItemIdResponse.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return ItemByLegacyItemIdResponse
  })()

  return itempb
})())

export { $root as default }
