import { Reducer } from 'redux'
import {
  ParsedFilter,
  StateAwareThunkDispatch,
  ThunkResult,
} from 'src/utils/shared-types'
import { api } from '../../utils/api'
import { FiltersAction, FiltersStoreState } from './filters-store.types'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'

export const filtersInitialState: FiltersStoreState = {
  filters: [],
  filtersError: null,
  itemFilters: [],
  selected: {},
}
const getInitialSelected = (filters: ParsedFilter[]) => {
  let initSelected = {}
  filters.forEach(({ name }) => (initSelected[name] = []))
  return initSelected
}

export const filtersReducer: Reducer<FiltersStoreState, FiltersAction> = (
  state = filtersInitialState,
  action: FiltersAction
): FiltersStoreState => {
  switch (action.type) {
    case 'GET_FILTERS':
      return {
        ...state,
        selected: getInitialSelected(action.filters),
        filters: action.filters,
      }
    case 'GET_FILTERS_ERROR':
      return {
        ...state,
        filters: [],
        filtersError: action.error,
      }

    case 'GET_ITEM_FILTERS':
      return { ...state, itemFilters: action.itemFilters }
    case 'GET_ITEM_FILTERS_ERROR':
      return { ...state, itemFilters: [] }

    case 'SELECT_FILTER':
      let filters
      if (state.selected[action.name]?.length) {
        filters = state.selected?.[action.name]?.includes(action.value)
          ? [
              ...state.selected?.[action.name]?.filter(
                (v) => v !== action.value
              ),
            ]
          : [...state.selected?.[action.name], action.value]
      } else {
        filters = [action.value]
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          [action.name]: filters,
        },
      }

    case 'CLEAR_FILTER': {
      if (state.selected[action.name]) {
        const selected = { ...state.selected, [action.name]: [] }
        return { ...state, selected }
      }
      return state
    }

    case 'CLEAR_ALL_FILTERS': {
      return { ...state, selected: getInitialSelected(state.filters) }
    }
    case 'SELECT_FILTERS':
      return {
        ...state,
        selected: { ...state.selected, ...action.filters },
      }

    default:
      return state
  }
}

// actions

export const getFilters = (
  itemType: string,
  selectedModel?: appliancepb.IProduct | null
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  try {
    const filters = await api.getFilters(itemType)
    dispatch({
      type: 'GET_FILTERS',
      filters,
    })
    if (selectedModel) {
      const selectedFiltersByModel = filters
        .filter((f) => selectedModel[f.name])
        .reduce((acc = {}, filter) => {
          const modelValue = selectedModel[filter.name]
          // This is beyond dirty, but needed in order to workaround certain edge cases
          // Example: selectedModel has "Stainless" color and the actual filter is "Stainless Steel"
          const filterDetail = filter.values?.find((v) =>
            v.toLowerCase().includes(modelValue.toLowerCase())
          )
          if (filterDetail) {
            acc[filter.name] = filterDetail
          }
          return acc
        }, {})
      dispatch(selectFilters(selectedFiltersByModel))
    }
  } catch (error) {
    console.warn('There was an error trying to get replacement filters')
    dispatch({
      type: 'GET_FILTERS_ERROR',
      error,
    })
  }
}

export const getItemFilters = (itemId?: string): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  try {
    const itemFilters = await api.getItemServiceFilters(itemId || '')
    dispatch({
      type: 'GET_ITEM_FILTERS',
      itemFilters: itemFilters.attributes,
    })
  } catch (error) {
    console.warn('There was an error trying to get item filters', error)
    dispatch({
      type: 'GET_ITEM_FILTERS_ERROR',
      // sending error even though we're not using it for the moment
      error,
    })
  }
}

export const selectFilters = (filters: object): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({
    type: 'SELECT_FILTERS',
    filters,
  })
}

export const toggleFilter = (
  name: string,
  value: string
): ThunkResult<void> => async (dispatch: StateAwareThunkDispatch) => {
  dispatch({
    type: 'SELECT_FILTER',
    name,
    value,
  })
}

export const clearFilter = (name: string): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({
    type: 'CLEAR_FILTER',
    name,
  })
}

export const clearAllFilters = (): ThunkResult<void> => async (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({
    type: 'CLEAR_ALL_FILTERS',
  })
}
