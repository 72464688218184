import { useState } from 'react'

const useScreenLock = () => {
  const [isLocked, setIsLocked] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  const lockScreen = () => {
    const windowHeight = window.innerHeight
    const currentScrollTop =
      window.scrollY || document.documentElement.scrollTop

    setScrollTop(currentScrollTop)

    const isScrollable = document.documentElement.scrollHeight > windowHeight

    document.documentElement.style.cssText = `
      width: 100%;
      height: ${windowHeight}px;
      overflow-y: ${isScrollable ? 'scroll' : 'hidden'};
      position: fixed;
    `

    document.body.style.cssText = `
      height: ${windowHeight + currentScrollTop}px;
      overflow: hidden;
      margin-top: -${currentScrollTop}px;
    `

    setIsLocked(true)
  }

  const unlockScreen = (resetPosition: boolean = false) => {
    document.documentElement.style.cssText = ''
    document.body.style.cssText = ''

    window.scrollTo(0, resetPosition ? 0 : scrollTop)
    setIsLocked(false)
  }

  return { isLocked, unlockScreen, lockScreen }
}

export default useScreenLock
