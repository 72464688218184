import React, { createContext, useEffect, useContext, useState } from 'react'
import { useMedia } from '../hooks/use-media'
import useScreenLock from './use-screen-lock'

type Props = {
  open: boolean
  toggleMenu(isOpen: boolean): void
  linkToggle: () => void
}
type TMenuContext = {
  children?: React.ReactNode
}
const MenuContext = createContext<Props>({} as Props)

const MenuProvider: React.FC<TMenuContext> = ({ children }) => {
  const { unlockScreen, lockScreen } = useScreenLock()
  const [open, setOpen] = useState<boolean>(false)
  const value = useMedia('(min-width:1138px)')

  const toggleMenu = (open: boolean) => {
    if (open) {
      setOpen(true)
      lockScreen()
    } else {
      setOpen(false)
      unlockScreen()
    }
  }
  const linkToggle = () => {
    unlockScreen(true)
    setOpen(false)
  }
  useEffect(() => {
    value && toggleMenu(false)
    //eslint-disable-next-line
  }, [value])
  return (
    <MenuContext.Provider value={{ open, toggleMenu, linkToggle }}>
      {children}
    </MenuContext.Provider>
  )
}

const useMenuContext = () => useContext(MenuContext)

export { MenuProvider, useMenuContext }
