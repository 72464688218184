import { TextArea, RadioGroup } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import React from 'react'
import { ChangeEvent } from 'react'

export interface OrderDetailFieldValue {
  action: string
  comment: string
}

interface OrderDetailsFieldProp {
  value: OrderDetailFieldValue
  onChange: (val: OrderDetailFieldValue) => void
  title: string
  options: string[]
  id: string
}

export const OrderDetailField = ({
  value,
  onChange,
  title,
  options,
  id,
}: OrderDetailsFieldProp) => {
  const onRadioChange = (radioVal: string) => {
    onChange({
      ...value,
      action: radioVal,
    })
  }

  const onCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange({
      ...value,
      comment: e.target.value,
    })
  }

  return (
    <div>
      <Text
        id={`order-detail-field-text-${id}`}
        className="mb-2"
        variant="heading-06"
      >
        {title}
      </Text>
      <RadioGroup
        id={`order-detail-field-radio-${id}`}
        className="mb-4"
        label={title}
        value={value.action}
        onChange={onRadioChange}
        radios={options.map((opt) => ({ id: opt, value: opt, label: opt }))}
        color="primary"
      />
      <TextArea
        id={`order-detail-field-text-area${id}`}
        value={value.comment}
        formField
        label="Comment"
        onChange={onCommentChange}
      />
    </div>
  )
}
