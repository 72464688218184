import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import AddToCompareButton from 'src/components/add-to-compare-button/add-to-compare-button'
import { useMedia } from '../../../hooks/use-media'
import {
  stylesRowElem,
  stylesRow,
  stylesRowElemMobile,
  stylesRowMobile,
} from '../helpers'

const ImageRow = () => {
  const isMobile = useMedia('(max-width:767px)')
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )

  return (
    <div
      className={`flex mb-4 md:justify-center`}
      style={isMobile ? stylesRowMobile : stylesRow}
    >
      {compareProducts.map((product) => {
        const [image] = product.images || []
        return (
          <div
            key={product.ID}
            className="flex flex-col px-6"
            style={isMobile ? stylesRowElemMobile : stylesRowElem}
          >
            <AddToCompareButton
              id={product.ID || ''}
              product={product}
              containerStyle={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 0,
                marginTop: 0,
                paddingLeft: 5,
                paddingRight: 5,
              }}
            />
            <figure className="w-full">
              <img
                src={image?.url || '/noimage.jpg'}
                alt="Product"
                className="object-contain max-h-full max-w-full md:w-1/3 m-auto"
              />
            </figure>
          </div>
        )
      })}
    </div>
  )
}

export default ImageRow
