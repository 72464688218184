import React, { useEffect, useState } from 'react'
import { Dialog } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
  ProgressIndicatorComponent as ProgressIndicator,
} from '../custom-fdr-components'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { api } from 'src/utils/api'
import { Tenant } from 'src/utils/shared-types'
import {
  OrderDetailField,
  OrderDetailFieldValue,
} from 'src/screens/exception-queue/order-details-dialog/order-detail-field'

interface IQueueOrderDetailDialog {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}

const initialOrderDetailValue: OrderDetailFieldValue = {
  action: '',
  comment: '',
}

const QueueOrderDetailDialog: React.FC<IQueueOrderDetailDialog> = ({
  isOpen,
  onClose,
}) => {
  const { selectedQueueItem, setSelectQueueItem } = useQueueModalContext()
  const { setShouldQueueDataReload } = useTabsTableContext()

  const [updatingInstaller, setPatchingReplacement] = useState(false)
  const [patchReplacementError, setPatchReplacementError] = useState<any>(null)

  const [loading, setLoading] = useState(true)
  const [dispatch, setDispatch] = useState<appliancepb.IDispatchInfoResponse>()

  const [correctCodesValue, setCorrectCodesValue] = useState(
    initialOrderDetailValue
  )
  const [transferredVendorValue, setTransferredVendorValue] = useState(
    initialOrderDetailValue
  )
  const [createReimbursementValue, setCreateReimbursementValue] = useState(
    initialOrderDetailValue
  )
  const [orderCancelledValue, setOrderCancelledValue] = useState(
    initialOrderDetailValue
  )
  const [orderErrorCodeValue, setOrderErrorCodeValue] = useState(
    initialOrderDetailValue
  )

  const clearForm = () => {
    setCorrectCodesValue(initialOrderDetailValue)
    setTransferredVendorValue(initialOrderDetailValue)
    setCreateReimbursementValue(initialOrderDetailValue)
    setOrderCancelledValue(initialOrderDetailValue)
    setOrderErrorCodeValue(initialOrderDetailValue)
  }

  const patchReplacement = async (
    replacementId: string,
    patchReplacement: appliancepb.IPatchReplacement,
    tenant?: string
  ) => {
    setPatchingReplacement(true)
    setPatchReplacementError(null)
    try {
      await api.patchReplacement(replacementId, patchReplacement, tenant)
      setSelectQueueItem(null)
      setShouldQueueDataReload(true)
      onClose()
    } catch (err) {
      setPatchReplacementError(err)
      console.warn('There was an error trying to patch the replacement')
    }
    setPatchingReplacement(false)
  }

  const onComplete = () => {
    const orderActions: appliancepb.IOrderAction[] = [
      correctCodesValue,
      transferredVendorValue,
      createReimbursementValue,
      orderCancelledValue,
      orderErrorCodeValue,
    ].filter((orderAction) => Boolean(orderAction.action))
    patchReplacement(
      selectedQueueItem?.ID || '',
      {
        status: Boolean(orderErrorCodeValue.action)
          ? 'ORDER_CANCELLED'
          : 'ORDERED',
        orderActions,
      },
      dispatch?.tenantAbbreviation || Tenant.AHS
    )
  }

  useEffect(() => {
    if (!selectedQueueItem) return
    const fetch = async () => {
      setLoading(true)
      try {
        const dispatch = await api.getDispatchInfo(
          selectedQueueItem?.dispatchID || ''
        )
        setDispatch(dispatch)
        clearForm()
      } catch (err) {
        console.warn(
          `There was an error getting the dispatch ${selectedQueueItem?.dispatchID}`
        )
        setLoading(false)
      }
      setLoading(false)
    }
    fetch()
    //eslint-disable-next-line
  }, [selectedQueueItem?.dispatchID])

  return (
    <Dialog
      header={<Text variant="heading-04">Order Details</Text>}
      className="my-dialog overflow-auto max-h-full h-full relative"
      modal={true}
      usePortal={true}
      onClose={onClose}
      open={isOpen}
      actions={[
        <Button
          id="queue-order-dialog-complete"
          label="Complete"
          loading={updatingInstaller}
          onClick={onComplete}
        />,
        <Button
          id="queue-order-dialog-close"
          variant="outlined"
          label="Go Back"
          loading={updatingInstaller}
          onClick={() => {
            onClose()
            clearForm()
          }}
        />,
      ]}
    >
      {loading ? (
        <div
          className="flex items-center justify-center"
          id="queue-order-progress"
        >
          <ProgressIndicator data-testid="spinner" size="small" />
        </div>
      ) : (
        <div data-testid="fields-container">
          <div className="mb-6">
            <OrderDetailField
              id="1"
              onChange={(val: OrderDetailFieldValue) =>
                setCorrectCodesValue(val)
              }
              value={correctCodesValue}
              title="Added correct codes to order"
              options={['Enter the missing code(s)', 'Change incorrect code']}
            />
          </div>
          <div className="mb-6">
            <OrderDetailField
              id="3"
              onChange={(val: OrderDetailFieldValue) =>
                setTransferredVendorValue(val)
              }
              value={transferredVendorValue}
              title="Transferred vendor for install"
              options={['Enter Name and/or Vendor ID who is installing']}
            />
          </div>
          <div className="mb-6">
            <OrderDetailField
              id="4"
              onChange={(val: OrderDetailFieldValue) =>
                setCreateReimbursementValue(val)
              }
              value={createReimbursementValue}
              title="Created CR Reimbursement for $125.00 install"
              options={["Enter Member's Zip code"]}
            />
          </div>
          <div className="mb-6">
            <OrderDetailField
              id="5"
              onChange={(val: OrderDetailFieldValue) =>
                setOrderCancelledValue(val)
              }
              value={orderCancelledValue}
              title="Order cancelled"
              options={[
                'Member cancelled order',
                'Duplicate order',
                'Error in Order',
                'Back order',
                'Other',
              ]}
            />
          </div>
          <div className="mb-6">
            <OrderDetailField
              id="6"
              onChange={(val: OrderDetailFieldValue) =>
                setOrderErrorCodeValue(val)
              }
              value={orderErrorCodeValue}
              title="Order error code"
              options={['Updated']}
            />
          </div>

          {patchReplacementError && (
            <Text id="queue-order-dialog-error" color="error" className="mt-4">
              {patchReplacementError.toString()}
            </Text>
          )}
        </div>
      )}
    </Dialog>
  )
}

export default QueueOrderDetailDialog
