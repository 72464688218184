import React, { createContext, useEffect, useContext, useState } from 'react'
import { api } from 'src/utils/api'
import { MenuItem } from 'src/utils/shared-types'
import { ApiMessage } from 'src/components/adminForms/types'
import {
  emptyItem,
  emptyCacheObject,
  emptyBanner,
} from '../components/adminForms/initials'

import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { convertToServerTimeZone } from '../components/adminForms/helpers'
import { TAdminContextProps } from '../utils/shared-types'

type TAdminContext = {
  children?: React.ReactNode
}
const AdminContext = createContext<TAdminContextProps>({} as TAdminContextProps)

const AdminProvider: React.FC<TAdminContext> = ({ children }) => {
  const [adminFunction, setAdminFunction] = useState<MenuItem>(emptyItem)
  const [selectedAction, setSelectedAction] = useState<MenuItem>(emptyItem)
  const [vendors, setVendors] = useState<MenuItem[]>([])
  const [selectedVendor, setSelectedVendor] = useState<MenuItem>(emptyItem)
  const [vendorsAreLoading, setVendorsAreLoading] = useState<boolean>(true)
  const [selectedCacheDB, setSelectedCacheDB] = useState<MenuItem>(emptyItem)
  const [selectedCacheKey, setSelectedCacheKey] = useState<MenuItem>(emptyItem)
  const [sku, setSku] = useState<string>('')
  const [zip, setZip] = useState<string>('')
  const [price, setPrice] = useState<string>('')
  const [file, setFile] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)
  const [cacheMessage, setCacheMessage] = useState<ApiMessage>(emptyCacheObject)
  const [refreshMessage, setRefreshMessage] = useState<ApiMessage>(
    emptyCacheObject
  )
  const [addFileMessage, setAddFileMessage] = useState<ApiMessage>(
    emptyCacheObject
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isfileDownloaded, setIsFileDownloaded] = useState<boolean>(false)
  const [isSaveBunnerDisabled, setIsSaveBannerDisabled] = useState<boolean>(
    false
  )

  const [banner, setBanner] = useState<appliancepb.IBanner>({
    activate: {
      seconds: convertToServerTimeZone().getTime() / 1000,
      nanos: 0,
    },
  })
  const [isEditBannerModalOpen, setIsEditBannerModalOpen] = useState<boolean>(
    false
  )
  const [isDeleteBannerConfirmation, setIsDeleteBannerConfirmation] = useState(
    false
  )
  const [bannerCustomMessage, setBannerCustomMessage] = useState<string>('')
  const saveBanner = (banner: appliancepb.IBanner) => {
    setBanner((state) => ({ ...state, ...banner }))
  }
  const clearBanner = () =>
    setBanner({
      ...emptyBanner,
      activate: {
        seconds: convertToServerTimeZone().getTime() / 1000,
        nanos: 0,
      },
    } as appliancepb.IBanner)

  const clearContext = () => {
    setSelectedAction(emptyItem)
    setSelectedVendor(emptyItem)
    setSelectedCacheDB(emptyItem)
    setSelectedCacheKey(emptyItem)
    setSku('')
    setZip('')
    setPrice('')
    setFile(null)
    setError(null)
    setCacheMessage(emptyCacheObject)
    setRefreshMessage(emptyCacheObject)
    setAddFileMessage(emptyCacheObject)
    setIsFileDownloaded(false)
  }
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        setVendorsAreLoading(true)
        const { vendors } = await api.getVendors()
        const vendorsArray: MenuItem[] = []
        vendors.forEach(({ ID: id, name: label }) => {
          vendorsArray.push({ id, label, value: label } as MenuItem)
        })

        setVendors(vendorsArray)
        setVendorsAreLoading(false)
      } catch (error: any) {
        console.log(error.message)
      }
    }
    fetchVendors()
  }, [])
  useEffect(() => {
    if (
      ((banner?.message !== 'other...' && banner?.message) ||
        bannerCustomMessage) &&
      banner?.activate?.seconds &&
      banner?.expire?.seconds &&
      typeof banner?.severity === 'number'
    ) {
      setIsSaveBannerDisabled(false)
    } else {
      setIsSaveBannerDisabled(true)
    }
    //eslint-disable-next-line
  }, [banner, bannerCustomMessage, banner.expire, banner.activate])

  return (
    <AdminContext.Provider
      value={{
        banner,
        saveBanner,
        clearBanner,
        bannerCustomMessage,
        setBannerCustomMessage,
        isSaveBunnerDisabled,
        isDeleteBannerConfirmation,
        setIsDeleteBannerConfirmation,
        isLoading,
        setIsLoading,
        adminFunction,
        setAdminFunction,
        vendors,
        vendorsAreLoading,
        selectedVendor,
        setSelectedVendor,
        setSelectedAction,
        selectedAction,
        sku,
        setSku,
        zip,
        setZip,
        price,
        setPrice,
        clearContext,
        selectedCacheDB,
        setSelectedCacheDB,
        selectedCacheKey,
        setSelectedCacheKey,
        file,
        setFile,
        error,
        setError,
        cacheMessage,
        setCacheMessage,
        refreshMessage,
        setRefreshMessage,
        addFileMessage,
        setAddFileMessage,
        isfileDownloaded,
        setIsFileDownloaded,
        isEditBannerModalOpen,
        setIsEditBannerModalOpen,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

const useAdminContext = () => useContext(AdminContext)

export { AdminProvider, useAdminContext }
