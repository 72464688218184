import React from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'

interface ProductAttributesProps {
  attributes: appliancepb.Product.IAttributeValuesPair[]
  id: string
}
export const ProductAttributes = ({
  attributes,
  id,
}: ProductAttributesProps) => (
  <ul className="mt-10 lg:mt-16 lg:grid grid-cols-2 row-gap-1 col-gap-24">
    {attributes.map(({ attribute, values }, i) => (
      <li
        id={`${id}-product-attributes-list-elem`}
        key={attribute || i}
        className="pb-1 grid grid-cols-2 col-gap-1 border-b-1 border-gray-300"
      >
        <Text
          id={`${id}-product-attributes-list-attr`}
          className="font-bold text-left flex items-center"
          color="primary"
        >
          {attribute}
        </Text>
        <Text
          id={`${id}-product-attributes-val`}
          className="text-right flex items-center justify-end"
          color="primary"
        >
          {values?.join(', ')}
        </Text>
      </li>
    ))}
  </ul>
)
