import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { AppState } from 'src/utils/shared-types'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getBanners } from 'src/store/admin-store'
import AlertBanner from '../adminForms/banners/alert-banner'
import { useDispatchInfoContext } from 'src/hooks/use-is-disptach-info'
import { useScrolledContext } from 'src/hooks/use-isScrolled-context'

export const AlertNotifications = () => {
  const dispatch = useDispatch()
  const { banners } = useSelector((state: AppState) => state.admin)
  const { isScrolled, setPositionFromTop } = useScrolledContext()
  const { isDispatchInfoVisible } = useDispatchInfoContext()
  const elementRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    dispatch(getBanners())
    //eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const wrapper = elementRef.current

    if (wrapper) {
      const wrapperHeight = wrapper.offsetHeight
      setPositionFromTop(wrapperHeight)
    }
    //eslint-disable-next-line
  }, [banners])
  return banners.length ? (
    <div
      ref={elementRef}
      id="alert-notification-wrapper"
      className="lg:fixed left-0 right-0 w-full z-40 bg-picket-white"
      style={
        isDispatchInfoVisible
          ? isScrolled
            ? { top: '5rem', transition: 'all .3s' }
            : { top: '8rem', transition: 'all .3s' }
          : isScrolled
          ? { top: '3rem', transition: 'all .3s' }
          : { top: '6rem', transition: 'all .3s' }
      }
    >
      {banners.length > 0 &&
        banners
          .filter(({ severity }) => severity === 2)
          .map((banner) => <AlertBanner {...banner} key={banner.id} />)}
      {banners.length > 0 &&
        banners
          .filter(({ severity }) => severity === 3)
          .map((banner) => <AlertBanner {...banner} key={banner.id} />)}
      {banners.length > 0 &&
        banners
          .filter(({ severity }) => severity === 1)
          .map((banner) => <AlertBanner {...banner} key={banner.id} />)}
    </div>
  ) : null
}
