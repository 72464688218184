import React from 'react'
import { IconInfoEmpty, useAppContext } from '@ftdr/blueprint-components-react'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'

const StatusInfo: React.FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { setStatusModalOpen } = useQueueModalContext()

  return (
    <div className="flex w-full h-full items-center">
      {localizedText('QUEUE_TABLE_HEAD_STATUS')}

      <span
        id="status-info-icon-wrapper"
        className="block cursor-pointer"
        onClick={() => setStatusModalOpen(true)}
      >
        <IconInfoEmpty size="36" color="primary" className="p-2" />
      </span>
    </div>
  )
}

export default StatusInfo
