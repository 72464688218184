import { Action } from 'redux'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

export interface ReplacementSurveyStoreState {
  replacement: appliancepb.IFullReplacementDetails | null
}

export enum CONSTANTS {
  ADD_REPLACEMENT = 'ADD_REPLACEMENT',
  REMOVE_REPLACEMENT = 'REMOVE_REPLACEMENT',
}

export interface AddReplacementAction
  extends Action<CONSTANTS.ADD_REPLACEMENT> {
  replacement: appliancepb.IFullReplacementDetails
}

export interface RemoveReplacementAction
  extends Action<CONSTANTS.REMOVE_REPLACEMENT> {
  replacement: null
}

export type ReplacementSurveyAction =
  | AddReplacementAction
  | RemoveReplacementAction
