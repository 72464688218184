import { init as initApm } from '@elastic/apm-rum'
import { api } from '../api'

export const apmRun = () =>
  initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'appliance-portal-ui',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl:
      'https://db620cbcc97643fd9627af1b97759eae.apm.us-east4.gcp.elastic-cloud.com:443',

    // Set service version (required for sourcemap feature)
    serviceVersion: '1.0',

    environment: api.config.ENV,
  })
