import React, { useMemo } from 'react'
import { List, IconCheck } from '@ftdr/blueprint-components-react'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

interface IProps {
  messageIndex: number
}

const AgentReplacementConfiramtionInfoList: React.FC<IProps> = ({
  messageIndex,
}) => {
  const {
    PAYMENT_WAS_PROCESSED_SUCCESSFULLY,
    REPLACEMENT_OFFER_EMAILED_TO_CUSTOMER,
    EXTERNAL_ORDER_WAS_SUCCESSFULLY_RECORDED,
  } = textTemplatesEnglish

  const messeges = useMemo(
    () => [
      PAYMENT_WAS_PROCESSED_SUCCESSFULLY,
      REPLACEMENT_OFFER_EMAILED_TO_CUSTOMER,
      EXTERNAL_ORDER_WAS_SUCCESSFULLY_RECORDED,
    ],
    //eslint-disable-next-line
    []
  )

  return (
    <List
      className="mb-4 -mx-2 font-bold text-primary-500"
      variant="unordered"
      unOrderedListDefaultIcon={<IconCheck color="primary" />}
      listItems={[{ text: messeges[messageIndex] }]}
    />
  )
}

export default AgentReplacementConfiramtionInfoList
