import { useEffect, useState } from 'react'
import { MenuItem } from 'src/utils/shared-types'
import { api } from 'src/utils/api'

export const useGetVendors = () => {
  const [loading, setLoading] = useState(false)
  const [vendorOptions, setVendorOptions] = useState<MenuItem[]>([])
  const vendorSelectNoValue: MenuItem = {
    id: '',
    value: '',
    label: 'All vendors',
  }
  const fetchVendors = async () => {
    try {
      setLoading(true)
      const { vendors } = await api.getVendors()
      const vendorsArray: MenuItem[] = []

      vendors.forEach(({ ID: id, name: label }) => {
        vendorsArray.push({ id, label, value: label } as MenuItem)
      })

      setVendorOptions(vendorsArray)
      setLoading(false)
    } catch (e: any) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    fetchVendors()
  }, [])

  return {
    fetchVendors,
    getVendors: (ids?: string[], selectNoValue?: boolean) =>
      selectNoValue
        ? [
            vendorSelectNoValue,
            ...vendorOptions.filter((v) =>
              ids?.includes(v.id) ? false : true
            ),
          ]
        : vendorOptions.filter((v) => (ids?.includes(v.id) ? false : true)),
    isVendorsLoading: loading,
  }
}
