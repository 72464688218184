import { Dispatch } from 'react'

export default class Debounce {
  callback: Dispatch<string>
  text: string
  delay: number
  timeout: ReturnType<typeof setTimeout> | null

  constructor(cb: Dispatch<string>, text: string, delay?: number) {
    this.callback = cb
    this.text = text
    this.delay = delay || 600
    this.timeout = null
  }
  public setDebounce() {
    this.timeout && clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.callback!(this.text!)
    }, this.delay)
  }
  public clearDebounce() {
    this.timeout && clearTimeout(this.timeout)
  }
}
