import React from 'react'
import { TextComponent as Text } from '../custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { formatDeliverDate } from 'src/utils/internationalization-helper'

interface DeliverDate {
  product: appliancepb.IProduct
  isHeader?: boolean
}
const DeliveryDate = ({
  product,
  isHeader,
}: DeliverDate): JSX.Element | null => {
  if (!product.IsAvailableForZip) return null
  if (isHeader && product.deliveryDate?.seconds) {
    return (
      <Text
        id="delivery-date-time-for-zip"
        variant="heading-05"
        className="mt-4"
        color="primary"
      >
        Earliest Delivery Date:{' '}
        <span id="delivery-date-earliest" className="font-normal">
          {formatDeliverDate(product?.deliveryDate?.seconds)}
        </span>
      </Text>
    )
  } else if (product.deliveryDate?.seconds) {
    return (
      <Text
        id="delivery-date-time"
        textTemplateKey="DELIVERY_DATE"
        color="primary"
        textTemplateData={{
          deliveryDate: `${formatDeliverDate(product.deliveryDate.seconds)}`,
        }}
      />
    )
  } else {
    return null
  }
}

export default React.memo(DeliveryDate)
