import React, { useState, useEffect } from 'react'
import { TextComponent as Text } from '../../components/custom-fdr-components'
import { BasicInfoSurveyStep } from './basic-info-step'
import { AgentSurvey } from './agent-survey-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submitAgentSurvey } from '../../store/survey-store'
import { clearModel } from '../../store/selected-model-store'
import { getFilters } from '../../store/filters-store'
import { useAuth } from '@ftdr/use-auth'
import { useSelector } from 'react-redux'
import { AppState } from '../../utils/shared-types'
import { useMedia } from '../../hooks/use-media'
import { isHSA } from 'src/utils/tenant-helper'

const initialSurvey: AgentSurvey.Survey = {
  dispatchID: '',
  dispatch: null,
  item: null,
  partRequestId: '',
  dcov: false,
}

export const AgentSurveyView = () => {
  const isDesktop = useMedia('(min-width:1024px)')
  const dispatch = useDispatch()
  const history = useHistory()

  const [survey, setSurvey] = useState<AgentSurvey.Survey>(initialSurvey)
  const { oidcUser } = useAuth()
  const itemType = useSelector(
    (state: AppState) => state.survey.newProductCategory
  )

  const nextStep = async (stepInfo?: AgentSurvey.IBasicInfoSurveyStep) => {
    const updatedSurvey = { ...survey, ...stepInfo }
    const updatedId = updatedSurvey.item?.id as string
    setSurvey(updatedSurvey)
    dispatch(submitAgentSurvey(updatedSurvey))
    try {
      if (itemType) {
        await dispatch(getFilters(itemType))
      } else {
        await dispatch(getFilters(updatedId))
      }
      history.push(`/browse`)
    } catch (e) {
      console.error(e)
    }
  }

  const goToMakeAndModelSearch = (
    stepInfo: AgentSurvey.IBasicInfoSurveyStep
  ) => {
    const updatedSurvey = { ...survey, ...stepInfo }
    setSurvey(updatedSurvey)
    dispatch(submitAgentSurvey(updatedSurvey))

    history.push(`/make-and-model`)
  }

  useEffect(() => {
    dispatch(clearModel())
  }, [dispatch])

  return (
    <div>
      <div
        id="container-3"
        className={`-mx-4 sm:-mx-10 md:-mx-24 lg:-mx-48 -mt-10 py-5 lg:py-10 px-4 sm:px-10 md:px-24 lg:px-48 mb-8  text-center md:text-left ${
          isHSA() ? 'bg-gray-200' : 'bg-picket-white'
        }`}
      >
        <Text
          id="agent-survey-profil-name"
          variant={`${isDesktop ? 'heading-03' : 'heading-05'}`}
          textTemplateKey="SURVEY_WELCOME_AGENT"
          textTemplateData={{
            name: `${oidcUser?.profile.name}`,
          }}
        />
        <Text textTemplateKey="SURVEY_APPLIANCE_OPTIONS_TITLE" />
      </div>
      <div>
        <BasicInfoSurveyStep
          survey={survey}
          onNext={nextStep}
          goToMakeAndModelSearch={goToMakeAndModelSearch}
        />
      </div>
    </div>
  )
}
