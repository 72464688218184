import React, { createContext, useContext, useState } from 'react'

type Props = {
  open: boolean
  setOpen(isOpen: boolean): void
}
type TCILModalProvider = {
  children?: React.ReactNode
}

const CILModalContext = createContext<Props>({} as Props)

const CILModalProvider: React.FC<TCILModalProvider> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <CILModalContext.Provider value={{ open, setOpen }}>
      {children}
    </CILModalContext.Provider>
  )
}

const useCILModalContext = () => useContext(CILModalContext)

export { CILModalProvider, useCILModalContext }
