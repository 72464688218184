import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'

export enum CONSTANTS {
  SELECT_MODEL = 'SELECT_MODEL',
  CLEAR_MODEL = 'CLEAR_MODEL',
}

export interface SelectedModelStoreState {
  data: appliancepb.IProduct | null
}

export type SelectedModelActions = SelectModelAction | ClearModelAction

type SelectModelAction = {
  type: CONSTANTS.SELECT_MODEL
  model: appliancepb.IProduct
}

type ClearModelAction = { type: CONSTANTS.CLEAR_MODEL }
