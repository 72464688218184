import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from '../custom-fdr-components'
import React from 'react'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'

interface CilDialogProps {
  close: () => void
  cil: number
}
export const CilDialog = ({ cil, close }: CilDialogProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  return (
    <Dialog
      open
      modal
      className="overflow-auto"
      closeOnOutsideClick
      onClose={close}
      actions={[
        <Button
          variant="outlined"
          color="gray"
          size="small"
          label={localizedText('BROWSE_CIL_CLOSE_BTN')}
          data-testid="CilDialogCloseButton"
          onClick={close}
        />,
      ]}
    >
      <>
        <Text
          variant="heading-04"
          className="normal-case mb-4"
          textTemplateKey="BROWSE_CIL_TEXT"
          data-testid="CilDialogHeadingText"
          textTemplateData={{
            cil: formatCentsCurrency(cil),
          }}
        />
        <Text
          variant="heading-04"
          className="normal-case"
          textTemplateKey="BROWSE_CIL_TEXT1"
        />
      </>
    </Dialog>
  )
}
