import React, { useMemo } from 'react'
import { Text, TableColumn, Table } from '@ftdr/blueprint-components-react'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'

const CILInfoTable: React.FC = () => {
  const { dataCILModal: data } = useQueueModalContext()

  const newData = Object.keys(data).map((key) => ({
    component: key.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase()
    }),
    value: data[key],
  }))

  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_CIL_MODAL_COMPONENT"
          />
        ),
        accessor: 'component', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },

      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="QUEUE_TABLE_CIL_MODAL_VALUE"
          />
        ),
        accessor: 'value', // accessor is the "key" in the data
        Cell: ({ value }: any) => (
          <Text variant="caption">
            {typeof value !== 'string' ? formatCentsCurrency(value) : value}
          </Text>
        ),
      },
    ],
    []
  )
  return (
    <div className="pb-8 relative z-10">
      <div className="relative">
        <Table
          id="cil-info-table-table"
          data={newData}
          columns={columns}
          striped="gray"
          variant="heavy"
          paginate={false}
          sortable={false}
          hover="gray"
        />
      </div>
      <div className="flex justify-between mt-8 items-start"></div>
    </div>
  )
}

export default CILInfoTable
