import React, { useState } from 'react'
import { Input, IconSearch } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import { api } from '../../utils/api'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { useSelector } from 'react-redux'
import { AppState } from 'src/store/initial-state'

interface MakeAndModelSearchProps {
  setModelOptions: (models: appliancepb.IProduct[]) => void
}
export const MakeAndModelSearch = ({
  setModelOptions,
}: MakeAndModelSearchProps) => {
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon
  )
  const [modelQuery, setModelQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const searchModel = async () => {
    setLoading(true)
    try {
      const { products } = await api.getModel(modelQuery, {
        trim_kit_size: trimKitSize || '',
        oim: !!icemakerAddon || '',
      })
      setModelOptions(products)
      setError('')
    } catch (err: any) {
      setError(err.message)
    }
    setLoading(false)
  }

  const onModelQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModelQuery(e.target.value.trim())
  }

  return (
    <div className="lg:flex items-start">
      <Input
        id="make-and-model-search-number"
        formField
        disabled={loading}
        label="Model Number"
        className="w-56"
        error={error}
        value={modelQuery}
        onChange={onModelQueryChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            searchModel()
          }
        }}
      />
      <Button
        id="make-and-model-search-search"
        className="lg:ml-4 mt-6"
        loading={loading}
        variant="outlined"
        size="medium"
        label="search"
        endIcon={<IconSearch size="35" />}
        onClick={searchModel}
      />
    </div>
  )
}
