import React, { createContext, Dispatch, useContext, useState } from 'react'

type Props = {
  open: boolean
  setOpen: Dispatch<boolean>
}
type TCILModalProvider = {
  children?: React.ReactNode
}

const AgentConfiramtionModalContext = createContext<Props>({} as Props)

const AgentConfiramtionProvider: React.FC<TCILModalProvider> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <AgentConfiramtionModalContext.Provider value={{ open, setOpen }}>
      {children}
    </AgentConfiramtionModalContext.Provider>
  )
}

const useAgentConfiramtion = () => useContext(AgentConfiramtionModalContext)

export { AgentConfiramtionProvider, useAgentConfiramtion }
