import React from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { isCurrentRangeDate } from '../helpers'
import { Notification } from '@ftdr/blueprint-components-react'
import { getStatus } from '../helpers'

const AlertBanner: React.FC<appliancepb.IBanner> = ({
  activate,
  expire,
  id,
  severity,
  message,
}: appliancepb.IBanner) => {
  return isCurrentRangeDate(
    new Date((activate?.seconds! * 1000) as number).getTime(),
    new Date((expire?.seconds! * 1000) as number).getTime()
  ) ? (
    <Notification
      id={`${id}-alert-notification`}
      key={id}
      showStatusLabel={false}
      status={getStatus(severity!)}
    >
      <p>{message as string}</p>
    </Notification>
  ) : null
}

export default AlertBanner
