import { todayAtMidnight } from 'src/utils/helper'
import yup, { valueSelected } from '../../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

const { DEFAULT_ERROR } = textTemplatesEnglish

export const continueReplacementSchema = yup.object().shape({
  contractID: yup.string().contractID(),
  dispatchID: yup.string().dispatchID(),
  firstName: yup.string().onlyLetters(),
  lastName: yup.string().onlyLetters(),
  billingState: valueSelected(yup),
  vendor: valueSelected(yup),
  date: yup
    .date()
    .required(DEFAULT_ERROR)
    .min(todayAtMidnight(), 'Min date is today'),
  agreements: yup.bool().oneOf([true], 'Required'),
  tenantAbbreviation: valueSelected(yup),
})
