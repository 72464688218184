import React, { useState, useEffect } from 'react'
import {
  IconAddCircledOutline,
  IconFormClearField,
} from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from '../custom-fdr-components'
import { useSelector, useDispatch } from 'react-redux'
import { addProduct, removeProduct } from '../../store/compare-store'
import { AppState } from 'src/utils/shared-types'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { isAHS, isHSA } from 'src/utils/tenant-helper'

interface IAddToCompareButton {
  product: appliancepb.IProduct
  containerStyle?: IContainerStyle
  isAllreadyAdded?: boolean
  buttonWidth?: 'full' | 'default'
  id: string
}
interface IContainerStyle {
  [key: string]: string | number
}
const defaultStyle: IContainerStyle = {
  height: 52,
  paddingTop: 10,
  marginTop: 8,
}

const AddToCompareButton: React.FC<IAddToCompareButton> = ({
  product,
  containerStyle = {},
  id,
}) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const variant = isHSA() ? 'ghost' : 'outlined'
  const [isAdded, setIsAdded] = useState<boolean>(false)
  const [isOverLimit, setIsOverLimit] = useState<boolean>(false)
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const dispatch = useDispatch()

  const toggleCompareProduct = (product: appliancepb.IProduct) => {
    if (compareProducts.some((item) => item.ID === product.ID)) {
      dispatch(removeProduct(product.ID as string))
      setIsAdded(false)
    } else if (!isOverLimit) {
      dispatch(addProduct(product))
      setIsAdded(true)
    }
  }
  useEffect(() => {
    compareProducts.some((item) => item.ID === product.ID)
      ? setIsAdded(true)
      : setIsAdded(false)
    compareProducts.length > 2 ? setIsOverLimit(true) : setIsOverLimit(false)
    //eslint-disable-next-line
  }, [compareProducts])

  return (
    <>
      <div style={{ ...defaultStyle, ...containerStyle }}>
        <div
          onMouseEnter={() => {
            setIsHovered(true)
          }}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Button
            id={`add-to-compare-button-add-${id}`}
            label={isAdded ? 'Remove' : 'Add to compare'}
            labelAlign="center"
            variant={variant}
            size="small"
            className="scale-05"
            disabled={isOverLimit && !isAdded}
            startIcon={
              isAdded ? (
                <IconFormClearField
                  color={isHovered && isAHS() ? 'white' : color}
                />
              ) : (
                <IconAddCircledOutline
                  color={isHovered && isAHS() ? 'white' : color}
                />
              )
            }
            onClick={() => toggleCompareProduct(product)}
          />
        </div>
        {!isAdded && (
          <div>
            <p
              id={`add-to-compare-button-info-${id}`}
              className="ml-8 lg:m-0 text-primary"
              style={{
                fontSize: 12,
                opacity: isOverLimit ? 0.5 : 1,
                whiteSpace: 'nowrap',
              }}
            >
              up to three items
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default AddToCompareButton
