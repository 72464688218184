import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { initValues, pTypes } from './default-values'
import { MenuItem } from 'src/utils/shared-types'
import { Select, useAppContext } from '@ftdr/blueprint-components-react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import ContiniueReplacementForm from '../payment-sub-forms/continue-replacement-form/continue-replacement-form'
import OutrightPaymentForm from '../payment-sub-forms/retail-payment-form/retail-payment-form'
import { useFormsContext } from 'src/hooks/use-forms-context'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import Description from '../description/description'
import AccessoriesForm from '../payment-sub-forms/accessories-form/accessories-form'
import RestockingFeeForm from '../payment-sub-forms/restocking-fee-form/restocking-fee-form'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { removeReplacement } from 'src/store/replacemnt-survey-store'

interface IForm {
  paymentType: MenuItem
}
const Index = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { setCounter, setFormType, formType } = useFormsContext()
  const { resetShoppingCartData } = useShoppingCartContext()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IForm>({
    mode: 'onTouched',

    defaultValues: initValues,
  })

  const submitForm = (data: IForm) => {
    if (data.paymentType.id !== formType.id) {
      resetShoppingCartData()
      setFormType(data.paymentType)
      setCounter()
      history.replace(
        history.location.pathname +
          '#' +
          data.paymentType.label.toLowerCase().replace(' ', '-')
      )
    }
  }

  useEffect(() => {
    let pt = pTypes().find(
      (type) =>
        `#${type.label.toLowerCase().replace(' ', '-')}` ===
        history.location.hash
    )
    if (!pt) {
      return
    }
    setValue('paymentType', pt)
    handleSubmit(submitForm)()
    //eslint-disable-next-line
  }, [history.location])
  return (
    <>
      <div className="max-w-sm md:max-w-lg">
        <Description
          payment={pTypes().find((t) => t.id === formType.id)?.label || ''}
        />
      </div>
      <div className="max-w-sm md:max-w-lg">
        <Controller
          name="paymentType"
          control={control}
          render={({ field }) => (
            <Select
              id="outright-payment-form-type"
              selected={field.value}
              error={errors.paymentType?.message}
              formField
              label={localizedText('OUTRIGHT_PAYMENT_TYPES_LABEL')}
              className="w-full md:w-56 mb-6"
              options={pTypes()}
              onSelect={(e: any) => {
                field.onChange(e)
                dispatch(removeReplacement())
                handleSubmit(submitForm)()
              }}
              onBlur={field.onBlur}
              placeholder="Select an option"
            />
          )}
        />
      </div>
      {formType.id === '5' ? <ContiniueReplacementForm /> : null}
      {formType.id ===
      `${appliancepb.OutrightPaymentRequest.PaymentType.PAYMENT_TYPE_OUTRIGHT_PAYMENT}` ? (
        <OutrightPaymentForm />
      ) : null}
      {formType.id ===
      `${appliancepb.OutrightPaymentRequest.PaymentType.PAYMENT_TYPE_ACCESSORIES}` ? (
        <AccessoriesForm />
      ) : null}
      {formType.id ===
      `${appliancepb.OutrightPaymentRequest.PaymentType.PAYMENT_TYPE_RESTOCKING_FEE}` ? (
        <RestockingFeeForm />
      ) : null}
    </>
  )
}

export default Index
