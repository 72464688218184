export const trimKitList = [{
    "GE-CEB515M2NS5": {"27": "GE-CX152M2NS5", "30": "GE-CX153M2NS5"},
    "GE-CEB515P2NSS": {"27": "GE-CX152P2MSS", "30": "GE-CX153P2MSS"},
    "GE-CEB515P3NDS": {"27": "GE-CX152P3MDS", "30": "GE-CX153P3MDS"},
    "GE-CEB515P4NWM": {"27": "GE-CX152P4MWM", "30": "GE-CX153P4MWM"},
    "GE-PEB7227ANDD": {"27": "GE-JX7227SLSS", "30": "GE-JX7230SLSS"},
    "GE-PEB7227DLBB": {"27": "GE-JX7227DLBB", "30": "GE-JX7230DLBB"},
    "GE-PEB7227DLWW": {"27": "GE-JX7227DLWW", "30": "GE-JX7230DLWW"},
    "GE-PEB9159DJBB": {"27": "GE-JX9152DJBB", "30": "GE-JX9153DJBB"},
    "GE-PEB9159SJSS": {"27": "GE-JX9152SJSS", "30": "GE-JX9153SJSS"},
    "GE-PEM31BMTS":   {"27": "GE-JX827BMTS", "30": "GE-JX830BMTS"},
    "GE-PEM31DFBB":   {"27": "GE-JX827DFBB", "30": "GE-JX830DFBB"},
    "GE-PEM31DFWW":   {"27": "GE-JX827DFWW", "30": "GE-JX830DFWW"},
    "GE-PEM31SFSS":   {"27": "GE-JX827SFSS", "30": "GE-JX830SFSS"},
    "GE-ZEB1227SLSS": {"27": "GE-ZX2127SLSS", "30": "GE-ZX2130SLSS"},
    "GE-ZEM115SJSS":  {"27": "GE-JX827SFSS", "30": "GE-JX830SFSS"},
    "WP-AMC4322GS":   {"27": "WP-MK2227AS", "30": "WP-MK2220AS"},
    "WP-JMC1116AS":   {"27": "WP-MK2167AS", "30": "WP-MK2160AS"},
    "WP-JMC3415ES":   {"27": "WP-MKC3157ES", "30": "WP-MKC3150ES"},
    "WP-KMCC5015GBS": {"27": "WP-MKC2157AV", "30": "WP-MKC2150AV"},
    "WP-KMCC5015GSS": {"27": "WP-MKC2157AS", "30": "WP-MKC2150AS"},
    "WP-KMCS1016GBS": {"27": "WP-MK2167AV", "30": "WP-MK2160AV"},
    "WP-KMCS1016GSS": {"27": "WP-MK2167AS", "30": "WP-MK2160AS"},
    "WP-KMCS3022GBS": {"27": "WP-MK2227AV", "30": "WP-MK2220AV"},
    "WP-KMCS3022GSS": {"27": "WP-MK2227AS", "30": "WP-MK2220AS"},
    "WP-UMC5225GZ":   {"27": "WP-MK2227AZ", "30": "WP-MK2220AZ"},
    "WP-WMC30516HB":  {"27": "WP-MK2167AB", "30": "WP-MK2160AB"},
    "WP-WMC30516HV":  {"27": "WP-MK2167AV", "30": "WP-MK2160AV"},
    "WP-WMC30516HW":  {"27": "WP-MK2167AW", "30": "WP-MK2160AW"},
    "WP-WMC30516HZ":  {"27": "WP-MK2167AS", "30": "WP-MK2160AS"},
    "WP-WMC50522HB":  {"27": "WP-MK2227AB", "30": "WP-MK2220AB"},
    "WP-WMC50522HS":  {"27": "WP-MK2227AS", "30": "WP-MK2220AS"},
    "WP-WMC50522HV":  {"27": "WP-MK2227AV", "30": "WP-MK2220AV"},
    "WP-WMC50522HW":  {"27": "WP-MK2227AW", "30": "WP-MK2220AW"},
    "WP-WMC50522HZ":  {"27": "WP-MK2227AZ", "30": "WP-MK2220AZ"},
}
]
