import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
export enum CONSTANTS {
  GET_BANNERS = 'GET_BANNERS',
  EDIT_BANNER = 'EDIT_BANNER',
  DELETE_BANNER = 'DELETE_BANNER',
  GET_IS_DELETED = 'GET_IS_DELETED',
  GET_LOADING = 'GET_LOADING',
  SAVE_LOADING = 'SAVE_LOADING',
  EDIT_LOADING = 'EDIT_LOADING',
  GET_IS_EDITED = 'GET_IS_BANNER_EDITED',
  DELETE_LOADING = 'DELETE_LOADING',
  GET_ERROR = 'LOADING_ERROR',
  DELETE_ERROR = 'DELETE_ERROR',
  EDIT_ERROR = 'EDIT_ERROR',
  SAVE_ERROR = 'SAVE_ERROR',
}

export interface AdminStoreState {
  getLoading: boolean
  getError: string
  saveLoading: boolean
  editLoading: boolean
  isBannerEdited: boolean
  editError: string
  deleteLoading: boolean
  isBannerDeleted: boolean
  deleteError: string
  saveError: string
  banners: appliancepb.IBanner[]
}
type GetBanners = {
  type: CONSTANTS.GET_BANNERS
  banners: appliancepb.IBanner[]
}

type EditBanner = { type: CONSTANTS.EDIT_BANNER; banner: appliancepb.IBanner }
type DeleteBanner = { type: CONSTANTS.DELETE_BANNER; id: string }
type GetIsDeletedBanner = {
  type: CONSTANTS.GET_IS_DELETED
  isBannerDeleted: boolean
}
type GetLoading = { type: CONSTANTS.GET_LOADING; getLoading: boolean }
type SaveLoading = { type: CONSTANTS.SAVE_LOADING; saveLoading: boolean }
type EditLoading = { type: CONSTANTS.EDIT_LOADING; getLoading: boolean }
type GetIsEditedBanner = {
  type: CONSTANTS.GET_IS_EDITED
  isBannerEdited: boolean
}
type DeleteLoading = { type: CONSTANTS.DELETE_LOADING; getLoading: boolean }
type GetError = { type: CONSTANTS.GET_ERROR; getError: string }
type EditError = { type: CONSTANTS.EDIT_ERROR; editError: string }
type DeleteError = { type: CONSTANTS.DELETE_ERROR; deleteError: string }
type SaveError = { type: CONSTANTS.SAVE_ERROR; saveError: string }

export type AdminActions =
  | GetBanners
  | SaveLoading
  | EditBanner
  | DeleteBanner
  | GetIsDeletedBanner
  | GetLoading
  | EditLoading
  | GetIsEditedBanner
  | DeleteLoading
  | GetError
  | EditError
  | DeleteError
  | SaveError
