import { Dialog } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import React from 'react'

interface IProps {
  callback: () => void
  toggleModal: (v: boolean) => void
  isOpen: boolean
}
const CartAvailabilityModal: React.FC<IProps> = ({
  callback,
  toggleModal,
  isOpen,
}) => {
  return (
    <Dialog
      id="cart-check-dialog"
      open={isOpen}
      modal={true}
      onClose={() => toggleModal(false)}
      className="mt-auto mb-auto"
      usePortal={true}
      actions={[
        <Button
          size="small"
          label="Continue"
          onClick={() => {
            callback()
            toggleModal(false)
          }}
        />,
        <Button
          variant="outlined"
          color="gray"
          size="small"
          label="Cancel"
          onClick={() => toggleModal(false)}
        />,
      ]}
    >
      <Text textTemplateKey="PAYMENTS_AVAILABILITY_MESSAGE" />
    </Dialog>
  )
}

export default CartAvailabilityModal
