import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react'

type Props = {
  open: boolean
  setOpen: Dispatch<boolean>
}
interface TCILPriceModalProvider extends PropsWithChildren {}

const CILPriceModalContext = createContext<Props>({} as Props)

const CILPriceModalProvider: React.FC<TCILPriceModalProvider> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <CILPriceModalContext.Provider value={{ open, setOpen }}>
      {children}
    </CILPriceModalContext.Provider>
  )
}

const useCILPriceModalContext = () => useContext(CILPriceModalContext)

export { CILPriceModalProvider, useCILPriceModalContext }
