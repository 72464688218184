import React from 'react'
import { IconInfoFull } from '@ftdr/blueprint-components-react'

interface MessageProps {
  children: React.ReactNode
  id: string
}
export const Message = ({ children, id }: MessageProps) => (
  <div
    id={`message-wrapper-${id}`}
    className="p-4 bg-pale-50 rounded-r-2 border-interactive inline-flex"
    style={{ borderLeftWidth: '8px' }}
  >
    <figure className="mr-4">
      <IconInfoFull width="22" height="22" color="interactive" />
    </figure>
    <div>{children}</div>
  </div>
)
