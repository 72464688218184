import React from 'react'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'
import { TextComponent as Text } from '../custom-fdr-components'

interface IProps {
  price: number
  reason: string
}
const TooltipContent: React.FC<IProps> = ({ price, reason }) => {
  return (
    <div
      className="w-full"
      style={{ width: reason.length * 12, minWidth: 280 }}
    >
      <Text>Original Price: {formatCentsCurrency(price)}</Text>
      <Text>Override Reason: {reason}</Text>
    </div>
  )
}

export default TooltipContent
