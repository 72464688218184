import { RefObject } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAppContext } from '@ftdr/blueprint-components-react'

import { formatPriceToCents } from 'src/utils/internationalization-helper'
import { stripeMinPaymentCents } from 'src/utils/helper'
import {
  AgregatedProcessPaymentResponse,
  PaymentsMethodMfeExposedApi,
} from '@ftdr/payment-method-micro-frontend'
import { payment3_paymentmethodpb } from '@ftdr/payment3_paymentmethod_coordinator-js-client'
import { api } from 'src/utils/api'
import {
  appliancepb,
  google,
} from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { IStripeFormData } from '../utils/shared-types'

import { usePaymentContext } from 'src/hooks/use-payment-context'

export const usePayments = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const {
    setStatus,
    setErrorMsg,
    setSavedReplacementId,
    clearSavedReplacementId,
    setSavedTenant,
    clearSavedTenant,
  } = usePaymentContext()

  const stripeErrMsgConversion = (error: string | null | undefined): string => {
    switch (error) {
      case "Your card's security code is incorrect.": {
        return localizedText('STRIPE_PAYMENT_FAILED_INVALID_CVV')
      }
      case 'Your card number is invalid.': {
        return localizedText('STRIPE_PAYMENT_FAILED_INVALID_CARD')
      }
      default: {
        return localizedText('STRIPE_PAYMENT_FAILED_GENERIC')
      }
    }
  }

  const handleStripeResponse = async (
    res: AgregatedProcessPaymentResponse,
    orderData: IStripeFormData
  ) => {
    if (res.stripe?.type === 'stripe_success') {
      const { tenantAbbreviation } = orderData
      const data = formatData(orderData, res)

      try {
        await api.createOutrightPayment(
          data,
          tenantAbbreviation?.id?.toLowerCase()
        )
        setSavedReplacementId(orderData.replacementID || null)
        setSavedTenant(tenantAbbreviation?.id?.toLowerCase() || null)
        setStatus('success')
      } catch (err) {
        console.log(err)
        let stripeErrMsg = stripeErrMsgConversion(
          res.stripe.stripeFailureResponse?.message
        )

        setErrorMsg(stripeErrMsg)

        setStatus('payment error')

        return
      }
    }
    if (res.stripe?.type === 'stripe_failure') {
      let stripeErrMsg = stripeErrMsgConversion(
        res.stripe.stripeFailureResponse?.message
      )

      setErrorMsg(stripeErrMsg)

      setStatus('payment error')

      return
    }
  }
  const submitStripePayment = async (
    submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi>,
    orderData: IStripeFormData
  ) => {
    clearSavedReplacementId()
    clearSavedTenant()

    const replacementID = uuidv4()

    if (formatPriceToCents(orderData.amount) >= stripeMinPaymentCents) {
      try {
        setStatus('loading')
        const result = await submitPaymentRef.current!.processPayment({
          stripe: {
            owner: {
              ownerIdentity: {
                type:
                  payment3_paymentmethodpb.OwnerIdentityType
                    .ReplacementIdentityType,
                replacementIdentity: {
                  replacementID,
                },
              },
            },
          },
        })
        if (!result || result.stripe?.type === 'not_valid_stripe_form') {
          setStatus(null)
          return
        }

        const res = await handleStripeResponse(
          result,

          { ...orderData, replacementID }
        )
        return res
      } catch (err) {
        setStatus('payment error')
      } finally {
        // history.push('/payment')
      }
    } else {
      setStatus('payment error')
      setErrorMsg(localizedText('STRIPE_PAYMENT_MIN_DOLLAR_ERROR'))
    }
  }

  const formatData = (
    orderData: IStripeFormData,

    res: AgregatedProcessPaymentResponse
  ) => {
    const paymentMethodID =
      res.stripe?.stripeSuccessResponse?.paymentMethod?.paymentMethodID
    const totalAmount: appliancepb.IPriceSetter = {
      apply: true,
      price: formatPriceToCents(orderData.amount),
    }

    const paymentDetails: appliancepb.IPaymentDetails = {
      ID: paymentMethodID,
      paymentProcessor: appliancepb.PaymentDetails.processor.STRIPE,
      totalPrice: totalAmount,
    }
    const customerBillingState: appliancepb.IAddress = {
      state: orderData.billingState,
    }
    const contract: appliancepb.IContract | null = orderData.contractID
      ? { id: orderData.contractID }
      : null
    const customer: appliancepb.ICustomer = {
      firstName: orderData.firstName,
      lastName: orderData.lastName,
      billingAddress: customerBillingState,
      id: orderData.customerID,
    }
    const time: google.protobuf.ITimestamp = {
      seconds: orderData.date!.getTime() / 1000,
    }
    const orderNumber: appliancepb.IOrder = {
      vendorOrderNumber: orderData.orderID,
    }
    const data: appliancepb.IOutrightPaymentRequest = orderData.cart
      ? {
          vendor: orderData.vendor,
          modelNumber: orderData.sku,
          orderDate: time,
          recordId: orderData.replacementID,
          customer, //verify costomer id is sent
          paymentDetails,
          contract,
          paymentType: +orderData.paymentType as appliancepb.OutrightPaymentRequest.PaymentType,
          dispatchId: orderData.dispatchID,
          order: orderNumber,
          cart: orderData.cart,
        }
      : {
          vendor: orderData.vendor,
          modelNumber: orderData.sku,
          orderDate: time,
          recordId: orderData.replacementID,
          customer, //verify costomer id is sent
          paymentDetails,
          contract,
          paymentType: +orderData.paymentType as appliancepb.OutrightPaymentRequest.PaymentType,
          dispatchId: orderData.dispatchID,
          order: orderNumber,
        }

    return data
  }

  return {
    submitStripePayment,
  }
}
