import React, { useCallback } from 'react'
import { usePaymentModalContext } from 'src/hooks/use-payment-modal-context'
import PaymentInfoDialog from '../payment-dialog/payment-dialog'
import CartDetailsTable from '../cart-payment-modal/cart-payment-modal'
import { RecordExternalOrderField } from 'src/screens/exception-queue/order-details-dialog/record-external-order-field'

const PaymentDialogsGroup = () => {
  const {
    cartInfoModalOpen,
    setCartInfoModalOpen,
    setCartInfoModal,
    recordExternalOrderModalOpen,
    setRecordExternalOrderModalOpen,
    setRecordExternalOrderModal,
  } = usePaymentModalContext()

  const closeDialogCart = useCallback(() => {
    setCartInfoModalOpen(false)
    setCartInfoModal([])
    //eslint-disable-next-line
  }, [])

  const closeActionDialog = useCallback(() => {
    setRecordExternalOrderModalOpen(false)
    setRecordExternalOrderModal({})
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <PaymentInfoDialog isOpen={cartInfoModalOpen} onClose={closeDialogCart}>
        <CartDetailsTable />
      </PaymentInfoDialog>
      <PaymentInfoDialog isOpen={recordExternalOrderModalOpen} onClose={closeActionDialog}>
        <RecordExternalOrderField onClose={closeActionDialog}/>
      </PaymentInfoDialog>
    </>
  )
}

export default PaymentDialogsGroup
