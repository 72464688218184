import React, { useState, useEffect, useMemo } from 'react'
import { Carousel, CarouselImage } from '@ftdr/blueprint-components-react'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import GalleryDialog from './gallery-dialog/gallery-dialog'
import { v4 } from 'uuid'
import { useSelectedSlideContext } from 'src/hooks/use-slide-context'
import { useMedia } from 'src/hooks/use-media'

interface CarouselProps {
  product: appliancepb.Product
  isGiftCard?: boolean
  isClickable?: boolean
}

const CarouselSlider = ({
  product,
  isGiftCard,
  isClickable = false,
}: CarouselProps) => {
  const isDesktop = useMedia('(min-width:1024px)')
  const [aspectRatio, setAspectRatio] = useState({
    width: 1,
    height: 1,
  })
  const [isGalleryOpen, setGalleryOpen] = useState<boolean>(false)
  const { selectedSlide, setSelectedSlide } = useSelectedSlideContext()

  const { length } = product.images
  const images = useMemo(() => product.images, [product.images])

  useEffect(() => {
    return () => {
      selectedSlide !== 0 && setSelectedSlide(0)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    !isDesktop && setGalleryOpen(false)
  }, [isDesktop])

  useEffect(
    () =>
      isGiftCard
        ? setAspectRatio({ width: 512, height: 324 })
        : setAspectRatio({ width: 416, height: 416 }),
    [isGiftCard, setAspectRatio]
  )
  return (
    <>
      <div
        id="corousel-wrapper"
        className={`w-full max-w-xl  relative lg:ml-auto lg:mr-0 ${
          length > 1 ? 'mx-auto sm:px-8' : 'mx-0'
        }`}
      >
        <Carousel
          showArrows={length > 1}
          aspectRatio={aspectRatio}
          className="w-full"
          loop={length > 1}
          showIndicators={length > 1}
          onChange={(i) => setSelectedSlide(i)}
          selectedSlide={selectedSlide}
          transitionDuration={isGalleryOpen ? 0 : 350}
        >
          {isClickable
            ? images.map((v) => (
                <span
                  onClick={() => isDesktop && setGalleryOpen(true)}
                  key={v4()}
                >
                  <CarouselImage
                    src={v.url as string}
                    alt={v.imageName as string}
                    className="object-contain"
                  />
                </span>
              ))
            : images.map((v) => (
                <CarouselImage
                  key={v4()}
                  src={v.url as string}
                  alt={v.imageName as string}
                  className="object-contain"
                />
              ))}
        </Carousel>
      </div>
      {isClickable && isDesktop ? (
        <GalleryDialog
          isOpen={isGalleryOpen}
          onClose={() => setGalleryOpen(false)}
          product={product}
        />
      ) : null}
    </>
  )
}
export default CarouselSlider
