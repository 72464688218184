import React from 'react'
import { useAdminContext } from '../../hooks/use-admin-context'
import { Select, useAppContext } from '@ftdr/blueprint-components-react'
import { MenuItem } from 'src/utils/shared-types'

import { initialFunctions } from './initials'

const AdminFunctions: React.FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { adminFunction, setAdminFunction, clearContext } = useAdminContext()
  return (
    <div>
      <Select
        id="admin-functions-selcect-fn"
        required
        formField
        label={localizedText('ADMIN_FUNCTIONS_LABEL')}
        className="w-full md:w-56 mb-6"
        options={initialFunctions}
        selected={adminFunction}
        onSelect={(item) => {
          clearContext()
          const option = { ...item } as MenuItem
          setAdminFunction(option)
        }}
      />
    </div>
  )
}

export default AdminFunctions
