import React from 'react'
import Form from '../Form/outright-payment-form'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import OutrightPaymentSuccess from '../SuccessScreen/outright-payment-success'

const Screen = () => {
  const { status } = usePaymentContext()
  return status === 'success' ? <OutrightPaymentSuccess /> : <Form />
}

export default Screen
