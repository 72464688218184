import { applyMiddleware, createStore, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import { initialState, AppState } from './initial-state'
import { replacementReducer } from './replacement-store'
import { adminReducer } from './admin-store'
import { filtersReducer } from './filters-store'
import { surveyReducer } from './survey-store'
import { selectedModelReducer } from './selected-model-store'
import { compareProductReducer } from './compare-store'
import { statusesReducer } from './statuses-store'
import { replacementSurveyReducer } from './replacemnt-survey-store'

const reduxDevtoolsKey = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'

const rootReducer = combineReducers<AppState>({
  admin: adminReducer,
  replacement: replacementReducer,
  filters: filtersReducer,
  survey: surveyReducer,
  selectedModel: selectedModelReducer,
  compareProducts: compareProductReducer,
  statuses: statusesReducer,
  replacementSurvey: replacementSurveyReducer,
})

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export function configureStore(init = initialState) {
  const devtoolsCompose = window[reduxDevtoolsKey] as typeof compose
  const composeEnhancers = devtoolsCompose || compose

  const store = createStore(
    persistedReducer,
    init,
    composeEnhancers(applyMiddleware(thunk))
  )

  let persistor = persistStore(store)

  return { store, persistor }
}

export const { store, persistor } = configureStore()
