import React, { useMemo } from 'react'
import { Text, IconClockOutline } from '@ftdr/blueprint-components-react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'

const customerServicePhone = '1-800-345-5535'

interface Params {
  replacementId: string
}

// TODO: Maybe create a service in the future
const VerificationErrors = {
  404: "Sorry, we couldn't find your replacement.",
  410: 'Sorry, your access has expired.',
  REPLACEMENT_ORDERED:
    'Something may have gone wrong, our records show a product has already been ordered.',
  CIL_OFFERED:
    'Something may have gone wrong, our records show a cash payout is in process.',
  default:
    "We had an error reaching the service please close your browser and retry the link. If that doesn't work please contact our customer service.",
}

function getError(error: any) {
  if (error) {
    if (error.status) {
      if (typeof error.data === 'string') {
        const [errorType] = error.data.split(':')
        if (VerificationErrors[errorType]) {
          return VerificationErrors[errorType]
        }
      }
      return VerificationErrors[error.status] || VerificationErrors.default
    }
    return error.toString()
  }
  return VerificationErrors.default
}

export const VerificationFailure = () => {
  const { replacementId } = useParams<Params>()
  const verificationError = useSelector(
    (state: AppState) => state.replacement.verificationError
  ) as any

  const error = useMemo(() => getError(verificationError), [verificationError])

  return (
    <div className="m-auto text-center max-w-xl">
      <figure className="mb-8 flex justify-center">
        <IconClockOutline color="primary" size="48" />
      </figure>
      <Text id="verification-failture-error" className="mb-4">
        {error}
      </Text>
      <Text id="verification-failture-call" className="mb-4">
        Please call{' '}
        <a
          id="verification-failture-call-link"
          className="whitespace-no-wrap text-primary"
          href={`tel:${customerServicePhone}`}
        >
          {customerServicePhone}
        </a>{' '}
        and our customer service will help with this issue.
      </Text>
      <Text className="mb-4">
        You'll need to provide the email address you used when you booked your
        service.
      </Text>
      <Text>
        This is your Replacement ID:{' '}
        <pre
          className="inline-block bg-gray-100 px-2 rounded"
          style={{ borderRadius: '5px' }}
        >
          {replacementId}
        </pre>
      </Text>
    </div>
  )
}
