import { useState, useEffect } from 'react'

export const useMedia = (mediaQuery: string) => {
  const match = () => {
    if (!window.matchMedia) {
      return false
    }
    return window.matchMedia(mediaQuery)?.matches
  }

  const [value, set] = useState<boolean>(match)

  useEffect(() => {
    const handler = () => {
      set(match())
    }

    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  })

  return value
}
