import React, { useEffect, useState } from 'react'
import { useAdminContext } from '../../hooks/use-admin-context'
import { Select, useAppContext } from '@ftdr/blueprint-components-react'
import { MenuItem } from 'src/utils/shared-types'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from '../custom-fdr-components'
import Message from './message'
import { emptyCacheObject } from './initials'
import {
  emptyItem,
  initialCacheDB,
  initialCacheKeyMongo,
  initialCacheKeyRedis,
} from './initials'
import { api } from 'src/utils/api'

const Cache: React.FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const setOptions = (dbName: string) => {
    setSelectedCacheKey(emptyItem)
    if (dbName === 'mongo') {
      return initialCacheKeyMongo
    } else if (dbName === 'redis') {
      return initialCacheKeyRedis
    } else {
      return []
    }
  }
  const {
    isLoading,
    setIsLoading,
    cacheMessage: { error, message },
    setCacheMessage,
    selectedCacheDB,
    selectedCacheKey,
    setSelectedCacheKey,
    setSelectedCacheDB,
  } = useAdminContext()

  const [optionsKey, setOptionsKey] = useState<MenuItem[]>([])
  const onClickDeleteHandler = async () => {
    setIsLoading(true)
    setCacheMessage(emptyCacheObject)
    const data = await api.adminCache(
      selectedCacheDB.value,
      selectedCacheKey.value,
      'delete'
    )
    setCacheMessage(data)
    setIsLoading(false)
  }
  const onClickRefreshHandler = async () => {
    setIsLoading(true)
    setCacheMessage(emptyCacheObject)
    const data = await api.adminCache(
      selectedCacheDB.value.toLowerCase(),
      selectedCacheKey.value.toLowerCase()
    )
    setIsLoading(false)
    setCacheMessage(data)
  }
  useEffect(() => {
    setOptionsKey(setOptions(selectedCacheDB.value))
    //eslint-disable-next-line
  }, [selectedCacheDB.value])
  return (
    <div>
      <Select
        id="cache-cache"
        required
        formField
        label={localizedText('ADMIN_LABEL_CACHE')}
        className="w-full md:w-56 mb-6"
        options={initialCacheDB}
        selected={selectedCacheDB}
        onSelect={(item) => {
          const option = { ...item } as MenuItem
          setSelectedCacheDB(option)
          setCacheMessage(emptyCacheObject)
        }}
      />

      <Select
        id="cache-key"
        required
        formField
        label={localizedText('ADMIN_LABEL_KEY')}
        className="w-full md:w-56 mb-6"
        options={optionsKey}
        selected={selectedCacheKey}
        onSelect={(item) => {
          const option = { ...item } as MenuItem
          setSelectedCacheKey(option)
          setCacheMessage(emptyCacheObject)
        }}
      />
      <Text className="py-4" textTemplateKey="ADMIN_INFO_TEXT_1" />

      <Button
        id="cache-delete"
        size="medium"
        loading={isLoading}
        label={localizedText('ADMIN_BUTTON_DELETE')}
        onClick={onClickDeleteHandler}
        disabled={!selectedCacheDB.value || !selectedCacheKey.value}
      />
      <Button
        id="cache-refresh"
        className="ml-4"
        size="medium"
        loading={isLoading}
        label={localizedText('ADMIN_BUTTON_REFRESH')}
        onClick={onClickRefreshHandler}
        disabled={!selectedCacheDB.value || !selectedCacheKey.value}
      />
      <Message id="cache" error={error} message={message} />
    </div>
  )
}

export default Cache
