import { Radio } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import React from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { isHSA } from 'src/utils/tenant-helper'

interface PartRequestSelectionProps {
  serviceItem?: appliancepb.IServiceItem
  selected: string

  onSelect(partRequestId: string): void
}

export const PartRequestSelection = ({
                                       serviceItem,
                                       selected,
                                       onSelect,
                                     }: PartRequestSelectionProps) => {
  const color = isHSA() ? 'primary' : 'interactive'
  return (
    <div>
      <Text
        variant='label'
        className='mb-1'
        textTemplateKey='PART_REQUEST_ID_LABEL'
      />
      {serviceItem?.partRequestIDs?.map((partRequestId) => (
        <div key={partRequestId}>
          <Radio
            id={`${partRequestId}-part-req-selection-radio`}
            color={color}
            label={partRequestId}
            checked={selected === partRequestId}
            onChange={() => onSelect(partRequestId)}
          />
        </div>
      ))}
    </div>
  )
}
