import { TIcemakerAddon, TKitSize } from 'src/utils/shared-types'
import { Action } from 'redux'
import { AgentSurvey } from '../../screens/agent-survey/agent-survey-types'

export interface Info {
  customerName: string[]
  customerPhoneNumber: string
  contractId: string
  dispatchId: string
  billingState: string
  adressID?: string
  tenantAbbreviation?: string
}
export type TInfo = Info | null

export interface SurveyStoreState {
  agentSurvey: AgentSurvey.Survey
  info: TInfo
  newProductCategory: string
  trimKitSize?: TKitSize
  icemakerAddon?: TIcemakerAddon
  acceptedCategories: string[]
}

export enum CONSTANTS {
  SUBMIT_AGENT_SURVEY = 'SUBMIT_AGENT_SURVEY',
  CHANGE_PRODUCT_CATEGORY = 'CHANGE_PRODUCT_CATEGORY',
  CHANGE_TRIM_KIT_SIZE = 'CHANGE_TRIM_KIT_SIZE',
  CHANGE_ICEMAKER_ADDON = 'CHANGE_ICEMAKER_ADDON',
  CHANGE_ACCEPTED_CATEGORIES = 'CHANGE_ACCEPTED_CATEGORIES',
  ADD_INFO = 'ADD_INFO',
}

export interface SelectFiltersAction
  extends Action<CONSTANTS.SUBMIT_AGENT_SURVEY> {
  survey: AgentSurvey.Survey
}

export interface ChangeProductCategoryAction
  extends Action<CONSTANTS.CHANGE_PRODUCT_CATEGORY> {
  product: string
}

export interface ChangeTrimKitSizeAction
  extends Action<CONSTANTS.CHANGE_TRIM_KIT_SIZE> {
  trimKitSize: TKitSize
}

export interface AddInfoAction extends Action<CONSTANTS.ADD_INFO> {
  info: TInfo
}
export interface ChangeIcemakerAddonAction
  extends Action<CONSTANTS.CHANGE_ICEMAKER_ADDON> {
  icemakerAddon: TIcemakerAddon
}

export interface ChangeAcceptedCategoriesAction
  extends Action<CONSTANTS.CHANGE_ACCEPTED_CATEGORIES> {
  acceptedCategories: string[]
}

export type SurveyAction =
  | SelectFiltersAction
  | ChangeProductCategoryAction
  | ChangeTrimKitSizeAction
  | AddInfoAction
  | ChangeIcemakerAddonAction
  | ChangeAcceptedCategoriesAction
