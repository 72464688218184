import React from 'react'
import { useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from '../custom-fdr-components'
import { useAdminContext } from 'src/hooks/use-admin-context'
import { api } from 'src/utils/api'
import { createdEndpoint } from './helpers'
import { saveAs } from 'file-saver'

interface IProps {
  className?: string
  label?: string
  disabledOption?: boolean
  onClick?: any
}

const DownloadButton: React.FC<IProps> = ({
  className = 'ml-4',
  label,
  disabledOption = true,
  onClick,
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const buttonLabel = label || localizedText('ADMIN_BUTTON_DOWNLOAD')
  const {
    isLoading,
    setIsLoading,
    adminFunction,
    vendorsAreLoading,
    selectedVendor,
    setError,
  } = useAdminContext()

  const onClickHandler = async () => {
    try {
      setError(null)
      const path = createdEndpoint(
        adminFunction.value === 'no_delivery'
          ? adminFunction.value.replace('_', '-')
          : adminFunction.value,
        selectedVendor.id
      )
      setIsLoading(true)
      const { data: downloadCSV, fileName } = await api.getAdminData(path)
      const file = new Blob([downloadCSV], { type: 'text/csv;charset=utf-8' })

      setIsLoading(false)
      saveAs(file, fileName)
    } catch (error: any) {
      setError(error.message)
      setIsLoading(false)
    }
  }

  return (
    <Button
      id="admin-download-button"
      className={className}
      loading={isLoading}
      size="medium"
      label={buttonLabel}
      onClick={onClick || onClickHandler}
      disabled={
        disabledOption
          ? selectedVendor.value === '' || vendorsAreLoading
          : false
      }
    />
  )
}

export default DownloadButton
