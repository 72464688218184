import React from 'react'
import { AdminProvider } from '../../hooks/use-admin-context'
import AdminForms from 'src/components/adminForms/admin-forms'

export const AdminScreen = () => {
  return (
    <AdminProvider>
      <AdminForms />
    </AdminProvider>
  )
}
