import { useGetVendors } from './use-get-vendors'
import { tenants } from 'src/utils/tenants'
import { states } from 'src/utils/states'
import { emptyItem } from 'src/components/adminForms/initials'
import { isValidGUID } from 'src/utils/validation-utils'

type FormOptions = 'billingState' | 'vendor' | 'tenantAbbreviation'

const usePaymentOptions = () => {
  const { getVendors } = useGetVendors()

  const getOptions = (key: FormOptions) => {
    switch (key) {
      case 'billingState':
        return states
      case 'vendor':
        return getVendors(['BH'])
      case 'tenantAbbreviation':
        return tenants
    }
  }
  const findTenant = (id: string) => {
    if (isValidGUID(id)) {
      if (id === 'ee312e77-8463-44bd-ad7e-2cd4e75c9e3d') {
        id = 'AHS'
      }
      if (id === '1dc12780-2d16-4c24-a781-3797221198e4') {
        id = 'HSA'
      }
      if (id === '53c5e9d5-3622-4ce4-92ce-40aabc1c6196') {
        id = 'PC'
      }
    }

    return (
      tenants.find((tenant) => tenant.id?.toUpperCase() === id.toUpperCase()) ||
      emptyItem
    )
  }
  const findTenantByLabel = (label: string) => {
    return (
      tenants.find(
        (tenant) => tenant.label?.toUpperCase() === label.toUpperCase()
      ) || emptyItem
    )
  }
  const findBillingState = (id: string) => {
    return states.find((state) => state.id === id) || emptyItem
  }
  const findVendor = (id: 'BH' | 'PC' | 'GE' | 'WP') => {
    const vendors = ['BH', 'PC', 'GE', 'WP'].filter((v) => v !== id)
    return getVendors(vendors)?.[0] || emptyItem
  }
  return {
    getOptions,
    findTenant,
    findBillingState,
    findTenantByLabel,
    findVendor,
  }
}

export default usePaymentOptions
