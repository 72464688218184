import { Reducer } from 'redux'
import {
  SelectedModelActions,
  SelectedModelStoreState,
  CONSTANTS,
} from './selected-model-store.types'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { StateAwareThunkDispatch } from 'src/utils/shared-types'

export const selectedModelInitialState: SelectedModelStoreState = {
  data: null,
}

type SelectedModelReducer = Reducer<
  SelectedModelStoreState,
  SelectedModelActions
>
export const selectedModelReducer: SelectedModelReducer = (
  state = selectedModelInitialState,
  action
): SelectedModelStoreState => {
  switch (action.type) {
    case CONSTANTS.SELECT_MODEL:
      return { ...state, data: action.model }

    case CONSTANTS.CLEAR_MODEL:
      return { ...state, data: null }

    default:
      return state
  }
}

export const selectModel = (model: appliancepb.IProduct) => (
  dispatch: StateAwareThunkDispatch
) => {
  dispatch({
    type: CONSTANTS.SELECT_MODEL,
    model,
  })
}

export const clearModel = () => (dispatch: StateAwareThunkDispatch) => {
  dispatch({
    type: CONSTANTS.CLEAR_MODEL,
  })
}
