import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'

export enum CONSTANTS {
  ADD_PRODUCT = 'ADD_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  CLAER_PRODUCTS = 'CLEAR_PRODUCTS',
  ADD_REPLACEMENT = 'ADD_REPLACEMENT',
  UPDATE_ATTRIBUTES = 'UPDATE_ATTRIBUTES',
}
export interface IAttributeGroups {
  [key: string]: string[]
}
export interface ProductStoreState {
  replacementId: string
  data: appliancepb.IProduct[]
  attributeGroups: IAttributeGroups
}

type GetProductAction = {
  type: CONSTANTS.ADD_PRODUCT
  product: appliancepb.IProduct
}

type CompareProductAction = { type: CONSTANTS.UPDATE_ATTRIBUTES }
type RemoveProductAction = { type: CONSTANTS.REMOVE_PRODUCT; productId: string }
type ClearProductAction = { type: CONSTANTS.CLAER_PRODUCTS }
type AddReplacementProductAction = {
  type: CONSTANTS.ADD_REPLACEMENT
  id: string
}

export type ProductActions =
  | GetProductAction
  | RemoveProductAction
  | ClearProductAction
  | AddReplacementProductAction
  | CompareProductAction
