import React, { PropsWithChildren, useLayoutEffect, useState } from 'react'
import { isAHS, isHSA } from 'src/utils/tenant-helper'
import { ProgressIndicatorComponent as ProgressIndicator } from '../custom-fdr-components'

interface IProps extends PropsWithChildren {}

const ThemeSelector: React.FC<IProps> = ({ children }) => {
  const [isCSSLoaded, setIsCSSLoaded] = useState<boolean>(false)
  const styles = async () =>
    isHSA()
      ? import('@ftdr/blueprint-core/themes/ahs-web-app.css')
      : import('@ftdr/blueprint-core/themes/ahs-2024.css')

  const globalStyles = async () =>
    isAHS() ? import('../../globalAHS.css') : import('../../globalHSA.css')

  const setStyles = async () => {
    await styles()
    await globalStyles()
    setIsCSSLoaded(true)
    return true
  }
  useLayoutEffect(() => {
    setStyles()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      {isCSSLoaded ? (
        children
      ) : (
        <div
          className="w-full flex items-center justify-center"
          style={{ height: '100vh' }}
        >
          <ProgressIndicator size="small" />
        </div>
      )}
    </>
  )
}

export default ThemeSelector
