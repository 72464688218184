import React, { createContext, Dispatch, useContext, useState } from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

type Props = {
  cartInfoModalOpen: boolean
  setCartInfoModalOpen: (isOpen: boolean) => void

  cartInfoModal: appliancepb.ICart[]
  setCartInfoModal: (data: appliancepb.ICart[]) => void

  recordExternalOrderModalOpen: boolean
  setRecordExternalOrderModalOpen: (isOpen: boolean) => void

  recordExternalOrderModal: appliancepb.IOutrightPaymentRecord
  setRecordExternalOrderModal: Dispatch<appliancepb.IOutrightPaymentRecord>
}
type TPaymentModalContext = {
  children?: React.ReactNode
}
const PaymentModalContext = createContext<Props>({} as Props)

const PaymentModalProvider: React.FC<TPaymentModalContext> = ({ children }) => {
  const [cartInfoModalOpen, setCartInfoModalOpen] = useState<boolean>(false)
  const [cartInfoModal, setCartInfoModal] = useState<appliancepb.ICart[]>([])
  const [recordExternalOrderModalOpen, setRecordExternalOrderModalOpen] = useState<boolean>(false)
  const [recordExternalOrderModal, setRecordExternalOrderModal] = useState<appliancepb.IOutrightPaymentRecord>({})

  return (
    <PaymentModalContext.Provider
      value={{
        // Cart Modal
        cartInfoModalOpen,
        setCartInfoModalOpen,

        // Cart Modal Details
        cartInfoModal,
        setCartInfoModal,

        // Record External Order

        recordExternalOrderModalOpen,
        setRecordExternalOrderModalOpen,

        // Record External Order Modal

        recordExternalOrderModal,
        setRecordExternalOrderModal,

      }}
    >
      {children}
    </PaymentModalContext.Provider>
  )
}

const usePaymentModalContext = () => useContext(PaymentModalContext)

export { PaymentModalProvider, usePaymentModalContext }
